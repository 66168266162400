import React, {useEffect} from 'react';
import { Button } from 'primereact/button';
import PublicLayout from '../layout/PublicLayout';
import bracelet from './images/ext.png';
import FeatureSection from './FeatureSection';
import Stats from './Stats';
import FrequentlyAskedQuestions from './Faqs';
import AOS from "aos";
import 'aos/dist/aos.css';
import About from './About';
import Graphs from './Graphs';
import Charts from './Graphs';
import hero from './images/hero.svg';
import graph from './images/graph-4.svg';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Home from './Home';



const LandingPage = (props) => {

  useEffect(() =>{
    AOS.init({duration : 3000})

  }, []);

  const fakeData = [
    {
      som: "RELIABILITY",
    },
    {
      som: "CONVINIENCE"
    },
    {
      som: "FLEXIBILITY"
    },
    {
      som: "MINIMIZED RISK"
    },
    {
      som: "MINIMIZED HUSTLE"
    },
    {
      som: "EFFICIENCY"
    },
    {
      som: "ROBUST"
    },
  ]

  const imageBodyTemplate = (rowData) => {
        return <icon className='pi pi-check'/>
    }


    const filterTemplateHeaderTemplate = (name) => {
          return (
              <Button label={name} className="p-button-outlined p-button-rounded" />

          );
      }

  return(
    <PublicLayout>


      <Home/>



      <div data-aos="zoom-in" style={{padding: '20px'}}>
        <div className="card" >
            <DataTable responsiveLayout="scroll" style={{textAlign: 'center'}} dataKey='som' value={fakeData} rows={15}>
                <Column
                  field="som"
                  style={{fontFamily: 'cursive'}}
                  className='text-primary'


                />
                <Column
                  header={filterTemplateHeaderTemplate("ACCESS TOP TALENT")}
                  body={imageBodyTemplate}
                  style={{fontFamily: 'cursive'}}
                  className='text-primary'
                />
                <Column
                  header={filterTemplateHeaderTemplate("FREELANCE WITH US")}
                  body={imageBodyTemplate}
                  style={{fontFamily: 'cursive'}}
                  className='text-primary'
                />
                <Column
                  header={filterTemplateHeaderTemplate("FIND A JOB")}
                  body={imageBodyTemplate}
                  style={{fontFamily: 'cursive'}}
                  className='text-primary'
                />
            </DataTable>
        </div>
      </div>
      <div style={{padding: '20px'}}>
        <FrequentlyAskedQuestions/>
      </div>




    </PublicLayout>
  );

}



export default LandingPage;

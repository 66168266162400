export const userInitialValues = {
    email: "",
    username: "",
    gender: "",
    first_name: "",
    middle_name: "",
    type: "jobseeker",
    last_name: "",
    title: "",
    country_of_citizenship: '',
    country_of_residence: '',
    province: '',
    district: '',
    description: '',
    availability: '',
    availability_category: '',
    phone_number: "",
    dob: "",
    whatsapp_number: '',
  

}


export const genderOptions = [
    {key: "MALE"},
    {key: "FEMALE"},
]



export const availabilityCategoryOptions = [
    {
        key: 'I AM CURRENTLY AVALIABLE FOR A JOB',
        value : 'I AM CURRENTLY AVALIABLE FOR A JOB'
    },
    {
        key: 'I AM LOOKING FOR A ONCE OFF TECHNICAL JOB FOR SERVICES I OFFER',
        value : 'I AM LOOKING FOR A ONCE OFF TECHNICAL JOB FOR SERVICES I OFFER'
    },
    {
        key: 'I AM AVALIABLE FOR A PARTTIME JOB',
        value : 'I AM AVALIABLE FOR A PARTTIME JOB'
    },
    {
        key: 'I AM OPEN FOR OFFERS',
        value : 'I AM OPEN FOR OFFERS'
    },
    {
        key: 'I AM JUST LOOKING FOR INTERNATIONAL OFFERS',
        value : 'I AM JUST LOOKING FOR INTERNATIONAL OFFERS'
    },
    {
        key: 'I AM AVAILABLE FOR A REMOTE JOB',
        value : 'I AM AVAILABLE FOR A REMOTE JOB'
    },
    {
        key: 'I AM OPEN TO ANYTHING AS LONG AS WITHIN MY AREA OF EXPERTISE',
        value : 'I AM OPEN TO ANYTHING AS LONG AS WITHIN MY AREA OF EXPERTISE'
    },

]

export const availabilityOptions = [
    {
        key: 'I AM CURRENTLY AVALIABLE FOR AN INTERVIEW',
        value: 'I AM CURRENTLY AVALIABLE FOR AN INTERVIEW'
    },
    {
        key: 'I AM CURRENTLY UNAVALIABLE FOR AN INTERVIEW',
        value: 'I AM CURRENTLY UNAVALIABLE FOR AN INTERVIEW'
    },
]

export const userTitleOptions = [
    {key: 'SIR'},
    {key: 'MISS'},
    {key: 'MR'},
    {key: 'MRS'},
    {key: 'Doctor'},
    {key: 'Proffesor'},
]
import React, { useState, useEffect, useRef } from 'react';
import {getDailyJobReports} from '../../actions/reports';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import {connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Skeleton } from 'primereact/skeleton';
import {getCountries} from '../../actions/places';







const DailyJobReports = props => {
  const initialParams = {
    date: '',
    country: '',
  }
  const [params, setParams] = useState(initialParams);
  const dt = useRef(null);
  
  const cols = [
      { field: 'title', header: 'TITLE' },
      { field: 'applications', header: 'APPLICATIONS' },
      { field: 'pending_decision_applications', header: 'PENDING DECISION APPLICATIONS' },
      { field: 'on_interview_applications', header: 'ON INTERVIEW APPLICATIONS' },
      { field: 'accepted_applications', header: 'ACCEPTED APPLICATIONS' },
      { field: 'rejected_applications', header: 'REJECTED APPLICATIONS' },
      { field: 'fixed_price', header: 'FIXED PRICE' },
      { field: 'from_hourly_price', header: 'FROM HOURLY PRICE' },
      { field: 'to_hourly_price', header: 'TO HOURLY PRICE' },
  ];
  // const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
  const {report} =props;
  const {jobs} = report;

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  }

  const exportPdf = () => {
      alert('Fuck you')
  }

  const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(jobs);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'jobs');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }




    const skeletonData = [
        {"key": 1},
        {"key": 2},
        {"key": 3},
        {"key": 4},
        {"key": 5},
        {"key": 6},
        {"key": 7},
        {"key": 8},
        {"key": 9},
        {"key": 10},
        {"key": 11},
        {"key": 12},
        {"key": 13},
        {"key": 14},
        {"key": 15},
        {"key": 16},
    ]



  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _params = {...params};
      _params[`${name}`] = val;
      setParams(_params);
  }


  useEffect(() => {
    props.getDailyJobReports(params, props.token);
    props.getCountries(props.token);
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    props.getDailyJobReports(params, props.token);
    props.getCountries(props.token);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const monthNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
          />
        );
    };

    const yearNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
          />
      )
    }


    const header = (
      <div className="flex align-items-center export-buttons">
          <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
          <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
          <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
          <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" />
      </div>
    );

    let footerGroup = <ColumnGroup>
        <Row>
          <Column footer="FIXED PRICE TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.fixed_prices_total} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="FROM HOURLY PRICE TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.from_hourly_prices_total} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TO HOURLY PRICE TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.to_hourly_prices_total} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="FIXED PRICE JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.fixed_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="HOURLY PRICE JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.per_hour_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="PERMANENT JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.permanent_jobs_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="CONTRACTUAL JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.contractual_jobs_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="INTERNSHIP JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.internship_jobs_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TEMPORARY JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.temporary_jobs_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="PUBLISHED JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.published_jobs_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="UNPUBLISHED JOBS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.unpublished_jobs_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="JOB APPLICATIONS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.job_applications_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="PERMANENT JOB APPLICATIONS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.permanent_jobs_applications_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="CONTRACTUAL JOB APPLICATIONS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.contractual_jobs_applications_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="INTERNSHIP JOB APPLICATIONS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.internship_jobs_applications_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TEMPORARY JOB APPLICATIONS TOTAL" colSpan={8} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.temporary_jobs_applications_num} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>

    </ColumnGroup>;

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>
  }


  return(
    <>
      <div className="card">
            <ul  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none p-fluid grid formgridid p-0 m-0">
              <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                  <div
                      className="col-12  md:w-2 md:flex-order-0 flex-order-1"
                      style={{fontSize: '15px', fontFamily: 'cursive'}}
                  >
                      DATE
                  </div>
                  <div className=" col-12 md:w-4 md:flex-order-0 flex-order-1">
                    <Calendar
                        id="navigatorstemplate"
                        showIcon
                        value={params.date}
                        onChange={(e) => onInputChange(e, 'date')}
                        monthNavigator
                        yearNavigator
                        yearRange="2010:2030"
                        format='dd:mm:yy'
                        monthNavigatorTemplate={monthNavigatorTemplate}
                        yearNavigatorTemplate={yearNavigatorTemplate}
                    />
                  </div>
                  <div
                      className="col-12  md:w-2 md:flex-order-0 flex-order-1"
                      style={{fontSize: '15px', fontFamily: 'cursive'}}
                  >
                      COUNTRY
                  </div>
                  <div className=" col-12 md:w-4 md:flex-order-0 flex-order-1">
                      <Dropdown
                        value={params.country}
                        optionLabel="name"
                        optionValue="name"
                        options={props.countries}
                        onChange={(e) => onInputChange(e, 'country')}
                        filter
                        filterBy="name"
                        showClear
                    /> 
                  </div>
              </li>
            </ul>
      </div>
      {props.loading
          ?<DataTable value={skeletonData} className="p-datatable-striped">
            <Column field="code"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="name"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="category"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
          </DataTable>
          :<div className="card" >
            <h5>Export</h5>

            <Tooltip target=".export-buttons>button" position="bottom" />

            <DataTable 
              ref={dt} 
              value={jobs} 
              header={header} 
              footerColumnGroup={footerGroup}
              emptyMessage='No Jobs for this Day'
              dataKey="slug" 
              showGridlines
              responsiveLayout="scroll"
                selectionMode="multiple" 
            >
                {
                    cols.map((col, index) => <Column style={{fontFamily: 'cursive', textAlign: 'center'}} key={index} field={col.field} header={col.header} />)
                }
            </DataTable>
          </div>
      }

    </>
  );

};



const mapStateToProps = state =>({
  report: state.reports.dailyJobReports,
  loading: state.reports.loading,
  token: state.auth.token,
  countries: state.places.countries,
})

export default connect(mapStateToProps, {getDailyJobReports, getCountries})(DailyJobReports);

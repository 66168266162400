import { updateObject } from "./utility";
import {
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FAIL,
  AUTH_START,
  USER_LOADED,
  USER_LOADING,
  FORGOT_START,
  FORGOT_SUCCESS,
  FORGOT_FAIL,
  RESET_START,
  RESET_SUCCESS,
  RESET_FAIL,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USER_START,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  CREATE_JOBSEEKER_START,
  CREATE_JOBSEEKER_SUCCESS,
  CREATE_JOBSEEKER_FAIL,
  CREATE_EMPLOYER_START,
  CREATE_EMPLOYER_SUCCESS,
  CREATE_EMPLOYER_FAIL,
  CREATE_FREELANCER_START,
  CREATE_FREELANCER_SUCCESS,
  CREATE_FREELANCER_FAIL,
  ACTIVATE_USER_START,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAIL,

} from '../types/authTypes';
import {UPDATE_USER} from '../types/userTypes';






const initialState = {
  token: null,
  email: null,
  userRole: null,
  userName: null,
  firstName: null,
  lastName: null,
  description: null,
  error: null,
  loading: false,
  user: {},
  isAuthenticated:null,
  isLoading: null,
  person: {},
  people: [],
  usernames: [],
  emails: [],
  phoneNumbers: [],
  verified:{},
  msg:{},
  forgotReaction: {},
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.user.token,
    email: action.user.email,
    userRole: action.user.userRole,
    userName: action.user.userName,
    firstName: action.user.firstName,
    lastName: action.user.lastName,
    description: action.user.description,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null
  });
};



export const forgotStart = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const forgotSuccess = (state, action) => {
  return updateObject(state, {
    error: action.error,
    msg: action.msg,
    loading: false
  });
};


export const forgotFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


export const resetStart = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const resetSuccess = (state, action) => {
  return updateObject(state, {
    msg: action.msg,
    error: action.error,
    loading: false,
  });
};


export const resetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



export const getUserListStart = (state, action)  => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const getUserListSuccess = (state, action)  => {
  return updateObject(state, {
    people: action.people,
    error: action.error,
    loading: false
  });
};


export const getUserListFail = (state, action)  => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


export const getUserDetailStart = (state, action)  => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

export const getUserDetailSuccess = (state, action)  => {
  return updateObject(state, {
    person: action.person,
    error: action.error,
    loading: false
  });
};


export const getUserDetailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const activateUserStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const activateUserSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    token: action.user.token,
    email: action.user.email,
    userRole: action.user.userRole,
    userName: action.user.userName,
    user: action.user,
    verified: action.user.verified,
  });
};


const activateUserFail = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false
  });
};


export const createEmployerStart = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: true,
  });
};

export const createEmployerSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    token: action.user.token,
    email: action.user.email,
    userRole: action.user.userRole,
    userName: action.user.userName,
    verified: action.user.verified,
  });
};


export const createEmployerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


export const createJobseekerStart = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: true,
  });
};

export const createJobseekerSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    token: action.user.token,
    email: action.user.email,
    userRole: action.user.userRole,
    userName: action.user.userName,
    verified: action.user.verified,
  });
};


export const createJobseekerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


export const createFreelancerStart = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: true,
  });
};

export const createFreelancerSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false,
    token: action.user.token,
    email: action.user.email,
    userRole: action.user.userRole,
    userName: action.user.userName,
    verified: action.user.verified,
  });
};


export const createFreelancerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};





const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_JOBSEEKER_START:
      return createJobseekerStart(state, action);
    case CREATE_JOBSEEKER_SUCCESS:
      return createJobseekerSuccess(state, action);
    case CREATE_JOBSEEKER_FAIL:
      return createJobseekerFail(state, action);
    case CREATE_EMPLOYER_START:
      return createEmployerStart(state, action);
    case CREATE_EMPLOYER_SUCCESS:
      return createEmployerSuccess(state, action);
    case CREATE_EMPLOYER_FAIL:
      return createEmployerFail(state, action);
    case CREATE_FREELANCER_START:
      return createFreelancerStart(state, action);
    case CREATE_FREELANCER_SUCCESS:
      return createFreelancerSuccess(state, action);
    case CREATE_FREELANCER_FAIL:
      return createFreelancerFail(state, action);
    case ACTIVATE_USER_START:
      return activateUserStart(state, action);
    case ACTIVATE_USER_SUCCESS:
      return activateUserSuccess(state, action);
    case ACTIVATE_USER_FAIL:
      return activateUserFail(state, action);
    case AUTH_START:
      return authStart(state, action);
    case AUTH_SUCCESS:
      return authSuccess(state, action);
    case AUTH_FAIL:
      return authFail(state, action);
    case AUTH_LOGOUT:
      return authLogout(state, action);
    case FORGOT_START:
      return forgotStart(state, action);
    case FORGOT_SUCCESS:
      return forgotSuccess(state, action);
    case FORGOT_FAIL:
      return forgotFail(state, action);
    case RESET_START:
      return resetStart(state, action);
    case RESET_SUCCESS:
      return resetSuccess(state, action);
    case RESET_FAIL:
      return resetFail(state, action);
    case GET_USERS_START:
      return getUserListStart(state, action);
    case GET_USERS_SUCCESS:
      return getUserListSuccess(state, action);
    case GET_USERS_FAIL:
      return getUserListFail(state, action);
    case GET_USER_START:
      return getUserDetailStart(state, action);
    case GET_USER_SUCCESS:
      return getUserDetailSuccess(state, action);
    case GET_USER_FAIL:
      return getUserDetailFail(state, action);
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
      case UPDATE_USER:
        const peopleArray = state.people;
        peopleArray.splice(
                peopleArray.findIndex(item => item.slug === action.payload.data.slug),
                1,
                action.payload.data
              );
        return {
          ...state,
          people:peopleArray,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

import React, {useEffect, useState} from 'react';
import ManagementLayout from '../layout/ManagementLayout';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Column } from 'primereact/column';
import {
    getManagementJob,
    getJobSatisfactionQuestions, 
    publishManagementJob,
    getJobSkills,
    getJobQualifications,
    editJobQualification,
    addJobQualification,
    deleteJobQualification,
    editJobSkill,
    addJobSkill,
    deleteJobSkill,
    getJobLevels,
    addJobLevel,
    editJobLevel,
    deleteJobLevel,

} from '../../actions/jobs';
import {
    getCountries,
    getProvinces,
    getDistricts

} from '../../actions/places';
import { getUsers } from '../../actions/auth';
import {editManagementJob} from '../../actions/jobs';
import { connect } from 'react-redux';
import { Chip } from 'primereact/chip';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { useNavigate } from 'react-router-dom';
import {
    chargeCategoryOptions,
    visibilityStatusOptions,
    jobTypeOptions,
    jobFishingExpectedTimelineOptions,
    jobDurationOptions,
    prefferedSkillsetOptions,
    geographicalLocationOptions,
    locationOptions,
    compensationOptions,
    initialValues,
    skillValues,
    competencyOptions,
    qualificationValues,
    levelValues,
    levelOptions,

} from './data';
import {getSkills, getQualifications}from '../../actions/skills';
import JobDialog from './dialogs/JobDialog';
import { getFaculties} from '../../actions/skills';





const Job = (props) => {
    const {
        token,
        getManagementJob,
        getJobSatisfactionQuestions,
        job,
        questions,
    } = props;
    let { slug } = useParams();
    const [record, setRecord] = useState(initialValues);
    const [skillRecord, setSkillRecord] = useState(skillValues);
    const [qualificationRecord, setQualificationRecord] = useState(qualificationValues);
    const [levelRecord, setLevelRecord] = useState(levelValues);
    const employerOptions = props.users.filter((user) => user.type ==="employer")
    const navigate = useNavigate()
    const [jobDialog, setJobDialog] = useState(false);
    const [skillDialog, setSkillDialog] = useState(false);
    const [isSkillSubmit, setIsSkillSubmit] = useState(false);
    const [qualificationDialog, setQualificationDialog] = useState(false);
    const [isQualificationSubmit, setIsQualificationSubmit] = useState(false);
    const [isLevelSubmit, setIsLevelSubmit] = useState(false);
    const [levelDialog, setLevelDialog] = useState(false);
    const [skillErrors, setSkillErrors] = useState({});
    const [qualificationErrors, setQualificationErrors] = useState({});
    const [levelErrors, setLevelErrors] = useState({});


    const resetForm = () => {
        setRecord(initialValues);
    }


    const editJob = (record) => {
        setRecord({...record});
        setJobDialog(true);
    }

    const hideJobDialog = () => {
        setJobDialog(false);
    }

    const editSkill = (record) => {
        setSkillRecord({...record});
        setSkillDialog(true);
    }

    const hideSkillDialog = () => {
        setSkillDialog(false);
        setSkillRecord(skillValues);
    }

    const openSkillDialog = () => {
        setSkillDialog(true);
    }


    const editQualification = (record) => {
        setQualificationRecord({...record});
        console.log(record)
        setQualificationDialog(true);
    }

    const hideQualificationDialog = () => {
        setQualificationDialog(false);
        setQualificationRecord(qualificationValues);
    }

    const openQualificationDialog = () => {
        setQualificationDialog(true);
    }

    const editLevel = (record) => {
        setLevelRecord({...record});
        setLevelDialog(true);
    }

    const hideLevelDialog = () => {
        setLevelDialog(false);
        setLevelRecord(levelValues);
    }

    const openLevelDialog = () => {
        setLevelDialog(true);
    }



    useEffect(() =>{
        if(!props.fetched){
            getManagementJob(slug, token);
            getJobSatisfactionQuestions(slug, token);
            props.getJobSkills(slug, token);
            props.getJobQualifications(slug, token);
            props.getJobLevels(slug, token);
            props.getCountries(props.token);
            props.getProvinces(props.token);
            props.getDistricts(props.token);
            props.getUsers(props.token);
            props.getSkills(props.token);
            props.getQualifications(props.token);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{

        getManagementJob(slug, token);
        getJobSatisfactionQuestions(slug, token);
        props.getJobSkills(slug, token);
        props.getJobQualifications(slug, token);
        props.getCountries(props.token);
        props.getProvinces(props.token);
        props.getDistricts(props.token);
        props.getUsers(props.token);
        props.getSkills(props.token);
        props.getFaculties(props.token);
        props.getJobLevels(slug, token);
        setIsSkillSubmit(false);
        setIsQualificationSubmit(false);
        setIsLevelSubmit(false);


    }, [isSkillSubmit, isQualificationSubmit, isLevelSubmit]) // eslint-disable-line react-hooks/exhaustive-deps


    const validateSkill = (fieldValues = skillRecord) => {
        let temp = { ...skillErrors }
        if ('skill' in fieldValues)
            temp.skill= fieldValues.skill? "" : "Skill is Required"
        if ('competency' in fieldValues)
            temp.competency= fieldValues.competency? "" : "Competency is Required"
        if ('years_of_experience' in fieldValues)
            temp.years_of_experience= fieldValues.years_of_experience? "" : "Years of Experience is Required"
        setSkillErrors({
            ...temp
        })

        if (fieldValues === skillRecord)
            return Object.values(temp).every(x => x === "")
      }


      const validateQualification = (fieldValues = qualificationRecord) => {
          let temp = { ...qualificationErrors }
          if ('qualification' in fieldValues)
              temp.qualification= fieldValues.qualification? "" : "Qualification is Required"

          setQualificationErrors({
              ...temp
          })

          if (fieldValues === qualificationRecord)
              return Object.values(temp).every(x => x === "")
        }


        const validateLevel = (fieldValues = levelRecord) => {
            let temp = { ...levelErrors }
            if ('name' in fieldValues)
                temp.name= fieldValues.name? "" : "Level is Required"

            setLevelErrors({
                ...temp
            })

            if (fieldValues === levelRecord)
                return Object.values(temp).every(x => x === "")
        }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }


    const onSkillInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...skillRecord};
        _record[`${name}`] = val;
        setSkillRecord(_record);
    }

    const onQualificationInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...qualificationRecord};
        _record[`${name}`] = val;
        setQualificationRecord(_record);
    }

    const onLevelInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...levelRecord};
        _record[`${name}`] = val;
        setLevelRecord(_record);
    }


    const skillActionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-text p-button-warning mr-2"
                    onClick={() => editSkill(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-text p-button-danger"
                />
            </React.Fragment>
        );
    }

    const skillToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-outlined p-button-success mr-2"
                    onClick={openSkillDialog}
                />

            </React.Fragment>
        )
    }

    const skillRightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='SKILLS'
                    className=" p-button-text p-button-success mr-2"
                />

            </React.Fragment>
        )
    }



    const qualificationActionTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className=" p-button-text p-button-success "
                    onClick={() => editQualification(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-text p-button-danger"
                />
            </React.Fragment>
        );
    }

    const qualificationToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-outlined p-button-success"
                    onClick={openQualificationDialog}
                />

            </React.Fragment>
        )
    }

    const qualificationRightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    style={{fontSize: '15px'}}
                    label='QUALIFICATIONS USED FOR MATCHING SUITABLE CANDIDATES'
                    className=" p-button-text p-button-success"
                />

            </React.Fragment>
        )
    }





    const levelToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-outlined p-button-success mr-2"
                    onClick={openLevelDialog}
                />

            </React.Fragment>
        )
    }


    const levelRightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    style={{fontSize: '11px'}}
                    label='CERTIFICATE LEVELS ASSOCIATED WITH ACADEMIC QUALIFICATIONS FOR MATCHING CANDIDATES'
                    className=" p-button-text p-button-success"
                />

            </React.Fragment>
        )
    }


    const handleSkillSubmit = (e) => {
        e.preventDefault();
        if (skillRecord.slug){
            if (validateSkill()){
                props.editJobSkill(skillRecord.slug, skillRecord, props.token);
                setIsSkillSubmit(true);
                hideSkillDialog();
              }
        }else{
          if (validateSkill()){
              console.log(skillRecord)
              console.log(skillErrors)
              let _record = {...skillRecord}
              _record.job = slug
              console.log(_record)
              props.addJobSkill(_record, props.token);
              setIsSkillSubmit(true);
              hideSkillDialog();
          }
        }


    }


    const handleQualificationSubmit = (e) => {
        e.preventDefault();
        if (qualificationRecord.slug){
          if (validateQualification()){
              props.editJobQualification(qualificationRecord.slug, qualificationRecord, props.token);
              setIsQualificationSubmit(true);
              hideQualificationDialog();
          }
        }else{
          if (validateQualification()){
              let _record = {...qualificationRecord}
              _record.job = slug
              console.log(_record)
              props.addJobQualification(_record, props.token);
              setIsQualificationSubmit(true);
              hideQualificationDialog();
            }
        }


    }


    const handleLevelSubmit = (e) => {
        e.preventDefault();
        if (levelRecord.slug){
          if(validateLevel()){
              props.editJobLevel(levelRecord.slug, levelRecord, props.token);
              setIsLevelSubmit(true);
              hideLevelDialog();
          }
        }else{
          if(validateLevel()){
              let _record = {...levelRecord}
              _record.job = slug
              console.log(_record)
              props.addJobLevel(_record, props.token);
              setIsLevelSubmit(true);
              hideLevelDialog();
            }
        }
    }



    const publishJob = (slug, data, token) => {
        props.publishManagementJob(slug, data, token);
        navigate("/management/unpublished-jobs", {replace: true})
    };



    return (
        <ManagementLayout>
            <div className="card">

                <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">

                    <div className="mt-5 lg:mt-0">
                      <Button
                          label="EDIT"
                          className="p-button-text p-button-warning"
                          icon="pi pi-pencil"
                          onClick={() => editJob(job)}
                      />

                    </div>
                    <div className="mt-5 lg:mt-0">
                      <Button
                          label="PUBLISH"
                          className="p-button-text p-button-success"
                          icon="pi pi-check"
                          onClick={() => publishJob(job.slug, job, props.token)}
                      />

                    </div>
                    <div className="mt-5 lg:mt-0">
                      <Button
                          label="DELETE"
                          className="p-button-text p-button-danger"
                          icon="pi pi-trash"
                      />
                    </div>
                </div>
            </div>
            <div className='card' style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className="font-medium text-3xl  mb-3">JOB INFORMATION</div>
                <div className=" mb-5">{job.title}</div>
                <ul className="list-none p-0 m-0">
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            DATE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.created_at} icon='pi pi-calendar'   />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            EMPLOYER
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.employer} icon='pi pi-user'   />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            DURATION EXPECTATION
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.expected_duration} icon='pi pi-calendar'   />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            JOB TYPE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.category} icon="pi pi-check"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            REQUIRED SKILLS
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.required_skillset} icon="pi pi-check"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            LOCATION
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.location_type} icon="pi pi-flag-fill"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            JOB TYPE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.location} icon="pi pi-flag-fill"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            PAYMENT TYPE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.charge_category}  />
                        </div>

                    </li>
                    {job.charge_category === "FIXED"
                        ?<li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                                ESTIMATED BUDGET
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                    <Chip icon="pi pi-dollar"  label={`${job.fixed_price}`}  />
                            </div>

                        </li>
                        :<li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                                ESTIMATED BUDGET
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                    <Chip label={`Estimated Hourly Budget: $${job.from_hourly_price} - $${job.to_hourly_price}`}  />
                            </div>

                        </li>

                    }

                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            DESCRIPTION
                            </div>
                            <div style={{overflowWrap: 'break-word'}} className="col-12 md:w-12 font-medium">
                                {job.description}
                        </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            HOW TO APPLY
                            </div>
                            <div style={{overflowWrap: 'break-word'}} className="col-12 md:w-12 font-medium">
                                {job.how_to_apply}
                        </div>

                    </li>
                </ul>
            </div>
            <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                    <div className="col-12 md:w-12 font-medium">QUESTIONS ASKED</div>

                    <ul className="list-none p-0 m-0 card">


                        {questions.map((question) =>{
                            return(
                                <>
                                    <li key={question.slug} className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div className=" col-12 md:w-12 font-medium">
                                            <Chip label={question.question} className="mr-2"></Chip>
                                        </div>
                                    </li>

                                </>

                            );

                        })

                    }

                </ul>
            </div>
            <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className=" col-12 md:w-12 font-medium">REQUIRED QUALIFICATIONS</div>

                <div className="card">
                    <div className="grid fluid">
                        {props.qualifications.map((qual) =>{
                            return(
                                <div key={qual.slug} className="col-12 flex-grow-1 md:col-4 card border-50 box border-round shadow-8" style={{padding: '20px'}}>
                                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className=" col-12 md:w-12 font-medium">
                                                QUALIFICATION
                                            </div>
                                            <div className=" col-12 md:w-12 font-medium">
                                                <Chip label={qual.qualification}  className="mr-2 mb-2  " />
                                            </div>
                                        </li>

                                    </ul>

                                    <div className='grid p-fluid'>
                                        <div className="col-12 md:col-2">
                                            <Button
                                                className="p-button-text p-button-warning"
                                                icon="pi pi-pencil"
                                                onClick={()=> editQualification(qual)}
                                            />
                                        </div>
                                        <div className="col-12 md:col-8">
                                        </div>
                                        <div className="col-12 md:col-2">
                                            <Button
                                                icon="pi pi-trash"
                                                className="p-button-text p-button-danger"

                                            />
                                        </div>

                                    </div>

                                </div>

                            )
                        })


                    }
                    </div>
                </div>
            </div>
            <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className=" col-12 md:w-12 font-medium">QUALIFICATION LEVELS</div>

                <div className="card">
                    <div className="grid fluid">
                        {props.levels.map((level) =>{
                            return(
                                <div key={level.slug} className="col-12 flex-grow-1 md:col-4 card border-50 box border-round shadow-8" style={{padding: '20px'}}>
                                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className=" col-12 md:w-12 font-medium">
                                                LEVEL
                                            </div>
                                            <div className=" col-12 md:w-12 font-medium">
                                                <Chip label={level.name}  className="mr-2 mb-2  " />
                                            </div>
                                        </li>

                                    </ul>

                                    <div className='grid p-fluid'>
                                        <div className="col-12 md:col-2">
                                            <Button
                                                className="p-button-text p-button-warning"
                                                icon="pi pi-pencil"
                                                onClick={() => editLevel(level)}
                                            />
                                        </div>
                                        <div className="col-12 md:col-8">
                                        </div>
                                        <div className="col-12 md:col-2">
                                            <Button
                                                icon="pi pi-trash"
                                                className="p-button-text p-button-danger"

                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })

                    }
                    </div>
                </div>
            </div>
            <Dialog
                header="EDIT FORM"
                maximizable
                modal
                style={{ width: '80%' }}
                visible={jobDialog}
                className="p-fluid"
                onHide={hideJobDialog}
                >
                    <JobDialog
                        editManagementJob={props.editManagementJob}
                        record={record}
                        employerOptions={employerOptions}
                        onInputChange={onInputChange}
                        setRecord={setRecord}
                        token={token}
                    />
            </Dialog>
            <Dialog
                header="QUALIFICATION FORM"
                maximizable
                modal
                style={{ width: '40%' }}
                visible={qualificationDialog}
                className="p-fluid"
                onHide={hideQualificationDialog}
            >
              <div className="card">
                  <form onSubmit={handleQualificationSubmit}>
                   <ul  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                          <div
                              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                              style={{fontSize: '15px', fontFamily: 'cursive'}}
                          >
                              QUALIFICATION
                          </div>
                          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                              <Dropdown
                                  optionLabel="title"
                                  optionValue="title"
                                  value={qualificationRecord.qualification}
                                  onChange={(e) => onQualificationInputChange(e, 'qualification')}
                                  options={props.qualificationOptions}
                                  filter
                                  showClear
                                  filterBy="title"
                              />
                              <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                              {qualificationErrors.qualification}
                              </small>
                          </div>
                      </li>
                      <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                          <Button
                              type="submit"
                              label="Submit"
                              className="p-button-outlined p-button-warning"

                          />
                      </li>
                  </ul>
                  </form>
              </div>


            </Dialog>

            <Dialog
                header="LEVEL FORM"
                maximizable
                modal
                style={{ width: '40%' }}
                visible={levelDialog}
                className="p-fluid"
                onHide={hideLevelDialog}
              >
              <div className="card">
                <form onSubmit={handleLevelSubmit}>
                  <ul style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                          <div
                              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                              style={{fontSize: '15px', fontFamily: 'cursive'}}
                          >
                              NAME
                          </div>
                          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                              <Dropdown
                                  optionLabel="name"
                                  optionValue="name"
                                  value={levelRecord.name}
                                  onChange={(e) => onLevelInputChange(e, 'name')}
                                  options={levelOptions}
                                  filter
                                  showClear
                                  filterBy="name"
                              />
                              <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                {levelErrors.name}
                              </small>
                          </div>
                      </li>


                      <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                              <Button
                                  type="submit"
                                  label="Submit"
                                  className="p-button-outlined p-button-warning"

                              />
                      </li>
                  </ul>
                </form>
              </div>
            </Dialog>

        </ManagementLayout>
    );
};




const mapStateToProps = state => ({
    job: state.jobs.managementjob,
    questions: state.jobs.jobsatisfactionquestions,
    skills: state.jobs.jobskills,
    qualifications: state.jobs.jobqualifications,
    levels: state.jobs.joblevels,
    token: state.auth.token,
    countries: state.places.countries,
    provinces: state.places.provinces,
    districts: state.places.districts,
    users: state.auth.people,
    skillOptions: state.skills.skills,
    qualificationOptions: state.skills.faculties,

  });



export default connect(
    mapStateToProps,
    {
        getManagementJob,
        getJobSatisfactionQuestions,
        getCountries,
        getProvinces,
        getDistricts,
        getUsers,
        editManagementJob,
        publishManagementJob,
        getJobSkills,
        getJobQualifications,
        getSkills,
        editJobQualification,
        addJobQualification,
        deleteJobQualification,
        editJobSkill,
        addJobSkill,
        deleteJobSkill,
        getQualifications,
        getJobLevels,
        addJobLevel,
        editJobLevel,
        deleteJobLevel,
        getFaculties,

    }
  )(Job);

import axios from 'axios';
import {
    matchedUserPortfoliosURL,
    matchedUserServicesURL,
    matchedUserObjectivesURL,
    matchedUserAcademicQualificationsURL,
    matchedUserLanguagesURL,
    matchedUserLinksURL,
    matchedUserExperiencesURL,
    matchedUserSkillsURL,
    usersMatchedByJobURL,
    usersMatchedByIndustryURL,
    matchedUserProfileURL,
} from '../constants';
import {
    GET_MATCHED_USER_PORTFOLIOS_START,
    GET_MATCHED_USER_PORTFOLIOS_SUCCESS,
    GET_MATCHED_USER_PORTFOLIOS_FAIL,
    GET_MATCHED_USER_SERVICES_START,
    GET_MATCHED_USER_SERVICES_SUCCESS,
    GET_MATCHED_USER_SERVICES_FAIL,
    GET_MATCHED_USER_OBJECTIVES_START,
    GET_MATCHED_USER_OBJECTIVES_SUCCESS,
    GET_MATCHED_USER_OBJECTIVES_FAIL,
    GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_START,
    GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_SUCCESS,
    GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_FAIL,
    GET_MATCHED_USER_LANGUAGES_START,
    GET_MATCHED_USER_LANGUAGES_SUCCESS,
    GET_MATCHED_USER_LANGUAGES_FAIL,
    GET_MATCHED_USER_LINKS_START,
    GET_MATCHED_USER_LINKS_SUCCESS,
    GET_MATCHED_USER_LINKS_FAIL,
    GET_MATCHED_USER_EXPERIENCES_START,
    GET_MATCHED_USER_EXPERIENCES_SUCCESS,
    GET_MATCHED_USER_EXPERIENCES_FAIL,
    GET_MATCHED_USER_SKILLS_START,
    GET_MATCHED_USER_SKILLS_SUCCESS,
    GET_MATCHED_USER_SKILLS_FAIL,
    GET_USERS_MATCHED_BY_JOB_START,
    GET_USERS_MATCHED_BY_JOB_SUCCESS,
    GET_USERS_MATCHED_BY_JOB_FAIL,
    GET_USERS_MATCHED_BY_INDUSTRY_START,
    GET_USERS_MATCHED_BY_INDUSTRY_SUCCESS,
    GET_USERS_MATCHED_BY_INDUSTRY_FAIL,
    GET_MATCHED_USER_PROFILE_START,
    GET_MATCHED_USER_PROFILE_SUCCESS,
    GET_MATCHED_USER_PROFILE_FAIL,

} from '../types/matchTypes';


const getMatchedUserPortfoliosStart = () => {
    return {
      type: GET_MATCHED_USER_PORTFOLIOS_START
    };
  };

const getMatchedUserPortfoliosSuccess = matchedUserPortfolios => {
    return {
      type: GET_MATCHED_USER_PORTFOLIOS_SUCCESS,
      matchedUserPortfolios
    };
};

const getMatchedUserPortfoliosFail = error => {
    return {
      type: GET_MATCHED_USER_PORTFOLIOS_FAIL,
      error: error
    };
};


const getMatchedUserServicesStart = () => {
    return {
      type: GET_MATCHED_USER_SERVICES_START
    };
  };

const getMatchedUserServicesSuccess = matchedUserServices => {
    return {
      type: GET_MATCHED_USER_SERVICES_SUCCESS,
      matchedUserServices
    };
};

const getMatchedUserServicesFail = error => {
    return {
      type: GET_MATCHED_USER_SERVICES_FAIL,
      error: error
    };
};

const getMatchedUserObjectivesStart = () => {
    return {
      type: GET_MATCHED_USER_OBJECTIVES_START
    };
  };

const getMatchedUserObjectivesSuccess = matchedUserObjectives => {
    return {
      type: GET_MATCHED_USER_OBJECTIVES_SUCCESS,
      matchedUserObjectives
    };
};

const getMatchedUserObjectivesFail = error => {
    return {
      type: GET_MATCHED_USER_OBJECTIVES_FAIL,
      error: error
    };
};


const getMatchedUserAcademicQualificationsStart = () => {
    return {
      type: GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_START
    };
  };

const getMatchedUserAcademicQualificationsSuccess = matchedUserAcademicQualifications => {
    return {
      type: GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_SUCCESS,
      matchedUserAcademicQualifications
    };
};

const getMatchedUserAcademicQualificationsFail = error => {
    return {
      type: GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_FAIL,
      error: error
    };
};


const getMatchedUserLanguagesStart = () => {
    return {
      type: GET_MATCHED_USER_LANGUAGES_START
    };
  };

const getMatchedUserLanguagesSuccess = matchedUserLanguages => {
    return {
      type: GET_MATCHED_USER_LANGUAGES_SUCCESS,
      matchedUserLanguages
    };
};

const getMatchedUserLanguagesFail = error => {
    return {
      type: GET_MATCHED_USER_LANGUAGES_FAIL,
      error: error
    };
};


const getMatchedUserLinksStart = () => {
    return {
      type: GET_MATCHED_USER_LINKS_START
    };
  };

const getMatchedUserLinksSuccess = matchedUserLinks => {
    return {
      type: GET_MATCHED_USER_LINKS_SUCCESS,
      matchedUserLinks
    };
};

const getMatchedUserLinksFail = error => {
    return {
      type: GET_MATCHED_USER_LINKS_FAIL,
      error: error
    };
};


const getMatchedUserExperiencesStart = () => {
    return {
      type: GET_MATCHED_USER_EXPERIENCES_START
    };
  };

const getMatchedUserExperiencesSuccess = matchedUserExperiences => {
    return {
      type: GET_MATCHED_USER_EXPERIENCES_SUCCESS,
      matchedUserExperiences
    };
};

const getMatchedUserExperiencesFail = error => {
    return {
      type: GET_MATCHED_USER_EXPERIENCES_FAIL,
      error: error
    };
};


const getMatchedUserSkillsStart = () => {
    return {
      type: GET_MATCHED_USER_SKILLS_START
    };
  };

const getMatchedUserSkillsSuccess = matchedUserSkills => {
    return {
      type: GET_MATCHED_USER_SKILLS_SUCCESS,
      matchedUserSkills
    };
};

const getMatchedUserSkillsFail = error => {
    return {
      type: GET_MATCHED_USER_SKILLS_FAIL,
      error: error
    };
};


const getUsersMatchedByJobStart = () => {
    return {
      type: GET_USERS_MATCHED_BY_JOB_START
    };
  };

const getUsersMatchedByJobSuccess = usersMatchedByJob => {
    return {
      type: GET_USERS_MATCHED_BY_JOB_SUCCESS,
      usersMatchedByJob
    };
};

const getUsersMatchedByJobFail = error => {
    return {
      type: GET_USERS_MATCHED_BY_JOB_FAIL,
      error: error
    };
};


const getUsersMatchedByIndustryStart = () => {
    return {
      type: GET_USERS_MATCHED_BY_INDUSTRY_START
    };
  };

const getUsersMatchedByIndustrySuccess = usersMatchedByIndustry => {
    return {
      type: GET_USERS_MATCHED_BY_INDUSTRY_SUCCESS,
      usersMatchedByIndustry
    };
};

const getUsersMatchedByIndustryFail = error => {
    return {
      type: GET_USERS_MATCHED_BY_INDUSTRY_FAIL,
      error: error
    };
};


const getMatchedUserProfileStart = () => {
    return {
      type: GET_MATCHED_USER_PROFILE_START
    };
  };

const getMatchedUserProfileSuccess = matchedUserProfile => {
    return {
      type: GET_MATCHED_USER_PROFILE_SUCCESS,
      matchedUserProfile
    };
};

const getMatchedUserProfileFail = error => {
    return {
      type: GET_MATCHED_USER_PROFILE_FAIL,
      error: error
    };
};


export const getMatchedUserPortfolios = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserPortfoliosStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserPortfoliosURL,{ params }, headers)
        .then(res => {
          const matchedUserPortfolios = res.data;
          dispatch(getMatchedUserPortfoliosSuccess(matchedUserPortfolios));
          })
        .catch(err => {
          dispatch(getMatchedUserPortfoliosFail(err));
        });
    };
};


export const getMatchedUserServices = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserServicesStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserServicesURL,{ params }, headers)
        .then(res => {
          const matchedUserServices = res.data;
          dispatch(getMatchedUserServicesSuccess(matchedUserServices));
          })
        .catch(err => {
          dispatch(getMatchedUserServicesFail(err));
        });
    };
};


export const getMatchedUserObjectives = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserObjectivesStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserObjectivesURL,{ params }, headers)
        .then(res => {
          const matchedUserObjectives = res.data;
          dispatch(getMatchedUserObjectivesSuccess(matchedUserObjectives));
          })
        .catch(err => {
          dispatch(getMatchedUserObjectivesFail(err));
        });
    };
};

export const getMatchedUserAcademicQualifications = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserAcademicQualificationsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserAcademicQualificationsURL,{ params }, headers)
        .then(res => {
          const matchedUserAcademicQualifications = res.data;
          dispatch(getMatchedUserAcademicQualificationsSuccess(matchedUserAcademicQualifications));
          })
        .catch(err => {
          dispatch(getMatchedUserAcademicQualificationsFail(err));
        });
    };
};


export const getMatchedUserLanguages = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserLanguagesStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserLanguagesURL,{ params }, headers)
        .then(res => {
          const matchedUserLanguages = res.data;
          dispatch(getMatchedUserLanguagesSuccess(matchedUserLanguages));
          })
        .catch(err => {
          dispatch(getMatchedUserLanguagesFail(err));
        });
    };
};


export const getMatchedUserLinks = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserLinksStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserLinksURL,{ params }, headers)
        .then(res => {
          const matchedUserLinks = res.data;
          dispatch(getMatchedUserLinksSuccess(matchedUserLinks));
          })
        .catch(err => {
          dispatch(getMatchedUserLinksFail(err));
        });
    };
};


export const getMatchedUserExperiences = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserExperiencesStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserExperiencesURL,{ params }, headers)
        .then(res => {
          const matchedUserExperiences = res.data;
          dispatch(getMatchedUserExperiencesSuccess(matchedUserExperiences));
          })
        .catch(err => {
          dispatch(getMatchedUserExperiencesFail(err));
        });
    };
};


export const getMatchedUserSkills = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserSkillsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserSkillsURL,{ params }, headers)
        .then(res => {
          const matchedUserSkills = res.data;
          dispatch(getMatchedUserSkillsSuccess(matchedUserSkills));
          })
        .catch(err => {
          dispatch(getMatchedUserSkillsFail(err));
        });
    };
};


export const getUsersMatchedByJob = (params, token) => {
  return dispatch => {
      dispatch(getUsersMatchedByJobStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(usersMatchedByJobURL,{ params }, headers)
        .then(res => {
          const usersMatchedByJob = res.data;
          dispatch(getUsersMatchedByJobSuccess(usersMatchedByJob));
          })
        .catch(err => {
          dispatch(getUsersMatchedByJobFail(err));
        });
    };
};

export const getUsersMatchedByIndustry = (params, token) => {
  return dispatch => {
      dispatch(getUsersMatchedByIndustryStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(usersMatchedByIndustryURL,{ params }, headers)
        .then(res => {
          const usersMatchedByIndustry = res.data;
          dispatch(getUsersMatchedByIndustrySuccess(usersMatchedByIndustry));
          })
        .catch(err => {
          dispatch(getUsersMatchedByIndustryFail(err));
        });
    };
};



export const getMatchedUserProfile = (params, token) => {
  return dispatch => {
      dispatch(getMatchedUserProfileStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(matchedUserProfileURL,{ params }, headers)
        .then(res => {
          const matchedUserProfile = res.data;
          dispatch(getMatchedUserProfileSuccess(matchedUserProfile));
          })
        .catch(err => {
          dispatch(getMatchedUserProfileFail(err));
        });
    };
};

import React, {useEffect, useState} from 'react';
import { RadioButton } from 'primereact/radiobutton';
import EmployerLayout from '../layout/EmployerLayout';
import BestMatchedUsers from './BestMatchedUsers';
import NearestNeighbourMatches from './NearestNeighbourMatches';
import {useParams} from 'react-router-dom';


const IndustrialMatchedUsers = (props) =>{
  const {slug} = useParams();

  const [type, setType] = useState('BEST MATCHES');
  const onTypeChange = (e) => {
      setType(e.value);
  }

  const displayUsers = () =>{
    if(type==="BEST MATCHES"){
      return(
        <BestMatchedUsers
          industrySlug={slug}
          queryParam ="BEST MATCHES"
          stingRay="topMatched"
          emptyTitle="NO BEST MATCHES FOR THIS JOB"
          emptyPlea="THERE SEEMS TO BE NO CANDIDATES SCORING THE BEST SCORE FOR THIS JOB. TRY NEAREST NEIGHBOURS"
        />

      );
    }else if(type==="NEAREST NEIGHBOURS"){
      return(
        <NearestNeighbourMatches
          industrySlug={slug}
          queryParam ="NEAREST NEIGHBOURS"
          stingRay="topMatched"
          emptyTitle="NO NEAREST NEIGHBOURS FOR THIS JOB"
          emptyPlea="THERE SEEMS TO BE NO CANDIDATES SCORING AS NEAREST NEIGHBOURS FOR YOUR JOB. CONTACT US TO PROVIDE MORE OPTIONS"
        />

      );
    }
  }




  return(
    <EmployerLayout>
      <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
          <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
              <div className=" col-12 md:w-6 font-medium">
                <RadioButton
                    inputId="category1"
                    style={{paddingRight: '20px'}}
                    name="category"
                    value="BEST MATCHES"
                    onChange={onTypeChange}
                    checked={type === "BEST MATCHES"}
                  />
                <label className="text-primary" htmlFor="category1">
                  BEST MATCHES
                </label>
              </div>
              <div className=" col-12 md:w-6 font-medium">
                <RadioButton
                    inputId="category1"
                    style={{paddingRight: '20px'}}
                    name="category"
                    value="NEAREST NEIGHBOURS"
                    onChange={onTypeChange}
                    checked={type === "NEAREST NEIGHBOURS"}
                  />
                <label className="text-primary" htmlFor="category1">
                  NEAREST NEIGHBOURS
                </label>
              </div>
              </li>
          </ul>
      </div>

      {displayUsers()}


    </EmployerLayout>


  );
}


export default IndustrialMatchedUsers;

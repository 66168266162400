import axios from 'axios'
import {
    GET_CANDIDATE_ACADEMIC_QUALIFICATIONS,
    ADD_CANDIDATE_ACADEMIC_QUALIFICATION,
    EDIT_CANDIDATE_ACADEMIC_QUALIFICATION,
    DELETE_CANDIDATE_ACADEMIC_QUALIFICATION,
    GET_CANDIDATE_AVAILABILITY,
    ADD_CANDIDATE_AVAILABILITY,
    EDIT_CANDIDATE_AVAILABILITY,
    DELETE_CANDIDATE_AVAILABILITY,
    GET_CANDIDATE_RESUME,
    ADD_CANDIDATE_RESUME,
    EDIT_CANDIDATE_RESUME,
    DELETE_CANDIDATE_RESUME,
    GET_CANDIDATE_LANGUAGES,
    ADD_CANDIDATE_LANGUAGE,
    EDIT_CANDIDATE_LANGUAGE,
    DELETE_CANDIDATE_LANGUAGE,
    GET_CANDIDATE_ROLES,
    ADD_CANDIDATE_ROLE,
    EDIT_CANDIDATE_ROLE,
    DELETE_CANDIDATE_ROLE,
    GET_CANDIDATE_LINKS,
    ADD_CANDIDATE_LINK,
    EDIT_CANDIDATE_LINK,
    DELETE_CANDIDATE_LINK,
    GET_CANDIDATE_SKILLS,
    ADD_CANDIDATE_SKILL,
    EDIT_CANDIDATE_SKILL,
    DELETE_CANDIDATE_SKILL,

} from '../types/resumeTypes';

import {
    candidateresumeURL,
    candidateacademicqualificationsURL,
    candidateavailabilityURL,
    candidatelanguagesURL,
    candidaterolesURL,
    candidatelinksURL,
    jobseekerresumeURL,
    jobseekeravailabilityURL,
    candidateskillsURL,

} from '../constants';
import {  returnErrors } from './messages';


//CANDIDATE RESUME
export const getCandidateResume = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(jobseekerresumeURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_RESUME,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateResume = (resume, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidateresumeURL, resume, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_RESUME,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateResume = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidateresumeURL}${slug}/delete`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_RESUME,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editCandidateResume = (slug, resume, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidateresumeURL}${slug}/`, resume, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_RESUME,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//CANDIDATE AVAILABILITY
export const getCandidateAvailability = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(jobseekeravailabilityURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_AVAILABILITY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateAvailability = (availability, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidateavailabilityURL, availability, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_AVAILABILITY,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateAvailability = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidateavailabilityURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_AVAILABILITY,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editCandidateAvailability = (slug, availability, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidateavailabilityURL}${slug}/`, availability, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_AVAILABILITY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//CANDIDATE ACADEMIC QUALIFICATION

export const getCandidateAcademicQualifications = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(candidateacademicqualificationsURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_ACADEMIC_QUALIFICATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateAcademicQualification = (education, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidateacademicqualificationsURL, education, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_ACADEMIC_QUALIFICATION,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateAcademicQualification = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidateacademicqualificationsURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_ACADEMIC_QUALIFICATION,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editCandidateAcademicQualification = (slug, education, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidateacademicqualificationsURL}${slug}/`, education, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_ACADEMIC_QUALIFICATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//CANDIDATE LANGUAGES

export const getCandidateLangauges = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(candidatelanguagesURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_LANGUAGES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateLangauge = (language, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidatelanguagesURL, language, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_LANGUAGE,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateLangauge = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidatelanguagesURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_LANGUAGE,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editCandidateLangauge = (slug, language, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidatelanguagesURL}${slug}/`, language, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_LANGUAGE,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//CANDIDATE LINKS

export const getCandidateLinks = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(candidatelinksURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_LINKS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateLink = (link, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidatelinksURL, link, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_LINK,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateLink = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidatelinksURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_LINK,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editCandidateLink = (slug, link, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidatelinksURL}${slug}/`, link, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_LINK,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//CANDIDATE LINKS

export const getCandidateRoles = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(candidaterolesURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_ROLES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateRole = (role, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidaterolesURL, role, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_ROLE,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateRole = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidaterolesURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_ROLE,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editCandidateRole = (slug, role, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidaterolesURL}${slug}/`, role, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_ROLE,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//CANDIDATE SKILLS

export const getCandidateSkills = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(candidateskillsURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_SKILLS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateSkill = (skill, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidateskillsURL, skill, headers)
        .then(res => {
            dispatch({
                type: ADD_CANDIDATE_SKILL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCandidateSkill = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${candidateskillsURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_CANDIDATE_SKILL,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editCandidateSkill = (slug, skill, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidateskillsURL}${slug}/`, skill, headers)
        .then(res => {
            dispatch({
                type: EDIT_CANDIDATE_SKILL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


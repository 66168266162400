import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import EmployerLayout from '../layout/EmployerLayout';
import {getAdminChats, readMessages} from '../../actions/chats';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { Avatar } from '@mui/material';

export const Chats = (props) => {
    const {chats, token} = props;
    const navigate = useNavigate();
    const [hour, setHour] = useState(null);
    const {lastName, firstName} = props;
    const [seed, setSeed] = useState('');


    useEffect(() =>{
        setSeed(Math.floor(Math.random() * 5000))

    }, [])
    

    const getHour = () => {
       const date = new Date();
       const hour = date.getHours()
       setHour(hour);
    }


    useEffect(() => {
      getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick = (slug, chat) =>{
        props.readMessages(slug, chat, token)
        navigate(`/employ/chats/${slug}`, {replace: true})
    }

    useEffect(() =>{
        if(!props.fetched){
            props.getAdminChats(props.token);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <EmployerLayout>
            <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center">
                  <div style={{fontFamily: 'cursive'}} className="text-primary font-bold text-5xl mb-3">{hour < 12 ? "Good Morning" : "Good Afternoon"}</div>
                  <div className="text-primary text-2xl mb-5">{firstName} {lastName}</div>
                  <h5 style={{fontSize: '15px', fontFamily: 'cursive'}} className="text-primary">THESE ARE YOUR COMMUNICATIONS WITH YOUR PROSPECTIVE HIRES. YOU CAN ALWAYS CLOSE A CONVERSATION WHEN DONE</h5>
              </div>
            </div>
            <ul  className="list-none card p-0 m-0" style={{textAlign: 'center'}}>
                {chats.map((chat) =>{
                    return(
                        <li onClick={() => handleClick(chat.slug, chat)} key={chat.slug} className="flex card align-items-center chat-link py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="text-primary w-full md:w-6 md:flex-order-0 flex-order-1">
                                
                            </div>
                            <div className="text-primary w-full md:w-4 md:flex-order-0 flex-order-1">
                                {chat.name} 
                            </div>
                            <div className="text-primary w-full md:w-1 md:flex-order-0 flex-order-1">
                                <Avatar src={`https://avatars.dicebear.com/api/human/${seed}.svg`}/>
                            </div>
                            <div className="text-primary w-full md:w-1 md:flex-order-0 flex-order-1">
                                    <i  className="pi pi-envelope p-text-secondary p-overlay-badge" style={{ fontSize: '2rem', marginRight: 'auto' }}>
                                        {chat.unread > 0
                                            ?<Badge severity="danger" className='p-button-outlined' value={chat.unread}/>  
                                            : <></>  
                                        }
                                    
                                    </i>

                            </div>
                            
                            
                           
                        </li>

                    )}

                )
                    
                } 

            </ul>
        </EmployerLayout>
    )
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    chats: state.chats.adminchats,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,

})


export default connect(mapStateToProps, {getAdminChats, readMessages})(Chats)
import axios from 'axios';
import {
	GET_USER_PRIVACY_AGREEMENTS_START,
	GET_USER_PRIVACY_AGREEMENTS_SUCCESS,
	GET_USER_PRIVACY_AGREEMENTS_FAIL,
	GET_USER_PRIVACY_AGREEMENT_START,
	GET_USER_PRIVACY_AGREEMENT_SUCCESS,
	GET_USER_PRIVACY_AGREEMENT_FAIL,
	ADD_USER_PRIVACY_AGREEMENT_START,
	ADD_USER_PRIVACY_AGREEMENT_SUCCESS,
	ADD_USER_PRIVACY_AGREEMENT_FAIL,
	EDIT_USER_PRIVACY_AGREEMENT_START,
	EDIT_USER_PRIVACY_AGREEMENT_SUCCESS,
	EDIT_USER_PRIVACY_AGREEMENT_FAIL,
	DELETE_USER_PRIVACY_AGREEMENT_START,
	DELETE_USER_PRIVACY_AGREEMENT_SUCCESS,
	DELETE_USER_PRIVACY_AGREEMENT_FAIL,
  GET_CANDIDATE_PORTFOLIOS_START,
  GET_CANDIDATE_PORTFOLIOS_SUCCESS,
  GET_CANDIDATE_PORTFOLIOS_FAIL,
  GET_CANDIDATE_PORTFOLIO_START,
  GET_CANDIDATE_PORTFOLIO_SUCCESS,
  GET_CANDIDATE_PORTFOLIO_FAIL,
  ADD_CANDIDATE_PORTFOLIO_START,
  ADD_CANDIDATE_PORTFOLIO_SUCCESS,
  ADD_CANDIDATE_PORTFOLIO_FAIL,
  EDIT_CANDIDATE_PORTFOLIO_START,
  EDIT_CANDIDATE_PORTFOLIO_SUCCESS,
  EDIT_CANDIDATE_PORTFOLIO_FAIL,
  DELETE_CANDIDATE_PORTFOLIO_START,
  DELETE_CANDIDATE_PORTFOLIO_SUCCESS,
  DELETE_CANDIDATE_PORTFOLIO_FAIL,
  GET_CANDIDATE_SERVICES_START,
  GET_CANDIDATE_SERVICES_SUCCESS,
  GET_CANDIDATE_SERVICES_FAIL,
  GET_CANDIDATE_SERVICE_START,
  GET_CANDIDATE_SERVICE_SUCCESS,
  GET_CANDIDATE_SERVICE_FAIL,
  ADD_CANDIDATE_SERVICE_START,
  ADD_CANDIDATE_SERVICE_SUCCESS,
  ADD_CANDIDATE_SERVICE_FAIL,
  EDIT_CANDIDATE_SERVICE_START,
  EDIT_CANDIDATE_SERVICE_SUCCESS,
  EDIT_CANDIDATE_SERVICE_FAIL,
  DELETE_CANDIDATE_SERVICE_START,
  DELETE_CANDIDATE_SERVICE_SUCCESS,
  DELETE_CANDIDATE_SERVICE_FAIL,
  GET_CANDIDATE_OBJECTIVES_START,
  GET_CANDIDATE_OBJECTIVES_SUCCESS,
  GET_CANDIDATE_OBJECTIVES_FAIL,
  GET_CANDIDATE_OBJECTIVE_START,
  GET_CANDIDATE_OBJECTIVE_SUCCESS,
  GET_CANDIDATE_OBJECTIVE_FAIL,
  ADD_CANDIDATE_OBJECTIVE_START,
  ADD_CANDIDATE_OBJECTIVE_SUCCESS,
  ADD_CANDIDATE_OBJECTIVE_FAIL,
  EDIT_CANDIDATE_OBJECTIVE_START,
  EDIT_CANDIDATE_OBJECTIVE_SUCCESS,
  EDIT_CANDIDATE_OBJECTIVE_FAIL,
  DELETE_CANDIDATE_OBJECTIVE_START,
  DELETE_CANDIDATE_OBJECTIVE_SUCCESS,
  DELETE_CANDIDATE_OBJECTIVE_FAIL,
  GET_CANDIDATE_MISSIONS_START,
  GET_CANDIDATE_MISSIONS_SUCCESS,
  GET_CANDIDATE_MISSIONS_FAIL,
  GET_CANDIDATE_MISSION_START,
  GET_CANDIDATE_MISSION_SUCCESS,
  GET_CANDIDATE_MISSION_FAIL,
  ADD_CANDIDATE_MISSION_START,
  ADD_CANDIDATE_MISSION_SUCCESS,
  ADD_CANDIDATE_MISSION_FAIL,
  EDIT_CANDIDATE_MISSION_START,
  EDIT_CANDIDATE_MISSION_SUCCESS,
  EDIT_CANDIDATE_MISSION_FAIL,
  DELETE_CANDIDATE_MISSION_START,
  DELETE_CANDIDATE_MISSION_SUCCESS,
  DELETE_CANDIDATE_MISSION_FAIL,
  ADD_PORTFOLIO_SKILL_START,
  ADD_PORTFOLIO_SKILL_SUCCESS,
  ADD_PORTFOLIO_SKILL_FAIL,
  ADD_PORTFOLIO_IMAGE_START,
  ADD_PORTFOLIO_IMAGE_SUCCESS,
  ADD_PORTFOLIO_IMAGE_FAIL,
  ADD_SERVICE_IMAGE_START,
  ADD_SERVICE_IMAGE_SUCCESS,
  ADD_SERVICE_IMAGE_FAIL,

} from '../types/portfolioTypes';
import {
	userPrivacyAgreementsURL,
	userPrivacyAgreementURL,
  candidatePortfoliosURL,
  candidateServicesURL,
  candidateObjectivesURL,
  candidateMissionsURL,
} from '../constants';
import {  returnErrors } from './messages';



//PRIVACY
//list
const getUserPrivacyAgreementsStart = () => {
  return {
    type: GET_USER_PRIVACY_AGREEMENTS_START
  };
};

const getUserPrivacyAgreementsSuccess = userPrivacyAgreements => {
  return {
    type: GET_USER_PRIVACY_AGREEMENTS_SUCCESS,
    userPrivacyAgreements
  };
};

const getUserPrivacyAgreementsFail = error => {
  return {
    type: GET_USER_PRIVACY_AGREEMENTS_FAIL,
    error: error
  };
};

//get detail
const getUserPrivacyAgreementStart = () => {
  return {
    type: GET_USER_PRIVACY_AGREEMENT_START
  };
};

const getUserPrivacyAgreementSuccess = userPrivacyAgreement => {
  return {
    type: GET_USER_PRIVACY_AGREEMENT_SUCCESS,
    userPrivacyAgreement
  };
};

const getUserPrivacyAgreementFail = error => {
  return {
    type: GET_USER_PRIVACY_AGREEMENT_FAIL,
    error: error
  };
};

//add
const addUserPrivacyAgreementStart = () => {
  return {
    type: ADD_USER_PRIVACY_AGREEMENT_START
  };
};

const addUserPrivacyAgreementSuccess = userPrivacyAgreement => {
  return {
    type: ADD_USER_PRIVACY_AGREEMENT_SUCCESS,
    userPrivacyAgreement
  };
};

const addUserPrivacyAgreementFail = error => {
  return {
    type: ADD_USER_PRIVACY_AGREEMENT_FAIL,
    error: error
  };
};

//edit
const editUserPrivacyAgreementStart = () => {
  return {
    type: EDIT_USER_PRIVACY_AGREEMENT_START
  };
};

const editUserPrivacyAgreementSuccess = userPrivacyAgreement => {
  return {
    type: EDIT_USER_PRIVACY_AGREEMENT_SUCCESS,
    userPrivacyAgreement
  };
};

const editUserPrivacyAgreementFail = error => {
  return {
    type: EDIT_USER_PRIVACY_AGREEMENT_FAIL,
    error: error
  };
};

//delete
const deleteUserPrivacyAgreementStart = () => {
  return {
    type: DELETE_USER_PRIVACY_AGREEMENT_START
  };
};

const deleteUserPrivacyAgreementSuccess = userPrivacyAgreement => {
  return {
    type: DELETE_USER_PRIVACY_AGREEMENT_SUCCESS,
    userPrivacyAgreement
  };
};

const deleteUserPrivacyAgreementFail = error => {
  return {
    type: DELETE_USER_PRIVACY_AGREEMENT_FAIL,
    error: error
  };
};


//PORTFOLIO
//list
const getCandidatePortfoliosStart = () => {
  return {
    type: GET_CANDIDATE_PORTFOLIOS_START
  };
};

const getCandidatePortfoliosSuccess = candidatePortfolios => {
  return {
    type: GET_CANDIDATE_PORTFOLIOS_SUCCESS,
    candidatePortfolios
  };
};

const getCandidatePortfoliosFail = error => {
  return {
    type: GET_CANDIDATE_PORTFOLIOS_FAIL,
    error: error
  };
};

//get detail
const getCandidatePortfolioStart = () => {
  return {
    type: GET_CANDIDATE_PORTFOLIO_START
  };
};

const getCandidatePortfolioSuccess = candidatePortfolio => {
  return {
    type: GET_CANDIDATE_PORTFOLIO_SUCCESS,
    candidatePortfolio
  };
};

const getCandidatePortfolioFail = error => {
  return {
    type: GET_CANDIDATE_PORTFOLIO_FAIL,
    error: error
  };
};

//add
const addCandidatePortfolioStart = () => {
  return {
    type: ADD_CANDIDATE_PORTFOLIO_START
  };
};

const addCandidatePortfolioSuccess = candidatePortfolio => {
  return {
    type: ADD_CANDIDATE_PORTFOLIO_SUCCESS,
    candidatePortfolio
  };
};

const addCandidatePortfolioFail = error => {
  return {
    type: ADD_CANDIDATE_PORTFOLIO_FAIL,
    error: error
  };
};

//edit
const editCandidatePortfolioStart = () => {
  return {
    type: EDIT_CANDIDATE_PORTFOLIO_START
  };
};

const editCandidatePortfolioSuccess = candidatePortfolio => {
  return {
    type: EDIT_CANDIDATE_PORTFOLIO_SUCCESS,
    candidatePortfolio
  };
};

const editCandidatePortfolioFail = error => {
  return {
    type: EDIT_CANDIDATE_PORTFOLIO_FAIL,
    error: error
  };
};

//delete
const deleteCandidatePortfolioStart = () => {
  return {
    type: DELETE_CANDIDATE_PORTFOLIO_START
  };
};

const deleteCandidatePortfolioSuccess = candidatePortfolio => {
  return {
    type: DELETE_CANDIDATE_PORTFOLIO_SUCCESS,
    candidatePortfolio
  };
};

const deleteCandidatePortfolioFail = error => {
  return {
    type: DELETE_CANDIDATE_PORTFOLIO_FAIL,
    error: error
  };
};


//SERVICES
//list
const getCandidateServicesStart = () => {
  return {
    type: GET_CANDIDATE_SERVICES_START
  };
};

const getCandidateServicesSuccess = candidateServices => {
  return {
    type: GET_CANDIDATE_SERVICES_SUCCESS,
    candidateServices
  };
};

const getCandidateServicesFail = error => {
  return {
    type: GET_CANDIDATE_SERVICES_FAIL,
    error: error
  };
};

//get detail
const getCandidateServiceStart = () => {
  return {
    type: GET_CANDIDATE_SERVICE_START
  };
};

const getCandidateServiceSuccess = candidateService => {
  return {
    type: GET_CANDIDATE_SERVICE_SUCCESS,
    candidateService
  };
};

const getCandidateServiceFail = error => {
  return {
    type: GET_CANDIDATE_SERVICE_FAIL,
    error: error
  };
};

//add
const addCandidateServiceStart = () => {
  return {
    type: ADD_CANDIDATE_SERVICE_START
  };
};

const addCandidateServiceSuccess = candidateService => {
  return {
    type: ADD_CANDIDATE_SERVICE_SUCCESS,
    candidateService
  };
};

const addCandidateServiceFail = error => {
  return {
    type: ADD_CANDIDATE_SERVICE_FAIL,
    error: error
  };
};

//edit
const editCandidateServiceStart = () => {
  return {
    type: EDIT_CANDIDATE_SERVICE_START
  };
};

const editCandidateServiceSuccess = candidateService => {
  return {
    type: EDIT_CANDIDATE_SERVICE_SUCCESS,
    candidateService
  };
};

const editCandidateServiceFail = error => {
  return {
    type: EDIT_CANDIDATE_SERVICE_FAIL,
    error: error
  };
};

//delete
const deleteCandidateServiceStart = () => {
  return {
    type: DELETE_CANDIDATE_SERVICE_START
  };
};

const deleteCandidateServiceSuccess = candidateService => {
  return {
    type: DELETE_CANDIDATE_SERVICE_SUCCESS,
    candidateService
  };
};

const deleteCandidateServiceFail = error => {
  return {
    type: DELETE_CANDIDATE_SERVICE_FAIL,
    error: error
  };
};


//OBJECTIVES
//list
const getCandidateObjectivesStart = () => {
  return {
    type: GET_CANDIDATE_OBJECTIVES_START
  };
};

const getCandidateObjectivesSuccess = candidateObjectives => {
  return {
    type: GET_CANDIDATE_OBJECTIVES_SUCCESS,
    candidateObjectives
  };
};

const getCandidateObjectivesFail = error => {
  return {
    type: GET_CANDIDATE_OBJECTIVES_FAIL,
    error: error
  };
};

//get detail
const getCandidateObjectiveStart = () => {
  return {
    type: GET_CANDIDATE_OBJECTIVE_START
  };
};

const getCandidateObjectiveSuccess = candidateObjective => {
  return {
    type: GET_CANDIDATE_OBJECTIVE_SUCCESS,
    candidateObjective
  };
};

const getCandidateObjectiveFail = error => {
  return {
    type: GET_CANDIDATE_OBJECTIVE_FAIL,
    error: error
  };
};

//add
const addCandidateObjectiveStart = () => {
  return {
    type: ADD_CANDIDATE_OBJECTIVE_START
  };
};

const addCandidateObjectiveSuccess = candidateObjective => {
  return {
    type: ADD_CANDIDATE_OBJECTIVE_SUCCESS,
    candidateObjective
  };
};

const addCandidateObjectiveFail = error => {
  return {
    type: ADD_CANDIDATE_OBJECTIVE_FAIL,
    error: error
  };
};

//edit
const editCandidateObjectiveStart = () => {
  return {
    type: EDIT_CANDIDATE_OBJECTIVE_START
  };
};

const editCandidateObjectiveSuccess = candidateObjective => {
  return {
    type: EDIT_CANDIDATE_OBJECTIVE_SUCCESS,
    candidateObjective
  };
};

const editCandidateObjectiveFail = error => {
  return {
    type: EDIT_CANDIDATE_OBJECTIVE_FAIL,
    error: error
  };
};

//delete
const deleteCandidateObjectiveStart = () => {
  return {
    type: DELETE_CANDIDATE_OBJECTIVE_START
  };
};

const deleteCandidateObjectiveSuccess = candidateObjective => {
  return {
    type: DELETE_CANDIDATE_OBJECTIVE_SUCCESS,
    candidateObjective
  };
};

const deleteCandidateObjectiveFail = error => {
  return {
    type: DELETE_CANDIDATE_OBJECTIVE_FAIL,
    error: error
  };
};



//OBJECTIVES
//list
const getCandidateMissionsStart = () => {
  return {
    type: GET_CANDIDATE_MISSIONS_START
  };
};

const getCandidateMissionsSuccess = candidateMissions => {
  return {
    type: GET_CANDIDATE_MISSIONS_SUCCESS,
    candidateMissions
  };
};

const getCandidateMissionsFail = error => {
  return {
    type: GET_CANDIDATE_MISSIONS_FAIL,
    error: error
  };
};

//get detail
const getCandidateMissionStart = () => {
  return {
    type: GET_CANDIDATE_MISSION_START
  };
};

const getCandidateMissionSuccess = candidateMission => {
  return {
    type: GET_CANDIDATE_MISSION_SUCCESS,
    candidateMission
  };
};

const getCandidateMissionFail = error => {
  return {
    type: GET_CANDIDATE_MISSION_FAIL,
    error: error
  };
};

//add
const addCandidateMissionStart = () => {
  return {
    type: ADD_CANDIDATE_MISSION_START
  };
};

const addCandidateMissionSuccess = candidateMission => {
  return {
    type: ADD_CANDIDATE_MISSION_SUCCESS,
    candidateMission
  };
};

const addCandidateMissionFail = error => {
  return {
    type: ADD_CANDIDATE_MISSION_FAIL,
    error: error
  };
};

//edit
const editCandidateMissionStart = () => {
  return {
    type: EDIT_CANDIDATE_MISSION_START
  };
};

const editCandidateMissionSuccess = candidateMission => {
  return {
    type: EDIT_CANDIDATE_MISSION_SUCCESS,
    candidateMission
  };
};

const editCandidateMissionFail = error => {
  return {
    type: EDIT_CANDIDATE_MISSION_FAIL,
    error: error
  };
};

//delete
const deleteCandidateMissionStart = () => {
  return {
    type: DELETE_CANDIDATE_MISSION_START
  };
};

const deleteCandidateMissionSuccess = candidateMission => {
  return {
    type: DELETE_CANDIDATE_MISSION_SUCCESS,
    candidateMission
  };
};

const deleteCandidateMissionFail = error => {
  return {
    type: DELETE_CANDIDATE_MISSION_FAIL,
    error: error
  };
};


const addPortfolioSkillStart = () => {
  return {
    type: ADD_PORTFOLIO_SKILL_START
  };
};

const addPortfolioSkillSuccess = portfolioSkill => {
  return {
    type: ADD_PORTFOLIO_SKILL_SUCCESS,
    portfolioSkill
  };
};

const addPortfolioSkillFail = error => {
  return {
    type: ADD_PORTFOLIO_SKILL_FAIL,
    error: error
  };
};


const addPortfolioImageStart = () => {
  return {
    type: ADD_PORTFOLIO_IMAGE_START
  };
};

const addPortfolioImageSuccess = portfolioImage => {
  return {
    type: ADD_PORTFOLIO_IMAGE_SUCCESS,
    portfolioImage
  };
};

const addPortfolioImageFail = error => {
  return {
    type: ADD_PORTFOLIO_IMAGE_FAIL,
    error: error
  };
};


const addServiceImageStart = () => {
  return {
    type: ADD_SERVICE_IMAGE_START
  };
};

const addServiceImageSuccess = serviceImage => {
  return {
    type: ADD_SERVICE_IMAGE_SUCCESS,
    serviceImage
  };
};

const addServiceImageFail = error => {
  return {
    type: ADD_SERVICE_IMAGE_FAIL,
    error: error
  };
};


//PRIVACY AGREEMENTS

export const getUserPrivacyAgreements = (token) => {
    return dispatch => {
      dispatch(getUserPrivacyAgreementsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(userPrivacyAgreementsURL, headers)
        .then(res => {
          const userPrivacyAgreements = res.data;
          dispatch(getUserPrivacyAgreementsSuccess(userPrivacyAgreements));
          })
        .catch(err => {
          dispatch(getUserPrivacyAgreementsFail(err));
        });
    };
}
/////////////////////////
export const getUserPrivacyAgreement = (token) => {
    return dispatch => {
      dispatch(getUserPrivacyAgreementStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(userPrivacyAgreementURL, headers)
        .then(res => {
          const userPrivacyAgreement = res.data;
          dispatch(getUserPrivacyAgreementSuccess(userPrivacyAgreement));
          })
        .catch(err => {
          dispatch(getUserPrivacyAgreementFail(err));
        });
    };
}
/////////////////////////
export const addUserPrivacyAgreement = (privacy, token) => {
    return dispatch => {
      dispatch(addUserPrivacyAgreementStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .post(userPrivacyAgreementsURL,privacy, headers)
        .then(res => {
          const userPrivacyAgreement = res.data;
          dispatch(addUserPrivacyAgreementSuccess(userPrivacyAgreement));

          })
        .catch(err => {
          dispatch(addUserPrivacyAgreementFail(err));
          dispatch(returnErrors(err.response.data, err.response.status));
        });
    };
}
///////////////////////
export const editUserPrivacyAgreement = (slug, privacy, token) => {
    return dispatch => {
      dispatch(editUserPrivacyAgreementStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .patch(`${userPrivacyAgreementsURL}${slug}/`,privacy, headers)
        .then(res => {
          const userPrivacyAgreement = res.data;
          dispatch(editUserPrivacyAgreementSuccess(userPrivacyAgreement));

          })
        .catch(err => {
          dispatch(editUserPrivacyAgreementFail(err));
          dispatch(returnErrors(err.response.data, err.response.status));
        });
    };
}

///////////////////////


export const deleteUserPrivacyAgreement = (slug, privacy, token) => {
    return dispatch => {
      dispatch(deleteUserPrivacyAgreementStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .delete(`${userPrivacyAgreementsURL}${slug}/deleter/`,privacy, headers)
        .then(res => {
          const userPrivacyAgreement = res.data;
          dispatch(deleteUserPrivacyAgreementSuccess(userPrivacyAgreement));

          })
        .catch(err => {
          dispatch(deleteUserPrivacyAgreementFail(err));
          dispatch(returnErrors(err.response.data, err.response.status));
        });
    };
}

///////////////////////

//CANDIDATE PORTFOLIOS

export const getCandidatePortfolios = (token) => {
  return dispatch => {
    dispatch(getCandidatePortfoliosStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(candidatePortfoliosURL, headers)
      .then(res => {
        const candidatePortfolios = res.data;
        dispatch(getCandidatePortfoliosSuccess(candidatePortfolios));
        })
      .catch(err => {
        dispatch(getCandidatePortfoliosFail(err));
      });
  };
}
/////////////////////////
export const getCandidatePortfolio = (slug, token) => {
  return dispatch => {
    dispatch(getCandidatePortfolioStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(`${candidatePortfoliosURL}${slug}`, headers)
      .then(res => {
        const candidatePortfolio = res.data;
        dispatch(getCandidatePortfolioSuccess(candidatePortfolio));
        })
      .catch(err => {
        dispatch(getCandidatePortfolioFail(err));
      });
  };
}
/////////////////////////
export const addCandidatePortfolio = (privacy, token) => {
  return dispatch => {
    dispatch(addCandidatePortfolioStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(candidatePortfoliosURL,privacy, headers)
      .then(res => {
        const candidatePortfolio = res.data;
        dispatch(addCandidatePortfolioSuccess(candidatePortfolio));

        })
      .catch(err => {
        dispatch(addCandidatePortfolioFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}
///////////////////////
export const editCandidatePortfolio = (slug, privacy, token) => {
  return dispatch => {
    dispatch(editCandidatePortfolioStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .patch(`${candidatePortfoliosURL}${slug}/`,privacy, headers)
      .then(res => {
        const candidatePortfolio = res.data;
        dispatch(editCandidatePortfolioSuccess(candidatePortfolio));

        })
      .catch(err => {
        dispatch(editCandidatePortfolioFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}

///////////////////////


export const deleteCandidatePortfolio = (slug, privacy, token) => {
  return dispatch => {
    dispatch(deleteCandidatePortfolioStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(`${candidatePortfoliosURL}${slug}/deleter/`,privacy, headers)
      .then(res => {
        const candidatePortfolio = res.data;
        dispatch(deleteCandidatePortfolioSuccess(candidatePortfolio));

        })
      .catch(err => {
        dispatch(deleteCandidatePortfolioFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}



///////////////////////

//CANDIDATE SERVICES

export const getCandidateServices = (token) => {
  return dispatch => {
    dispatch(getCandidateServicesStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(candidateServicesURL, headers)
      .then(res => {
        const candidateServices = res.data;
        dispatch(getCandidateServicesSuccess(candidateServices));
        })
      .catch(err => {
        dispatch(getCandidateServicesFail(err));
      });
  };
}
/////////////////////////
export const getCandidateService = (slug, token) => {
  return dispatch => {
    dispatch(getCandidateServiceStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(`${candidateServicesURL}${slug}`, headers)
      .then(res => {
        const candidateService = res.data;
        dispatch(getCandidateServiceSuccess(candidateService));
        })
      .catch(err => {
        dispatch(getCandidateServiceFail(err));
      });
  };
}
/////////////////////////
export const addCandidateService = (privacy, token) => {
  return dispatch => {
    dispatch(addCandidateServiceStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(candidateServicesURL,privacy, headers)
      .then(res => {
        const candidateService = res.data;
        dispatch(addCandidateServiceSuccess(candidateService));

        })
      .catch(err => {
        dispatch(addCandidateServiceFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}
///////////////////////
export const editCandidateService = (slug, privacy, token) => {
  return dispatch => {
    dispatch(editCandidateServiceStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .patch(`${candidateServicesURL}${slug}/`,privacy, headers)
      .then(res => {
        const candidateService = res.data;
        dispatch(editCandidateServiceSuccess(candidateService));

        })
      .catch(err => {
        dispatch(editCandidateServiceFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}

///////////////////////


export const deleteCandidateService = (slug, privacy, token) => {
  return dispatch => {
    dispatch(deleteCandidateServiceStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(`${candidateServicesURL}${slug}/deleter/`,privacy, headers)
      .then(res => {
        const candidateService = res.data;
        dispatch(deleteCandidateServiceSuccess(candidateService));

        })
      .catch(err => {
        dispatch(deleteCandidateServiceFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}



///////////////////////

//CANDIDATE OBJECTIVES

export const getCandidateObjectives = (token) => {
  return dispatch => {
    dispatch(getCandidateObjectivesStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(candidateObjectivesURL, headers)
      .then(res => {
        const candidateObjectives = res.data;
        dispatch(getCandidateObjectivesSuccess(candidateObjectives));
        })
      .catch(err => {
        dispatch(getCandidateObjectivesFail(err));
      });
  };
}
/////////////////////////
export const getCandidateObjective = (slug, token) => {
  return dispatch => {
    dispatch(getCandidateObjectiveStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(`${candidateObjectivesURL}${slug}`, headers)
      .then(res => {
        const candidateObjective = res.data;
        dispatch(getCandidateObjectiveSuccess(candidateObjective));
        })
      .catch(err => {
        dispatch(getCandidateObjectiveFail(err));
      });
  };
}
/////////////////////////
export const addCandidateObjective = (privacy, token) => {
  return dispatch => {
    dispatch(addCandidateObjectiveStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(candidateObjectivesURL,privacy, headers)
      .then(res => {
        const candidateObjective = res.data;
        dispatch(addCandidateObjectiveSuccess(candidateObjective));

        })
      .catch(err => {
        dispatch(addCandidateObjectiveFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}
///////////////////////
export const editCandidateObjective = (slug, privacy, token) => {
  return dispatch => {
    dispatch(editCandidateObjectiveStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .patch(`${candidateObjectivesURL}${slug}`,privacy, headers)
      .then(res => {
        const candidateObjective = res.data;
        dispatch(editCandidateObjectiveSuccess(candidateObjective));

        })
      .catch(err => {
        dispatch(editCandidateObjectiveFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}

///////////////////////


export const deleteCandidateObjective = (slug, privacy, token) => {
  return dispatch => {
    dispatch(deleteCandidateObjectiveStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .delete(`${candidateObjectivesURL}${slug}/deleter/`,privacy, headers)
      .then(res => {
        const candidateObjective = res.data;
        dispatch(deleteCandidateObjectiveSuccess(candidateObjective));

        })
      .catch(err => {
        dispatch(deleteCandidateObjectiveFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}




///////////////////////

//CANDIDATE MISSIONS

export const getCandidateMissions = (token) => {
  return dispatch => {
    dispatch(getCandidateMissionsStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(candidateMissionsURL, headers)
      .then(res => {
        const candidateMissions = res.data;
        dispatch(getCandidateMissionsSuccess(candidateMissions));
        })
      .catch(err => {
        dispatch(getCandidateMissionsFail(err));
      });
  };
}
/////////////////////////
export const getCandidateMission = (slug, token) => {
  return dispatch => {
    dispatch(getCandidateMissionStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .get(`${candidateMissionsURL}${slug}`, headers)
      .then(res => {
        const candidateMission = res.data;
        dispatch(getCandidateMissionSuccess(candidateMission));
        })
      .catch(err => {
        dispatch(getCandidateMissionFail(err));
      });
  };
}
/////////////////////////
export const addCandidateMission = (privacy, token) => {
  return dispatch => {
    dispatch(addCandidateMissionStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(candidateMissionsURL,privacy, headers)
      .then(res => {
        const candidateMission = res.data;
        dispatch(addCandidateMissionSuccess(candidateMission));

        })
      .catch(err => {
        dispatch(addCandidateMissionFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}
///////////////////////
export const editCandidateMission = (slug, privacy, token) => {
  return dispatch => {
    dispatch(editCandidateMissionStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .patch(`${candidateMissionsURL}${slug}`,privacy, headers)
      .then(res => {
        const candidateMission = res.data;
        dispatch(editCandidateMissionSuccess(candidateMission));

        })
      .catch(err => {
        dispatch(editCandidateMissionFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}

///////////////////////


export const deleteCandidateMission = (slug, privacy, token) => {
  return dispatch => {
    dispatch(deleteCandidateMissionStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .delete(`${candidateMissionsURL}${slug}/deleter/`,privacy, headers)
      .then(res => {
        const candidateMission = res.data;
        dispatch(deleteCandidateMissionSuccess(candidateMission));

        })
      .catch(err => {
        dispatch(deleteCandidateMissionFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}



export const addPortfolioImage = (slug, privacy, token) => {
  return dispatch => {
    dispatch(addPortfolioImageStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(`${candidatePortfoliosURL}${slug}/add_image/`, privacy, headers)
      .then(res => {
        const portfolioImage = res.data;
        dispatch(addPortfolioImageSuccess(portfolioImage));

        })
      .catch(err => {
        dispatch(addPortfolioImageFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}


export const addPortfolioSkill = (slug, privacy, token) => {
  return dispatch => {
    dispatch(addPortfolioSkillStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(`${candidatePortfoliosURL}${slug}/add_skill/`, privacy, headers)
      .then(res => {
        const portfolioSkill = res.data;
        dispatch(addPortfolioSkillSuccess(portfolioSkill));

        })
      .catch(err => {
        dispatch(addPortfolioSkillFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}


export const addServiceImage = (slug, privacy, token) => {
  return dispatch => {
    dispatch(addServiceImageStart());
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios
      .post(`${candidateServicesURL}${slug}/add_image/`, privacy, headers)
      .then(res => {
        const serviceImage = res.data;
        dispatch(addServiceImageSuccess(serviceImage));

        })
      .catch(err => {
        dispatch(addServiceImageFail(err));
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  };
}

import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Chip } from 'primereact/chip';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import 'aos/dist/aos.css';




const proficiencyOptions = [
    {key: 'EXPERT', value: 'EXPERT'},
    {key: 'AVERAGE', value: 'AVERAGE'},
    {key: 'BASIC', value: 'BASIC'},

]


const Languages = (props) => {
    const {
        token,
        addCandidateLanguage,
        editCandidateLanguage,
        getCandidateLanguages,
        records,
        languages,
        deleteCandidateLangauge,

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    let initialValues = {
        language: '',
        proficiency: '',


    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [layout, setLayout] = useState('grid');



    useEffect(() => {
        getCandidateLanguages(token);
        setIsSubmit(false);

    }, [isSubmit]);


    const deleteLanguage = (slug) =>{
        deleteCandidateLangauge(slug, token);
        setIsSubmit(true);

    }

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('language' in fieldValues)
            temp.language= fieldValues.language? "" : "Language is Required"
        if ('proficiency' in fieldValues)
            temp.proficiency= fieldValues.proficiency? "" : "Profficiency is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                setErrors(validate(record));
                setIsSubmit(true);
                editCandidateLanguage(record.slug, record, token);
                getCandidateLanguages(token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                addCandidateLanguage(record, token);
                getCandidateLanguages(token);
                resetForm();
                setDisplayMaximizable(false);
            }

          }

    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }


    const editLanguage = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }




    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search"  placeholder="Search..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD LANGAUGE'
                    className="p-button-success p-button-outlined"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }

    const dialogLanguageHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Language Form</span>
              </div>
          )
      }


    const renderGridItem = (data) => {
        return (

            <div
                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">

                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        LANGAUGE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.language}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        PROFFICIENCY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.proficiency}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>

                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                    <div className="col-12 md:w-6 font-medium">
                                        <Button
                                            icon="pi pi-pencil"
                                            label='UPDATE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => editLanguage(data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-6 font-medium">
                                        <Button
                                            icon="pi pi-trash"
                                            label='DELETE'
                                            className="p-button-rounded p-button-outlined p-button-danger"
                                            onClick={() => deleteLanguage(data.slug)}
                                        />
                                    </div>
                                </li>

                        </ul>

                    </div>
                    </div>
            </div>
        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="w-6 md:w-2 flex justify-content-end">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-text p-button-success"
                        onClick={() => editLanguage(rowData)}
                    />
                </div>
                <div className="w-6 md:w-2 flex justify-content-end">
                    <Button
                        icon="pi pi-trash"
                        className="p-button-text p-button-danger"
                        onClick={() => deleteLanguage(rowData.slug)}
                    />
                </div>
            </React.Fragment>
        );
    }

    const header = renderHeader();

    return (
        <>

            <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}

            />
            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY LANGUAGES</h5>
                                            <p className='text-primary'>CURRENTLY THERE ARE NO LANGAUGES</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO LANGAUGES"
                                />
                            </div>
                        }
                    </div>
                </div>


            </div>
                <Dialog header={dialogLanguageHeader()}
                    data-aos="fade-up-right"
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '1000px' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT THE LANGUAGE
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.language}
                                                optionLabel="name"
                                                optionValue="name"
                                                options={languages}
                                                onChange={(e) => onInputChange(e, 'language')}
                                                filter

                                                filterBy="name"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.language}</small>
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT YOUR PROFFICIENCY IN THE LANGUAGE
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <Dropdown
                                                value={record.proficiency}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={proficiencyOptions}
                                                onChange={(e) => onInputChange(e, 'proficiency')}
                                                filter

                                                filterBy="key"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.proficiency}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <Button
                                            type="submit"
                                            label="ADD LANGUAGE"
                                            className="p-button-outlined p-button-warning"
                                        />
                                    </li>
                                </ul>

                            </div>
                        </form>
                    </div>

                </Dialog>
         </>
    );
}


export default Languages;

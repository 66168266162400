import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import ManagementLayout from '../layout/ManagementLayout';
import {getJobSeekerSkills} from '../../actions/candidates';


const Skills = (props) => {

  const [filters2, setFilters2] = useState({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'skill': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'competency': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      'years_of_experience': { value: null, matchMode: FilterMatchMode.IN },
  });

  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const [loading2, setLoading2] = useState(false);
  const {records, token} = props;
  const {slug} = useParams();

  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2['global'].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  }


    useEffect(() => {
          props.getJobSeekerSkills(slug, token);

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }


    const header2 = renderHeader2();

  return (
    <ManagementLayout>
      <div className="datatable-filter-demo">
            <div className="card">
                <DataTable
                  value={records}
                  paginator
                  className="p-datatable-customers"
                  rows={10}
                  dataKey="slug"
                  filters={filters2}
                  filterDisplay="row"
                  style={{fontSize: '15px', fontFamily: 'cursive'}}
                  loading={loading2}
                  responsiveLayout="scroll"
                  showGridlines
                  globalFilterFields={['skill', 'years_of_experience', 'competency']}
                  header={header2}
                  emptyMessage="No skills found"
                >
                  <Column
                      field="skill"
                      header="NAME"
                      filter
                      style={{ minWidth: '12rem', fontFamily: 'cursive' }}
                      className='text-primary'
                  />
                  <Column
                    header="COMPETENCY"
                    field="competency"
                    style={{ minWidth: '12rem', fontFamily: 'cursive' }}
                      className='text-primary'
                    filter

                  />
                  <Column
                    header="YEARS OF EXPERIENCE"
                    field="years_of_experience"
                    style={{ minWidth: '12rem', fontFamily: 'cursive' }}
                      className='text-primary'
                    filter

                  />

                </DataTable>
            </div>

          </div>
      </ManagementLayout>

    );
}

const mapStateToProps = (state) => ({
      token: state.auth.token,
      records: state.candidates.jobseekerskills,

})

export default connect(mapStateToProps,{getJobSeekerSkills})(Skills);

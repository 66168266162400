import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import ManagementLayout from '../layout/ManagementLayout';
import { getUsers } from '../../actions/auth';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import {useNavigate} from 'react-router-dom';


export const CandidateDetails = (props) => {
    const employerOptions = props.users.filter((user) => user.type ==="jobseeker");
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [id, setId] = useState('');
    const [slug, setSlug] = useState('');
    const navigate = useNavigate();


    const goToSkills = (slug) =>{
      navigate(`/management/candidate-details/skills/${slug}`, {replace: false})
    }


    const goToAcademicQualifications = (slug) =>{

        navigate(`/management/candidate-details/academic-qualifications/${slug}`, {replace: false})
    }

    const goToJobExperiences = (slug) =>{

        navigate(`/management/candidate-details/job-experiences/${slug}`, {replace: false})
    }

    const goToJobLanguages = (slug) =>{
        navigate(`/management/candidate-details/languages/${slug}`, {replace: false})
    }

    const goToJobLinks = (slug) =>{
        navigate(`/management/candidate-details/links/${slug}`, {replace: false})
    }

    const goToPendingProposals = (slug) =>{
        navigate(`/management/candidate-details/pending-proposals/${slug}`, {replace: false})
    }

    const goToInterviewProposals = (slug) =>{
        navigate(`/management/candidate-details/called-for-interview-proposals/${slug}`, {replace: false})
    }

    const goToRejectedProposals = (slug) =>{
        navigate(`/management/candidate-details/rejected-proposals/${slug}`, {replace: false})
    }




    const getEmployee = (employees, username) =>{
        let emps = employees.filter((emp) => emp.username === username)
        return emps[0]
    }



    useEffect(() =>{
        if (!props.fetched){
            props.getUsers(props.token);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        let employeesCopy = [...employerOptions];
        let employee = getEmployee(employeesCopy, username);
        if (employee !== undefined){
            setEmail(employee.email);
            setFirstName(employee.first_name);
            setLastName(employee.last_name);
            setGender(employee.gender);
            setId(employee.id);
            setSlug(employee.slug);
        }

    }, [username]) // eslint-disable-line react-hooks/exhaustive-deps







    return (
        <ManagementLayout>
            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className="col-12 md:col-7">
                    <div className="surface-section  text-primary card px-4 py-8 md:px-6 lg:px-8">
                        JobSeeker Details

                        <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    USER
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        optionLabel="username"
                                        optionValue="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        options={employerOptions}
                                        filter
                                        filterBy="username"
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    ID
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="name"
                                        value={id}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    SYSTEM ID
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="slug"
                                        value={slug}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    FIRST NAME
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="first_name"
                                        value={first_name}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    LAST NAME
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="last_name"
                                        value={last_name}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    EMAIL
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="email"
                                        value={email}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    GENDER
                                </div>
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="gender"
                                        value={gender}
                                        disabled
                                    />

                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className="col-12 md:col-5 text-primary">
                    <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                        Option Details


                        <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                            {username !== undefined &&  username !== ''
                                ?<>


                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW PENDING PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToPendingProposals(slug)}

                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW ACCEPTED PROPOSALS'
                                                className="p-button-outlined p-button-primary"

                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW CALLED FOR INTERVIEW PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToInterviewProposals(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW REJECTED PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToRejectedProposals(slug)}

                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW PROFILE'
                                                className="p-button-outlined p-button-primary"

                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW RESUME'
                                                className="p-button-outlined p-button-primary"

                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW ACADEMIC QUALIFICATIONS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToAcademicQualifications(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW LANGUAGES PROFFICIENCY'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() => goToJobLanguages(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW SKILLS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() => goToSkills(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW JOB EXPERIENCES'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToJobExperiences(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW LINKS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToJobLinks(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW REPORTS'
                                                className="p-button-outlined p-button-primary"

                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW COMMUNICATIONS'
                                                className="p-button-outlined p-button-primary"

                                            />
                                        </div>
                                    </li>
                                </>
                                :<>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW PENDING PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW ACCEPTED PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW CALLED FOR INTERVIEW PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW REJECTED PROPOSALS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW PROFILE'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW RESUME'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW ACADEMIC QUALIFICATIONS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW LANGUAGES PROFFICIENCY'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW SKILLS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW JOB EXPERIENCES'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW REPORTS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button
                                                label='VIEW COMMUNICATIONS'
                                                className="p-button-outlined p-button-primary"
                                                disabled

                                            />
                                        </div>
                                        </li>
                            </>
                            }
                        </ul>
                    </div>
                </div>

            </div>

        </ManagementLayout>
    )
}



const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.people,
});



export default connect(mapStateToProps, {getUsers})(CandidateDetails);

import React, {useEffect, useState} from 'react'
import { Avatar } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from 'primereact/button';
import ManagementLayout from '../layout/ManagementLayout';
import { useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import {getChatMessages, getChat, addMessage, deleteMessage} from '../../actions/chats';
import { InputTextarea } from 'primereact/inputtextarea';



const Chat = props => {
    const [seed, setSeed] = useState('');
    const {slug} = useParams();
    const {userName, chat} = props;

    const initialValues ={
        chat: slug,
        body: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [isSubmit, setIsSubmit]= useState(false);
    const currentColor = localStorage.getItem("currentColor") || 'dark' ;

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }



    useEffect(() =>{
        setSeed(Math.floor(Math.random() * 5000))

    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>{
        if(!props.fetched){
            props.getChatMessages(slug, props.token);
            props.getChat(slug, props.token);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
          (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
          // less than one minute ago
          prefix = "just now...";
        } else if (timeDiff < 60 && timeDiff > 1) {
          // less than sixty minutes ago
          prefix = `${timeDiff} minutes ago`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
          // less than 24 hours ago
          prefix = `${Math.round(timeDiff / 60)} hours ago`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
          // less than 7 days ago
          prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
        } else {
          prefix = `${new Date(timestamp)}`;
        }
        return prefix;
      };


    useEffect(() =>{

        props.getChatMessages(slug, props.token);
        setIsSubmit(false);


    }, [isSubmit]) // eslint-disable-line react-hooks/exhaustive-deps



    const handleSubmit =(e) =>{
        e.preventDefault();
        props.addMessage(record, props.token);
        setIsSubmit(true);
        setRecord(initialValues);
    }

    const removeMessage = (slug, data) =>{
        props.deleteMessage(slug, data, props.token);
        setIsSubmit(true);
    }



  return (
      <ManagementLayout>
            <div className='card chat'>
                <div className="chatHeader" >
                    <Avatar
                        src={`https://avatars.dicebear.com/api/human/${seed}.svg`}
                    />
                    <div className="chatHeaderInfo">
                        <h5>{chat.name}</h5>
                        <p>Last seen ...</p>

                    </div>
                    <div className="chatHeaderRight">
                        <IconButton>
                            <SearchIcon/>
                        </IconButton>
                        <IconButton>
                            <ChatBubbleOutlineIcon/>
                        </IconButton>
                        <IconButton>
                            <MoreVertIcon/>
                        </IconButton>

                    </div>
                </div>


                {currentColor === 'dark'
                  ?<div className="card-w-title live-support">
                      <ul>
                        {props.chatmessages.map((message) => {
                                const {author, slug} = message;
                                return(
                                      <>
                                        {author === userName ?
                                            <li
                                              key={slug}
                                              className="message-to"
                                              style={{fontFamily: 'cursive', textAlign: 'center'}}

                                            >
                                              <div className="grid">
                                                  <div className="col">
                                                    <div
                                                      className="chat-message"
                                                        style={{background: 'radial-gradient(100% 1126.49% at 100% 0%, rgba(106, 200, 212, 0.4) 0%, rgba(33, 33, 33, 0) 100%), rgb(33, 33, 33)'}}
                                                    >
                                                      {message.body}
                                                    <br/>
                                                      <span className='chatTimestamp'>
                                                          {renderTimestamp(message.created_at)}
                                                      </span>
                                                      <Button
                                                          icon='pi pi-trash'
                                                          className="p-button-text p-button-danger"
                                                          onClick={() => removeMessage(message.slug, message)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-fixed">
                                                    <span>me</span>
                                                  </div>

                                              </div>
                                            </li>
                                            :<li
                                              key={slug}
                                              className="message-from"
                                              style={{fontFamily: 'cursive', textAlign: 'center'}}

                                            >
                                              <div className="grid">
                                                  <div className="col-fixed">
                                                    <span>{author}</span>
                                                  </div>
                                                  <div className="col">
                                                    <div
                                                      className="chat-message"
                                                      style={{background: 'radial-gradient(100% 1126.49% at 100% 0%, rgba(50, 200, 212, 0.4) 0%, rgba(20, 20, 20, 0) 100%), rgb(20, 20, 20)'}}

                                                    >
                                                      {message.body}
                                                    <br/>
                                                      <span className='chatTimestamp'>
                                                          {renderTimestamp(message.created_at)}
                                                      </span>

                                                    </div>
                                                  </div>
                                              </div>
                                            </li>
                                        }
                                    </>
                                )

                            })

                        }

                      </ul>
                  </div>
                  :<div className="card-w-title live-chat">
                      <ul>
                        {props.chatmessages.map((message) => {
                                const {author, slug} = message;
                                return(
                                      <>
                                        {author === userName ?
                                            <li
                                              key={slug}
                                              className="message-to"
                                              style={{fontFamily: 'cursive', textAlign: 'center'}}

                                            >
                                              <div className="grid">
                                                  <div className="col">
                                                    <div
                                                      className="chat-message"
                                                    >
                                                      {message.body}
                                                    <br/>
                                                      <span className='chatTimestamp'>
                                                          {renderTimestamp(message.created_at)}
                                                      </span>
                                                      <Button
                                                          icon='pi pi-trash'
                                                          className="p-button-text p-button-danger"
                                                          onClick={() => removeMessage(message.slug, message)}
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-fixed">
                                                    <span>me</span>
                                                  </div>

                                              </div>
                                            </li>
                                            :<li
                                              key={slug}
                                              className="message-from"
                                              style={{fontFamily: 'cursive', textAlign: 'center'}}

                                            >
                                              <div className="grid">
                                                  <div className="col-fixed">
                                                    <span>{author}</span>
                                                  </div>
                                                  <div className="col">
                                                    <div
                                                      className="chat-message"


                                                    >
                                                      {message.body}
                                                    <br/>
                                                      <span className='chatTimestamp'>
                                                          {renderTimestamp(message.created_at)}
                                                      </span>

                                                    </div>
                                                  </div>
                                              </div>
                                            </li>
                                        }
                                    </>
                                )

                            })

                        }

                      </ul>
                  </div>
                }

                <ul  className="list-none card p-0 m-0" style={{textAlign: 'center'}}>


                        <form onSubmit={handleSubmit}>
                            <li className="flex card align-items-center chat-link py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-primary w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputTextarea
                                        id="username"
                                        style={{width: '100%'}}
                                        rows={1}
                                        cols={30}
                                        autoResize
                                        required
                                        value={record.body}
                                        onChange={(e) => onInputChange(e, 'body')}
                                    />
                                </div>
                                <div className="text-primary w-full md:w-2 md:flex-order-0 flex-order-1">
                                    <Button
                                        icon='pi pi-send'
                                        label='send'
                                        type='submit'
                                        className="p-button-text p-button-primary"
                                    />
                                </div>

                            </li>
                        </form>



                </ul>
            </div>
    </ManagementLayout>
  )
};

const mapStateToProps = (state) =>({
    token: state.auth.token,
    userName: state.auth.userName,
    chatmessages: state.chats.chatmessages,
    chat: state.chats.chat,
})


export default connect(mapStateToProps, {getChat, getChatMessages, addMessage, deleteMessage})(Chat);

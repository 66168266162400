import React from 'react';
import { Button } from 'primereact/button';
import PublicLayout from './public/layout/PublicLayout';
import { useNavigate } from 'react-router-dom';
import feature2 from './images/illustrations/404.svg';


const UnAuthorized = () => {
    const navigate = useNavigate()

    return(
        <PublicLayout>
          <div className="flex card">
              <div className="w-12 sm:w-6 px-4 py-8 md:px-6 lg:px-8" style={{textAlign: 'center',fontFamily: 'cursive'}}>
                <div className="border-left-2 border-pink-500">
                  <span className="bg-white text-pink-500 font-bold text-2xl inline-block px-3">
                    404
                  </span>
                </div>
                <div className="mt-6 mb-5 text-primary font-bold text-6xl text-900">
                  Page Not Found
                </div>
                <p className="text-700 text-primary text-3xl mt-0 mb-6">
                  Sorry, we couldn't find the page.
                </p>
                <div>
                  <Button
                    label="GO BACK TO DASHBOARD"
                    icon="pi pi-arrow-left"
                    onClick={() =>{navigate('/login')}}
                    className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                  />
                  </div>
                  </div>
                  <div
                    className="w-6 hidden sm:block"
                    style={{backgroundImage: `url(${feature2}`}}
                  >
                  </div>
              </div>
        </PublicLayout>

    );
}


export default UnAuthorized;

import {
    GET_FAQS,
    ADD_FAQ,
    EDIT_FAQ,
    DELETE_FAQ
} from '../types/faqTypes';



const initialState = {
    faqs: [],
};





export default function faqs(state = initialState, action){

    switch(action.type){

        case GET_FAQS:
            return {
                ...state,
               faqs : action.payload
            };

        case ADD_FAQ:
            return {
                ...state,
               faq: [...state.faqs, action.payload]
            }

        case EDIT_FAQ:
            const faqArray = state.faqs;
           faqArray.splice(
                   faqArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
               faqs:faqArray,
            };
        
        case DELETE_FAQ:
            return {
                ...state,
               faq: state.faqs.filter(faq=>faq.slug !== action.payload)
            };
    
        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import PublicLayout from '../layout/PublicLayout';
import { RadioButton } from 'primereact/radiobutton';
import RecruiterRegistration from "./RecruiterRegistration";
import JobseekerRegistration from "./JobseekerRegistration";
import { Dropdown } from 'primereact/dropdown';



const Registration = (props) => {

    const [hour, setHour] = useState(null);
    const [type, setType] = useState('jobseeker')

    let typeOptions = [
      {"key": "jobseeker"},
      {"key": "employer"},
    ]


    const onTypeChange = (e) => {
      setType(e.value);
    }

    const getHour = () => {
       const date = new Date();
       const hour = date.getHours()
       setHour(hour);
    }


    useEffect(() => {
      getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;




    const getForm = () =>{
      if (type === 'jobseeker'){
        return(
          <>
            <JobseekerRegistration/>
          </>

        );
      }else if (type ==='employer'){
        return(
          <>
            <RecruiterRegistration/>
          </>
        );
      }else{
        return(
          <>

          </>
        );

      }

    }





    const getHourDisplay = () => {

        if (hour < 12){
          return(
            <>
              Good Morning!
            </>
          )
        }else if(hour < 18){
          return (
            <>Good Afternoon!!</>
          )
        }else{
          return(
            <>Good Evening!!</>
          );
        }
    }


    return (
      <PublicLayout>
        <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
          <div className="col-12 md:col-12">
            <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center">
                  <div className="text-900 font-bold text-5xl mb-3">{getHourDisplay()}</div>
                  <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
              </div>
            </div>

              <div className="tabview-demos">
                  <div className="card" style={{textAlign: 'center'}}>
                      <div className=" text-700 text-center">

                        <div className="text-900 font-bold text-5xl mb-3">Choose Signup Option</div>
                          <Dropdown
                            value={type}
                            onChange={(e) => setType(e.value)}
                            options={typeOptions}
                            optionLabel="key"
                            optionValue="key"
                            editable
                            placeholder="Select Type of User"
                            className="w-full md:w-14rem"
                          />
                      </div>
                        <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                          <li className="flex align-items-center py-3 px-2 ">

                          </li>
                        </ul>
                      {getForm()}

                  </div>
                </div>
              </div>
            </div>

        </PublicLayout>
    )
}



export default Registration;

export const GET_SKILLS = "GET_SKILLS";
export const ADD_SKILL = "ADD_SKILL";
export const EDIT_SKILL = "EDIT_SKILL";
export const DELETE_SKILL = "DELETE_SKILL";
export const GET_FACULTYS = "GET_FACULTYS";
export const ADD_FACULTY = "ADD_FACULTY";
export const EDIT_FACULTY = "EDIT_FACULTY";
export const DELETE_FACULTY = "DELETE_FACULTY";
export const GET_ACADEMIC_QUALIFICATIONS = "GET_ACADEMIC_QUALIFICATIONS";
export const ADD_ACADEMIC_QUALIFICATION = "ADD_ACADEMIC_QUALIFICATION";
export const EDIT_ACADEMIC_QUALIFICATION = "EDIT_ACADEMIC_QUALIFICATION";
export const DELETE_ACADEMIC_QUALIFICATION = "DELETE_ACADEMIC_QUALIFICATION";



export const GET_NEAREST_NEIGHBOURS = "GET_NEAREST_NEIGHBOURS";
export const ADD_NEAREST_NEIGHBOUR = "ADD_NEAREST_NEIGHBOUR";
export const EDIT_NEAREST_NEIGHBOUR = "EDIT_NEAREST_NEIGHBOUR";
export const DELETE_NEAREST_NEIGHBOUR = "DELETE_NEAREST_NEIGHBOUR";
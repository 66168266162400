import React, {useState, useEffect} from 'react';
import { Password } from 'primereact/password';
import {
  validatePassword,
} from '../../../actions/validations';
import {connect} from 'react-redux';


const PasswordInput = (props) => {
  const {
    passwordClear,
    setPasswordClear,
    password2Clear,
    setPassword2Clear,
    password,
    password2,
    onPasswordInputChange,
    onPassword2InputChange,
    passwordValidation,
    username,
    setSubmitCleared,
    setGalivanting,
  } =props;

  const [checking, setChecking] = useState(false);
  const [payload, setPayload] = useState({});
  const [errors, setErrors] = useState({})
  const [passwordStatus, setPasswordStatus] = useState('');
  const initialParams ={
    password:password,
    username: username
  }



  useEffect(() => {
    if (password !== ''){
      const timer = setTimeout(() => {
        props.validatePassword(initialParams)
        setChecking(true);
        setPasswordClear(false);
      }, 3000)

      return () => clearTimeout(timer)
    }



  }, [password])

  useEffect(() =>{
    setChecking(false);
    if (passwordValidation.password_length !==undefined){
      let validation = {...passwordValidation}
      setPayload({
            password_length: validation.password_length,
            characters: validation.characters,
            vulnerability: validation.vulnerability,
            username_test: validation.username_test,

      })
    }else{
      setPayload({})
    }

  }, [passwordValidation, checking])

  useEffect(() =>{
    if (password2 !== password){
      setPasswordStatus('Passwords Do Not Match');
      setSubmitCleared(false)
    }else{
      setPasswordStatus('');
      if (passwordValidation.password_length && passwordValidation.characters && passwordValidation.vulnerability && passwordValidation.username_test ){
        setSubmitCleared(true);
        setGalivanting(true);
      }
    }

  }, [password2])

  useEffect(() =>{
    if (password !== password2){
      setPasswordStatus('Passwords Do Not Match');
      setSubmitCleared(false)
    }else{
      setPasswordStatus('');
      if (passwordValidation.password_length && passwordValidation.characters && passwordValidation.vulnerability && passwordValidation.username_test ){
        setSubmitCleared(true);
        setGalivanting(true);
      }
    }

  }, [password])

  const displayPasswordMessage = (payload) =>{
    if(payload.password_length === undefined){
      return;
    }
    return (
      <>
        {!payload.password_length
          ?<small
            id="username-help mb-3"

            className="p-error">
              Password Should be at least 8 Characters !!
          </small>
          :<></>
        }
      <br/>
        {!payload.characters
          ?<small
            id="username-help mb-3"

            className="p-error">
              Password Not Strong.(Add at least 1 Uppercase,  1 Alphanumeric, 1 Digit, 1 Lowercase )
          </small>
          :<></>
        }
        <br/>
        {!payload.vulnerability
          ?<small
            id="username-help mb-3"

            className="p-error">
              The password you entered was found in the Vulnerable Password List!!!
          </small>
          :<></>
        }
        <br/>
        {!payload.username_test
          ?<small
            id="username-help mb-3"

            className="p-error">
              Sorry your Password cannot be similar to your username!!!
          </small>
          :<></>
        }
        <br/>
      </>
    )

  }

  return (
    <ul  style={{textAlign: 'center'}} className="list-none card grid p-fluid p-0 m-0" >
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
        <div
            className="w-full md:w-12 md:flex-order-0 flex-order-1"
        >
            {displayPasswordMessage(payload)}
        </div>
          <div
              className="w-full mb-3  md:w-12 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px'}}
          >

              SET PASSWORD
          </div>


          <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
          <Password
              className="w-full mb-3"
              id="password"
              value={password}
              onChange={(e) => onPasswordInputChange(e,"password")}
              toggleMask
              feedback={false}
          />

        </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full mb-3  md:w-12 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px'}}
          >

              CONFIRM PASSWORD
          </div>
          <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
          <small id="username2-help" className="p-error block">{passwordStatus}</small>


          <Password
              className="w-full mb-3"
              id="password2"
              value={password2}
              onChange={(e) => onPassword2InputChange(e,"password2")}
              feedback={false}
              toggleMask
          />
          <small id="username2-help" className="p-error block">
          </small>
          </div>
      </li>

    </ul>
  )
}

const mapStateToProps = state =>({
  passwordValidation: state.validations.passwordValidation,
  loading: state.validations.loading,
})

export default connect(mapStateToProps, {validatePassword}) (PasswordInput);

import axios from 'axios';
import {
    GET_CONTINENTS,
    EDIT_CONTINENT,
    DELETE_CONTINENT,
    ADD_CONTINENT,
    GET_COUNTRIES,
    EDIT_COUNTRY,
    DELETE_COUNTRY,
    ADD_COUNTRY,
    GET_PROVINCES,
    EDIT_PROVINCE,
    DELETE_PROVINCE,
    ADD_PROVINCE,
    GET_DISTRICTS,
    EDIT_DISTRICT,
    DELETE_DISTRICT,
    ADD_DISTRICT,

} from '../types/placeTypes';

import {
    continentsURL,
    countriesURL,
    provincesURL,
    districtsURL
} from '../constants';
import {  returnErrors } from './messages';




export const getContinents = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(continentsURL, headers)
        .then(res => {
            dispatch({
                type: GET_CONTINENTS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addContinent = (continent, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(continentsURL, continent, headers)
        .then(res => {
            dispatch({
                type: ADD_CONTINENT,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteContinent = (id, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(continentsURL, id, headers)
        .then(res => {
            dispatch({
                type: DELETE_CONTINENT,
                payload: id
            });
        }).catch(err => console.log(err))
}




export const editContinent = (id, continent, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(id, null, 3)

    axios.patch(`${continentsURL}${id}/`, continent, headers)
        .then(res => {
            dispatch({
                type: EDIT_CONTINENT,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getCountries = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(countriesURL, headers)
        .then(res => {
            dispatch({
                type: GET_COUNTRIES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCountry = (country, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(countriesURL, country, headers)
        .then(res => {
            dispatch({
                type: ADD_COUNTRY,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteCountry = (id, token) => dispatch => {
    let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(countriesURL, id, headers)
        .then(res => {
            dispatch({
                type: DELETE_COUNTRY,
                payload: id
            });
        }).catch(err => console.log(err))
}




export const editCountry = (id, country, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(id, null, 3)

    axios.patch(`${countriesURL}${id}/`, country, headers)
        .then(res => {
            dispatch({
                type: EDIT_COUNTRY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getProvinces = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(provincesURL, headers)
        .then(res => {
            dispatch({
                type: GET_PROVINCES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addProvince = (province, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(provincesURL, province, headers)
        .then(res => {
            dispatch({
                type: ADD_PROVINCE,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteProvince = (id, token) => dispatch => {
    let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(provincesURL, id, headers)
        .then(res => {
            dispatch({
                type: DELETE_PROVINCE,
                payload: id
            });
        }).catch(err => console.log(err))
}




export const editProvince = (id, province, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(id, null, 3)

    axios.patch(`${provincesURL}${id}/`, province, headers)
        .then(res => {
            dispatch({
                type: EDIT_PROVINCE,
                payload: res.data
            });
        }).catch(err => console.log(err))
}




export const getDistricts = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(districtsURL, headers)
        .then(res => {
            dispatch({
                type: GET_DISTRICTS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addDistrict = (district, token) => dispatch => {
    let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
    };

    axios.post(districtsURL, district, headers)
        .then(res => {
            dispatch({
                type: ADD_DISTRICT,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteDistrict = (id, token) => dispatch => {
    let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(districtsURL, id, headers)
        .then(res => {
            dispatch({
                type: DELETE_DISTRICT,
                payload: id
            });
        }).catch(err => console.log(err))
}




export const editDistrict = (id, district, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(id, null, 3)

    axios.patch(`${districtsURL}${id}/`, district, headers)
        .then(res => {
            dispatch({
                type: EDIT_DISTRICT,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

export const GET_CANDIDATE_ACADEMIC_QUALIFICATIONS = "GET_CANDIDATE_ACADEMIC_QUALIFICATIONS";
export const ADD_CANDIDATE_ACADEMIC_QUALIFICATION = "ADD_CANDIDATE_ACADEMIC_QUALIFICATION";
export const EDIT_CANDIDATE_ACADEMIC_QUALIFICATION = "EDIT_CANDIDATE_ACADEMIC_QUALIFICATION";
export const DELETE_CANDIDATE_ACADEMIC_QUALIFICATION = "DELETE_CANDIDATE_ACADEMIC_QUALIFICATION";
export const GET_CANDIDATE_AVAILABILITY = "GET_CANDIDATE_AVAILABILITY";
export const ADD_CANDIDATE_AVAILABILITY = "ADD_CANDIDATE_AVAILABILITY";
export const EDIT_CANDIDATE_AVAILABILITY = "EDIT_CANDIDATE_AVAILABILITY";
export const DELETE_CANDIDATE_AVAILABILITY = "DELETE_CANDIDATE_AVAILABILITY";
export const GET_CANDIDATE_RESUME = "GET_CANDIDATE_RESUME";
export const ADD_CANDIDATE_RESUME = "ADD_CANDIDATE_RESUME";
export const EDIT_CANDIDATE_RESUME = "EDIT_CANDIDATE_RESUME";

export const DELETE_CANDIDATE_RESUME = "DELETE_CANDIDATE_RESUME";
export const GET_CANDIDATE_LANGUAGES = "GET_CANDIDATE_LANGUAGES";
export const ADD_CANDIDATE_LANGUAGE = "ADD_CANDIDATE_LANGUAGE";
export const EDIT_CANDIDATE_LANGUAGE = "EDIT_CANDIDATE_LANGUAGE";

export const DELETE_CANDIDATE_LANGUAGE = "DELETE_CANDIDATE_LANGUAGE";
export const GET_CANDIDATE_ROLES = "GET_CANDIDATE_ROLES";
export const ADD_CANDIDATE_ROLE = "ADD_CANDIDATE_ROLE";
export const EDIT_CANDIDATE_ROLE = "EDIT_CANDIDATE_ROLE";
export const DELETE_CANDIDATE_ROLE = "DELETE_CANDIDATE_ROLE";
export const GET_CANDIDATE_LINKS = "GET_CANDIDATE_LINKS";
export const ADD_CANDIDATE_LINK = "ADD_CANDIDATE_LINK";
export const EDIT_CANDIDATE_LINK = "EDIT_CANDIDATE_LINK";
export const DELETE_CANDIDATE_LINK = "DELETE_CANDIDATE_LINK";


export const GET_CANDIDATE_SKILLS = "GET_CANDIDATE_SKILLS";
export const ADD_CANDIDATE_SKILL = "ADD_CANDIDATE_SKILL";
export const EDIT_CANDIDATE_SKILL = "EDIT_CANDIDATE_SKILL";
export const DELETE_CANDIDATE_SKILL = "DELETE_CANDIDATE_SKILL";

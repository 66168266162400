import {
    GET_DAILY_JOB_REPORTS_START,
    GET_DAILY_JOB_REPORTS_SUCCESS,
    GET_DAILY_JOB_REPORTS_FAIL,
    GET_MANAGEMENT_JOB_WEEKLY_REPORTS_START,
    GET_MANAGEMENT_JOB_WEEKLY_REPORTS_SUCCESS,
    GET_MANAGEMENT_JOB_WEEKLY_REPORTS_FAIL,
    GET_MANAGEMENT_JOB_MONTHLY_REPORTS_START,
    GET_MANAGEMENT_JOB_MONTHLY_REPORTS_SUCCESS,
    GET_MANAGEMENT_JOB_MONTHLY_REPORTS_FAIL,
    GET_MANAGEMENT_JOB_YEARLY_REPORTS_START,
    GET_MANAGEMENT_JOB_YEARLY_REPORTS_SUCCESS,
    GET_MANAGEMENT_JOB_YEARLY_REPORTS_FAIL,
    GET_RECRUITER_DAILY_JOB_REPORTS_START,
    GET_RECRUITER_DAILY_JOB_REPORTS_SUCCESS,
    GET_RECRUITER_DAILY_JOB_REPORTS_FAIL,
    GET_RECRUITER_JOB_WEEKLY_REPORTS_START,
    GET_RECRUITER_JOB_WEEKLY_REPORTS_SUCCESS,
    GET_RECRUITER_JOB_WEEKLY_REPORTS_FAIL,
    GET_RECRUITER_JOB_MONTHLY_REPORTS_START,
    GET_RECRUITER_JOB_MONTHLY_REPORTS_SUCCESS,
    GET_RECRUITER_JOB_MONTHLY_REPORTS_FAIL,
    GET_RECRUITER_JOB_YEARLY_REPORTS_START,
    GET_RECRUITER_JOB_YEARLY_REPORTS_SUCCESS,
    GET_RECRUITER_JOB_YEARLY_REPORTS_FAIL,
    GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_EMPLOYER_DAILY_JOB_REPORTS_START,
    GET_EMPLOYER_DAILY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_DAILY_JOB_REPORTS_FAIL,
    GET_EMPLOYER_WEEKLY_JOB_REPORTS_START,
    GET_EMPLOYER_WEEKLY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_WEEKLY_JOB_REPORTS_FAIL,
    GET_EMPLOYER_MONTHLY_JOB_REPORTS_START,
    GET_EMPLOYER_MONTHLY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL,
    GET_EMPLOYER_YEARLY_JOB_REPORTS_START,
    GET_EMPLOYER_YEARLY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_YEARLY_JOB_REPORTS_FAIL,
    GET_MANAGEMENT_STATS_START,
    GET_MANAGEMENT_STATS_SUCCESS,
    GET_MANAGEMENT_STATS_FAIL,

    GET_MANAGEMENT_MONTHLY_JOB_DATA_START,
    GET_MANAGEMENT_MONTHLY_JOB_DATA_SUCCESS,
    GET_MANAGEMENT_MONTHLY_JOB_DATA_FAIL,
    GET_RECRUITER_STATS_START,
    GET_RECRUITER_STATS_SUCCESS,
    GET_RECRUITER_STATS_FAIL,
    GET_RECRUITER_MONTHLY_JOB_DATA_START,
    GET_RECRUITER_MONTHLY_JOB_DATA_SUCCESS,
    GET_RECRUITER_MONTHLY_JOB_DATA_FAIL,

} from '../types/reportTypes';
import {updateObject} from './utility';


const initialState = {
    dailyJobReports: {},
    managementJobWeeklyReports: {},
    managementJobMonthlyReports: {},
    managementJobYearlyReports: {},
    recruiterDailyJobReports: {},
    recruiterJobWeeklyReports: {},
    recruiterJobMonthlyReports: {},
    recruiterJobYearlyReports: {},
    managementDailyJobApplicationReports: {},
    managementWeeklyJobApplicationReports: {},
    managementMonthlyJobApplicationReports: {},
    managementYearlyJobApplicationReports: {},
    recruiterDailyJobApplicationReports: {},
    recruiterWeeklyJobApplicationReports: {},
    recruiterMonthlyJobApplicationReports: {},
    recruiterYearlyJobApplicationReports: {},
    candidateDailyJobApplicationReports: {},
    candidateWeeklyJobApplicationReports: {},
    candidateMonthlyJobApplicationReports: {},
    candidateYearlyJobApplicationReports: {},
    managementCandidateDailyJobApplicationReports: {},
    managementCandidateWeeklyJobApplicationReports: {},
    managementCandidateMonthlyJobApplicationReports: {},
    managementCandidateYearlyJobApplicationReports: {},
    employerDailyJobReports: {},
    employerWeeklyJobReports: {},
    employerMonthlyJobReports: {},
    employerYearlyJobReports: {},
    managementStats: {},
    managementMonthlyJobData: {},
    recruiterStats: {},
    recruiterMonthlyJobData: {},
    error: null,
    loading: false,


};


const getDailyJobReportsStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getDailyJobReportsSuccess = (state, action) => {
    return updateObject(state, {
      dailyJobReports: action.dailyJobReports,
      error: null,
      loading: false
    });
  };


const getDailyJobReportsFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};


//WEEKLY
const getManagementJobWeeklyReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementJobWeeklyReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementJobWeeklyReports: action.managementJobWeeklyReports,
    error: null,
    loading: false
  });
};


const getManagementJobWeeklyReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//monthly
const getManagementJobMonthlyReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementJobMonthlyReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementJobMonthlyReports: action.managementJobMonthlyReports,
    error: null,
    loading: false
  });
};


const getManagementJobMonthlyReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//yearly
const getManagementJobYearlyReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementJobYearlyReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementJobYearlyReports: action.managementJobYearlyReports,
    error: null,
    loading: false
  });
};


const getManagementJobYearlyReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//RECRUITER

const getRecruiterDailyJobReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterDailyJobReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterDailyJobReports: action.recruiterDailyJobReports,
    error: null,
    loading: false
  });
};


const getRecruiterDailyJobReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//WEEKLY
const getRecruiterJobWeeklyReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterJobWeeklyReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterJobWeeklyReports: action.recruiterJobWeeklyReports,
    error: null,
    loading: false
  });
};


const getRecruiterJobWeeklyReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//monthly
const getRecruiterJobMonthlyReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterJobMonthlyReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterJobMonthlyReports: action.recruiterJobMonthlyReports,
    error: null,
    loading: false
  });
};


const getRecruiterJobMonthlyReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//yearly
const getRecruiterJobYearlyReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterJobYearlyReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterJobYearlyReports: action.recruiterJobYearlyReports,
    error: null,
    loading: false
  });
};


const getRecruiterJobYearlyReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//  MANAGEMENT APPLICATIONS
const getManagementDailyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementDailyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementDailyJobApplicationReports: action.managementDailyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementDailyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getManagementWeeklyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementWeeklyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementWeeklyJobApplicationReports: action.managementWeeklyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementWeeklyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getManagementMonthlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementMonthlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementMonthlyJobApplicationReports: action.managementMonthlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementMonthlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getManagementYearlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementYearlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementYearlyJobApplicationReports: action.managementYearlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementYearlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//RECRUITER APPLICATIONS
const getRecruiterDailyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterDailyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterDailyJobApplicationReports: action.recruiterDailyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getRecruiterDailyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getRecruiterWeeklyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterWeeklyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterWeeklyJobApplicationReports: action.recruiterWeeklyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getRecruiterWeeklyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getRecruiterMonthlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterMonthlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterMonthlyJobApplicationReports: action.recruiterMonthlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getRecruiterMonthlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getRecruiterYearlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterYearlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterYearlyJobApplicationReports: action.recruiterYearlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getRecruiterYearlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//CANDIDATE
const getCandidateDailyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateDailyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    candidateDailyJobApplicationReports: action.candidateDailyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getCandidateDailyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getCandidateWeeklyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateWeeklyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    candidateWeeklyJobApplicationReports: action.candidateWeeklyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getCandidateWeeklyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getCandidateMonthlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateMonthlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    candidateMonthlyJobApplicationReports: action.candidateMonthlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getCandidateMonthlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getCandidateYearlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateYearlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    candidateYearlyJobApplicationReports: action.candidateYearlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getCandidateYearlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//MANAGEMENT
const getManagementCandidateDailyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementCandidateDailyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementCandidateDailyJobApplicationReports: action.managementCandidateDailyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementCandidateDailyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getManagementCandidateWeeklyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementCandidateWeeklyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementCandidateWeeklyJobApplicationReports: action.managementCandidateWeeklyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementCandidateWeeklyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getManagementCandidateMonthlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementCandidateMonthlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementCandidateMonthlyJobApplicationReports: action.managementCandidateMonthlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementCandidateMonthlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const getManagementCandidateYearlyJobApplicationReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementCandidateYearlyJobApplicationReportsSuccess = (state, action) => {
  return updateObject(state, {
    managementCandidateYearlyJobApplicationReports: action.managementCandidateYearlyJobApplicationReports,
    error: null,
    loading: false
  });
};


const getManagementCandidateYearlyJobApplicationReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


//EMPLOYER
const getEmployerDailyJobReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getEmployerDailyJobReportsSuccess = (state, action) => {
  return updateObject(state, {
    employerDailyJobReports: action.employerDailyJobReports,
    error: null,
    loading: false
  });
};


const getEmployerDailyJobReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getEmployerWeeklyJobReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getEmployerWeeklyJobReportsSuccess = (state, action) => {
  return updateObject(state, {
    employerWeeklyJobReports: action.employerWeeklyJobReports,
    error: null,
    loading: false
  });
};


const getEmployerWeeklyJobReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getEmployerMonthlyJobReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getEmployerMonthlyJobReportsSuccess = (state, action) => {
  return updateObject(state, {
    employerMonthlyJobReports: action.employerMonthlyJobReports,
    error: null,
    loading: false
  });
};


const getEmployerMonthlyJobReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getEmployerYearlyJobReportsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getEmployerYearlyJobReportsSuccess = (state, action) => {
  return updateObject(state, {
    employerYearlyJobReports: action.employerYearlyJobReports,
    error: null,
    loading: false
  });
};


const getEmployerYearlyJobReportsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getManagementStatsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementStatsSuccess = (state, action) => {
  return updateObject(state, {
    managementStats: action.managementStats,
    error: null,
    loading: false
  });
};


const getManagementStatsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



const getManagementMonthlyJobDataStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getManagementMonthlyJobDataSuccess = (state, action) => {
  return updateObject(state, {
    managementMonthlyJobData: action.managementMonthlyJobData,
    error: null,
    loading: false
  });
};


const getManagementMonthlyJobDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getRecruiterStatsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterStatsSuccess = (state, action) => {
  return updateObject(state, {
    recruiterStats: action.recruiterStats,
    error: null,
    loading: false
  });
};


const getRecruiterStatsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



const getRecruiterMonthlyJobDataStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getRecruiterMonthlyJobDataSuccess = (state, action) => {
  return updateObject(state, {
    recruiterMonthlyJobData: action.recruiterMonthlyJobData,
    error: null,
    loading: false
  });
};


const getRecruiterMonthlyJobDataFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



export default function reports(state = initialState, action){

    switch(action.type){
        case GET_DAILY_JOB_REPORTS_START:
            return getDailyJobReportsStart(state, action);
        case GET_DAILY_JOB_REPORTS_SUCCESS:
            return getDailyJobReportsSuccess(state, action);
        case GET_DAILY_JOB_REPORTS_FAIL:
            return getDailyJobReportsFail(state, action);

        //WEEKLY
        case GET_MANAGEMENT_JOB_WEEKLY_REPORTS_START:
            return getManagementJobWeeklyReportsStart(state, action);
        case GET_MANAGEMENT_JOB_WEEKLY_REPORTS_SUCCESS:
            return getManagementJobWeeklyReportsSuccess(state, action);
        case GET_MANAGEMENT_JOB_WEEKLY_REPORTS_FAIL:
            return getManagementJobWeeklyReportsFail(state, action);

        //MONTHLY
        case GET_MANAGEMENT_JOB_MONTHLY_REPORTS_START:
            return getManagementJobMonthlyReportsStart(state, action);
        case GET_MANAGEMENT_JOB_MONTHLY_REPORTS_SUCCESS:
            return getManagementJobMonthlyReportsSuccess(state, action);
        case GET_MANAGEMENT_JOB_MONTHLY_REPORTS_FAIL:
            return getManagementJobMonthlyReportsFail(state, action);

        //YEARLY
        case GET_MANAGEMENT_JOB_YEARLY_REPORTS_START:
            return getManagementJobYearlyReportsStart(state, action);
        case GET_MANAGEMENT_JOB_YEARLY_REPORTS_SUCCESS:
            return getManagementJobYearlyReportsSuccess(state, action);
        case GET_MANAGEMENT_JOB_YEARLY_REPORTS_FAIL:
            return getManagementJobYearlyReportsFail(state, action);


        //RECRUITER
        case GET_RECRUITER_DAILY_JOB_REPORTS_START:
            return getRecruiterDailyJobReportsStart(state, action);
        case GET_RECRUITER_DAILY_JOB_REPORTS_SUCCESS:
            return getRecruiterDailyJobReportsSuccess(state, action);
        case GET_RECRUITER_DAILY_JOB_REPORTS_FAIL:
            return getRecruiterDailyJobReportsFail(state, action);

        //WEEKLY
        case GET_RECRUITER_JOB_WEEKLY_REPORTS_START:
            return getRecruiterJobWeeklyReportsStart(state, action);
        case GET_RECRUITER_JOB_WEEKLY_REPORTS_SUCCESS:
            return getRecruiterJobWeeklyReportsSuccess(state, action);
        case GET_RECRUITER_JOB_WEEKLY_REPORTS_FAIL:
            return getRecruiterJobWeeklyReportsFail(state, action);

        //MONTHLY
        case GET_RECRUITER_JOB_MONTHLY_REPORTS_START:
            return getRecruiterJobMonthlyReportsStart(state, action);
        case GET_RECRUITER_JOB_MONTHLY_REPORTS_SUCCESS:
            return getRecruiterJobMonthlyReportsSuccess(state, action);
        case GET_RECRUITER_JOB_MONTHLY_REPORTS_FAIL:
            return getRecruiterJobMonthlyReportsFail(state, action);

        //YEARLY
        case GET_RECRUITER_JOB_YEARLY_REPORTS_START:
            return getRecruiterJobYearlyReportsStart(state, action);
        case GET_RECRUITER_JOB_YEARLY_REPORTS_SUCCESS:
            return getRecruiterJobYearlyReportsSuccess(state, action);
        case GET_RECRUITER_JOB_YEARLY_REPORTS_FAIL:
            return getRecruiterJobYearlyReportsFail(state, action);

        //MANAGEMENT APPLICATIONS
        case GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_START:
            return getManagementDailyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementDailyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementDailyJobApplicationReportsFail(state, action);
        case GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_START:
            return getManagementWeeklyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementWeeklyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementWeeklyJobApplicationReportsFail(state, action);
        case GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_START:
            return getManagementMonthlyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementMonthlyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementMonthlyJobApplicationReportsFail(state, action);
        case GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_START:
            return getManagementYearlyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementYearlyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementYearlyJobApplicationReportsFail(state, action);
        //RECRUITER APPLICATIONS
        case GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_START:
            return getRecruiterDailyJobApplicationReportsStart(state, action);
        case GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getRecruiterDailyJobApplicationReportsSuccess(state, action);
        case GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_FAIL:
            return getRecruiterDailyJobApplicationReportsFail(state, action);
        case GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_START:
            return getRecruiterWeeklyJobApplicationReportsStart(state, action);
        case GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getRecruiterWeeklyJobApplicationReportsSuccess(state, action);
        case GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_FAIL:
            return getRecruiterWeeklyJobApplicationReportsFail(state, action);
        case GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_START:
            return getRecruiterMonthlyJobApplicationReportsStart(state, action);
        case GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getRecruiterMonthlyJobApplicationReportsSuccess(state, action);
        case GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_FAIL:
            return getRecruiterMonthlyJobApplicationReportsFail(state, action);
        case GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_START:
            return getRecruiterYearlyJobApplicationReportsStart(state, action);
        case GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getRecruiterYearlyJobApplicationReportsSuccess(state, action);
        case GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_FAIL:
            return getRecruiterYearlyJobApplicationReportsFail(state, action);

        //CANDIDATE APPLICATIONS
        case GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START:
            return getCandidateDailyJobApplicationReportsStart(state, action);
        case GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getCandidateDailyJobApplicationReportsSuccess(state, action);
        case GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL:
            return getCandidateDailyJobApplicationReportsFail(state, action);
        case GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START:
            return getCandidateWeeklyJobApplicationReportsStart(state, action);
        case GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getCandidateWeeklyJobApplicationReportsSuccess(state, action);
        case GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL:
            return getCandidateWeeklyJobApplicationReportsFail(state, action);
        case GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START:
            return getCandidateMonthlyJobApplicationReportsStart(state, action);
        case GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getCandidateMonthlyJobApplicationReportsSuccess(state, action);
        case GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL:
            return getCandidateMonthlyJobApplicationReportsFail(state, action);
        case GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START:
            return getCandidateYearlyJobApplicationReportsStart(state, action);
        case GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getCandidateYearlyJobApplicationReportsSuccess(state, action);
        case GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL:
            return getCandidateYearlyJobApplicationReportsFail(state, action);

        //management
        case GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START:
            return getManagementCandidateDailyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementCandidateDailyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementCandidateDailyJobApplicationReportsFail(state, action);
        case GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START:
            return getManagementCandidateWeeklyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementCandidateWeeklyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementCandidateWeeklyJobApplicationReportsFail(state, action);
        case GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START:
            return getManagementCandidateMonthlyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementCandidateMonthlyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementCandidateMonthlyJobApplicationReportsFail(state, action);
        case GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START:
            return getManagementCandidateYearlyJobApplicationReportsStart(state, action);
        case GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS:
            return getManagementCandidateYearlyJobApplicationReportsSuccess(state, action);
        case GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL:
            return getManagementCandidateYearlyJobApplicationReportsFail(state, action);

        //employer
        case GET_EMPLOYER_DAILY_JOB_REPORTS_START:
          return getEmployerDailyJobReportsStart(state, action);
        case GET_EMPLOYER_DAILY_JOB_REPORTS_SUCCESS:
            return getEmployerDailyJobReportsSuccess(state, action);
        case GET_EMPLOYER_DAILY_JOB_REPORTS_FAIL:
            return getEmployerDailyJobReportsFail(state, action);
        case GET_EMPLOYER_WEEKLY_JOB_REPORTS_START:
          return getEmployerWeeklyJobReportsStart(state, action);
        case GET_EMPLOYER_WEEKLY_JOB_REPORTS_SUCCESS:
            return getEmployerWeeklyJobReportsSuccess(state, action);
        case GET_EMPLOYER_WEEKLY_JOB_REPORTS_FAIL:
            return getEmployerWeeklyJobReportsFail(state, action);
        case GET_EMPLOYER_MONTHLY_JOB_REPORTS_START:
          return getEmployerMonthlyJobReportsStart(state, action);
        case GET_EMPLOYER_MONTHLY_JOB_REPORTS_SUCCESS:
            return getEmployerMonthlyJobReportsSuccess(state, action);
        case GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL:
            return getEmployerMonthlyJobReportsFail(state, action);
        case GET_EMPLOYER_YEARLY_JOB_REPORTS_START:
          return getEmployerYearlyJobReportsStart(state, action);
        case GET_EMPLOYER_YEARLY_JOB_REPORTS_SUCCESS:
            return getEmployerYearlyJobReportsSuccess(state, action);
        case GET_EMPLOYER_YEARLY_JOB_REPORTS_FAIL:
            return getEmployerYearlyJobReportsFail(state, action);
        case GET_MANAGEMENT_STATS_START:
          return getManagementStatsStart(state, action);
        case GET_MANAGEMENT_STATS_SUCCESS:
            return getManagementStatsSuccess(state, action);
        case GET_MANAGEMENT_STATS_FAIL:
            return getManagementStatsFail(state, action);
        case GET_MANAGEMENT_MONTHLY_JOB_DATA_START:
          return getManagementMonthlyJobDataStart(state, action);
        case GET_MANAGEMENT_MONTHLY_JOB_DATA_SUCCESS:
            return getManagementMonthlyJobDataSuccess(state, action);
        case GET_MANAGEMENT_MONTHLY_JOB_DATA_FAIL:
            return getManagementMonthlyJobDataFail(state, action);

        case GET_RECRUITER_STATS_START:
          return getRecruiterStatsStart(state, action);
        case GET_RECRUITER_STATS_SUCCESS:
            return getRecruiterStatsSuccess(state, action);
        case GET_RECRUITER_STATS_FAIL:
            return getRecruiterStatsFail(state, action);

        case GET_RECRUITER_MONTHLY_JOB_DATA_START:
          return getRecruiterMonthlyJobDataStart(state, action);
        case GET_RECRUITER_MONTHLY_JOB_DATA_SUCCESS:
            return getRecruiterMonthlyJobDataSuccess(state, action);
        case GET_RECRUITER_MONTHLY_JOB_DATA_FAIL:
            return getRecruiterMonthlyJobDataFail(state, action);
        default:
            return state;
    }
}

import React from 'react';
import { useLocation, Navigate, Outlet } from "react-router-dom";
import {connect} from 'react-redux';


const RequireAuth = (props) => {
    const {token, allowedRoles, userRole} = props;
    const location = useLocation();
    let roles = [userRole, ]

    return (
        roles.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : token
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}



const mapStateToProps = state => {
    return {
      userRole: state.auth.userRole,
      token: state.auth.token,
    };
  };



  export default connect(
    mapStateToProps,
    {}
  )(RequireAuth);

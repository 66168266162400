import React, {useEffect, useState} from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import {useNavigate} from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge';
import { MultiSelect } from 'primereact/multiselect';




export const geographicalLocationOptions = [
    { key: 'WORLDWIDE', value: 'WORLDWIDE'},
    { key: 'AFRICA', value: 'AFRICA'},
    { key: 'EUROPE', value: 'EUROPE'},
    { key: 'NORTH AMERICA', value: 'NORTH AMERICA'},
    { key: 'SOUTH AMERICA', value: 'SOUTH AMERICA'},
    { key: 'ASIA', value: 'ASIA'},
]

export const Recommend = (props) => {
    const {
        params,
        setParams,
        jobOptions,
        candidateOptions,
        getRecommendationUsers,
        token,
        recommendPeople,
        initialParams,
    } =props;
    const [jobTitle, setJobTitle] = useState('');
    const [jobSlug, setJobSlug] = useState('');
    const [filteredCandidates, setFilteredCandidates] = useState([]);
    const initialValues = {
       people: [],
       job: '',
    }

    const [peopleRecord, setPeopleRecord] = useState(initialValues);


    const setJobParam = (value) => {
        let _params = {...params};
        _params['job'] = value;
        setParams(_params);
    }

    const setQualificationParam = () => {
        let _params = {...params};
        _params['qualification'] = 'QUALIFICATIONS';
        setParams(_params);
    }


    const setSkillParam = () => {
        let _params = {...params};
        _params['skill'] = 'SKILLS';
        setParams(_params);
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _params = {...params};
        _params[`${name}`] = val;
        setParams(_params);
    }


    const onPeopleInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _peopleRecord = {...peopleRecord};
        _peopleRecord[`${name}`] = val;
        setPeopleRecord(_peopleRecord);
    }

    const setPeopleJob = (slug) => {
        let _peopleRecord = {...peopleRecord};
        _peopleRecord['job'] = slug;
        setPeopleRecord(_peopleRecord);
    }


    useEffect(() =>{
        props.getRecommendationUsers(params, props.token);
        // let users = [...candidateOptions]
        // setFilteredCandidates(users);

    }, [params.geolocation]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        props.getRecommendationUsers(params, props.token);
        // let users = [...candidateOptions]
        // setFilteredCandidates(users);

    }, [params.qualification]) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() =>{
        props.getRecommendationUsers(params, props.token);
        // let users = [...candidateOptions]
        // setFilteredCandidates(users);

    }, [params.skill]) // eslint-disable-line react-hooks/exhaustive-deps





    const navigate = useNavigate();


    const goToSkills = (slug) =>{
      navigate(`/management/candidate-details/skills/${slug}`, {replace: false})
    }





    const getJob = (jobs, slug) =>{
        let jobs_ = jobs.filter((emp) => emp.slug === slug)
        return jobs_[0]
    }



    useEffect(() =>{
        if (!props.fetched){
            setFilteredCandidates([...candidateOptions]);
        }


    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        let jobsCopy = [...jobOptions];
        let job = getJob(jobsCopy, params.job);
        if (job !== undefined){
            setJobSlug(job.slug)
            let users = [...candidateOptions]
            setFilteredCandidates(users);
            setPeopleJob(job.slug)
        }

    }, [params.job]) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() =>{
        setJobParam(jobSlug);
        props.getRecommendationUsers(params, props.token);
        // let users = [...candidateOptions]
        // setFilteredCandidates(users);

    }, [jobSlug]) // eslint-disable-line react-hooks/exhaustive-deps


    const selectedPeopleTemplate = (option) => {
        if (option) {
            return (
                <div className="country-item card country-item-value">
                    <i className="pi pi-user mr-2"></i>
                    <div style={{fontFamily: 'cursive'}} className=''>{option.username}</div>
                </div>
            );
        }
      }

    const personTemplate = (option) => {
        return (
            <div className="country-item card">
                <i className="pi pi-user mr-2"></i>
                <div style={{fontFamily: 'cursive'}} className=''>{option.username}</div>
            </div>
        );
    }


    const handleSubmit = (e) =>{
      e.preventDefault();
        recommendPeople(peopleRecord, token);
        setPeopleRecord(initialValues);
        setParams(initialParams);
        jobSlug('');
    }



    return (
        <>
            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className="col-12 md:col-6">
                    <div className="surface-section   card px-4 py-8 md:px-6 lg:px-8">
                        Employer Details

                        <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    JOB
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        optionLabel="title"
                                        optionValue="slug"
                                        value={params.job}
                                        onChange={(e) => onInputChange(e, 'job')}
                                        options={jobOptions}
                                        filter
                                        filterBy="title"
                                    />

                                </div>
                            </li>

                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    SYSTEM ID
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="slug"
                                        value={jobSlug}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    JOB TITLE
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="slug"
                                        value={jobSlug}
                                        disabled
                                    />

                                </div>
                            </li>




                        </ul>

                    </div>
                </div>
                <div className="col-12 md:col-6 ">
                    <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                        Option Details

                        <form onSubmit={handleSubmit}>
                          <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                              {jobSlug !== undefined &&  jobSlug !== ''
                                  ?<>

                                      <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        >
                                            SELECT USERS FOR RECOMMENDATION
                                        </div>
                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >

                                            <MultiSelect
                                                value={peopleRecord.people}
                                                options={candidateOptions}
                                                optionLabel="username"
                                                filterBy="username"
                                                filter
                                                className="multiselect-custom"
                                                itemTemplate={personTemplate}
                                                selectedItemTemplate={selectedPeopleTemplate}
                                                onChange={(e) => onPeopleInputChange(e, 'people')}

                                              />
                                        </div>
                                      </li>

                                      <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                          <div
                                              className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                              style={{fontSize: '15px', fontFamily: 'cursive'}}
                                          >
                                              <Button
                                                  label='RECOMMEND SELECTED USERS'
                                                  className="p-button-outlined p-button-primary"
                                                  type='submit'

                                              />
                                          </div>
                                      </li>
                                  </>
                                  :<>


                                  <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                      <div
                                          className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                          style={{fontSize: '15px', fontFamily: 'cursive'}}
                                      >
                                          <Button
                                              label='VIEW UNPUBLISHED JOBS'
                                              className="p-button-outlined p-button-primary"
                                              disabled

                                          />
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                      <div
                                          className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                          style={{fontSize: '15px', fontFamily: 'cursive'}}
                                      >
                                          <Button
                                              label='VIEW CLOSED JOBS'
                                              className="p-button-outlined p-button-primary"
                                              disabled

                                          />
                                      </div>
                                  </li>

                              </>
                              }
                          </ul>
                      </form>
                    </div>
                </div>


            </div>
            <div className='card grid' style={{textAlign: 'center', fontFamily: 'cursive'}}>

                {params.job === ""
                    ?<>

                        <div className="col-12 card md:col-12">
                            <h1 className='' style={{fontSize: '15px'}}>
                                THIS IS A POOL FOR USERS THAT WILL BE FILTERED FOR A JOB
                                OF YOU WOULD WANT TO RECOMMEND THEM FOR
                            </h1>

                        </div>
                        {candidateOptions.map((user) =>{
                            return(

                                <div key={user.slug} className="col-12 card md:col-4">
                                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.first_name} {user.last_name}
                                            </div>
                                        </li>
                                        <li className="flex  align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.email}
                                            </div>
                                        </li>
                                        <li className="flex  align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.gender}
                                            </div>
                                        </li>
                                        <li className="flex  align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.matching_score === 0
                                                    ?<Button
                                                        type="button"
                                                        label="ACTIVE JOBS MATCHES"
                                                        className="p-button-outlined p-button-danger">
                                                        <Badge value="0" severity="danger" />
                                                    </Button>
                                                    :<Button
                                                        type="button"
                                                        label="ACTIVE JOBS MATCHES"
                                                        className="p-button-outlined p-button-success">
                                                        <Badge value={user.matching_score} severity="success" />
                                                    </Button>
                                                }
                                            </div>
                                        </li>
                                    </ul>

                                    <Accordion activeIndex={5}  style={{textAlign: 'center'}}>
                                        <AccordionTab data-aos="flip-left" header='MORE DETAILS'>
                                            <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW PENDING PROPOSALS'
                                                            className="p-button-outlined p-button-primary"


                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW ACCEPTED PROPOSALS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW CALLED FOR INTERVIEW PROPOSALS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW REJECTED PROPOSALS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW PROFILE'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>

                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW ACADEMIC QUALIFICATIONS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW LANGUAGES PROFFICIENCY'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW SKILLS'
                                                            className="p-button-outlined p-button-primary"
                                                            onClick={() => goToSkills(user.slug)}
                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW JOB EXPERIENCES'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>

                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW REPORTS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                            </ul>
                                        </AccordionTab>

                                    </Accordion>





                                </div>

                            );
                        })

                        }
                    </>
                    :<>
                        <div className="col-12 card md:col-12">
                            <h1 className='' style={{fontSize: '15px'}}>
                                THESE USERS PASSED A HLETIPANG RECRUITMENT TEST BY QUALIFICATIONS.
                                YOU CAN ALWAYS SEND THEIR PROFILES AS RECOMMENDATION TO THE RECRUITER
                            </h1>

                        </div>

                        {candidateOptions.map((user) =>{
                            return(
                                <div key={user.slug} className="col-12 card md:col-4">
                                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                        <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.first_name} {user.last_name}
                                            </div>
                                        </li>
                                        <li className="flex  align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.email}
                                            </div>
                                        </li>
                                        <li className="flex  align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.gender}
                                            </div>
                                        </li>
                                        <li className="flex  align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                            <div
                                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                                            >
                                                {user.matching_score === 0
                                                    ?<Button
                                                        type="button"
                                                        label="JOB MATCHING"
                                                        className="p-button-outlined p-button-danger">
                                                        <Badge value="0" severity="danger" />
                                                    </Button>
                                                    :<Button
                                                        type="button"
                                                        label="JOB MATCHING"
                                                        className="p-button-outlined p-button-success">
                                                        <Badge value={user.matching_score} severity="success" />
                                                    </Button>
                                                }
                                            </div>
                                        </li>
                                    </ul>

                                    <Accordion activeIndex={5}  style={{textAlign: 'center'}}>
                                        <AccordionTab data-aos="flip-left" header='MORE DETAILS'>
                                            <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW PENDING PROPOSALS'
                                                            className="p-button-outlined p-button-primary"


                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW ACCEPTED PROPOSALS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW CALLED FOR INTERVIEW PROPOSALS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW REJECTED PROPOSALS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW PROFILE'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>

                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW ACADEMIC QUALIFICATIONS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW LANGUAGES PROFFICIENCY'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW SKILLS'
                                                            className="p-button-outlined p-button-primary"
                                                            onClick={() => goToSkills(user.slug)}
                                                        />
                                                    </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW JOB EXPERIENCES'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>

                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                    <div
                                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        <Button
                                                            label='VIEW REPORTS'
                                                            className="p-button-outlined p-button-primary"

                                                        />
                                                    </div>
                                                    </li>
                                            </ul>
                                        </AccordionTab>

                                    </Accordion>





                                </div>

                            );
                        })

                        }
                    </>

                }





            </div>
        </>
    )
}





export default Recommend;

import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Chip } from 'primereact/chip';



const categoryOptions = [
    {key: 'PROFESSIONAL', value: 'PROFESSIONAL'},
    {key: 'SOCIAL', value: 'SOCIAL'},


]


const Links = (props) => {
    const {
        token,
        addCandidateLink,
        editCandidateLink,
        getCandidateLinks,
        records,
        deleteCandidateLink,


    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    let initialValues = {
        category: '',
        url: '',


    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);



    useEffect(() => {
        getCandidateLinks(token);
        setIsSubmit(false);

    }, [isSubmit]);

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('category' in fieldValues)
            temp.category= fieldValues.category? "" : "Category is Required"
        if ('url' in fieldValues)
            temp.url= fieldValues.url? "" : "URL is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }


    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);

          resetForm();
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                setIsSubmit(true);
                editCandidateLink(record.slug, record, token);
                getCandidateLinks(token);
                resetForm();
                setDisplayMaximizable(false);

            }
        }
        else {
            if (validate()){

                setIsSubmit(true);
                addCandidateLink(record, token);
                getCandidateLinks(token);
                resetForm();
                setDisplayMaximizable(false);
            }

          }

    }


    const editLink = (record) =>{
        setRecord({...record});
        setDisplayMaximizable(true);

    }

    const deleteLink = (slug) =>{
        deleteCandidateLink(slug, token);
        setIsSubmit(true);
    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }



    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search"  placeholder="Search..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD LINK'
                    className="p-button-primary p-button-outlined p-button-success"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>

            </React.Fragment>
        )
    }

    const dialogSkillHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Skill Form</span>
              </div>
          )
      }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="w-6 md:w-2 flex justify-content-end">
                    <Button
                        icon="pi pi-pencil"
                        className="p-button-text p-button-success"
                        onClick={() => editLink(rowData)}
                    />
                </div>
                <div className="w-6 md:w-2 flex justify-content-end">
                    <Button
                        icon="pi pi-trash"
                        className="p-button-text p-button-danger"
                        onClick={() => deleteLink(rowData.slug)}
                    />
                </div>
            </React.Fragment>
        );
    }


    return (
        <>
            <div className="card">

                <Toolbar
                    className="mb-4"
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}

                />
                <div style={{textAlign: 'center'}} className="">
                        <Accordion activeIndex={0}>
                            {records.map((records) =>{
                                    return(
                                        <AccordionTab data-aos="flip-left" className="  font-bold mb-3" key={records.slug} header={records.url}>
                                            <ul className="list-none p-0 m-0">
                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">URL</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={records.url}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>
                                                 <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">CATEGORY</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={records.category}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>

                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className=" w-6 md:w-4 font-medium">
                                                    <Button
                                                        icon="pi pi-pencil"
                                                        label='UPDATE'
                                                        className="p-button-rounded p-button-outlined  p-button-success"
                                                        onClick={() => editLink(records)}
                                                    />
                                                </div>
                                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Button
                                                        icon="pi pi-trash"
                                                        label='DELETE'
                                                        className="p-button-rounded p-button-outlined p-button-danger"
                                                        onClick={() => deleteLink(records.slug)}
                                                    />
                                                </div>

                                            </li>
                                            </ul>
                                        </AccordionTab>

                                        );
                                    }
                                )
                            }

                        </Accordion>
                    </div>
                </div>
                <Dialog header={dialogSkillHeader ()}
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '1000px' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT THE CATEGORY
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.category}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={categoryOptions}
                                                onChange={(e) => onInputChange(e, 'category')}
                                                filter
                                                filterBy="key"
                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.category}</small>
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHAT'S YOUR URL
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <InputText
                                                id="url"
                                                value={record.url}
                                                onChange={(e) => onInputChange(e, 'url')}
                                                type="url"
                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.url}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                         <Button
                                            type="submit"
                                            label="ADD LINK"
                                            className="p-button-outlined p-button-warning"
                                        />
                                    </li>
                                </ul>

                            </div>
                        </form>
                    </div>

                </Dialog>


        </>
    );
}


export default Links;

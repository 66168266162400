import React, { useState, useEffect } from 'react';

import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Chip } from 'primereact/chip';



const comptencyOptions = [
    {key: "BEGINER"},
    {key: "EXPERIENCED"},
    {key: "ADVANCED"},
    {key: "EXPERT"},

]


const categoryOptions = [
    {key: "INDUSTRY KNOWLEDGE"},
    {key: "INTERPERSONAL KNOWLDGE"},
    {key: "OTHER"},

]


const Skills = (props) => {
    const {
        token,
        getCandidateSkills,
        addCandidateSkill,
        editCandidateSkill,
        deleteCandidateSkill,
        records,
        faculties,
        getFaculties,

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    let initialValues = {
        skill: '',
        title: '',
        years_of_experience: '',
        competency: '',
        faculty: '',
        category: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);



    useEffect(() => {
        getCandidateSkills(token);
        setIsSubmit(false);

    }, [isSubmit]);


    const deleteSkill = (slug) =>{
        deleteCandidateSkill(slug, token);
        setIsSubmit(true);

    }

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('category' in fieldValues)
            temp.category= fieldValues.category? "" : "Category is Required"
        if ('title' in fieldValues)
            temp.title= fieldValues.title? "" : "Skil Name is Required"
        if ('faculty' in fieldValues)
            temp.faculty= fieldValues.faculty? "" : "Faculty is Required"
        if ('years_of_experience' in fieldValues)
            temp.years_of_experience= fieldValues.years_of_experience? "" : "Years of Experience is Required"
        if ('competency' in fieldValues)
            temp.competency= fieldValues.competency? "" : "Competency is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
          resetForm();
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                setErrors(validate(record));
                setIsSubmit(true);
                editCandidateSkill(record.slug, record, token);
                getCandidateSkills(token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                addCandidateSkill(record, token);
                getCandidateSkills(token);
                resetForm();
                setDisplayMaximizable(false);
            }

          }

    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }


    const editSkill = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }






    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD NEW'
                    className="p-button-primary p-button-outlined p-button-success"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>


            </React.Fragment>
        )
    }

    const dialogSkillHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Skill Form</span>
              </div>
          )
      }






    return (
        <>
            <div className="card">

                <Toolbar
                    className="mb-4"
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}

                />
                <div style={{textAlign: 'center'}} className="">
                        <Accordion activeIndex={5}>
                            {records.map((records) =>{
                                    return(
                                        <AccordionTab data-aos="flip-left" className="  font-bold mb-3" key={records.slug} header={records.title}>
                                            <ul className="list-none p-0 m-0">
                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">TITLE</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={records.title}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>
                                                 <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">CATEGORY</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={records.competency}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>
                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">YEARS OF EXPERIENCE</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={records.years_of_experience}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>

                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className=" w-6 md:w-4 font-medium">
                                                    <Button
                                                        icon="pi pi-pencil"
                                                        label='UPDATE'
                                                        className="p-button-rounded p-button-outlined  p-button-success"
                                                        onClick={() => editSkill(records)}
                                                    />
                                                </div>
                                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Button
                                                        icon="pi pi-trash"
                                                        label='DELETE'
                                                        className="p-button-rounded p-button-outlined p-button-danger"
                                                        onClick={() => deleteSkill(records.slug)}
                                                    />
                                                </div>

                                            </li>
                                            </ul>
                                        </AccordionTab>

                                        );
                                    }
                                )
                            }

                        </Accordion>
                    </div>
                </div>

                <Dialog header={dialogSkillHeader()}
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '1000px' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SKILL
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <InputText
                                                id="title"
                                                value={record.title}

                                                onChange={(e) => onInputChange(e, 'title')}

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.title}</small>

                                        </div>
                                        </li>

                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                             SELECT INDUSTRY
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.faculty}
                                                optionLabel="title"
                                                optionValue="title"
                                                options={faculties}
                                                onChange={(e) => onInputChange(e, 'faculty')}
                                                filter
                                                showClear
                                                filterBy="title"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.faculty}</small>
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                             SELECT CATEGORY
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.category}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={categoryOptions}
                                                onChange={(e) => onInputChange(e, 'category')}
                                                filter
                                                showClear
                                                filterBy="key"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.faculty}</small>
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            YEARS OF EXPERIENCE
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <InputText
                                                id="years_of_experience"
                                                value={record.years_of_experience}
                                                type='number'
                                                onChange={(e) => onInputChange(e, 'years_of_experience')}

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.years_of_experience}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT COMPETENCY
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <Dropdown
                                                value={record.competency}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={comptencyOptions}
                                                onChange={(e) => onInputChange(e, 'competency')}
                                                filter
                                                showClear
                                                filterBy="key"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.competency}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <Button
                                            type="submit"
                                            label="ADD Skill"
                                            className="p-button-outlined p-button-warning"

                                        />
                                    </li>
                                </ul>

                            </div>
                        </form>
                    </div>

                </Dialog>


        </>
    );
}


export default Skills;

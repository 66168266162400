import { combineReducers } from 'redux';
import errors from './errors';
import auth from './auth';
import places from './places';
import jobs from './jobs';
import languages from './languages';
import resumes from './resumes';
import faqs from './faqs';
import skills from './skills';
import chats from './chats';
import candidates from './candidates';
import recruiters from './recruiters';
import recommendations from './recommendations';
import candidatematches from './candidatematches';
import reports from './reports';
import portfolio from './portfolio';
import matches from './matches';
import feedback from './feedback';
import validations from "./validations";



export default combineReducers({
    errors,
    auth,
    places,
    jobs,
    languages,
    resumes,
    faqs,
    skills,
    chats,
    candidates,
    recruiters,
    recommendations,
    candidatematches,
    reports,
    portfolio,
    matches,
    feedback,
    validations,


});

import {
    GET_CANDIDATE_BEST_JOB_MATCHES_START,
    GET_CANDIDATE_BEST_JOB_MATCHES_SUCCESS,
    GET_CANDIDATE_BEST_JOB_MATCHES_FAIL,
    GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_START,
    GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_SUCCESS,
    GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_FAIL,
    GET_CANDIDATE_PERMANENT_ALL_JOBS_START,
    GET_CANDIDATE_PERMANENT_ALL_JOBS_SUCCESS,
    GET_CANDIDATE_PERMANENT_ALL_JOBS_FAIL,

    GET_CANDIDATE_APPLIED_FOR_JOBS_START,
    GET_CANDIDATE_APPLIED_FOR_JOBS_SUCCESS,
    GET_CANDIDATE_APPLIED_FOR_JOBS_FAIL,
} from '../types/candidateMatchTypes';
import {updateObject} from './utility';


const initialState = {
    candidatebestjobmatches: [],
    candidatepermanentjobnearestneaighbours:[],
    candidateallpermanentjobs:[],
    candidateappliedforjobs: [],
    error: null,
    loading: false,

};


const getPermanentJobBestMatchListStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getPermanentJobBestMatchListSuccess = (state, action) => {
    return updateObject(state, {
      candidatebestjobmatches: action.candidatebestjobmatches,
      error: null,
      loading: false
    });
  };


const getPermanentJobBestMatchListFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};


const getPermanentJobNearestNeighbourListStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getPermanentJobNearestNeighbourListSuccess = (state, action) => {
  return updateObject(state, {
    candidatepermanentjobnearestneaighbours: action.candidatepermanentjobnearestneaighbours,
    error: null,
    loading: false
  });
};


const getPermanentJobNearestNeighbourListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getCandidateAppliedForJobListStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateAppliedForJobListSuccess = (state, action) => {
  return updateObject(state, {
    candidateappliedforjobs: action.candidateappliedforjobs,
    error: null,
    loading: false
  });
};


const getCandidateAppliedForJobListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const getPermanentALLJobListStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getPermanentALLJobListSuccess = (state, action) => {
  return updateObject(state, {
    candidateallpermanentjobs: action.candidateallpermanentjobs,
    error: null,
    loading: false
  });
};


const getPermanentALLJobListFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



export default function candidatematches(state = initialState, action){

    switch(action.type){
        case GET_CANDIDATE_BEST_JOB_MATCHES_START:
            return getPermanentJobBestMatchListStart(state, action);
        case GET_CANDIDATE_BEST_JOB_MATCHES_SUCCESS:
            return getPermanentJobBestMatchListSuccess(state, action);
        case GET_CANDIDATE_BEST_JOB_MATCHES_FAIL:
            return getPermanentJobBestMatchListFail(state, action);


        //hello there this is permanent nearest neighbours
        case GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_START:
            return getPermanentJobNearestNeighbourListStart(state, action);
        case GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_SUCCESS:
            return getPermanentJobNearestNeighbourListSuccess(state, action);
        case GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_FAIL:
            return getPermanentJobNearestNeighbourListFail(state, action);

        //all permanent jobs
        case GET_CANDIDATE_PERMANENT_ALL_JOBS_START:
          return getPermanentALLJobListStart(state, action);
        case GET_CANDIDATE_PERMANENT_ALL_JOBS_SUCCESS:
            return getPermanentALLJobListSuccess(state, action);
        case GET_CANDIDATE_PERMANENT_ALL_JOBS_FAIL:
            return getPermanentALLJobListFail(state, action);

      // APPLIED FOR JOBS
        case GET_CANDIDATE_APPLIED_FOR_JOBS_START:
          return getCandidateAppliedForJobListStart(state, action);
        case GET_CANDIDATE_APPLIED_FOR_JOBS_SUCCESS:
            return getCandidateAppliedForJobListSuccess(state, action);
        case GET_CANDIDATE_APPLIED_FOR_JOBS_FAIL:
            return getCandidateAppliedForJobListFail(state, action);
        default:
            return state;
    }
}

export const GET_USER_PRIVACY_AGREEMENTS_START = "GET_USER_PRIVACY_AGREEMENTS_START";
export const GET_USER_PRIVACY_AGREEMENTS_SUCCESS = "GET_USER_PRIVACY_AGREEMENTS_SUCCESS";
export const GET_USER_PRIVACY_AGREEMENTS_FAIL = "GET_USER_PRIVACY_AGREEMENTS_FAIL";
export const GET_USER_PRIVACY_AGREEMENT_START = "GET_USER_PRIVACY_AGREEMENT_START";
export const GET_USER_PRIVACY_AGREEMENT_SUCCESS = "GET_USER_PRIVACY_AGREEMENT_SUCCESS";
export const GET_USER_PRIVACY_AGREEMENT_FAIL = "GET_USER_PRIVACY_AGREEMENT_FAIL";

export const ADD_USER_PRIVACY_AGREEMENT_START = "ADD_USER_PRIVACY_AGREEMENT_START";
export const ADD_USER_PRIVACY_AGREEMENT_SUCCESS = "ADD_USER_PRIVACY_AGREEMENT_SUCCESS";
export const ADD_USER_PRIVACY_AGREEMENT_FAIL = "ADD_USER_PRIVACY_AGREEMENT_FAIL";

export const EDIT_USER_PRIVACY_AGREEMENT_START = "EDIT_USER_PRIVACY_AGREEMENT_START";
export const EDIT_USER_PRIVACY_AGREEMENT_SUCCESS = "EDIT_USER_PRIVACY_AGREEMENT_SUCCESS";
export const EDIT_USER_PRIVACY_AGREEMENT_FAIL = "EDIT_USER_PRIVACY_AGREEMENT_FAIL";

export const DELETE_USER_PRIVACY_AGREEMENT_START = "DELETE_USER_PRIVACY_AGREEMENT_START";
export const DELETE_USER_PRIVACY_AGREEMENT_SUCCESS = "DELETE_USER_PRIVACY_AGREEMENT_SUCCESS";
export const DELETE_USER_PRIVACY_AGREEMENT_FAIL = "DELETE_USER_PRIVACY_AGREEMENT_FAIL";



export const GET_CANDIDATE_PORTFOLIOS_START = "GET_CANDIDATE_PORTFOLIOS_START";
export const GET_CANDIDATE_PORTFOLIOS_SUCCESS = "GET_CANDIDATE_PORTFOLIOS_SUCCESS";
export const GET_CANDIDATE_PORTFOLIOS_FAIL = "GET_CANDIDATE_PORTFOLIOS_FAIL";
export const GET_CANDIDATE_PORTFOLIO_START = "GET_CANDIDATE_PORTFOLIO_START";
export const GET_CANDIDATE_PORTFOLIO_SUCCESS = "GET_CANDIDATE_PORTFOLIO_SUCCESS";
export const GET_CANDIDATE_PORTFOLIO_FAIL = "GET_CANDIDATE_PORTFOLIO_FAIL";
export const ADD_CANDIDATE_PORTFOLIO_START = "ADD_CANDIDATE_PORTFOLIO_START";
export const ADD_CANDIDATE_PORTFOLIO_SUCCESS = "ADD_CANDIDATE_PORTFOLIO_SUCCESS";
export const ADD_CANDIDATE_PORTFOLIO_FAIL = "ADD_CANDIDATE_PORTFOLIO_FAIL";
export const EDIT_CANDIDATE_PORTFOLIO_START = "EDIT_CANDIDATE_PORTFOLIO_START";
export const EDIT_CANDIDATE_PORTFOLIO_SUCCESS = "EDIT_CANDIDATE_PORTFOLIO_SUCCESS";
export const EDIT_CANDIDATE_PORTFOLIO_FAIL = "EDIT_CANDIDATE_PORTFOLIO_FAIL";
export const DELETE_CANDIDATE_PORTFOLIO_START = "DELETE_CANDIDATE_PORTFOLIO_START";
export const DELETE_CANDIDATE_PORTFOLIO_SUCCESS = "DELETE_CANDIDATE_PORTFOLIO_SUCCESS";
export const DELETE_CANDIDATE_PORTFOLIO_FAIL = "DELETE_CANDIDATE_PORTFOLIO_FAIL";

export const GET_CANDIDATE_SERVICES_START = "GET_CANDIDATE_SERVICES_START";
export const GET_CANDIDATE_SERVICES_SUCCESS = "GET_CANDIDATE_SERVICES_SUCCESS";
export const GET_CANDIDATE_SERVICES_FAIL = "GET_CANDIDATE_SERVICES_FAIL";
export const GET_CANDIDATE_SERVICE_START = "GET_CANDIDATE_SERVICE_START";
export const GET_CANDIDATE_SERVICE_SUCCESS = "GET_CANDIDATE_SERVICE_SUCCESS";
export const GET_CANDIDATE_SERVICE_FAIL = "GET_CANDIDATE_SERVICE_FAIL";
export const ADD_CANDIDATE_SERVICE_START = "ADD_CANDIDATE_SERVICE_START";
export const ADD_CANDIDATE_SERVICE_SUCCESS = "ADD_CANDIDATE_SERVICE_SUCCESS";
export const ADD_CANDIDATE_SERVICE_FAIL = "ADD_CANDIDATE_SERVICE_FAIL";
export const EDIT_CANDIDATE_SERVICE_START = "EDIT_CANDIDATE_SERVICE_START";
export const EDIT_CANDIDATE_SERVICE_SUCCESS = "EDIT_CANDIDATE_SERVICE_SUCCESS";
export const EDIT_CANDIDATE_SERVICE_FAIL = "EDIT_CANDIDATE_SERVICE_FAIL";
export const DELETE_CANDIDATE_SERVICE_START = "DELETE_CANDIDATE_SERVICE_START";
export const DELETE_CANDIDATE_SERVICE_SUCCESS = "DELETE_CANDIDATE_SERVICE_SUCCESS";
export const DELETE_CANDIDATE_SERVICE_FAIL = "DELETE_CANDIDATE_SERVICE_FAIL";


export const GET_CANDIDATE_OBJECTIVES_START = "GET_CANDIDATE_OBJECTIVES_START";
export const GET_CANDIDATE_OBJECTIVES_SUCCESS = "GET_CANDIDATE_OBJECTIVES_SUCCESS";
export const GET_CANDIDATE_OBJECTIVES_FAIL = "GET_CANDIDATE_OBJECTIVES_FAIL";
export const GET_CANDIDATE_OBJECTIVE_START = "GET_CANDIDATE_OBJECTIVE_START";
export const GET_CANDIDATE_OBJECTIVE_SUCCESS = "GET_CANDIDATE_OBJECTIVE_SUCCESS";
export const GET_CANDIDATE_OBJECTIVE_FAIL = "GET_CANDIDATE_OBJECTIVE_FAIL";
export const ADD_CANDIDATE_OBJECTIVE_START = "ADD_CANDIDATE_OBJECTIVE_START";
export const ADD_CANDIDATE_OBJECTIVE_SUCCESS = "ADD_CANDIDATE_OBJECTIVE_SUCCESS";
export const ADD_CANDIDATE_OBJECTIVE_FAIL = "ADD_CANDIDATE_OBJECTIVE_FAIL";
export const EDIT_CANDIDATE_OBJECTIVE_START = "EDIT_CANDIDATE_OBJECTIVE_START";
export const EDIT_CANDIDATE_OBJECTIVE_SUCCESS = "EDIT_CANDIDATE_OBJECTIVE_SUCCESS";
export const EDIT_CANDIDATE_OBJECTIVE_FAIL = "EDIT_CANDIDATE_OBJECTIVE_FAIL";
export const DELETE_CANDIDATE_OBJECTIVE_START = "DELETE_CANDIDATE_OBJECTIVE_START";
export const DELETE_CANDIDATE_OBJECTIVE_SUCCESS = "DELETE_CANDIDATE_OBJECTIVE_SUCCESS";
export const DELETE_CANDIDATE_OBJECTIVE_FAIL = "DELETE_CANDIDATE_OBJECTIVE_FAIL";
export const GET_CANDIDATE_MISSIONS_START = "GET_CANDIDATE_MISSIONS_START";
export const GET_CANDIDATE_MISSIONS_SUCCESS = "GET_CANDIDATE_MISSIONS_SUCCESS";
export const GET_CANDIDATE_MISSIONS_FAIL = "GET_CANDIDATE_MISSIONS_FAIL";
export const GET_CANDIDATE_MISSION_START = "GET_CANDIDATE_MISSION_START";
export const GET_CANDIDATE_MISSION_SUCCESS = "GET_CANDIDATE_MISSION_SUCCESS";
export const GET_CANDIDATE_MISSION_FAIL = "GET_CANDIDATE_MISSION_FAIL";
export const ADD_CANDIDATE_MISSION_START = "ADD_CANDIDATE_MISSION_START";
export const ADD_CANDIDATE_MISSION_SUCCESS = "ADD_CANDIDATE_MISSION_SUCCESS";
export const ADD_CANDIDATE_MISSION_FAIL = "ADD_CANDIDATE_MISSION_FAIL";
export const EDIT_CANDIDATE_MISSION_START = "EDIT_CANDIDATE_MISSION_START";
export const EDIT_CANDIDATE_MISSION_SUCCESS = "EDIT_CANDIDATE_MISSION_SUCCESS";
export const EDIT_CANDIDATE_MISSION_FAIL = "EDIT_CANDIDATE_MISSION_FAIL";
export const DELETE_CANDIDATE_MISSION_START = "DELETE_CANDIDATE_MISSION_START";
export const DELETE_CANDIDATE_MISSION_SUCCESS = "DELETE_CANDIDATE_MISSION_SUCCESS";
export const DELETE_CANDIDATE_MISSION_FAIL = "DELETE_CANDIDATE_MISSION_FAIL";


export const ADD_PORTFOLIO_SKILL_START = "ADD_PORTFOLIO_SKILL_START";
export const ADD_PORTFOLIO_SKILL_SUCCESS = "ADD_PORTFOLIO_SKILL_SUCCESS";
export const ADD_PORTFOLIO_SKILL_FAIL = "ADD_PORTFOLIO_SKILL_FAIL";
export const ADD_PORTFOLIO_IMAGE_START = "ADD_PORTFOLIO_IMAGE_START";
export const ADD_PORTFOLIO_IMAGE_SUCCESS = "ADD_PORTFOLIO_IMAGE_SUCCESS";
export const ADD_PORTFOLIO_IMAGE_FAIL = "ADD_PORTFOLIO_IMAGE_FAIL";
export const ADD_SERVICE_IMAGE_START = "ADD_SERVICE_IMAGE_START";
export const ADD_SERVICE_IMAGE_SUCCESS = "ADD_SERVICE_IMAGE_SUCCESS";
export const ADD_SERVICE_IMAGE_FAIL = "ADD_SERVICE_IMAGE_FAIL";
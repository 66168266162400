import {
    GET_SKILLS,
    ADD_SKILL,
    EDIT_SKILL,
    DELETE_SKILL,
    GET_FACULTYS,
    ADD_FACULTY,
    EDIT_FACULTY,
    DELETE_FACULTY,
    GET_ACADEMIC_QUALIFICATIONS,
    ADD_ACADEMIC_QUALIFICATION,
    EDIT_ACADEMIC_QUALIFICATION,
    DELETE_ACADEMIC_QUALIFICATION,
    GET_NEAREST_NEIGHBOURS,
    ADD_NEAREST_NEIGHBOUR,
    EDIT_NEAREST_NEIGHBOUR,
    DELETE_NEAREST_NEIGHBOUR,
} from '../types/skillTypes';

const initialState = {
    skills: [],
    faculties: [],
    qualifications:[],
    nearestneighbours:[],
}


export default function skills(state = initialState, action){

    switch(action.type){

        case GET_SKILLS:
            return {
                ...state,
               skills : action.payload
            };

        case ADD_SKILL:
            return {
                ...state,
               skill: [...state.skills, action.payload]
            }

        case EDIT_SKILL:
            const skillArray = state.skills;
           skillArray.splice(
                   skillArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
               skills:skillArray,
            };
        
        case DELETE_SKILL:
            return {
                ...state,
                skill: [...state.skills, action.payload]
            };

        //qualifications
        case GET_ACADEMIC_QUALIFICATIONS:
            return {
                ...state,
               qualifications : action.payload
            };

        case ADD_ACADEMIC_QUALIFICATION:
            return {
                ...state,
               qualification: [...state.qualifications, action.payload]
            }

        case EDIT_ACADEMIC_QUALIFICATION:
            const qualificationArray = state.qualifications;
           qualificationArray.splice(
                   qualificationArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
               qualifications:qualificationArray,
            };
        
        case DELETE_ACADEMIC_QUALIFICATION:
            return {
                ...state,
                qualification: [...state.qualifications, action.payload]
            };

        //FACULTIES
        case GET_FACULTYS:
            return {
                ...state,
               faculties : action.payload
            };

        case ADD_FACULTY:
            return {
                ...state,
               facultie: [...state.faculties, action.payload]
            }

        case EDIT_FACULTY:
            const facultieArray = state.faculties;
           facultieArray.splice(
                   facultieArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
               faculties:facultieArray,
            };
        
        case DELETE_FACULTY:
            return {
                ...state,
                faculty: [...state.faculties, action.payload]
            };
        //nearest neighbour
        case GET_NEAREST_NEIGHBOURS:
            return {
                ...state,
               nearestneighbours : action.payload
            };

        case ADD_NEAREST_NEIGHBOUR:
            return {
                ...state,
               nearestneighbour: [...state.nearestneighbours, action.payload]
            }

        case EDIT_NEAREST_NEIGHBOUR:
            const nearestneighbourArray = state.nearestneighbours;
           nearestneighbourArray.splice(
                   nearestneighbourArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
               nearestneighbours:nearestneighbourArray,
            };
        
        case DELETE_NEAREST_NEIGHBOUR:
            return {
                ...state,
                nearestneighbour: [...state.nearestneighbours, action.payload]
            };   
        default:
            return state;
    }
}

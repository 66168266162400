import React, { useEffect} from 'react';
import { InputText } from 'primereact/inputtext';


const Details = (props) => {
  const {
    first_name,
    last_name,
    onInputChange,
    setProceedCleared,
  } =props;


  useEffect(()=>{
    const canProceed = Boolean(first_name !== '') && Boolean(last_name !== '');
    if (canProceed){
      setProceedCleared(true);
    }

  }, [first_name, last_name])




  return (
      <ul  style={{textAlign: 'center'}} className="list-none card grid p-fluid p-0 m-0" >


        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

            <div
                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px'}}
            >

                FIRSTNAME
            </div>
            <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
            <InputText
                id="first_name"
                type="text"
                name="first_name"
                className="w-full mb-3"
                value={first_name}
                onChange={(e) => onInputChange(e,"first_name")}

            />

            </div>
        </li>

        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

            <div
                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px'}}
            >

                LAST NAME
            </div>
            <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
              <InputText
                  id="last_name"
                  type="text"
                  name="last_name"
                  className="w-full mb-3"
                  value={last_name}
                  onChange={(e) => onInputChange(e,"last_name")}

              />

            </div>
        </li>
    </ul>
  )
}

export default Details;

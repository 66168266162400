import React, {useEffect, useState} from 'react';
import JobSeekerLayout from '../layout/JobSeekerLayout';
import { Button } from 'primereact/button';
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import {
    getCandidateJob,
    getJobSatisfactionQuestions,
    addCandidateJobProposal,
    getJobQualifications,
    getJobLevels,

} from '../../actions/jobs';
import {
    getCountries,
    getProvinces,
    getDistricts

} from '../../actions/places';
import { getUsers } from '../../actions/auth';
import { connect } from 'react-redux';
import { Chip } from 'primereact/chip';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { useNavigate } from 'react-router-dom';
import {

    jobFishingExpectedTimelineOptions,
    initialValues,

} from './data';




const Job = (props) => {
    const {
        token,
        getCandidateJob,
        getJobSatisfactionQuestions,
        job,
        questions,
        addCandidateJobProposal,
    } = props;
    let { slug } = useParams();
    const [record, setRecord] = useState(initialValues);
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    const [enquiries, setEnquiries] = useState([]);
    const navigate = useNavigate()
    const [modalState, setModalState] = useState(false);
    const [job_duration, setJobDuration] = useState('');
    const [cover_letter, setCoverLetter] = useState('');
    const [spano, setSpano] = useState('');

    const {hasApplied} = job;


    const resetForm = () => {
        setRecord(initialValues);
    }

    const makeEnquiries = (questions) => {
      const myeEnquiries = questions.map(item => {
      const container = {};

        container.slug = item.slug;
        container.title = item.question;
        container.answer = "";

        return container;
      });
    return myeEnquiries;

    }

    

    useEffect(() =>{
        if(!props.fetched){
            getCandidateJob(slug, token);
            getJobSatisfactionQuestions(slug, token);
            props.getJobQualifications(slug, token);
            props.getJobLevels(slug, token);
        }

    }, [])


    useEffect(() => {
       let qs = makeEnquiries(questions)
       setEnquiries(qs)
       setSpano(job.slug)

    }, [modalState])


    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }



    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const editJob = (record) => {
        setDisplayMaximizable(true);
        setModalState(true);


    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let proposal = {
            spano,
            cover_letter,
            job_duration,
            enquiries
        }
        addCandidateJobProposal(proposal, props.token);
        navigate("/jobs", {replace: true})
    }


    const handleQuestionOnChange = (slug, e) =>{

        e.preventDefault();
        e.persist();
        setEnquiries((prev) => {
            return prev.map((item) =>{
                if (item.slug !== slug){
                    return item;
                }
                return {
                    ...item,
                    [e.target.name]: e.target.value
                }
            });

        });

    }



    return (
        <JobSeekerLayout>
            <div className='card'>
                <div className="surface-1 card text-700 text-center">
                    <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                        <div>

                        </div>
                        {hasApplied
                            ?<div className="mt-3 lg:mt-0">
                                <Button
                                    label="YOU HAVE APPLIED FOR THIS JOB"
                                    className="p-button-outlined p-button-success"
                                    disabled={true}
                                />
                            </div>
                            :<div className="mt-3 lg:mt-0">
                                <Button
                                    label="APPLY FOR THIS JOB"
                                    className="p-button-outlined p-button-warning"
                                    icon="pi pi-pencil"
                                    onClick={() => editJob(job)}
                                />
                            </div>

                        }

                    </div>
                </div>
            </div>
            <div className='card' style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className="font-medium text-3xl  mb-3">JOB INFORMATION</div>
                <div className=" mb-5">{job.title}</div>
                <ul className="list-none p-0 m-0">
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            DATE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.created_at} icon='pi pi-calendar'   />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            EMPLOYER
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.employer} icon='pi pi-user'   />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            DURATION EXPECTATION
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.expected_duration} icon='pi pi-calendar'   />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            JOB TYPE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.category} icon="pi pi-check"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            REQUIRED SKILLS
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.required_skillset} icon="pi pi-check"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            LOCATION
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.location_type} icon="pi pi-flag-fill"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            JOB TYPE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.location} icon="pi pi-flag-fill"  />
                            </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            PAYMENT TYPE
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                <Chip label={job.charge_category}  />
                        </div>

                    </li>
                    {job.charge_category === "FIXED"
                        ?<li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                                ESTIMATED BUDGET
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                    <Chip icon="pi pi-dollar"  label={`${job.fixed_price}`}  />
                            </div>

                        </li>
                        :<li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                                ESTIMATED BUDGET
                            </div>
                            <div className="col-12 md:w-12 font-medium">
                                    <Chip label={`Estimated Hourly Budget: $${job.from_hourly_price} - $${job.to_hourly_price}`}  />
                            </div>

                        </li>

                    }

                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            DESCRIPTION
                            </div>
                            <div style={{overflowWrap: 'break-word'}} className="col-12 md:w-12 font-medium">
                                {job.description}
                        </div>

                    </li>
                    <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div className="col-12 md:w-12 font-medium">
                            HOW TO APPLY
                            </div>
                            <div style={{overflowWrap: 'break-word'}} className="col-12 md:w-12 font-medium">
                                {job.how_to_apply}
                        </div>

                    </li>
                </ul>
            </div>
            <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                    <div className="col-12 md:w-12 font-medium">QUESTIONS ASKED</div>

                    <ul className="list-none p-0 m-0 card">


                        {questions.map((question) =>{
                            return(
                                <>
                                    <li key={question.slug} className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div className=" col-12 md:w-12 font-medium">
                                            <Chip label={question.question} className="mr-2"></Chip>
                                        </div>
                                    </li>

                                </>

                            );

                        })

                    }

                </ul>
            </div>
            <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className=" col-12 md:w-12 font-medium">REQUIRED QUALIFICATIONS</div>

                <div className="card">
                    <div className="grid fluid">
                        {props.qualifications.map((qual) =>{
                            return(
                                <div key={qual.slug} className="col-12 flex-grow-1 md:col-4 card border-50 box border-round shadow-8" style={{padding: '20px'}}>
                                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary col-12 md:w-12 font-medium">
                                                QUALIFICATION
                                            </div>
                                            <div className="text-primary col-12 md:w-12 font-medium">
                                                <Chip label={qual.qualification}  className="mr-2 mb-2 text-primary " />
                                            </div>
                                        </li>

                                    </ul>
                                </div>

                            )
                        })


                    }
                    </div>
                </div>
            </div>
            <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className=" col-12 md:w-12 font-medium">QUALIFICATION LEVELS</div>

                <div className="card">
                    <div className="grid fluid">
                        {props.levels.map((level) =>{
                            return(
                                <div key={level.slug} className="col-12 flex-grow-1 md:col-4 card border-50 box border-round shadow-8" style={{padding: '20px'}}>
                                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary col-12 md:w-12 font-medium">
                                                LEVEL
                                            </div>
                                            <div className="text-primary col-12 md:w-12 font-medium">
                                                <Chip label={level.name}  className="mr-2 mb-2 text-primary " />
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            )
                        })

                    }
                    </div>
                </div>
            </div>
            <Dialog
                header="PROPOSE HOW YOU WOULD DO THE JOB"
                visible={displayMaximizable}
                maximizable
                modal
                style={{ width: '800px' }}
                onHide={() => onHide('displayMaximizable')}
            >
                <div className="cards">
                  <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                    <div className="text-700 text-center">
                        <div className="text-blue-600 font-bold mb-3"><i className="pi pi-discord"></i>&nbsp;APPLY FOR THIS JOB</div>
                        <div className="text-900 font-bold text-5xl mb-3"></div>
                        <div className="text-700 text-2xl mb-5">WHEN YOU APPLY FOR THIS JOB YOUR PROFILE SHALL BE SHOWN TO PROSPECTIVE EMPLOYER</div>
                    </div>
                  </div>

                    <form onSubmit={handleSubmit}>
                        <div className="grid p-fluid">
                            <div
                              className="surface-section card px-4 py-8 col-12 md:col-12"
                              style={{padding: "30px"}}
                              >
                              <div className="text-700 ">
                                    <div className="text-700 text-2xl mb-5">HOW LONG DO YOU THINK IT CAN TAKE FOR YOU TO WRAP UP THIS PROJECT?</div>
                                    <Dropdown
                                        optionLabel="key"
                                        optionValue="key"
                                        value={job_duration}
                                        onChange={(e) => setJobDuration(e.target.value)}
                                        options={jobFishingExpectedTimelineOptions}
                                        filter
                                        showClear
                                        filterBy="key"
                                        required
                                    />
                              </div>
                            </div>
                            <div
                              className="surface-section card px-4 py-8 col-12 md:col-12"
                              style={{padding: "50px"}}
                              >
                              <div className="text-700 ">
                                    <div className="text-700 text-2xl mb-5">
                                      ANSWER THE FOLLOWING QUESTIONS
                                    </div>

                                    {enquiries.map((item, i) =>{
                                        return(
                                          <div key={item.slug}>
                                            <div className="text-700 text-2xl mb-5">
                                              {item.title}
                                            </div>
                                            <InputTextarea
                                                rows={2}
                                                cols={30}
                                                autoResize
                                                name="answer"
                                                value={item.answer}
                                                onChange={(e) => handleQuestionOnChange(item.slug, e)}
                                            />
                                          </div>
                                        );
                                    })
                                  }

                              </div>
                            </div>

                            <div
                              className="surface-section card px-4 py-8 col-12 md:col-12"
                              style={{padding: "50px"}}
                              >
                              <div className="text-700 ">
                                    <div className="text-700 text-2xl mb-5">
                                      WRITE A COVER LETTER APLLYING FOR THIS JOB
                                    </div>
                                    <InputTextarea
                                        rows={2}
                                        cols={30}
                                        autoResize
                                        value={cover_letter}
                                        name='cover_letter'
                                        onChange={(e) => setCoverLetter(e.target.value)}
                                    />
                              </div>
                            </div>


                            <div className="col-12 md:col-4">
                                <Button
                                    type="submit"
                                    label="Submit"
                                    className="mt-2"

                                />
                                <label htmlFor="horizontal"></label>
                            </div>

                        </div>
                    </form>
                </div>

            </Dialog>
        </JobSeekerLayout>
    );
};




const mapStateToProps = state => ({
    job: state.jobs.candidatejob,
    questions: state.jobs.jobsatisfactionquestions,
    token: state.auth.token,
    countries: state.places.countries,
    provinces: state.places.provinces,
    districts: state.places.districts,
    users: state.auth.people,
    questions: state.jobs.jobsatisfactionquestions,
    skills: state.jobs.jobskills,
    qualifications: state.jobs.jobqualifications,
    levels: state.jobs.joblevels,

  });



export default connect(
    mapStateToProps,
    {
        getCandidateJob,
        getJobSatisfactionQuestions,
        addCandidateJobProposal,
        getJobQualifications,
        getJobLevels,


    }
  )(Job);

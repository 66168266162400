export const GET_DAILY_JOB_REPORTS_START = 'GET_DAILY_JOB_REPORTS_START';
export const GET_DAILY_JOB_REPORTS_SUCCESS = 'GET_DAILY_JOB_REPORTS_SUCCESS';
export const GET_DAILY_JOB_REPORTS_FAIL = 'GET_DAILY_JOB_REPORTS_FAIL';
export const GET_MANAGEMENT_JOB_WEEKLY_REPORTS_START = 'GET_MANAGEMENT_JOB_WEEKLY_REPORTS_START';
export const GET_MANAGEMENT_JOB_WEEKLY_REPORTS_SUCCESS = 'GET_MANAGEMENT_JOB_WEEKLY_REPORTS_SUCCESS';
export const GET_MANAGEMENT_JOB_WEEKLY_REPORTS_FAIL = 'GET_MANAGEMENT_JOB_WEEKLY_REPORTS_FAIL';
export const GET_MANAGEMENT_JOB_MONTHLY_REPORTS_START = 'GET_MANAGEMENT_JOB_MONTHLY_REPORTS_START';
export const GET_MANAGEMENT_JOB_MONTHLY_REPORTS_SUCCESS = 'GET_MANAGEMENT_JOB_MONTHLY_REPORTS_SUCCESS';
export const GET_MANAGEMENT_JOB_MONTHLY_REPORTS_FAIL = 'GET_MANAGEMENT_JOB_MONTHLY_REPORTS_FAIL';
export const GET_MANAGEMENT_JOB_YEARLY_REPORTS_START = 'GET_MANAGEMENT_JOB_YEARLY_REPORTS_START';
export const GET_MANAGEMENT_JOB_YEARLY_REPORTS_SUCCESS = 'GET_MANAGEMENT_JOB_YEARLY_REPORTS_SUCCESS';
export const GET_MANAGEMENT_JOB_YEARLY_REPORTS_FAIL = 'GET_MANAGEMENT_JOB_YEARLY_REPORTS_FAIL';
export const GET_RECRUITER_DAILY_JOB_REPORTS_START = 'GET_RECRUITER_DAILY_JOB_REPORTS_START';
export const GET_RECRUITER_DAILY_JOB_REPORTS_SUCCESS = 'GET_RECRUITER_DAILY_JOB_REPORTS_SUCCESS';
export const GET_RECRUITER_DAILY_JOB_REPORTS_FAIL = 'GET_RECRUITER_DAILY_JOB_REPORTS_FAIL';
export const GET_RECRUITER_JOB_WEEKLY_REPORTS_START = 'GET_RECRUITER_JOB_WEEKLY_REPORTS_START';
export const GET_RECRUITER_JOB_WEEKLY_REPORTS_SUCCESS = 'GET_RECRUITER_JOB_WEEKLY_REPORTS_SUCCESS';
export const GET_RECRUITER_JOB_WEEKLY_REPORTS_FAIL = 'GET_RECRUITER_JOB_WEEKLY_REPORTS_FAIL';
export const GET_RECRUITER_JOB_MONTHLY_REPORTS_START = 'GET_RECRUITER_JOB_MONTHLY_REPORTS_START';
export const GET_RECRUITER_JOB_MONTHLY_REPORTS_SUCCESS = 'GET_RECRUITER_JOB_MONTHLY_REPORTS_SUCCESS';
export const GET_RECRUITER_JOB_MONTHLY_REPORTS_FAIL = 'GET_RECRUITER_JOB_MONTHLY_REPORTS_FAIL';
export const GET_RECRUITER_JOB_YEARLY_REPORTS_START = 'GET_RECRUITER_JOB_YEARLY_REPORTS_START';
export const GET_RECRUITER_JOB_YEARLY_REPORTS_SUCCESS = 'GET_RECRUITER_JOB_YEARLY_REPORTS_SUCCESS';
export const GET_RECRUITER_JOB_YEARLY_REPORTS_FAIL = 'GET_RECRUITER_JOB_YEARLY_REPORTS_FAIL';
export const GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_START = 'GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_START';
export const GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_FAIL = 'GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_START = 'GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_START';
export const GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_START = 'GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_START';
export const GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_START = 'GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_START';
export const GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START = 'GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START';
export const GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL = 'GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START = 'GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START';
export const GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START = 'GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START';
export const GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START = 'GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START';
export const GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START = 'GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START';
export const GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS = 'GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS';
export const GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL = 'GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL';
export const GET_EMPLOYER_DAILY_JOB_REPORTS_START = 'GET_EMPLOYER_DAILY_JOB_REPORTS_START';
export const GET_EMPLOYER_DAILY_JOB_REPORTS_SUCCESS = 'GET_EMPLOYER_DAILY_JOB_REPORTS_SUCCESS';
export const GET_EMPLOYER_DAILY_JOB_REPORTS_FAIL = 'GET_EMPLOYER_DAILY_JOB_REPORTS_FAIL';
export const GET_EMPLOYER_WEEKLY_JOB_REPORTS_START = 'GET_EMPLOYER_WEEKLY_JOB_REPORTS_START';
export const GET_EMPLOYER_WEEKLY_JOB_REPORTS_SUCCESS = 'GET_EMPLOYER_WEEKLY_JOB_REPORTS_SUCCESS';
export const GET_EMPLOYER_WEEKLY_JOB_REPORTS_FAIL = 'GET_EMPLOYER_WEEKLY_JOB_REPORTS_FAIL';
export const GET_EMPLOYER_MONTHLY_JOB_REPORTS_START = 'GET_EMPLOYER_MONTHLY_JOB_REPORTS_START';
export const GET_EMPLOYER_MONTHLY_JOB_REPORTS_SUCCESS = 'GET_EMPLOYER_MONTHLY_JOB_REPORTS_SUCCESS';
export const GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL = 'GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL';
export const GET_EMPLOYER_YEARLY_JOB_REPORTS_START = 'GET_EMPLOYER_YEARLY_JOB_REPORTS_START';
export const GET_EMPLOYER_YEARLY_JOB_REPORTS_SUCCESS = 'GET_EMPLOYER_YEARLY_JOB_REPORTS_SUCCESS';
export const GET_EMPLOYER_YEARLY_JOB_REPORTS_FAIL = 'GET_EMPLOYER_YEARLY_JOB_REPORTS_FAIL';


export const GET_MANAGEMENT_STATS_START = 'GET_MANAGEMENT_STATS_START';
export const GET_MANAGEMENT_STATS_SUCCESS = 'GET_MANAGEMENT_STATS_SUCCESS';
export const GET_MANAGEMENT_STATS_FAIL = 'GET_MANAGEMENT_STATS_FAIL';
export const GET_MANAGEMENT_MONTHLY_JOB_DATA_START = 'GET_MANAGEMENT_MONTHLY_JOB_DATA_START';
export const GET_MANAGEMENT_MONTHLY_JOB_DATA_SUCCESS = 'GET_MANAGEMENT_MONTHLY_JOB_DATA_SUCCESS';
export const GET_MANAGEMENT_MONTHLY_JOB_DATA_FAIL = 'GET_MANAGEMENT_MONTHLY_JOB_DATA_FAIL';


export const GET_RECRUITER_STATS_START = 'GET_RECRUITER_STATS_START';
export const GET_RECRUITER_STATS_SUCCESS = 'GET_RECRUITER_STATS_SUCCESS';
export const GET_RECRUITER_STATS_FAIL = 'GET_RECRUITER_STATS_FAIL';
export const GET_RECRUITER_MONTHLY_JOB_DATA_START = 'GET_RECRUITER_MONTHLY_JOB_DATA_START';
export const GET_RECRUITER_MONTHLY_JOB_DATA_SUCCESS = 'GET_RECRUITER_MONTHLY_JOB_DATA_SUCCESS';
export const GET_RECRUITER_MONTHLY_JOB_DATA_FAIL = 'GET_RECRUITER_MONTHLY_JOB_DATA_FAIL';

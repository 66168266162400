import axios from 'axios';
import {
    GET_JOBSEEKER_EXPERIENCES,
    GET_JOBSEEKER_LINKS,
    GET_JOBSEEKER_LANGUAGES,
    GET_JOBSEEKER_SKILLS,
    GET_JOBSEEKER_JOB_APPLICATIONS,
    GET_JOBSEEKER_RESUME,
    GET_JOBSEEKER_AVAILABILITY,
    GET_JOBSEEKER_ACADEMIC_APPLICATIONS,
    GET_USERNAMES,
    GET_EMAILS,
    GET_USER_PROFILE,

} from '../types/candidateTypes';

import {
    jobseekerexperiencesURL,
    jobseekerlinksURL,
    jobseekeracademicqualificationsURL,
    jobseekerlanguagesURL,
    jobseekerskillsURL,
    jobseekerjobapplicationsURL,
    candidatesavalaibilityURL,
    candidatesresumeURL,
    emailsURL,
    usernamesURL,
    userprofileURL,

} from '../constants';


export const getUserProfile = (token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${userprofileURL}`, headers)
        .then(res => {
            dispatch({
                type: GET_USER_PROFILE,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getEmails = (token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${emailsURL}`, headers)
        .then(res => {
            dispatch({
                type: GET_EMAILS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getUsernames = (token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${usernamesURL}`, headers)
        .then(res => {
            dispatch({
                type: GET_USERNAMES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}




export const getJobSeekerExperiences = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobseekerexperiencesURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_EXPERIENCES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getJobSeekerLinks = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobseekerlinksURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_LINKS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getJobSeekerLanguages = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobseekerlanguagesURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_LANGUAGES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

export const getJobSeekerAcademicQualifications = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobseekeracademicqualificationsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_ACADEMIC_APPLICATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getJobSeekerSkills = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobseekerskillsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_SKILLS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getJobSeekerJobApplications = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobseekerjobapplicationsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_JOB_APPLICATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getJobSeekerResume = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidatesresumeURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_RESUME,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getJobSeekerAvailability = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidatesavalaibilityURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOBSEEKER_AVAILABILITY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import ManagementLayout from '../layout/ManagementLayout';
import {connect} from 'react-redux';
import {
    getDistricts, 
    addDistrict,
    editDistrict,
    getProvinces
    
} from '../../actions/places';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Chip } from 'primereact/chip';
import AOS from "aos";
import 'aos/dist/aos.css';



const Districts = (props) => {

    const [filters2, setFilters2] = useState({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'name': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'province': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });


    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [loading2, setLoading2] = useState(false);
    const {records, token} = props;
    

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    let initialValues = {
        name: '',
        province: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const sortOptions = [
        {label: 'Price High to Low', value: '!price'},
        {label: 'Price Low to High', value: 'price'},
    ];
    
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [tableDialog, setTableDialog] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    const [globalFilter, setGlobalFilter] = useState(null);
    


    useEffect(() => {
        if (!props.fetched){
            props.getDistricts(props.token);
        }
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        props.getDistricts(props.token);
        props.getProvinces(props.token);
        console.log('Call this nigga!!!');
        setIsSubmit(false);
        

    }, [isSubmit])// eslint-disable-line react-hooks/exhaustive-deps


    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();
        let _record = {...record};
        if (record.id) {
            if (validate()){
                setIsSubmit(true);
                props.editDistrict(record.id, record, props.token);
                props.getDistricts(props.token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                console.log(errors)
                props.addDistrict(record, props.token);
                props.getDistricts(props.token);
                resetForm();
                setDisplayMaximizable(false);
            }

        }
        
    }

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "NAME IS REQUIRED."
        if ('province' in fieldValues)
            temp.province = fieldValues.province ? "" : "PROVINCE IS REQUIRED."

        setErrors({
            ...temp

        })
    
        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
        'tableDialog': setTableDialog,
    }

    const editContinentButton = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }


    

    const renderListItem = (data) => {
        return (
            <div className="col-12 flex-grow-1 md:col-12 card border-50 box border-round shadow-8" style={{padding: '20px', fontFamily: 'cursive'}}>
                <ul  className="box shadow-8 list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                    <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                        <div className=" col-12 md:w-12 font-medium">
                            <Chip label={data.name}  className="mr-2 mb-2 " />
                        </div>       
                    </li>
                    <li className="flex-wrap col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                        <div className=" col-12 md:w-12 font-medium">
                            PROVINCE
                        </div> 
                        <div className=" col-12 md:w-12 font-medium">
                            <Chip label={data.province}  className="mr-2 mb-2  " />
                        </div>       
                    </li>
                    <li>
                        <div className='grid p-fluid'>
                            <div className="col-12 md:col-2">
                                <Button
                                    className="p-button-text p-button-warning"
                                    icon="pi pi-pencil"
                                    onClick={() => editContinentButton(data)}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }

    const renderGridItem = (data) => {
        return (
            <div data-aos="flip-left" className="col-12 flex-grow-1 md:col-6 card border-50 box border-round shadow-8" style={{padding: '20px', fontFamily: 'cursive'}}>
                <div data-aos="zoom-in">
                    <ul data-aos="fade-down"  className="box shadow-8 list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.name}  className="mr-2 mb-2 " />
                            </div>       
                        </li>
                        <li className="flex-wrap col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                PROVINCE
                            </div> 
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.province}  className="mr-2 mb-2  " />
                            </div>       
                        </li>
                        <li>
                            <div className='grid p-fluid'>
                                <div className="col-12 md:col-2">
                                    <Button
                                        className="p-button-text p-button-warning"
                                        icon="pi pi-pencil"
                                        onClick={() => editContinentButton(data)}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }

    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                
                <div className="col-4" style={{textAlign: 'center', padding: '20px'}}>
                    <Button
                        label="ADD NEW"
                        className="p-button-text p-button-warning "
                        onClick={() => onClick('displayMaximizable')}
                    />
                </div>
                <div className="col-2" style={{textAlign: 'center', padding: '20px'}}>
                    <Button
                        label="TABLE"
                        className="p-button-text "
                        onClick={() => onClick('tableDialog')}
                    />       
                </div>
                <div className="col-2" style={{textAlign: 'center', padding: '20px'}}>
                    <DataViewLayoutOptions 
                        layout={layout} 
                    />
                </div>
            </div>
        );
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }


    const tableHeader = (
        <div className="table-header">
                <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label="ADD NEW"
                    className="p-button-text p-button-warning "
                    onClick={() => onClick('displayMaximizable')}
                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
               
                <Button icon="pi pi-upload" className="p-button-text"/>
            </React.Fragment>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-text p-button-success mr-2" 
                    onClick={() => editContinentButton(rowData)} 
                />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-text p-button-warning"  
                />
            </React.Fragment>
        );
    }

    const header = renderHeader();
    

    return (
        <ManagementLayout>
            <div className="dataview-demo">
                <div className="card">
                    <DataView 
                        value={props.records} 
                        layout={layout} 
                        header={header}
                        itemTemplate={itemTemplate} 
                        paginator rows={30}
                        sortOrder={sortOrder} 
                        sortField={sortField} 
                    />
                </div>
            </div>
            <Dialog
                header="DISTRICT FORM" 
                visible={displayMaximizable} 
                maximizable modal 
                style={{ width: '50vw' }} 
                onHide={() => onHide('displayMaximizable')}
            >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <ul data-aos="flip-left"  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                                <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        SELECT NAME
                                    </div>
                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="name"
                                        value={record.name}
                                        onChange={(e) => onInputChange(e, 'name')}
                                    />    
                                    <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.name}</small>
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        SELECT PROVINCE
                                    </div>
                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        value={record.province}
                                        optionLabel="name"
                                        optionValue="name"
                                        options={props.provinces}
                                        onChange={(e) => onInputChange(e, 'province')}
                                        filter
                                        filterBy="name"  
                                    />    
                                    <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.province}</small>  
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                                    <Button 
                                        type="submit" 
                                        label="ADD DISTRICT" 
                                        className="p-button-outlined p-button-warning"
                                        
                                    />
                                </li>
                            </ul>
                        </form>    
                    </div>
                    
            </Dialog>
            <Dialog
                header="DISTRICTS" 
                visible={tableDialog} 
                maximizable modal 
                style={{ width: '1200px' }} 
                onHide={() => onHide('tableDialog')}
            >
                <div className="card">
                    <Toolbar 
                        className="mb-4" 
                        left={leftToolbarTemplate} 
                        right={rightToolbarTemplate}
                    />
                    <DataTable 
                        value={props.records} 
                        responsiveLayout="scroll"
                        dataKey="id" 
                        paginator 
                        globalFilter={globalFilter}
                        filterDisplay="row"
                        filters={filters2}
                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                        rows={30} 
                        header={tableHeader}
                        globalFilterFields={['name', 'province', ]}
                        rowsPerPageOptions={[5, 10, 25]}
                        showGridlines
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} COUNTRIES"
                    >
                        <Column 
                            field="name" 
                            header="NAME"
                            sortable 
                            filter
                        />
                        <Column 
                            field="province" 
                            header="PROVINCE"
                            sortable 
                            filter
                        />
                        
                         <Column 
                            body={actionBodyTemplate} 
                            exportable={false} 
                            style={{ minWidth: '8rem' }}
                        />
                    </DataTable>
                </div>


            </Dialog>
        </ManagementLayout>
    );
}



const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.places.districts,
    provinces: state.places.provinces
})

export default connect(
  mapStateToProps,
  {getProvinces, 
    addDistrict,
    editDistrict,
    getDistricts,
} )
  (Districts);
   
import {
    GET_MATCHED_USER_PORTFOLIOS_START,
    GET_MATCHED_USER_PORTFOLIOS_SUCCESS,
    GET_MATCHED_USER_PORTFOLIOS_FAIL,
    GET_MATCHED_USER_SERVICES_START,
    GET_MATCHED_USER_SERVICES_SUCCESS,
    GET_MATCHED_USER_SERVICES_FAIL,
    GET_MATCHED_USER_OBJECTIVES_START,
    GET_MATCHED_USER_OBJECTIVES_SUCCESS,
    GET_MATCHED_USER_OBJECTIVES_FAIL,
    GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_START,
    GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_SUCCESS,
    GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_FAIL,
    GET_MATCHED_USER_LANGUAGES_START,
    GET_MATCHED_USER_LANGUAGES_SUCCESS,
    GET_MATCHED_USER_LANGUAGES_FAIL,
    GET_MATCHED_USER_LINKS_START,
    GET_MATCHED_USER_LINKS_SUCCESS,
    GET_MATCHED_USER_LINKS_FAIL,
    GET_MATCHED_USER_EXPERIENCES_START,
    GET_MATCHED_USER_EXPERIENCES_SUCCESS,
    GET_MATCHED_USER_EXPERIENCES_FAIL,
    GET_MATCHED_USER_SKILLS_START,
    GET_MATCHED_USER_SKILLS_SUCCESS,
    GET_MATCHED_USER_SKILLS_FAIL,
    GET_USERS_MATCHED_BY_JOB_START,
    GET_USERS_MATCHED_BY_JOB_SUCCESS,
    GET_USERS_MATCHED_BY_JOB_FAIL,
    GET_USERS_MATCHED_BY_INDUSTRY_START,
    GET_USERS_MATCHED_BY_INDUSTRY_SUCCESS,
    GET_USERS_MATCHED_BY_INDUSTRY_FAIL,
    GET_MATCHED_USER_PROFILE_START,
    GET_MATCHED_USER_PROFILE_SUCCESS,
    GET_MATCHED_USER_PROFILE_FAIL,

} from '../types/matchTypes';
import {updateObject} from './utility';

const initialState = {
    matchedUserPortfolios: [],
    matchedUserServices: [],
    matchedUserObjectives: [],
    matchedUserAcademicQualifications: [],
    matchedUserLanguages: [],
    matchedUserLinks: [],
    matchedUserExperiences: [],
    matchedUserSkills: [],
    matchedUserProfile: {},
    usersMatchedByJob: [],
    usersMatchedByIndustry: [],
    error: null,
    loading: false,
}


const getMatchedUserPortfoliosStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getMatchedUserPortfoliosSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserPortfolios: action.matchedUserPortfolios,
      error: null,
      loading: false
    });
  };


const getMatchedUserPortfoliosFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};


const getMatchedUserServicesStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getMatchedUserServicesSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserServices: action.matchedUserServices,
      error: null,
      loading: false
    });
  };


const getMatchedUserServicesFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



const getMatchedUserObjectivesStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getMatchedUserObjectivesSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserObjectives: action.matchedUserObjectives,
      error: null,
      loading: false
    });
  };


const getMatchedUserObjectivesFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



const getMatchedUserAcademicQualificationsStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getMatchedUserAcademicQualificationsSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserAcademicQualifications: action.matchedUserAcademicQualifications,
      error: null,
      loading: false
    });
  };


const getMatchedUserAcademicQualificationsFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};


const getMatchedUserLanguagesStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getMatchedUserLanguagesSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserLanguages: action.matchedUserLanguages,
      error: null,
      loading: false
    });
  };


const getMatchedUserLanguagesFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



const getMatchedUserLinksStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getMatchedUserLinksSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserLinks: action.matchedUserLinks,
      error: null,
      loading: false
    });
  };


const getMatchedUserLinksFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



const getMatchedUserExperiencesStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
};


const getMatchedUserExperiencesSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserExperiences: action.matchedUserExperiences,
      error: null,
      loading: false
    });
};


const getMatchedUserExperiencesFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



const getMatchedUserSkillsStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
};


const getMatchedUserSkillsSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserSkills: action.matchedUserSkills,
      error: null,
      loading: false
    });
};


const getMatchedUserSkillsFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};


const getMatchedUserProfileStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
};


const getMatchedUserProfileSuccess = (state, action) => {
    return updateObject(state, {
      matchedUserProfile: action.matchedUserProfile,
      error: null,
      loading: false
    });
};


const getMatchedUserProfileFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



const getUsersMatchedByJobStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
};


const getUsersMatchedByJobSuccess = (state, action) => {
    return updateObject(state, {
      usersMatchedByJob: action.usersMatchedByJob,
      error: null,
      loading: false
    });
};


const getUsersMatchedByJobFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};


const getUsersMatchedByIndustryStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
};


const getUsersMatchedByIndustrySuccess = (state, action) => {
    return updateObject(state, {
      usersMatchedByIndustry: action.usersMatchedByIndustry,
      error: null,
      loading: false
    });
};


const getUsersMatchedByIndustryFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};



export default function matches(state = initialState, action){
    switch(action.type){
      case GET_MATCHED_USER_PORTFOLIOS_START:
        return getMatchedUserPortfoliosStart(state, action);
      case GET_MATCHED_USER_PORTFOLIOS_SUCCESS:
          return getMatchedUserPortfoliosSuccess(state, action);
      case GET_MATCHED_USER_PORTFOLIOS_FAIL:
          return getMatchedUserPortfoliosFail(state, action);
      case GET_MATCHED_USER_SERVICES_START:
        return getMatchedUserServicesStart(state, action);
      case GET_MATCHED_USER_SERVICES_SUCCESS:
          return getMatchedUserServicesSuccess(state, action);
      case GET_MATCHED_USER_SERVICES_FAIL:
          return getMatchedUserServicesFail(state, action);
      case GET_MATCHED_USER_OBJECTIVES_START:
        return getMatchedUserObjectivesStart(state, action);
      case GET_MATCHED_USER_OBJECTIVES_SUCCESS:
          return getMatchedUserObjectivesSuccess(state, action);
      case GET_MATCHED_USER_OBJECTIVES_FAIL:
          return getMatchedUserObjectivesFail(state, action);
      case GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_START:
        return getMatchedUserAcademicQualificationsStart(state, action);
      case GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_SUCCESS:
          return getMatchedUserAcademicQualificationsSuccess(state, action);
      case GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_FAIL:
          return getMatchedUserAcademicQualificationsFail(state, action);
      case GET_MATCHED_USER_LANGUAGES_START:
        return getMatchedUserLanguagesStart(state, action);
      case GET_MATCHED_USER_LANGUAGES_SUCCESS:
          return getMatchedUserLanguagesSuccess(state, action);
      case GET_MATCHED_USER_LANGUAGES_FAIL:
          return getMatchedUserLanguagesFail(state, action);
      case GET_MATCHED_USER_LINKS_START:
        return getMatchedUserLinksStart(state, action);
      case GET_MATCHED_USER_LINKS_SUCCESS:
          return getMatchedUserLinksSuccess(state, action);
      case GET_MATCHED_USER_LINKS_FAIL:
          return getMatchedUserLinksFail(state, action);
      case GET_MATCHED_USER_EXPERIENCES_START:
        return getMatchedUserExperiencesStart(state, action);
      case GET_MATCHED_USER_EXPERIENCES_SUCCESS:
          return getMatchedUserExperiencesSuccess(state, action);
      case GET_MATCHED_USER_EXPERIENCES_FAIL:
          return getMatchedUserExperiencesFail(state, action);
      case GET_MATCHED_USER_SKILLS_START:
        return getMatchedUserSkillsStart(state, action);
      case GET_MATCHED_USER_SKILLS_SUCCESS:
          return getMatchedUserSkillsSuccess(state, action);
      case GET_MATCHED_USER_SKILLS_FAIL:
          return getMatchedUserSkillsFail(state, action);
      case GET_MATCHED_USER_PROFILE_START:
        return getMatchedUserProfileStart(state, action);
      case GET_MATCHED_USER_PROFILE_SUCCESS:
          return getMatchedUserProfileSuccess(state, action);
      case GET_MATCHED_USER_PROFILE_FAIL:
          return getMatchedUserProfileFail(state, action);
      case GET_USERS_MATCHED_BY_JOB_START:
        return getUsersMatchedByJobStart(state, action);
      case GET_USERS_MATCHED_BY_JOB_SUCCESS:
          return getUsersMatchedByJobSuccess(state, action);
      case GET_USERS_MATCHED_BY_JOB_FAIL:
          return getUsersMatchedByJobFail(state, action);
      case GET_USERS_MATCHED_BY_INDUSTRY_START:
        return getUsersMatchedByIndustryStart(state, action);
      case GET_USERS_MATCHED_BY_INDUSTRY_SUCCESS:
          return getUsersMatchedByIndustrySuccess(state, action);
      case GET_USERS_MATCHED_BY_INDUSTRY_FAIL:
          return getUsersMatchedByIndustryFail(state, action);
      default:
          return state;

    }

}

import axios from 'axios';
import {
    GET_ADMIN_CHATS,
    GET_CHAT_MESSAGES,
    READ_MESSAGES,
    DELETE_MESSAGE,
    ADD_MESSAGE,
    GET_CHAT,
    GET_USER_MESSAGES 
} from '../types/chatTypes';

import {
    adminchannelsURL,
    chatmessagesURL,
    usermessagesURL,

} from '../constants';
import { returnErrors } from './messages';



export const getAdminChats = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(adminchannelsURL, headers)
        .then(res => {
            dispatch({
                type: GET_ADMIN_CHATS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getChat = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${adminchannelsURL}${slug}/`, headers)
        .then(res => {
            dispatch({
                type: GET_CHAT,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

export const getChatMessages = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${chatmessagesURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_CHAT_MESSAGES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const deleteMessage = (slug, data, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.post(`${chatmessagesURL}${slug}/deleter/`, data, headers)
        .then(res => {
            dispatch({
                type: DELETE_MESSAGE,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const addMessage = (message, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(chatmessagesURL, message, headers)
        .then(res => {
            dispatch({
                type: ADD_MESSAGE,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const readMessages = (slug, chat, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${adminchannelsURL}${slug}/read_messages/`, chat, headers)
        .then(res => {
            dispatch({
                type: READ_MESSAGES,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}



export const getUserMessages = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(usermessagesURL, headers)
        .then(res => {
            dispatch({
                type: GET_USER_MESSAGES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}





import axios from 'axios';
import {
    GET_FAQS,
    ADD_FAQ,
    EDIT_FAQ,
    DELETE_FAQ
} from '../types/faqTypes';

import {
    faqsURL

} from '../constants';
import {  returnErrors } from './messages';




export const getFaqs = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(faqsURL, headers)
        .then(res => {
            dispatch({
                type: GET_FAQS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addFaq = (faq, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(faqsURL, faq, headers)
        .then(res => {
            dispatch({
                type: ADD_FAQ,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteFaq = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(faqsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_FAQ,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editFaq = (slug, faq, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${faqsURL}${slug}/`, faq, headers)
        .then(res => {
            dispatch({
                type: EDIT_FAQ,
                payload: res.data
            });
        }).catch(err => console.log(err))
}
import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ManagementLayout from '../layout/ManagementLayout';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    getCountries,
    getProvinces,
    getDistricts

} from '../../actions/places';
import { getUsers } from '../../actions/auth';
import {addManagementJob, getManagementJobs} from '../../actions/jobs';
import {getSkills, getQualifications} from '../../actions/skills';

import {
    chargeCategoryOptions,
    jobTypeOptions,
    jobFishingExpectedTimelineOptions,
    jobDurationOptions,
    prefferedSkillsetOptions,
    geographicalLocationOptions,
    locationOptions,
    compensationOptions,
    competencyOptions,
    genderOptions,
    levelOptions

} from './data';




const JobForm = (props) => {
    let requirementInitialData = {
      gender: '',
      age_high: '',
      age_low: ''
    }
    const [requirement, setRequirement] = useState(requirementInitialData);
    const [compensation_type, setCompensationType] = useState(null);
    const [location, setLocation] = useState(null);
    const [fixed_price, setFixedPrice] = useState(null);
    const [from_hourly_price, setFromHourlyPrice] = useState(null);
    const [to_hourly_price, setToHourlyPrice] = useState(null);
    const [duration_category, setDurationCategory] = useState(null);
    const [category, setCategory] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [charge_category, setChargeCategory] = useState(null);
    const [expected_duration, setExpectedDuration] = useState(null);
    const [required_skillset, setRequiredSkillset] = useState(null);
    const [country, setCountry] = useState(null);
    const [location_type, setLocationType] = useState(null);
    const [preffered_city, setPrefferedCity] = useState(null);
    const [preffered_district, setPrefferedDistrict] = useState(null);
    const [employer, setEmployer] = useState(null);
    const [how_to_apply, setHowToApply] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [skills, setSkills] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const employerOptions = props.users.filter((user) => user.type ==="employer")
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [errors, setErrors] = useState({});


    const validate = () => {
      let temp = { ...errors }
      if (title){
        temp.title = title ? "" : "TITLE IS REQUIRED."
      }
          
      setErrors({
          ...temp
      })
  
      
      return Object.values(temp).every(x => x === "")
    }

    


    useEffect(() =>{
        if(!props.fetched){
            props.getCountries(props.token);
            props.getProvinces(props.token);
            props.getDistricts(props.token);
            props.getUsers(props.token);
            props.getSkills(props.token);
            props.getQualifications(props.token);
        };


    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
      props.getManagementJobs(props.token);
      setIsSubmit(false);

    }, [isSubmit]) // eslint-disable-line react-hooks/exhaustive-deps


    const resetForm = () => {
        setCompensationType();
        setLocation();
        setFixedPrice();
        setFromHourlyPrice();
        setToHourlyPrice();
        setDurationCategory();
        setCategory();
        setTitle();
        setDescription();
        setChargeCategory();
        setExpectedDuration();
        setRequiredSkillset();
        setCountry();
        setLocationType();
        setPrefferedCity();
        setPrefferedDistrict();
        setEmployer();
        // setQuestions();
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let job = {
            compensation_type,
            location,
            fixed_price,
            from_hourly_price,
            to_hourly_price,
            duration_category,
            category,
            title,
            description,
            charge_category,
            expected_duration,
            required_skillset,
            country,
            location_type,
            preffered_city,
            preffered_district,
            employer,
            questions,
            skills,
            qualifications,
            requirement,
            how_to_apply

        }
        if (validate()){
          props.addManagementJob(job, props.token);
          console.log(job)
          resetForm();
          setIsSubmit(true);
          props.getManagementJobs(props.token);
          navigate("/management/jobs", {replace: true });
        }
      }


    const onOptionsChange = (e, func) => {
        func(e.value);
    }


    const onRequirementChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...requirement};
        _record[`${name}`] = val;
        setRequirement(_record);
    }


    const handleAddQuestion = (e) => {
        e.preventDefault();
        const inputState = {
            id: Math.random(),
            question: ""
        };
        setQuestions((prev) => [...prev, inputState])

    }



    const handleQuestionOnChange = (id, e) =>{
        e.preventDefault();
        e.persist();
        setQuestions((prev) => {
            return prev.map((item) =>{
                if (item.id !== id){
                    return item;
                }

                return {
                    ...item,
                    [e.target.name]: e.target.value
                }

            });

        });

    }

    const handleRemoveField = (e, index) => {
        e.preventDefault();
        setQuestions((prev) => prev.filter((item) => item !== prev[index]));
    };


      const handleAddSkill = (e) => {
        e.preventDefault();
        const inputState = {
            id: Math.random(),
            skill: "",
            years_of_experience: "",
            competency: "",

        };
        setSkills((prev) => [...prev, inputState])

    }



    const handleSkillOnChange = (id, e) =>{
        e.preventDefault();
        e.persist();
        setSkills((prev) => {
            return prev.map((item) =>{
                if (item.id !== id){
                    return item;
                }

                return {
                    ...item,
                    [e.target.name]: e.target.value
                }

            });

        });

    }

    const handleSkillSelectOnChange = (id, e) =>{
      e.preventDefault();
      // e.persist();
      setSkills((prev) => {
          return prev.map((item) =>{
              if (item.id !== id){
                  return item;
              }

              return {
                  ...item,
                  [e.target.name]: e.target.value
              }

          });

      });

  }


  const handleRemoveSkill = (e, index) => {
      e.preventDefault();
      setSkills((prev) => prev.filter((item) => item !== prev[index]));
  };


  const handleAddQualification = (e) => {
      e.preventDefault();
      const inputState = {
          id: Math.random(),
          qualification: "",
          levels: [],
      };
      setQualifications((prev) => [...prev, inputState])

    }


    const handleQualificationSelectOnChange = (id, e) =>{
          e.preventDefault();
          setQualifications((prev) => {
              return prev.map((item) =>{
                  if (item.id !== id){
                      return item;
                  }

                  return {
                      ...item,
                      [e.target.name]: e.target.value
                  }

              });

          });

    }


    const handleRemoveQualification = (e, index) => {
      e.preventDefault();
      setQualifications((prev) => prev.filter((item) => item !== prev[index]));
    };

    console.log(questions)

    console.log(skills)

    console.log(qualifications)

    console.log(requirement)

    return (
        <ManagementLayout>
            <div className="cards">
              <div className="surface-0 text-700 text-center">
                <div className="text-blue-600 font-bold mb-3"><i className="pi pi-heart"></i>&nbsp;POWERED BY HLETIPANG</div>
                <div className="text-900 font-bold text-5xl mb-3">ADD YOUR JOB</div>
                <div className="text-700 text-2xl mb-5">GAIN ACCESS TO EMPLOY ONE OF THE BRILLIANT MINDS WITH HLETIPANG </div>

              </div>

                <form onSubmit={handleSubmit}>

                    <div className="grid p-fluid">
                      
                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                WHATS YOUR JOB TITLE ?
                              </div>
                              <span className="p-float-label p-input-icon-right">

                                  <i className="pi pi-spin pi-spinner" />
                                  <InputText
                                      id="name"
                                      value={title}
                                      onChange={(e) => setTitle(e.target.value)}
                                  />
                              </span>
                              <small id="username2-help" className="p-error block">{errors.title}</small>
                        </div>
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                DESCRIBE YOUR PROJECT
                              </div>
                              <span className="p-float-label p-input-icon-right">

                                  <i className="pi pi-spin pi-spinner" />
                                    <InputTextarea
                                        rows={2}
                                        cols={30}
                                        autoResize
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                              </span>
                        </div>
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                HOW WOULD YOU WANT CANDIDATES TO APPLY
                              </div>
                              <span className="p-float-label p-input-icon-right">

                                  <i className="pi pi-spin pi-spinner" />
                                    <InputTextarea
                                        rows={2}
                                        cols={30}
                                        autoResize
                                        value={how_to_apply}
                                        onChange={(e) => setHowToApply(e.target.value)}
                                    />
                              </span>
                        </div>

                      </div>
                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className="text-700 ">
                            <div className="col-12 md:col-12">
                              <div className="text-400 text-2xl mb-5">
                                WOULD YOU WANT YOUR JOB TO BE DONE REMOTE OR ONSITE ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={location}
                                  onChange={(e) => onOptionsChange(e, setLocation)}
                                  options={locationOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />
                            </div>
                            <div className="col-12 md:col-12">
                              <div className="text-700 text-2xl mb-5">
                                HOW WOULD YOU DESCRIBE YOUR JOB?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={category}
                                  onChange={(e) => onOptionsChange(e, setCategory)}
                                  options={jobTypeOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />
                          </div>
                        </div>

                      </div>

                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                HOW WOULD YOU WANT TO COMPENSATE YOUR EMPLOYEE ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={compensation_type}
                                  onChange={(e) => onOptionsChange(e, setCompensationType)}
                                  options={compensationOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />
                        </div>
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                WHAT CALIBRE & EXPERIENCE WOULD YOU BE PRIORITIZING ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={required_skillset}
                                  onChange={(e) => onOptionsChange(e, setRequiredSkillset)}
                                  options={prefferedSkillsetOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  placeholder='PREFFERED SKILLSET'
                                  required
                              />
                        </div>

                      </div>


                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12 "
                        style={{padding: "30px"}}
                        >
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                HOW LONG WOULD YOU WANT YOUR PROJECT TO TAKE, MONTHS, DAYS, HOURS OR YEARS ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={duration_category}
                                  onChange={(e) => onOptionsChange(e, setDurationCategory)}
                                  options={jobDurationOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />
                              <div className="text-700 text-2xl mb-5">
                                HOW LONG LONG EXACTLY ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={expected_duration}
                                  onChange={(e) => onOptionsChange(e, setExpectedDuration)}
                                  options={jobFishingExpectedTimelineOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />

                        </div>

                      </div>

                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                HOW WOULD YOU WANT TO BE CHARGED ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={charge_category}
                                  onChange={(e) => onOptionsChange(e, setChargeCategory)}
                                  options={chargeCategoryOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />
                        </div>
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                WHAT WOULD BE YOUR FIXED BUDGET ?
                              </div>
                              <InputNumber
                                inputId="horizontal"
                                showButtons
                                buttonLayout="horizontal"
                                step={0.25}
                                decrementButtonClassName="p-button-danger"
                                incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                mode="currency"
                                currency="USD"
                                required
                                value={fixed_price}
                                onChange={(e) => setFixedPrice(e.value)}
                            />

                        </div>

                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                FROM THOSE THAT CHARGE HOURLY FROM WHICH AMOUNT WOULD YOU START CONSIDERING?
                              </div>
                              <InputNumber
                                  inputId="horizontal"
                                  showButtons
                                  buttonLayout="horizontal"
                                  step={0.25}
                                  decrementButtonClassName="p-button-danger"
                                  incrementButtonClassName="p-button-success"
                                  incrementButtonIcon="pi pi-plus"
                                  decrementButtonIcon="pi pi-minus"
                                  mode="currency"
                                  currency="USD"
                                  required
                                  value={from_hourly_price}
                                  onChange={(e) => setFromHourlyPrice(e.value)}
                              />
                        </div>
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                FROM THOSE THAT CHARGE HOURLY TO WHICH AMOUNT WOULD YOU STOP CONSIDERING?
                              </div>
                              <InputNumber
                                  inputId="horizontal"
                                  showButtons
                                  buttonLayout="horizontal"
                                  step={0.25}
                                  decrementButtonClassName="p-button-danger"
                                  incrementButtonClassName="p-button-success"
                                  incrementButtonIcon="pi pi-plus"
                                  decrementButtonIcon="pi pi-minus"
                                  mode="currency"
                                  currency="USD"
                                  value={to_hourly_price}
                                  required
                                  onChange={(e) => setToHourlyPrice(e.value)}
                              />
                        </div>

                      </div>
                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className="text-700 ">
                              <div className="text-700 text-2xl mb-5">
                                WHERE WOULD YOU WANT YOUR EMPLOYEES TO COME FROM ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={location_type}
                                  onChange={(e) => onOptionsChange(e, setLocationType)}
                                  options={geographicalLocationOptions}
                                  filter
                                  showClear
                                  filterBy="key"
                                  required
                              />
                        </div>
                        <div className="text-700 ">
                            <div className="text-700 text-2xl mb-5">
                              COUNTRY WHERE YOUR PROJECT IS LOCATED ?
                            </div>
                            <Dropdown
                                optionLabel="name"
                                optionValue="name"
                                value={country}
                                onChange={(e) => onOptionsChange(e, setCountry)}
                                options={props.countries}
                                filter
                                showClear
                                filterBy="name"
                                placeholder='COUNTRY'
                                required
                            />
                      </div>
                      <div className="text-700 ">
                          <div className="text-700 text-2xl mb-5">
                            CITY WHERE YOUR PROJECT IS LOCATED ?
                          </div>
                          <Dropdown
                              optionLabel="name"
                              optionValue="name"
                              value={preffered_city}
                              onChange={(e) => onOptionsChange(e, setPrefferedCity)}
                              options={props.provinces}
                              filter
                              showClear
                              filterBy="name"
                              required
                          />
                    </div>
                    <div className="text-700 ">
                        <div className="text-700 text-2xl mb-5">
                          DISTRICT WHERE YOUR PROJECT IS LOCATED ?
                        </div>
                        <Dropdown
                            optionLabel="name"
                            optionValue="name"
                            value={preffered_district}
                            onChange={(e) => onOptionsChange(e, setPrefferedDistrict)}
                            options={props.districts}
                            filter
                            showClear
                            filterBy="name"
                            required
                        />
                    </div>

                    </div>
                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12"
                      style={{padding: "30px"}}
                      >
                      <div className="text-700 ">
                            <div className="text-700 text-2xl mb-5">
                              WHO IS THE EMPLOYER?
                            </div>
                            <Dropdown
                                optionLabel="username"
                                optionValue="username"
                                value={employer}
                                onChange={(e) => onOptionsChange(e, setEmployer)}
                                options={employerOptions}
                                filter
                                showClear
                                filterBy="username"
                                required
                            />
                      </div>



                    </div>
                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12 "
                      style={{padding: "30px"}}
                      >
                      <div className=" grid p-fluid">
                            <div className=" text-2xl md:col-12">
                              <span style={{fontSize: '15px'}}>ENTER THE REQUIREMENTS BELOW IN ORDER TO HELP THE SYSTEM MATCH YOU WITH RIGHT PEOPLE FOR THE JOB</span>
                            </div>
                            <div className="col-12 md:col-6">
                                <Dropdown
                                    optionLabel="key"
                                    optionValue="key"
                                    name="gender"
                                    value={requirement.gender}
                                    placeholder="GENDER"
                                    onChange={(e)=> onRequirementChange(e, 'gender')}
                                    options={genderOptions}
                                    filter
                                    showClear
                                    filterBy="key"
                                    required
                                />
                                
                            </div>
                            <div className="col-12 md:col-3">
                                <InputText
                                    id="age_high"
                                    required
                                    value={requirement.age_high}
                                    placeholder="AGE HIGH"
                                    type='number'
                                    onChange={(e)=> onRequirementChange(e, 'age_high')}

                                />
                            </div>
                            <div className="col-12 md:col-3">
                                <InputText
                                      id="age_low"
                                      required
                                      value={requirement.age_low}
                                      placeholder="AGE LOW"
                                      type='number'
                                      onChange={(e)=> onRequirementChange(e, 'age_low')}

                                  />
                            </div>
                      </div>
                      


                    </div>
                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12"
                      style={{padding: "30px"}}
                      >
                      <div className="text-700 ">
                            <div className="text-700 text-2xl mb-5">
                              WHAT QUESTIONS WOULD YOU WANT THE APPLICANTS TO SATISFY
                            </div>
                            <div >
                                {
                                    questions.map((item, i)=>{
                                    return(

                                        <div className="grid p-fluid" key={item.id}>

                                        <div className="col-12 md:col-11">
                                            <span className="p-float-label p-input-icon-right">
                                                <i className="pi pi-spin pi-spinner" />
                                                <InputTextarea
                                                    rows={2}
                                                    cols={30}
                                                    autoResize
                                                    name="question"
                                                    value={item.question}
                                                    placeholder="QUESTION"
                                                    onChange={(e)=> handleQuestionOnChange(item.id, e)}

                                                />
                                            </span>
                                        </div>


                                        <div className="col-12 md:col-1">

                                            <Button
                                                className="p-button-rounded p-button-danger p-button-outlined"
                                                icon = "pi pi-trash"
                                                onClick={(e) => handleRemoveField(e, i)}
                                            />

                                        </div>
                                    </div>
                                    );
                                } )}
                                <div className="col-12 md:col-1">
                                    <Button
                                        className="p-button-rounded p-button-outlined"
                                        onClick={ handleAddQuestion}
                                        icon = "pi pi-plus"
                                    />
                                </div>
                            </div>
                      </div>


                    </div>

                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12"
                      style={{padding: "30px"}}
                      >
                      <div className="text-700 ">
                            <div className="text-700 text-2xl mb-5">
                              WHAT SKILLS ARE REQUIRED
                            </div>
                            <div >
                                {
                                    skills.map((item, i)=>{
                                      
                                    return(

                                        <div className="grid p-fluid" key={item.id}>

                                        <div className="col-12 md:col-5">
                                          <Dropdown
                                              optionLabel="title"
                                              optionValue="slug"
                                              value={item.skill}
                                              name='skill'
                                              onChange={(e)=> handleSkillSelectOnChange(item.id, e)}
                                              options={props.skills}
                                              filter
                                              showClear
                                              filterBy="title"
                                              placeholder='SKILL'
                                              required
                                          />
                                        </div>
                                        <div className="col-12 md:col-5">
                                          <Dropdown
                                              optionLabel="key"
                                              optionValue="key"
                                              value={item.competency}
                                              name='competency'
                                              onChange={(e)=> handleSkillSelectOnChange(item.id, e)}
                                              options={competencyOptions}
                                              filter
                                              showClear
                                              filterBy="key"
                                              placeholder='COMPETENCY'
                                              required
                                          />
                                        </div>
                                        <div className="col-12 md:col-1">
                                          <InputText
                                              id="years_of_experience"
                                              name='years_of_experience'
                                              required
                                              value={item.years_of_experience}
                                              type='number'
                                              placeholder='YEARS'
                                              onChange={(e)=> handleSkillOnChange(item.id, e)}
                                          />
                                        </div>
                                        <div className="col-12 md:col-1">

                                            <Button
                                                className="p-button-rounded p-button-danger p-button-outlined"
                                                icon = "pi pi-trash"
                                                onClick={(e) => handleRemoveSkill(e, i)}
                                            />

                                        </div>
                                    </div>
                                    );
                                } )}
                                <div className="col-12 md:col-1">
                                    <Button
                                        className="p-button-rounded p-button-outlined"
                                        onClick={ handleAddSkill}
                                        icon = "pi pi-plus"
                                    />
                                </div>
                            </div>
                      </div>
                    </div>

                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12"
                      style={{padding: "30px"}}
                      >
                      <div className="text-700 ">
                            <div className="text-700 text-2xl mb-5">
                              WHAT ARE QUALIFICATIONS REQUIRED
                            </div>
                            <div >
                                {
                                    qualifications.map((item, i)=>{
                                      
                                    return(

                                        <div className="grid p-fluid" key={item.id}>

                                        <div className="col-12 md:col-5">
                                          <Dropdown
                                              optionLabel="title"
                                              optionValue="slug"
                                              value={item.qualification}
                                              name='qualification'
                                              onChange={(e)=> handleQualificationSelectOnChange(item.id, e)}
                                              options={props.qualifications}
                                              filter
                                              showClear
                                              filterBy="title"
                                              placeholder='QUALIFICATION'
                                              required
                                          />
                                        </div>
                                        <div className="col-12 md:col-6">
                                          <MultiSelect 
                                            value={item.levels} 
                                            name='levels'
                                            options={levelOptions} 
                                            onChange={(e)=> handleQualificationSelectOnChange(item.id, e)}
                                            optionLabel="key" 
                                            placeholder="Select Levels" 
                                            filter 
                                            className="multiselect-custom"
                                          />
                                        </div>
                                        
                                        
                                        <div className="col-12 md:col-1">

                                            <Button
                                                className="p-button-rounded p-button-danger p-button-outlined"
                                                icon = "pi pi-trash"
                                                onClick={(e) => handleRemoveQualification(e, i)}
                                            />

                                        </div>
                                    </div>
                                    );
                                } )}
                                <div className="col-12 md:col-1">
                                    <Button
                                        className="p-button-rounded p-button-outlined"
                                        onClick={ handleAddQualification}
                                        icon = "pi pi-plus"
                                    />
                                </div>
                            </div>
                      </div>
                    </div>


                        <div className="col-12 md:col-4">
                            <Button
                                type="submit"
                                label="Submit"
                                className="mt-2"

                            />
                            <label htmlFor="horizontal"></label>
                        </div>
                    </div>
                </form>
            </div>
        </ManagementLayout>
    );
}


const mapStateToProps = state =>({
    token: state.auth.token,
    countries: state.places.countries,
    provinces: state.places.provinces,
    districts: state.places.districts,
    users: state.auth.people,
    skills: state.skills.skills,
    qualifications: state.skills.qualifications,
  })

  export default connect(
    mapStateToProps,
    {

        getCountries,
        getProvinces,
        getDistricts,
        getUsers,
        addManagementJob,
        getManagementJobs,
        getSkills, 
        getQualifications
    } )
  (JobForm);

import React ,{useEffect} from 'react';
import header from './images/diamond/header-image.png';
import headerImg from './images/diamond/bg-header.svg';
import feat1 from './images/diamond/feature-image-1.png';
import feat3 from './images/diamond/feature-image-3.png';
import hero from './images/hero.svg';
import graph from './images/graph-4.svg';
import { Button } from 'primereact/button';
import ico1 from './images/diamond/icon-design.svg';
import ico2 from './images/diamond/icon-devices.svg';
import ico3 from './images/diamond/icon-document.svg';
import ico4 from './images/diamond/icon-diamond.svg';
import charts from './images/diamond/charts.png';
import AOS from "aos";
import 'aos/dist/aos.css';


const Home = () =>{

  useEffect(() =>{
    AOS.init({duration : 3000})

  }, []);


  return(
    <>
      <div data-aos="zoom-out" className="landing-body block-content card">
        <div className="landing-wrappers">
          <div>
               <div className=" px-4 py-8 md:px-6 lg:px-10" data-aos="zoom-in">
                 <div className="flex flex-wrap" data-aos="zoom-in">
                   <div className="w-12 lg:w-6 p-4">
                     <h1 className="text-6xl font-bold text-primary mt-0 mb-3">
                         Save yourself from a headache of recruitment with ZimJobsCenter
                       </h1>
                     <p className="text-3xl text-primary mt-0 mb-5">
                       AI-backed Intelligent Network that helps
                       you source, vet, match, and manage the
                       world's best talent
                     </p>
                     <ul className="list-none p-0 m-0" data-aos="zoom-out">
                       <li className="mb-3 flex align-items-center">
                         <i className="pi pi-compass text-yellow-500 text-xl mr-2">
                         </i>
                         <span className="text-primary line-height-3" data-aos="zoom-out">
                           HIRE TOP TALENT
                         </span>
                       </li>
                       <li className="mb-3 flex align-items-center" data-aos="zoom-out">
                         <i className="pi pi-map text-yellow-500 text-xl mr-2">
                           </i>
                           <span className="text-primary line-height-3">
                             FREELANCE WITH US
                           </span>
                       </li>
                       <li className="mb-3 flex align-items-center" data-aos="zoom-out">
                         <i className="pi pi-search text-yellow-500 text-xl mr-2">
                         </i>
                         <span className="text-primary line-height-3">
                           FIND A JOB
                         </span>
                       </li>
                       <li className="mb-3 flex align-items-center" data-aos="zoom-out">
                         <i className="pi pi-star text-yellow-500 text-xl mr-2">
                         </i>
                         <span className="text-primary line-height-3">
                           ADVERTISE YOUR SKILLS
                         </span>
                       </li>
                       <li className="mb-3 flex align-items-center" data-aos="zoom-out">
                         <i className="pi pi-percentage text-yellow-500 text-xl mr-2">
                         </i>
                         <span className="text-primary line-height-3">
                           FIND A TECHNICIAN NEXT TO YOU
                         </span>
                       </li>
                       <li className="mb-3 flex align-items-center" data-aos="zoom-out">
                         <i className="pi pi-wallet text-yellow-500 text-xl mr-2">
                         </i>
                         <span className="text-primary line-height-3">
                           EARN
                         </span>
                       </li>
                     </ul>
                     <Button
                       label='LINK & THINK WITH THE BEST'
                       className='p-button-rounded p-button-outlined'
                     />
                   </div>
                     <div  data-aos="zoom-in" className="w-12 lg:w-6 text-center lg:text-right overflow-hidden">
                       <img data-aos="flip-left" src={hero} alt="Images" className="w-full lg:w-auto"/>
                     </div>
                   </div>
            </div>
          </div>
          <div id="meetDiamond" className="landing-meetdiamond" data-aos="zoom-in">
            <div className="section-title">
                <h1 className="text-primary">Welcome TO ZimJobsCenter</h1>
                <span className="text-primary">ARTIFICIAL INTELLIGENCE BACKED SYSTEM</span>
            </div>
            <div className="grid">
                <div className="col-12 md:col-6 lg:col-3" data-aos="zoom-out">
                    <div className="card shadow-8 widget-feature-box" data-aos="zoom-in">
                        <img className="text-primary" src={ico2} alt="device" data-aos="flip-up"/>
                        <h2 className="text-primary">Hire Quickly</h2>
                        <span className="text-primary" >
                          Hire in under 48 hours. Scale up or down, no strings attached.
                          We offer flexible engagements from hourly to full-time.
                        </span>
                    </div>
                </div>
                <div className="col-12 shadow-8 md:col-6 lg:col-3" data-aos="zoom-in">
                    <div className="card widget-feature-box">
                        <img className="text-primary" src={ico1} alt="design"/>
                        <h2 className="text-primary">Elite Hire</h2>
                        <span className="text-primary">
                          Every applicant to the Hletipang network is rigorously tested and vetted.
                          Our highly selective process leads to a 98% trial-to-hire success rate.
                        </span>
                    </div>
                </div>
                <div className="col-12 shadow-8 md:col-6 lg:col-3" data-aos="zoom-in">
                    <div className="card widget-feature-box">
                        <img className="text-primary" src={ico3} alt="document"/>
                        <h2 className="text-primary">Leading the Future of Work</h2>
                        <span className="text-primary">
                          Our network is ready for tomorrow's business challenges by embracing advanced and specialized
                          skills including blockchain and AI.
                        </span>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3" data-aos="zoom-in">
                    <div className="card shadow-8 widget-feature-box">
                        <img className="text-primary" src={ico4} alt="diamond"/>
                        <h2 className="text-primary">A Level Above</h2>
                        <span className="text-primary" >
                          Every single freelancer/jobseeker in our global network embodies the highest
                          levels of integrity,
                          professionalism, and communication.
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div id="features" className="landing-features" data-aos="zoom-in">
            <div className="feature-row">
                <div className="feature-text">
                    <h2 className="text-primary">
                      Talk to One of Our Industry Experts
                    </h2>
                    <span className="text-primary">
                      An expert on our team will work with you to understand your goals,
                      technical needs, and team dynamics.
                      </span>
                </div>
                <div className="feature-image">
                    <img className="animated" alt="feature"
                       src={header}/>
                </div>
            </div>
            <div className="feature-row row-reverse" data-aos="zoom-in">
                <div className="feature-text">
                    <h2 className="text-primary" >
                      Work With Best Matched Talent
                    </h2>
                    <span className="text-primary">
                            Within days, we'll introduce you to the right talent for your project.
                            Average time to match is under 2 minutes.
                          </span>
                </div>
                <div className="feature-image">
                    <img className="animated" alt="feature"
                      src={charts}/>
                </div>
            </div>
            <div className="feature-row" data-aos="zoom-in">
                <div className="feature-text">
                    <h2 className="text-primary">
                      The Right Fit, Guaranteed
                    </h2>
                    <span className="text-primary">
                      Work with your new team member on a trial basis (pay only if satisfied),
                      ensuring you hire the right people for the job.
                    </span>
                </div>
                <div className="feature-image">
                    <img className="animated" alt="feature"
                      src={feat3}/>
                </div>
            </div>
        </div>
        <div id="pricing" className="landing-pricing" data-aos="zoom-out">
            <div className="section-title">
                <h1 className="text-primary">Our Network</h1>
                <span className="text-primary">The best for our Stakeholders</span>
            </div>
            <div className="pricing-cards">
                <div className="widget-pricing-card shadow-8 " data-aos="zoom-in">
                    <h2 className="text-primary">HIRE</h2>
                    <span className="text-primary">Hire With Us</span>
                    <h3 className="text-primary">Simple</h3>
                    <ul>
                        <li className="text-primary">Headache Free</li>
                        <li className="text-primary">Minimize Costs</li>
                        <li className="text-primary">Hire the Best</li>
                        <li className="text-primary">Add Value to your Initiativ</li>
                        <li className="text-primary">Meet Excellence</li>
                    </ul>
                    <Button label='HIRE NOW' className='p-button-outlined' />
                </div>
                <div className="widget-pricing-card shadow-8" data-aos="zoom-in">
                    <h2 className="text-primary">FREELANCE</h2>
                    <span className="text-primary">Freelance with Us</span>
                    <h3 className="text-primary">Advertise your Talent</h3>
                    <ul>
                        <li className="text-primary">Access Opportunities</li>
                        <li className="text-primary">Earn A living</li>
                        <li className="text-primary">Be Visibly</li>
                        <li className="text-primary">Earn Experience</li>
                        <li className="text-primary">Team up with the Best</li>
                    </ul>
                    <Button label="DON'T MISS OUT" className='p-button-outlined' />
                </div>
                <div className="widget-pricing-card shadow-8" data-aos="zoom-in">
                    <h2 className="text-primary">FIND A JOB</h2>
                    <span className="text-primary">GET EMPLOYED WITH US</span>
                    <h3 className="text-primary">Hustle Free</h3>
                    <ul>
                        <li className="text-primary">Don't be Afraid of Your Dreams</li>
                        <li className="text-primary">Get Hired</li>
                        <li className="text-primary">Acces your Career Goals</li>
                        <li className="text-primary">Advertise your Skills</li>
                        <li className="text-primary">Learn from the best</li>
                    </ul>
                    <Button label="EARN" className='p-button-outlined' />
                </div>
            </div>
        </div>
        </div>
      </div>

    </>

  );
}

export  default Home;


export const yearChoices = [
    {key: 2020},
    {key: 2021},
    {key: 2022},
    {key: 2023},
    {key: 2024},
    {key: 2025},
    {key: 2026},
    {key: 2027},
    {key: 2028},
    {key: 2029},
    {key: 2030},
]


export const weekChoices = [
    {key: 1}, 
    {key: 2}, 
    {key: 3}, 
    {key: 4}, 
    {key: 5}, 
    {key: 6}, 
    {key: 7}, 
    {key: 8}, 
    {key: 9}, 
    {key: 10}, 
    {key: 11}, 
    {key: 12}, 
    {key: 13}, 
    {key: 14}, 
    {key: 15}, 
    {key: 16}, 
    {key: 17}, 
    {key: 18}, 
    {key: 19}, 
    {key: 20}, 
    {key: 21}, 
    {key: 22}, 
    {key: 23}, 
    {key: 24}, 
    {key: 25}, 
    {key: 26}, 
    {key: 27}, 
    {key: 28}, 
    {key: 29}, 
    {key: 30}, 
    {key: 31}, 
    {key: 32}, 
    {key: 33}, 
    {key: 34}, 
    {key: 35}, 
    {key: 36}, 
    {key: 37}, 
    {key: 38}, 
    {key: 39}, 
    {key: 40}, 
    {key: 41}, 
    {key: 42}, 
    {key: 43}, 
    {key: 44}, 
    {key: 45}, 
    {key: 46}, 
    {key: 47}, 
    {key: 48}, 
    {key: 49}, 
    {key: 50}, 
    {key: 51}, 
    {key: 52}
]


export const monthChoices = [
    {key: "JANUARY"},
    {key: "FEBRUARY"},
    {key: "MARCH"},
    {key: "APRIL"},
    {key: "MAY"},
    {key: "JUNE"},
    {key: "JULY"},
    {key: "AUGUST"},
    {key: "SEPTEMBER"},
    {key: "OCTOBER"},
    {key: "NOVEMBER"},
    {key: "DECEMBER"},
]
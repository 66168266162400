import axios from 'axios';
import {
    GET_SKILLS,
    ADD_SKILL,
    EDIT_SKILL,
    DELETE_SKILL,
    GET_FACULTYS,
    ADD_FACULTY,
    EDIT_FACULTY,
    DELETE_FACULTY,
    GET_ACADEMIC_QUALIFICATIONS,
    ADD_ACADEMIC_QUALIFICATION,
    EDIT_ACADEMIC_QUALIFICATION,
    DELETE_ACADEMIC_QUALIFICATION,
    GET_NEAREST_NEIGHBOURS,
    ADD_NEAREST_NEIGHBOUR,
    EDIT_NEAREST_NEIGHBOUR,
    DELETE_NEAREST_NEIGHBOUR,
} from '../types/skillTypes';

import {
    facultiesURL,
    skillsURL,
    qualificationsURL,
    nearestneighboursURL,

} from '../constants';
import {  returnErrors } from './messages';


//nearest neighbours 
export const getNearestNeighbours = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(nearestneighboursURL, headers)
        .then(res => {
            dispatch({
                type: GET_NEAREST_NEIGHBOURS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addNearestNeighbour = (nearestNeighbour, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(nearestneighboursURL, nearestNeighbour, headers)
        .then(res => {
            dispatch({
                type: ADD_NEAREST_NEIGHBOUR,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteNearestNeighbour = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${nearestneighboursURL}${slug}/delete/`, slug, headers)
        .then(res => {
            console.log(slug)
            dispatch({
                type: DELETE_NEAREST_NEIGHBOUR,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editNearestNeighbour = (slug, nearestNeighbour, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${nearestneighboursURL}${slug}/`, nearestNeighbour, headers)
        .then(res => {
            dispatch({
                type: EDIT_NEAREST_NEIGHBOUR,
                payload: res.data
            });
        }).catch(err => console.log(err))
}




//skills
export const getSkills = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(skillsURL, headers)
        .then(res => {
            dispatch({
                type: GET_SKILLS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addSkill = (skill, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(skillsURL, skill, headers)
        .then(res => {
            dispatch({
                type: ADD_SKILL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteSkill = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${skillsURL}${slug}/delete/`, slug, headers)
        .then(res => {
            console.log(slug)
            dispatch({
                type: DELETE_SKILL,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editSkill = (slug, skill, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${skillsURL}${slug}/`, skill, headers)
        .then(res => {
            dispatch({
                type: EDIT_SKILL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

//faculties
export const getFaculties = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(facultiesURL, headers)
        .then(res => {
            dispatch({
                type: GET_FACULTYS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addFaculty = (faculty, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(facultiesURL, faculty, headers)
        .then(res => {
            dispatch({
                type: ADD_FACULTY,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteFaculty = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${facultiesURL}${slug}/delete/`, slug, headers)
      .then(res => {
          console.log(slug)
          dispatch({
              type: DELETE_FACULTY,
              payload: slug
          });
      }).catch(err => console.log(err))
}




export const editFaculty = (slug, faculty, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${facultiesURL}${slug}/`, faculty, headers)
        .then(res => {
            dispatch({
                type: EDIT_FACULTY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//qualifications

export const getQualifications = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(qualificationsURL, headers)
        .then(res => {
            dispatch({
                type: GET_ACADEMIC_QUALIFICATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addQualification = (qualification, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(qualificationsURL, qualification, headers)
        .then(res => {
            dispatch({
                type: ADD_ACADEMIC_QUALIFICATION,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteQualification = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

      axios.delete(`${qualificationsURL}${slug}/delete/`, slug, headers)
      .then(res => {
          console.log(slug)
          dispatch({
              type: DELETE_ACADEMIC_QUALIFICATION,
              payload: slug
          });
      }).catch(err => console.log(err))
}




export const editQualification = (slug, qualification, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${qualificationsURL}${slug}/`, qualification, headers)
        .then(res => {
            dispatch({
                type: EDIT_ACADEMIC_QUALIFICATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

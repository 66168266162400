import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { Calendar } from 'primereact/calendar';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { RadioButton } from 'primereact/radiobutton';




const Portifolios = (props) => {
    const {
        token,
        getCandidatePortfolios,
        addCandidatePortfolio,
        editCandidatePortfolio,
        deleteCandidatePortfolio,
        records,
        skills,
        addPortfolioSkill,
        addPortfolioImage,

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');

    const initialValues = {
        title:'',
        image:null,
        role:'',
        challenge:'',
        solution:'',
        start_date:'',
        end_date:'',
        ongoing:false,

    }

    const imageInitialValues = {
        image: null,
    }
    const skillInitialValues = {
        skill: '',
    }
    const [skillRecord, setSkillRecord] = useState(skillInitialValues);
    const [imageRecord, setImageRecord] = useState(imageInitialValues);
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [layout, setLayout] = useState('grid');
    const [imageDialog, setImageDialog] = useState(false);
    const [skillDialog, setSkillDialog] = useState(false);
    const [isImageSubmit, setIsImageSubmit] = useState(false);
    const [isSkillSubmit, setIsSkillSubmit] = useState(false);
    const [portfolio, setPortfolio] = useState('');
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    useEffect(() => {
        getCandidatePortfolios(token);
        setIsSubmit(false);

    }, [isSubmit, isImageSubmit, isSkillSubmit]);





    const deleteQual = (slug, data) =>{
        deleteCandidatePortfolio(slug, data, token)
        setIsSubmit(true);

    }


    const editImage = (record) => {
        setImageRecord({...record});
        setImageDialog(true);
    }

    const hideImageDialog = () => {
        setImageDialog(false);
        setImageRecord(imageInitialValues);
    }

    const openImageDialog = (slug) => {
        setPortfolio(slug)
        setImageDialog(true);
    }


    const editSkill = (record) => {
        setSkillRecord({...record});
        setSkillDialog(true);
    }

    const hideSkillDialog = () => {
        setSkillDialog(false);
        setSkillRecord(skillInitialValues);
    }

    const openSkillDialog = (slug) => {
        setPortfolio(slug)
        setSkillDialog(true);
    }


    const validate = (fieldValues = record) => {

        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title= fieldValues.title? "" : "Title of Education is Required"
        if ('image' in fieldValues)
            temp.image= fieldValues.image? "" : "Image is Required"
        if ('role' in fieldValues)
            temp.role= fieldValues.role? "" : "Role is Required"
        if ('challenge' in fieldValues)
            temp.challenge= fieldValues.challenge? "" : "Challenge is Required"
        if ('solution' in fieldValues)
            temp.solution= fieldValues.solution? "" : "Solution is Required"
        if ('start_date' in fieldValues)
            temp.start_date= fieldValues.start_date? "" : "Start Date is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }


      const validateImage = (fieldValues = imageRecord) => {
        let temp = { ...errors }
        if ('image' in fieldValues)
            temp.image= fieldValues.image? "" : "Image is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === imageRecord)
            return Object.values(temp).every(x => x === "")
      }

      const validateSkill = (fieldValues = skillRecord) => {
        let temp = { ...errors }
        if ('skill' in fieldValues)
            temp.skill= fieldValues.skill? "" : "Skill is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === skillRecord)
            return Object.values(temp).every(x => x === "")
      }


    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
          resetForm();
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                console.log("Wrong")
                setErrors(validate(record));
                setIsSubmit(true);
                editCandidatePortfolio(record.slug, record, token);
                getCandidatePortfolios(token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){

                setIsSubmit(true);
                addCandidatePortfolio(record, token);
                getCandidatePortfolios(token);

                resetForm();
                setDisplayMaximizable(false);
            }

          }
        //
    }

    const handleImageSubmit = (e) => {
        e.preventDefault();
        if (validateImage()){
            let _record = {...imageRecord}
            addPortfolioImage(portfolio, _record, props.token);
            setIsImageSubmit(true);
            getCandidatePortfolios(token);
            hideImageDialog();

        }
    }


    const handleSkillSubmit = (e) => {
        e.preventDefault();
        if (validateSkill()){
            let _record = {...skillRecord}
            addPortfolioSkill(portfolio, _record, props.token);
            setIsSkillSubmit(true);
            getCandidatePortfolios(token);
            hideSkillDialog();

        }
    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }


    const onSkillChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...skillRecord};
        _record[`${name}`] = val;
        setSkillRecord(_record);
  }

    const editAcademicQualification = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }


    const handleImageRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        onImageChange(base64)

    }


    const onImageChange = (data) => {
        let _record = {...record};
        _record[`image`] = data;
        setRecord(_record);
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        onFileChange(base64)

    }


    const onFileChange = (data) => {
        let _record = {...imageRecord};
        _record[`image`] = data;
        setImageRecord(_record);
    }


    const onGoingChange = (e) => {
        let _record = {...record};
        _record['ongoing'] = e.value;
        setRecord(_record);
    }

    const monthNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
          />
        );
    };

    const yearNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
          />
      )
    }

    const dialogPortifolioHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Portifolio Form</span>
              </div>
          )
      }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD NEW'

                    className="p-button-primary p-button-success p-button-outlined"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>



            </React.Fragment>
        )
    }


    const portFolioHeader = (image) => {
        return <img style={{height: '600px', width: '80%'}} alt="Card" src={image} />;
    }


    const imageTemplate = (data) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mbddddddd-3">
                        <img
                            src={data.image}
                            style={{height: '400px'}}
                            alt='NADA'
                            className="product-image"
                        />
                    </div>
                </div>
            </div>
        );
    }



    const renderGridItem = (data) => {
        return (


                <Card
                    data-aos="zoom-in"
                    className="col-12 md:col-12 border-50  mb-3 box shadow-8 flex-grow-1"
                    header={portFolioHeader(data.image)}
                    style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
                >


                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">


                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.title}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        ROLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.role}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CHALLENGE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.challenge}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        SOLUTION
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.solution}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        START DATE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.start_date}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                {data.end_end
                                    ?<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            END DATE
                                        </div>
                                        <div className=" col-12 md:w-12 font-medium">
                                            <Chip label={data.end_date}  className="mr-2 mb-2 text-primary" />

                                        </div>
                                    </li>
                                    :<></>
                                }

                                {data.skills && data.skills.length > 0
                                    ?<li style={{textAlign:'center'}} className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            SKILLS USED
                                        </div>
                                        {data.skills.map((skill) => {
                                                return(

                                                    <div key={skill.id} className=" w-full col-12 md:w-3 font-medium">
                                                        <Chip label={skill.skill}  className="mr-2 mb-2  " />
                                                    </div>

                                                );

                                            })

                                        }

                                    </li>
                                    :<></>
                                }
                                {data.images && data.images.length > 0
                                    ?<li style={{textAlign:'center'}} className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className="carousel-demo">
                                            <div className="card" >
                                                <Carousel
                                                    value={data.images}
                                                    numVisible={3}
                                                    numScroll={1}
                                                    responsiveOptions={responsiveOptions}
                                                    className="custom-carousel"
                                                    circular
                                                    autoplayInterval={3000}
                                                    itemTemplate={imageTemplate}

                                                />
                                            </div>

                                        </div>

                                    </li>
                                    :<></>
                                }
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-pencil"
                                            label='UPDATE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => editAcademicQualification(data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-trash"
                                            label='DELETE'
                                            className="p-button-rounded p-button-outlined p-button-danger"
                                            onClick={() => deleteQual(data.slug, data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-plus"
                                            label='ADD IMAGE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => openImageDialog(data.slug)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-plus"
                                            label='ADD SKILL'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => openSkillDialog(data.slug)}
                                        />
                                    </div>
                                </li>

                        </ul>

                    </div>
            </Card>
        );
    }


    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();






    return (
        <>

            <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}

            />
            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY PORTFOLIO</h5>
                                            <p className='text-primary'>CURRENTLY THERE ARE NO PORTFOLIOS ATTACHED TO YOUR PROFILE</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO PORTFOLIOS ATTACHED TO YOUR PROFILE"
                                />
                            </div>
                        }
                    </div>
                </div>


            </div>

                <Dialog header={dialogPortifolioHeader()}
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '1000px' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="card">
                        <form onSubmit={onSubmit}>

                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                          {record.slug !=undefined
                                            ?<></>

                                            : <InputText
                                                accept="image/*"
                                                type="file"
                                                name='image'

                                                onChange={(evt) => handleImageRead(evt)}
                                            />
                                          }
                                            <small id="username2-help"  className="p-error block">{errors.image}</small>
                                        </div>
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            {record.image !=null
                                                ?<Avatar
                                                    image={record.image}
                                                    className="mr-2"
                                                    size="xlarge"
                                                    shape="circle"
                                                />
                                                :<div
                                                        className="w-full text-primary md:w-6 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        ADD IMAGE
                                                </div>

                                            }

                                        </div>

                                    </li>

                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            TITLE
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">

                                            <InputText
                                                id="title"
                                                value={record.title}
                                                onChange={(e) => onInputChange(e, 'title')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.title}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            ROLE
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="role"
                                                value={record.role}
                                                onChange={(e) => onInputChange(e, 'role')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.role}</small>


                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            CHALLENGE
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="challenge"
                                                value={record.challenge}

                                                onChange={(e) => onInputChange(e, 'challenge')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.challenge}</small>


                                        </div>
                                    </li>

                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SOLUTION
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="solution"
                                                value={record.solution}

                                                onChange={(e) => onInputChange(e, 'solution')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.solution}</small>


                                        </div>
                                    </li>
                                     <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            START DATE
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                          {record.slug !=undefined
                                            ?<InputText
                                                id="start_date"
                                                type="date"
                                                name="start_date"
                                                className="w-full mb-3"
                                                value={record.start_date}
                                                onChange={(e) => onInputChange(e,"start_date")}

                                            />
                                            :<Calendar
                                                id="navigatorstemplate"
                                                showIcon
                                                value={record.start_date}
                                                onChange={(e) => onInputChange(e,"start_date")}
                                                monthNavigator
                                                yearNavigator
                                                dateFormat="yy-mm-dd"
                                                yearRange="1968:2030"
                                                monthNavigatorTemplate={monthNavigatorTemplate}
                                                yearNavigatorTemplate={yearNavigatorTemplate}
                                            />
                                          }
                                            <small id="username2-help"  className="p-error block">{errors.start_date}</small>

                                        </div>
                                    </li>
                                    <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                                      <li className="flex card flex card align-items-center py-3 px-2 border-300 flex-wrap grid fluid">

                                          <div className="text-primary col-12 md:w-4 font-medium">
                                            ONGOING
                                        </div>
                                        <div className="formgrid grid">

                                            <div className=" col-12 md:w-2 font-medium">
                                                <RadioButton
                                                    inputId="category2"
                                                    name="category"
                                                    value={true}
                                                    onChange={onGoingChange}
                                                    checked={record.ongoing === true}
                                                  />
                                                <label className="" htmlFor="category1">YES</label>
                                            </div>
                                            <div className="col-12 md:w-2 font-medium">
                                            </div>
                                            <div className="col-12 md:w-2 font-medium">
                                            </div>
                                            <div className="col-12 md:w-2 font-medium">
                                                <RadioButton
                                                    inputId="category2"
                                                    name="category"
                                                    value={false}
                                                    onChange={onGoingChange}
                                                    checked={record.ongoing === false}

                                                  />
                                                <label className="" htmlFor="category2">NO</label>
                                            </div>

                                        </div>
                                    </li>
                                  </ul>





                                     {record.ongoing === false


                                          ?<li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                <div
                                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                >
                                                    END DATE
                                                </div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                                  {record.slug !=undefined
                                                    ?<InputText
                                                        id="end_date"
                                                        type="date"
                                                        name="end_date"
                                                        className="w-full mb-3"
                                                        value={record.end_date}
                                                        onChange={(e) => onInputChange(e,"end_date")}

                                                    />
                                                    :<Calendar
                                                        id="navigatorstemplate"
                                                        showIcon
                                                        value={record.end_date}
                                                        onChange={(e) => onInputChange(e,"end_date")}
                                                        monthNavigator
                                                        yearNavigator
                                                        dateFormat="yy-mm-dd"
                                                        yearRange="1968:2030"
                                                        monthNavigatorTemplate={monthNavigatorTemplate}
                                                        yearNavigatorTemplate={yearNavigatorTemplate}
                                                    />
                                                  }
                                                </div>
                                            </li>

                                          :<></>
                                      }




                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <Button
                                            type="submit"
                                            label="SUBMIT"
                                            className="p-button-outlined p-button-warning"
                                        />
                                    </li>
                                </ul>


                            </div>

                        </form>
                    </div>

                </Dialog>
                <Dialog
                    header="IMAGE FORM"
                    maximizable
                    modal
                    style={{ width: '700px' }}
                    visible={imageDialog}
                    className="p-fluid"
                    onHide={hideImageDialog}
                >
                    <div className="card">
                        <form onSubmit={handleImageSubmit}>

                            <div className="l">
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <InputText
                                                accept="image/*"
                                                type="file"
                                                name='image'

                                                onChange={(evt) => handleFileRead(evt)}
                                            />
                                            <small id="username2-help"  className="p-error block">{errors.image}</small>
                                        </div>
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            {imageRecord.image !=null
                                                ?<Avatar
                                                    image={imageRecord.image}
                                                    className="mr-2"
                                                    size="xlarge"
                                                    shape="circle"
                                                />
                                                :<div
                                                        className="w-full text-primary md:w-6 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        ADD IMAGE
                                                </div>

                                            }

                                        </div>

                                    </li>
                                </ul>



                                <div className="col-12 md:col-4">
                                    <Button
                                        type="submit"
                                        label="Submit"
                                        className="mt-2"

                                    />
                                    <label htmlFor="horizontal"></label>
                                </div>

                            </div>
                        </form>
                    </div>
                </Dialog>
                <Dialog
                    header="SKILL FORM"
                    maximizable
                    modal
                    style={{ width: '700px' }}
                    visible={skillDialog}
                    className="p-fluid"
                    onHide={hideSkillDialog}
                >
                    <div className="card">
                        <form onSubmit={handleSkillSubmit}>
                            <div className="l">
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT SKILL
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={skillRecord.skill}
                                                optionLabel="title"
                                                optionValue="slug"
                                                options={skills}
                                                onChange={(e) => onSkillChange(e, 'skill')}
                                                filter
                                                filterBy="title"
                                            />
                                            <small id="username2-help"  className="p-error block">{errors.skill}</small>
                                        </div>
                                    </li>
                                </ul>

                                {JSON.stringify(record)}



                                <div className="col-12 md:col-4">
                                    <Button
                                        type="submit"
                                        label="Submit"
                                        className="mt-2"

                                    />
                                    <label htmlFor="horizontal"></label>
                                </div>

                            </div>
                        </form>
                    </div>
                </Dialog>


        </>
    );
}


export default Portifolios;

import React, {useEffect,useState} from 'react';
import { Chip } from 'primereact/chip';
import 'aos/dist/aos.css';
import AOS from "aos";
import { Button } from 'primereact/button';

const UserProfile = (props) => {
	const {
        profile,
        } = props;

	const {
  		email,
  		slug,
  		username,
  		title,
  		gender,
  		first_name,
  		middle_name,
  		last_name,
  		phone_number,
  		whatsapp_number,
  		start_date,
  		dob,
  		country_of_citizenship,
  		country_of_residence,
  		province,
  		district,
  		description,
  		availability,
  		availability_category,

	}= profile;

    useEffect(() =>{
        AOS.init({duration : 3000})

    }, []);// eslint-disable-line react-hooks/exhaustive-deps




	const renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
          (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
          // less than one minute ago
          prefix = "just now...";
        } else if (timeDiff < 60 && timeDiff > 1) {
          // less than sixty minutes ago
          prefix = `${timeDiff} minutes ago`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
          // less than 24 hours ago
          prefix = `${Math.round(timeDiff / 60)} hours ago`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
          // less than 7 days ago
          prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
        } else {
          prefix = `${new Date(timestamp)}`;
        }
        return prefix;
      };





	return (
        <>
            <div data-aos="fade-up-right" div className='card'>

                <div style={{textAlign: 'center'}} className="">
                    <ul className="list-none p-0 m-0">
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">REGISTERED AT HLETIPANG </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={renderTimestamp(start_date)}  className="mr-2 mb-2 " />
                            </div>

                        </li>


                        {title

                            ?
                                <>
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                                    <div className=" col-12 md:w-12 font-medium">TITLE</div>
                                    <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                        <Chip label={title}  className="mr-2 mb-2 " />
                                    </div>

                                </li>
                                </>
                            :<></>
                        }


                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">FIRST NAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={first_name}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">FIRST NAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={first_name}  className="mr-2 mb-2 " />
                            </div>
                        </li>

                        {middle_name

                            ?
                                <>
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                                    <div className=" col-12 md:w-12 font-medium">MIDDLE NAME</div>
                                    <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                        <Chip label={middle_name}  className="mr-2 mb-2 " />
                                    </div>
                                </li>

                                </>
                            :<></>
                        }

                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">LAST NAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={last_name}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">GENDER</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={gender}  className="mr-2 mb-2 " />
                            </div>
                        </li>

                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">PHONE NUMBER</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={phone_number}  className="mr-2 mb-2 " />
                            </div>
                        </li>

                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">COUNTRY OF CITIZENSHIP</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={country_of_citizenship}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">COUNTRY OF RESIDENCE</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={country_of_residence}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">CITY</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={province}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">TOWNSHIP</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={district}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">DESCRIPTION</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                {description}
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">AVAILABILITY</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={availability}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">AVAILABILITY CATEGORY</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={availability_category}  className="mr-2 mb-2 " />
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

        </>

	);
}


export default UserProfile;

import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProductService } from '../../service/ProductService';
import EmployerLayout from '../layout/EmployerLayout';
import AOS from "aos";
import 'aos/dist/aos.css';
import { getRecruiterStats, getRecruiterMonthlyJobData } from '../../actions/reports';
import { connect } from 'react-redux';
import graph from '../../public/home/images/graph-4.svg';



const lineData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
        {
            label: 'First Dataset',
            data: [65, 59, 80, 81, 56, 55, 40],
            fill: false,
            backgroundColor: '#2f4860',
            borderColor: '#2f4860',
            tension: .4
        },
        {
            label: 'Second Dataset',
            data: [28, 48, 40, 19, 86, 27, 90],
            fill: false,
            backgroundColor: '#00bb7e',
            borderColor: '#00bb7e',
            tension: .4
        }
    ]
};

const Dashboard = (props) => {
    const [products, setProducts] = useState(null);
    const menu1 = useRef(null);
    const menu2 = useRef(null);
    const [lineOptions, setLineOptions] = useState(null)
    const {stats, recruiterMonthlyJobData} =props;





    useEffect(() =>{
        AOS.init({duration : 3000})

      }, []);


    useEffect(() =>{
        props.getRecruiterStats(props.token);
        props.getRecruiterMonthlyJobData(props.token);
    }, [])


    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    useEffect(() => {
        const productService = new ProductService();
        productService.getProductsSmall().then(data => setProducts(data));
    }, []);

    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);


    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    return (
        <EmployerLayout>
            <div className="grid" data-aos="zoom-in-up" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.open_onetime_jobs}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-id-card text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>OPEN TEMPORARY JOBS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.open_permanent_jobs}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-id-card text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>OPEN PERMANENT JOBS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.open_contractual_jobs}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-id-card text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>OPEN CONTRACTUAL JOBS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.open_internship_jobs}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-id-card text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>OPEN INTERNSHIP JOBS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.declined_applications}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-users text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>DECLINED APPLICATIONS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.accepted_applications}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-users text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>ACCEPTED APPLICATIONS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.pending_decision_applications}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-spinner text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>PENDING APPLICATIONS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="surface-card shadow-2 border-round">
                        <div className="flex justify-content-between mb-3">
                            <div>
                                <div className="text-900 font-medium text-green-500 text-xl ml-3 mt-3">{stats.on_interview_applications}</div>
                            </div>
                            <div className="flex align-items-center justify-content-center  mt-3">
                                <i className="pi pi-id-card text-blue-500 text-xl mr-3"/>
                            </div>
                        </div>
                        <span className="" style={{fontSize: '14px'}}>ON INTERVIEW APPLICATIONS</span>
                        <img src={graph} alt="graph-1" className="w-full"/>
                    </div>
                </div>
                <div className="col-12 xl:col-12">
                    <div className="card" style={{textAlign: 'center'}}>
                        <h5>JOBS VALUE</h5>
                        <Chart type="line" data={recruiterMonthlyJobData} options={lineOptions} />
                    </div>
                </div>
            </div>
        </EmployerLayout>
    );
}

const mapStateToProps = state =>({
    token: state.auth.token,
    stats: state.reports.recruiterStats,
    loading: state.reports.loading,
    recruiterMonthlyJobData: state.reports.recruiterMonthlyJobData,
})

export default connect( mapStateToProps, {getRecruiterStats, getRecruiterMonthlyJobData}) (Dashboard);

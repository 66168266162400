import {
	GET_USER_PRIVACY_AGREEMENTS_START,
	GET_USER_PRIVACY_AGREEMENTS_SUCCESS,
	GET_USER_PRIVACY_AGREEMENTS_FAIL,
	GET_USER_PRIVACY_AGREEMENT_START,
	GET_USER_PRIVACY_AGREEMENT_SUCCESS,
	GET_USER_PRIVACY_AGREEMENT_FAIL,
	ADD_USER_PRIVACY_AGREEMENT_START,
	ADD_USER_PRIVACY_AGREEMENT_SUCCESS,
	ADD_USER_PRIVACY_AGREEMENT_FAIL,
	EDIT_USER_PRIVACY_AGREEMENT_START,
	EDIT_USER_PRIVACY_AGREEMENT_SUCCESS,
	EDIT_USER_PRIVACY_AGREEMENT_FAIL,
	DELETE_USER_PRIVACY_AGREEMENT_START,
	DELETE_USER_PRIVACY_AGREEMENT_SUCCESS,
	DELETE_USER_PRIVACY_AGREEMENT_FAIL,
  GET_CANDIDATE_PORTFOLIOS_START,
  GET_CANDIDATE_PORTFOLIOS_SUCCESS,
  GET_CANDIDATE_PORTFOLIOS_FAIL,
  GET_CANDIDATE_PORTFOLIO_START,
  GET_CANDIDATE_PORTFOLIO_SUCCESS,
  GET_CANDIDATE_PORTFOLIO_FAIL,
  ADD_CANDIDATE_PORTFOLIO_START,
  ADD_CANDIDATE_PORTFOLIO_SUCCESS,
  ADD_CANDIDATE_PORTFOLIO_FAIL,
  EDIT_CANDIDATE_PORTFOLIO_START,
  EDIT_CANDIDATE_PORTFOLIO_SUCCESS,
  EDIT_CANDIDATE_PORTFOLIO_FAIL,
  DELETE_CANDIDATE_PORTFOLIO_START,
  DELETE_CANDIDATE_PORTFOLIO_SUCCESS,
  DELETE_CANDIDATE_PORTFOLIO_FAIL,
  GET_CANDIDATE_SERVICES_START,
  GET_CANDIDATE_SERVICES_SUCCESS,
  GET_CANDIDATE_SERVICES_FAIL,
  GET_CANDIDATE_SERVICE_START,
  GET_CANDIDATE_SERVICE_SUCCESS,
  GET_CANDIDATE_SERVICE_FAIL,
  ADD_CANDIDATE_SERVICE_START,
  ADD_CANDIDATE_SERVICE_SUCCESS,
  ADD_CANDIDATE_SERVICE_FAIL,
  EDIT_CANDIDATE_SERVICE_START,
  EDIT_CANDIDATE_SERVICE_SUCCESS,
  EDIT_CANDIDATE_SERVICE_FAIL,
  DELETE_CANDIDATE_SERVICE_START,
  DELETE_CANDIDATE_SERVICE_SUCCESS,
  DELETE_CANDIDATE_SERVICE_FAIL,
  GET_CANDIDATE_OBJECTIVES_START,
  GET_CANDIDATE_OBJECTIVES_SUCCESS,
  GET_CANDIDATE_OBJECTIVES_FAIL,
  GET_CANDIDATE_OBJECTIVE_START,
  GET_CANDIDATE_OBJECTIVE_SUCCESS,
  GET_CANDIDATE_OBJECTIVE_FAIL,
  ADD_CANDIDATE_OBJECTIVE_START,
  ADD_CANDIDATE_OBJECTIVE_SUCCESS,
  ADD_CANDIDATE_OBJECTIVE_FAIL,
  EDIT_CANDIDATE_OBJECTIVE_START,
  EDIT_CANDIDATE_OBJECTIVE_SUCCESS,
  EDIT_CANDIDATE_OBJECTIVE_FAIL,
  DELETE_CANDIDATE_OBJECTIVE_START,
  DELETE_CANDIDATE_OBJECTIVE_SUCCESS,
  DELETE_CANDIDATE_OBJECTIVE_FAIL,
  GET_CANDIDATE_MISSIONS_START,
  GET_CANDIDATE_MISSIONS_SUCCESS,
  GET_CANDIDATE_MISSIONS_FAIL,
  GET_CANDIDATE_MISSION_START,
  GET_CANDIDATE_MISSION_SUCCESS,
  GET_CANDIDATE_MISSION_FAIL,
  ADD_CANDIDATE_MISSION_START,
  ADD_CANDIDATE_MISSION_SUCCESS,
  ADD_CANDIDATE_MISSION_FAIL,
  EDIT_CANDIDATE_MISSION_START,
  EDIT_CANDIDATE_MISSION_SUCCESS,
  EDIT_CANDIDATE_MISSION_FAIL,
  DELETE_CANDIDATE_MISSION_START,
  DELETE_CANDIDATE_MISSION_SUCCESS,
  DELETE_CANDIDATE_MISSION_FAIL,
  ADD_PORTFOLIO_SKILL_START,
  ADD_PORTFOLIO_SKILL_SUCCESS,
  ADD_PORTFOLIO_SKILL_FAIL,
  ADD_PORTFOLIO_IMAGE_START,
  ADD_PORTFOLIO_IMAGE_SUCCESS,
  ADD_PORTFOLIO_IMAGE_FAIL,
  ADD_SERVICE_IMAGE_START,
  ADD_SERVICE_IMAGE_SUCCESS,
  ADD_SERVICE_IMAGE_FAIL,

} from '../types/portfolioTypes';
import {
	updateObject
} from './utility';


const initialState = {
	error: null,
    loading: false,
    userPrivacyAgreements:[],
    userPrivacyAgreement:{},
    candidatePortfolios:[],
    candidatePortfolio:{},
    candidateServices:[],
    candidateService:{},
    candidateObjectives:[],
    candidateObjective:{},
    candidateMissions:[],
    candidateMission:{},

}




//PRIVACY
const getUserPrivacyAgreementsStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getUserPrivacyAgreementsSuccess = (state, action) => {
    return updateObject(state, {
      userPrivacyAgreements: action.userPrivacyAgreements,
      error: null,
      loading: false
    });
  };


const getUserPrivacyAgreementsFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};

//////////////////////////
const getUserPrivacyAgreementStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const getUserPrivacyAgreementSuccess = (state, action) => {
    return updateObject(state, {
      userPrivacyAgreement: action.userPrivacyAgreement,
      error: null,
      loading: false
    });
  };


const getUserPrivacyAgreementFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};

//////////////////////////
const addUserPrivacyAgreementStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const addUserPrivacyAgreementSuccess = (state, action) => {
    return updateObject(state, {
      userPrivacyAgreement: action.userPrivacyAgreement,
      error: null,
      loading: false
    });
  };


const addUserPrivacyAgreementFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};

//////////////////////////
const editUserPrivacyAgreementStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const editUserPrivacyAgreementSuccess = (state, action) => {
    return updateObject(state, {
      userPrivacyAgreement: action.userPrivacyAgreement,
      error: null,
      loading: false
    });
  };


const editUserPrivacyAgreementFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};

//////////////////////////
const deleteUserPrivacyAgreementStart = (state, action) => {
    return updateObject(state, {
      error: null,
      loading: true
    });
  };


const deleteUserPrivacyAgreementSuccess = (state, action) => {
    return updateObject(state, {
      userPrivacyAgreement: action.userPrivacyAgreement,
      error: null,
      loading: false
    });
  };


const deleteUserPrivacyAgreementFail = (state, action) => {
    return updateObject(state, {
      error: action.error,
      loading: false
    });
};




//PRIVACY
const getCandidatePortfoliosStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidatePortfoliosSuccess = (state, action) => {
  return updateObject(state, {
    candidatePortfolios: action.candidatePortfolios,
    error: null,
    loading: false
  });
};


const getCandidatePortfoliosFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const getCandidatePortfolioStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidatePortfolioSuccess = (state, action) => {
  return updateObject(state, {
    candidatePortfolio: action.candidatePortfolio,
    error: null,
    loading: false
  });
};


const getCandidatePortfolioFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const addCandidatePortfolioStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addCandidatePortfolioSuccess = (state, action) => {
  return updateObject(state, {
    candidatePortfolio: action.candidatePortfolio,
    error: null,
    loading: false
  });
};


const addCandidatePortfolioFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const editCandidatePortfolioStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const editCandidatePortfolioSuccess = (state, action) => {
  return updateObject(state, {
    candidatePortfolio: action.candidatePortfolio,
    error: null,
    loading: false
  });
};


const editCandidatePortfolioFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const deleteCandidatePortfolioStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const deleteCandidatePortfolioSuccess = (state, action) => {
  return updateObject(state, {
    candidatePortfolio: action.candidatePortfolio,
    error: null,
    loading: false
  });
};


const deleteCandidatePortfolioFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



//SERVICES
const getCandidateServicesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateServicesSuccess = (state, action) => {
  return updateObject(state, {
    candidateServices: action.candidateServices,
    error: null,
    loading: false
  });
};


const getCandidateServicesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const getCandidateServiceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateServiceSuccess = (state, action) => {
  return updateObject(state, {
    candidateService: action.candidateService,
    error: null,
    loading: false
  });
};


const getCandidateServiceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const addCandidateServiceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addCandidateServiceSuccess = (state, action) => {
  return updateObject(state, {
    candidateService: action.candidateService,
    error: null,
    loading: false
  });
};


const addCandidateServiceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const editCandidateServiceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const editCandidateServiceSuccess = (state, action) => {
  return updateObject(state, {
    candidateService: action.candidateService,
    error: null,
    loading: false
  });
};


const editCandidateServiceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const deleteCandidateServiceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const deleteCandidateServiceSuccess = (state, action) => {
  return updateObject(state, {
    candidateService: action.candidateService,
    error: null,
    loading: false
  });
};


const deleteCandidateServiceFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



//OBJECTIVES
const getCandidateObjectivesStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateObjectivesSuccess = (state, action) => {
  return updateObject(state, {
    candidateObjectives: action.candidateObjectives,
    error: null,
    loading: false
  });
};


const getCandidateObjectivesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const getCandidateObjectiveStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateObjectiveSuccess = (state, action) => {
  return updateObject(state, {
    candidateObjective: action.candidateObjective,
    error: null,
    loading: false
  });
};


const getCandidateObjectiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const addCandidateObjectiveStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addCandidateObjectiveSuccess = (state, action) => {
  return updateObject(state, {
    candidateObjective: action.candidateObjective,
    error: null,
    loading: false
  });
};


const addCandidateObjectiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const editCandidateObjectiveStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const editCandidateObjectiveSuccess = (state, action) => {
  return updateObject(state, {
    candidateObjective: action.candidateObjective,
    error: null,
    loading: false
  });
};


const editCandidateObjectiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const deleteCandidateObjectiveStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const deleteCandidateObjectiveSuccess = (state, action) => {
  return updateObject(state, {
    candidateObjective: action.candidateObjective,
    error: null,
    loading: false
  });
};


const deleteCandidateObjectiveFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



//OBJECTIVES
const getCandidateMissionsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateMissionsSuccess = (state, action) => {
  return updateObject(state, {
    candidateMissions: action.candidateMissions,
    error: null,
    loading: false
  });
};


const getCandidateMissionsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const getCandidateMissionStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const getCandidateMissionSuccess = (state, action) => {
  return updateObject(state, {
    candidateMission: action.candidateMission,
    error: null,
    loading: false
  });
};


const getCandidateMissionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const addCandidateMissionStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addCandidateMissionSuccess = (state, action) => {
  return updateObject(state, {
    candidateMission: action.candidateMission,
    error: null,
    loading: false
  });
};


const addCandidateMissionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const editCandidateMissionStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const editCandidateMissionSuccess = (state, action) => {
  return updateObject(state, {
    candidateMission: action.candidateMission,
    error: null,
    loading: false
  });
};


const editCandidateMissionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

//////////////////////////
const deleteCandidateMissionStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const deleteCandidateMissionSuccess = (state, action) => {
  return updateObject(state, {
    candidateMission: action.candidateMission,
    error: null,
    loading: false
  });
};


const deleteCandidateMissionFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};




const addPortfolioImageStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addPortfolioImageSuccess = (state, action) => {
  return updateObject(state, {
    portfolioImage: action.portfolioImage,
    error: null,
    loading: false
  });
};


const addPortfolioImageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const addPortfolioSkillStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addPortfolioSkillSuccess = (state, action) => {
  return updateObject(state, {
    portfolioSkill: action.portfolioSkill,
    error: null,
    loading: false
  });
};


const addPortfolioSkillFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const addServiceImageStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const addServiceImageSuccess = (state, action) => {
  return updateObject(state, {
    serviceImage: action.serviceImage,
    error: null,
    loading: false
  });
};


const addServiceImageFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



export default function portfolio(state = initialState, action){

    switch(action.type){

    	case GET_USER_PRIVACY_AGREEMENTS_START:
    		return getUserPrivacyAgreementsStart(state, action);
    	case GET_USER_PRIVACY_AGREEMENTS_SUCCESS:
    		return getUserPrivacyAgreementsSuccess(state, action);
    	case GET_USER_PRIVACY_AGREEMENTS_FAIL:
    		return getUserPrivacyAgreementsFail(state, action);
    	case GET_USER_PRIVACY_AGREEMENT_START:
    		return getUserPrivacyAgreementStart(state, action);
    	case GET_USER_PRIVACY_AGREEMENT_SUCCESS:
    		return getUserPrivacyAgreementSuccess(state, action);
    	case GET_USER_PRIVACY_AGREEMENT_FAIL:
    		return getUserPrivacyAgreementFail(state, action);
    	case ADD_USER_PRIVACY_AGREEMENT_START:
    		return addUserPrivacyAgreementStart(state, action);
    	case ADD_USER_PRIVACY_AGREEMENT_SUCCESS:
    		return addUserPrivacyAgreementSuccess(state, action);
    	case ADD_USER_PRIVACY_AGREEMENT_FAIL:
    		return addUserPrivacyAgreementFail(state, action);
    	case EDIT_USER_PRIVACY_AGREEMENT_START:
    		return editUserPrivacyAgreementStart(state, action);
    	case EDIT_USER_PRIVACY_AGREEMENT_SUCCESS:
    		return editUserPrivacyAgreementSuccess(state, action);
    	case EDIT_USER_PRIVACY_AGREEMENT_FAIL:
    		return editUserPrivacyAgreementFail(state, action);
    	case DELETE_USER_PRIVACY_AGREEMENT_START:
    		return deleteUserPrivacyAgreementStart(state, action);
    	case DELETE_USER_PRIVACY_AGREEMENT_SUCCESS:
    		return deleteUserPrivacyAgreementSuccess(state, action);
    	case DELETE_USER_PRIVACY_AGREEMENT_FAIL:
    		return deleteUserPrivacyAgreementFail(state, action);

      //
      case GET_CANDIDATE_PORTFOLIOS_START:
    		return getCandidatePortfoliosStart(state, action);
    	case GET_CANDIDATE_PORTFOLIOS_SUCCESS:
    		return getCandidatePortfoliosSuccess(state, action);
    	case GET_CANDIDATE_PORTFOLIOS_FAIL:
    		return getCandidatePortfoliosFail(state, action);
    	case GET_CANDIDATE_PORTFOLIO_START:
    		return getCandidatePortfolioStart(state, action);
    	case GET_CANDIDATE_PORTFOLIO_SUCCESS:
    		return getCandidatePortfolioSuccess(state, action);
    	case GET_CANDIDATE_PORTFOLIO_FAIL:
    		return getCandidatePortfolioFail(state, action);
    	case ADD_CANDIDATE_PORTFOLIO_START:
    		return addCandidatePortfolioStart(state, action);
    	case ADD_CANDIDATE_PORTFOLIO_SUCCESS:
    		return addCandidatePortfolioSuccess(state, action);
    	case ADD_CANDIDATE_PORTFOLIO_FAIL:
    		return addCandidatePortfolioFail(state, action);
    	case EDIT_CANDIDATE_PORTFOLIO_START:
    		return editCandidatePortfolioStart(state, action);
    	case EDIT_CANDIDATE_PORTFOLIO_SUCCESS:
    		return editCandidatePortfolioSuccess(state, action);
    	case EDIT_CANDIDATE_PORTFOLIO_FAIL:
    		return editCandidatePortfolioFail(state, action);
    	case DELETE_CANDIDATE_PORTFOLIO_START:
    		return deleteCandidatePortfolioStart(state, action);
    	case DELETE_CANDIDATE_PORTFOLIO_SUCCESS:
    		return deleteCandidatePortfolioSuccess(state, action);
    	case DELETE_CANDIDATE_PORTFOLIO_FAIL:
    		return deleteCandidatePortfolioFail(state, action);
      //
      case GET_CANDIDATE_SERVICES_START:
    		return getCandidateServicesStart(state, action);
    	case GET_CANDIDATE_SERVICES_SUCCESS:
    		return getCandidateServicesSuccess(state, action);
    	case GET_CANDIDATE_SERVICES_FAIL:
    		return getCandidateServicesFail(state, action);
    	case GET_CANDIDATE_SERVICE_START:
    		return getCandidateServiceStart(state, action);
    	case GET_CANDIDATE_SERVICE_SUCCESS:
    		return getCandidateServiceSuccess(state, action);
    	case GET_CANDIDATE_SERVICE_FAIL:
    		return getCandidateServiceFail(state, action);
    	case ADD_CANDIDATE_SERVICE_START:
    		return addCandidateServiceStart(state, action);
    	case ADD_CANDIDATE_SERVICE_SUCCESS:
    		return addCandidateServiceSuccess(state, action);
    	case ADD_CANDIDATE_SERVICE_FAIL:
    		return addCandidateServiceFail(state, action);
    	case EDIT_CANDIDATE_SERVICE_START:
    		return editCandidateServiceStart(state, action);
    	case EDIT_CANDIDATE_SERVICE_SUCCESS:
    		return editCandidateServiceSuccess(state, action);
    	case EDIT_CANDIDATE_SERVICE_FAIL:
    		return editCandidateServiceFail(state, action);
    	case DELETE_CANDIDATE_SERVICE_START:
    		return deleteCandidateServiceStart(state, action);
    	case DELETE_CANDIDATE_SERVICE_SUCCESS:
    		return deleteCandidateServiceSuccess(state, action);
    	case DELETE_CANDIDATE_SERVICE_FAIL:
    		return deleteCandidateServiceFail(state, action);

      //
      case GET_CANDIDATE_OBJECTIVES_START:
    		return getCandidateObjectivesStart(state, action);
    	case GET_CANDIDATE_OBJECTIVES_SUCCESS:
    		return getCandidateObjectivesSuccess(state, action);
    	case GET_CANDIDATE_OBJECTIVES_FAIL:
    		return getCandidateObjectivesFail(state, action);
    	case GET_CANDIDATE_OBJECTIVE_START:
    		return getCandidateObjectiveStart(state, action);
    	case GET_CANDIDATE_OBJECTIVE_SUCCESS:
    		return getCandidateObjectiveSuccess(state, action);
    	case GET_CANDIDATE_OBJECTIVE_FAIL:
    		return getCandidateObjectiveFail(state, action);
    	case ADD_CANDIDATE_OBJECTIVE_START:
    		return addCandidateObjectiveStart(state, action);
    	case ADD_CANDIDATE_OBJECTIVE_SUCCESS:
    		return addCandidateObjectiveSuccess(state, action);
    	case ADD_CANDIDATE_OBJECTIVE_FAIL:
    		return addCandidateObjectiveFail(state, action);
    	case EDIT_CANDIDATE_OBJECTIVE_START:
    		return editCandidateObjectiveStart(state, action);
    	case EDIT_CANDIDATE_OBJECTIVE_SUCCESS:
    		return editCandidateObjectiveSuccess(state, action);
    	case EDIT_CANDIDATE_OBJECTIVE_FAIL:
    		return editCandidateObjectiveFail(state, action);
    	case DELETE_CANDIDATE_OBJECTIVE_START:
    		return deleteCandidateObjectiveStart(state, action);
    	case DELETE_CANDIDATE_OBJECTIVE_SUCCESS:
    		return deleteCandidateObjectiveSuccess(state, action);
    	case DELETE_CANDIDATE_OBJECTIVE_FAIL:
    		return deleteCandidateObjectiveFail(state, action);

      //
      case GET_CANDIDATE_MISSIONS_START:
    		return getCandidateMissionsStart(state, action);
    	case GET_CANDIDATE_MISSIONS_SUCCESS:
    		return getCandidateMissionsSuccess(state, action);
    	case GET_CANDIDATE_MISSIONS_FAIL:
    		return getCandidateMissionsFail(state, action);
    	case GET_CANDIDATE_MISSION_START:
    		return getCandidateMissionStart(state, action);
    	case GET_CANDIDATE_MISSION_SUCCESS:
    		return getCandidateMissionSuccess(state, action);
    	case GET_CANDIDATE_MISSION_FAIL:
    		return getCandidateMissionFail(state, action);
    	case ADD_CANDIDATE_MISSION_START:
    		return addCandidateMissionStart(state, action);
    	case ADD_CANDIDATE_MISSION_SUCCESS:
    		return addCandidateMissionSuccess(state, action);
    	case ADD_CANDIDATE_MISSION_FAIL:
    		return addCandidateMissionFail(state, action);
    	case EDIT_CANDIDATE_MISSION_START:
    		return editCandidateMissionStart(state, action);
    	case EDIT_CANDIDATE_MISSION_SUCCESS:
    		return editCandidateMissionSuccess(state, action);
    	case EDIT_CANDIDATE_MISSION_FAIL:
    		return editCandidateMissionFail(state, action);
    	case DELETE_CANDIDATE_MISSION_START:
    		return deleteCandidateMissionStart(state, action);
    	case DELETE_CANDIDATE_MISSION_SUCCESS:
    		return deleteCandidateMissionSuccess(state, action);
    	case DELETE_CANDIDATE_MISSION_FAIL:
    		return deleteCandidateMissionFail(state, action);


      //
      case ADD_PORTFOLIO_IMAGE_START:
    		return addPortfolioImageStart(state, action);
    	case ADD_PORTFOLIO_IMAGE_SUCCESS:
    		return addPortfolioImageSuccess(state, action);
    	case ADD_PORTFOLIO_IMAGE_FAIL:
    		return addPortfolioImageFail(state, action);
      case ADD_PORTFOLIO_SKILL_START:
        return addPortfolioSkillStart(state, action);
      case ADD_PORTFOLIO_SKILL_SUCCESS:
        return addPortfolioSkillSuccess(state, action);
      case ADD_PORTFOLIO_SKILL_FAIL:
        return addPortfolioSkillFail(state, action);
      case ADD_SERVICE_IMAGE_START:
        return addServiceImageStart(state, action);
      case ADD_SERVICE_IMAGE_SUCCESS:
        return addServiceImageSuccess(state, action);
      case ADD_SERVICE_IMAGE_FAIL:
        return addServiceImageFail(state, action);
      default:
          return state;
    }
}

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import {
    activateUser
} from "../../actions/auth";
import PublicLayout from '../layout/PublicLayout';
import { Toast } from 'primereact/toast';






const Activate = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {
      activateToken
    } = useParams();
    const fromManagement = location.state?.from?.pathname || "/management";
    const fromJobSeeker = location.state?.from?.pathname || "/jobs";
    const fromEmployer = location.state?.from?.pathname || "/employ";

    const {
      user,
      token,
      userRole,
      loading,
    } =props;
    const toast = useRef(null);

    const data = {
        activate_code: activateToken,
    }

    console.log(user.verified)

    useEffect(() => {
        if (user.verified !== undefined){
          if(user.verified){
            toast.current.show({
              severity: 'success',
              summary: 'Successful',
              detail: user.msg,
              life: 10000
            });

          }else{
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: 'Something Wrong with your Verification Token',
              life: 10000
            });
          }
          if (token){
              if (userRole === 'admin'){
                  navigate(fromManagement, { replace: true });
                  console.log(fromManagement)
              }
              else if(userRole === 'employer'){
                  navigate(fromEmployer, { replace: true });
                  console.log(fromEmployer)

              }
              else if(userRole === 'jobseeker'){
                  navigate(fromJobSeeker, { replace: true });
                  console.log(fromJobSeeker)

              }
          }

        }else return;

      }, [token, user]);// eslint-disable-line react-hooks/exhaustive-deps



      useEffect(() => {
        if (user.verified !== undefined){
          if(user.verified){
            toast.current.show({
              severity: 'success',
              summary: 'Successful',
              detail: user.msg,
              life: 10000
            });

          }else{
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: 'Something Wrong with your Verification Token', life: 10000
            });
          }
          if (token){
              if (userRole === 'admin'){
                  navigate(fromManagement, { replace: true });
                  console.log(fromManagement)
              }
              else if(userRole === 'employer'){
                  navigate(fromEmployer, { replace: true });
                  console.log(fromEmployer)

              }
              else if(userRole === 'jobseeker'){
                  navigate(fromJobSeeker, { replace: true });
                  console.log(fromJobSeeker)

              }
          }else return;

        }else return;
      }, [loading, token, user]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (e) =>{
      props.activateUser(data)
    }

    return (
        <PublicLayout>

          <div className='grid'>
            <Toast ref={toast} />
            <div className='col-12 md:col-3'>

            </div>
            <div className='col-12 md:col-6'>
              <div className="card text-700 text-center">
                  <div className="text-900 font-bold text-5xl mb-3">Click to verify your Account</div>
                  <Button
                    label="Verify"
                    icon="pi pi-lock-open"
                    className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                    onClick={(e) =>handleSubmit(e)}
                  />
              </div>
            </div>
          </div>




        </PublicLayout>

    )
}

const mapStateToProps = state => {
    return {
      token: state.auth.token,
      loading: state.auth.loading,
      error: state.auth.error,
      token: state.auth.token,
      user: state.auth.user,
      userRole: state.auth.userRole,
    };
  };



  export default connect(
    mapStateToProps,
    {activateUser}
  )(Activate);

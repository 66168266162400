import React, {useState, useEffect, useRef} from 'react';
import { useNavigate, useLocation, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import {
    createJobseeker
} from "../../actions/auth";
import silas from './zimjobs.png';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { RadioButton } from 'primereact/radiobutton';
import { ProgressBar } from 'primereact/progressbar';
import Email from './recruiterSteps/Email';
import Username from './recruiterSteps/Username';
import PasswordInput from './recruiterSteps/PasswordInput';
import PhoneNumber from './recruiterSteps/PhoneNumber';
import Details from './steps/Details';
import {
    getCountries,
    getProvinces,
    getDistricts

} from '../../actions/places';





const genderOptions = [
    {key: "MALE"},
    {key: "FEMALE"},
    {key: "OTHER"},
]

const Register = (props) => {
    const initialValues = {
        email: "",
        phone_number: "",
        email_or_phonenumber: "email",
        username: "",
        type: "jobseeker",
        password: "",
        password2: "",
        country_of_citizenship: '',
        country_of_residence: '',
        province: '',
        district: '',
        description: '',
        availability: '',
        availability_category: '',
        phone_number: "",
        dob: "",
        whatsapp_number: '',
        profile_picture: null,
        gender: '',
        middle_name: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const {
      user,
      token,
      userRole,
      loading,
      countries,
      provinces,
      districts,
      getCountries,
      getProvinces,
      getDistricts,
    } =props;
    const toast = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [clear, setClear] =useState(false);
    const [emailClear, setEmailClear] =useState(false);
    const [passwordClear, setPasswordClear] =useState(false);
    const [password2Clear, setPassword2Clear] =useState(false);
    const [page, setPage] = useState(0);
    const [value, setValue] = useState(33);
    const [proceedCleared, setProceedCleared] =useState(false);
    const [submitCleared, setSubmitCleared] = useState(false);
    const [galivantingCleared, setGalivanting] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [cityOptions, setCityOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([])
    const fromManagement = location.state?.from?.pathname || "/management";
    const fromJobSeeker = location.state?.from?.pathname || "/jobs";
    const fromEmployer = location.state?.from?.pathname || "/employ";

    const FormTitles = [
      "Email",
      "Username",
      "Phone Number",
      "Details",
      "Password "
  ];

  useEffect(() =>{
    if (page === 0){
      setValue(20)
    }else if(page === 1){
      setValue(40)
    }else if(page === 2){
      setValue(60)
    }else if(page === 3){
      setValue(80)
    }else if(page === 4){
      setValue(100)
    }
    setProceedCleared(false);
    setSubmitCleared(false);
    if (galivantingCleared){
      setSubmitCleared(true);
    }

  }, [page])


    const getCityOptions = (cities, country) =>{
       return cities.filter((city) => city.country === country);
    }

    useEffect(() =>{
          let citiesCopy = [...provinces]
          let cities = getCityOptions(citiesCopy, record.country_of_residence);
          setCityOptions(cities);

    }, [record.country_of_residence])  // eslint-disable-line react-hooks/exhaustive-deps


    const getDistrictOptions = (districts, province) =>{
      return districts.filter((ds) => ds.province === province);
    }


    useEffect(() =>{
          let districtsCopy = [...districts]
          let districts_ = getDistrictOptions(districtsCopy, record.province);
          setDistrictOptions(districts_);

    }, [record.province]) //





    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }


      const handleFileRead = async (event) => {
            const file = event.target.files[0]
            const base64 = await convertBase64(file)
            onImageChange(base64)
      }

  useEffect(() => {
    if (token){
          if (userRole === 'admin'){
              navigate(fromManagement, { replace: true });
              console.log(fromManagement)
          }
          else if(userRole === 'employer'){
              navigate(fromEmployer, { replace: true });
              console.log(fromEmployer)

          }
          else if(userRole === 'jobseeker'){
              navigate(fromJobSeeker, { replace: true });
              console.log(fromJobSeeker)

          }
      }
    }, [loading, token, user]);// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
      if (token){
            if (userRole === 'admin'){
                navigate(fromManagement, { replace: true });
                console.log(fromManagement)
            }
            else if(userRole === 'employer'){
                navigate(fromEmployer, { replace: true });
                console.log(fromEmployer)

            }
            else if(userRole === 'jobseeker'){
                navigate(fromJobSeeker, { replace: true });
                console.log(fromJobSeeker)

            }
        }
      }, [loading, token, user]);// eslint-disable-line react-hooks/exhaustive-deps







    const resetForm = () =>{
        setRecord(initialValues)
    }

    useEffect(() =>{
      if(user.passed !== undefined){
          if(user.passed){
            toast.current.show({
              severity: 'success',
              summary: 'Successful',
              detail: user.msg,
              life: 10000
            });
            setIsLoading(false);
          }else{
            toast.current.show({
              severity: 'error',
              summary: 'Failed',
              detail: user.msg, life: 10000
            });
            setIsLoading(false);
          }
      }
    }, [user])



    const onImageChange = (data) => {
        let _record = {...record};
        _record[`profile_picture`] = data;
        setRecord(_record);
    }


    useEffect(() =>{
        if (!props.fetched){
            getCountries(props.token);
            getProvinces(props.token);
            getDistricts(props.token);
        }

    }, [])



    const handleSubmit = (e) => {
        e.preventDefault();
        props.createJobseeker(record);
        setPage(0)
        setSubmitCleared(false);
        setProceedCleared(false)
        resetForm();
        setIsLoading(true);

            // navigate("/login")
    }



    const onUsernameInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
        setClear(true);
        setProceedCleared(false);
        setSubmitCleared(false)
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
        setProceedCleared(false);
    }

    const onEmailInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
        setEmailClear(true);
        setProceedCleared(false);
        setSubmitCleared(false)
    }

    const onPhoneNumberInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
        setEmailClear(true);
        setProceedCleared(false);
        setSubmitCleared(false)
    }


    const onPasswordInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
        setPasswordClear(true);
        setSubmitCleared(false);
    }

    const onPassword2InputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
        setPassword2Clear(true);
        setSubmitCleared(false);
    }


    const onTypeChange = (e) => {
        let _record = {...record};
        _record['email_or_phonenumber'] = e.value;
        setRecord(_record);

    }




    useEffect(() =>{
        let temp = { ...errors }
        temp.email_unique = ''
        setErrors({
            ...temp
        })

    }, [record.email])


    useEffect(() =>{
        let temp = { ...errors }
        temp.username_unique = ''
        setErrors({
            ...temp
        })

    }, [record.username])

    useEffect(() =>{
        let temp = { ...errors }
        temp.phone_number_unique = ''
        setErrors({
            ...temp
        })

    }, [record.phone_number])


    useEffect(() =>{
        let temp = { ...errors }
        temp.password2 = ''
        setErrors({
            ...temp
        })

    }, [record.password2])


    const getFormDisplay =()=>{
      if (page ===0){
        return(
          <Email
            email ={record.email}
            onInputChange= {onEmailInputChange}
            emailClear={emailClear}
            setEmailClear={setEmailClear}
            setProceedCleared={setProceedCleared}

          />

        );
      }else if (page===1){
        return(
          <Username
              username ={record.username}
              onInputChange= {onUsernameInputChange}
              clear={clear}
              setClear={setClear}
              setProceedCleared={setProceedCleared}
          />
        );
      }else if (page===2){
        return(
          <PhoneNumber
            onPhoneNumberInputChange={onPhoneNumberInputChange}
            phone_number ={record.phone_number}
            clear={clear}
            setClear={setClear}
            setProceedCleared={setProceedCleared}
          />
        );
      }else if (page===3){
        return(
          <Details
            onInputChange={onInputChange}
            first_name ={record.first_name}
            last_name ={record.last_name}
            setProceedCleared={setProceedCleared}
            country_of_citizenship={record.country_of_citizenship}
            country_of_residence={record.country_of_residence}
            province={record.province}
            district={record.district}
            description={record.description}
            availability={record.availability}
            availability_category={record.availability_category}
            phone_number={record.phone_number}
            dob={record.dob}
            whatsapp_number={record.whatsapp_number}
            profile_picture={record.profile_picture}
            handleFileRead={handleFileRead}

            countries = {countries}
            cityOptions = {cityOptions}
            districtOptions = {districtOptions}
            middle_name={record.middle_name}
            gender={record.gender}
          />
        );
      }else{
        return(
          <PasswordInput
            passwordClear={passwordClear}
            setPasswordClear={setPasswordClear}
            password2Clear={password2Clear}
            setPassword2Clear={setPassword2Clear}
            password ={record.password}
            username ={record.username}
            password2 ={record.password2}
            onPasswordInputChange= {onPasswordInputChange}
            onPassword2InputChange= {onPassword2InputChange}
            setSubmitCleared={setSubmitCleared}
            setGalivanting={setGalivanting}
          />

        );
      }
    }





    return (
        <>
            <Toast ref={toast} />
              {isLoading
                ?<>

                  <div className="card">
                    <div className="custom-skeleton p-4">
                        <div className="flex mb-3">
                            <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                            <div>
                                <Skeleton width="10rem" className="mb-2"></Skeleton>
                                <Skeleton width="5rem" className="mb-2"></Skeleton>
                                <Skeleton height=".5rem"></Skeleton>
                            </div>
                        </div>
                        <Skeleton width="100%" height="150px"></Skeleton>
                        <div className="flex justify-content-between mt-3">
                            <Skeleton width="4rem" height="2rem"></Skeleton>
                            <Skeleton width="4rem" height="2rem"></Skeleton>
                        </div>
                    </div>
                  </div>
                </>
                :<div className='grid fluid'>
                    <div className='col-12 md:w-2'></div>
                    <div className="flex card shadow-8  col-12 md:w-8 align-items-center justify-content-center">
                    <div className="border-round w-full">
                        <div className="text-center mb-5" style={{textAlign: 'center'}}>
                            <span className="text-600 font-medium line-height-3">Have an account?</span>
                            <Link
                                className="font-medium no-underline ml-2 mr-3 text-blue-500 cursor-pointer"
                                to='/login'
                            >
                                    Login
                            </Link>


                        </div>


                          <div className="surface-0 text-700 text-center">
                            <div className="text-900 font-bold text-5xl mb-3">{FormTitles[page]}</div>
                          </div>

                            <ProgressBar value={value}/>

                            {getFormDisplay()}



                            <ul  style={{textAlign: 'center'}} className="list-none card grid p-fluid p-0 m-0" >
                                <li className="flex grid card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                  <div className=" col-12 md:w-6 md:flex-order-0 flex-order-1">
                                      <Button
                                          label="PREVIOUS"
                                          disabled={page === 0}
                                          onClick={()=> setPage((currPage) => currPage - 1)}
                                          className="p-button-outlined p-button-rounded"
                                      />
                                  </div>
                                  <div className=" col-12 md:w-6 md:flex-order-0 flex-order-1">
                                    {proceedCleared
                                      ?<Button
                                        label="NEXT"
                                        disabled={page === FormTitles.length - 1}
                                        onClick={()=> setPage((currPage) => currPage + 1)}
                                        className="p-button-outlined p-button-rounded"
                                      />
                                      :<Button
                                        label="NEXT"
                                        className="p-button-outlined p-button-danger p-button-rounded"
                                        disabled={true}
                                      />

                                    }

                                  </div>
                                  <div className=" col-12 md:w-12 md:flex-order-0 flex-order-1">
                                    {page ===4
                                      ?<>
                                        {submitCleared
                                          ?<Button
                                            label="SUBMIT"
                                            onClick={(e) =>handleSubmit(e)}
                                            className="p-button-outlined p-button-rounded"
                                          />
                                          :<Button
                                            label="SUBMIT"
                                            className="p-button-outlined p-button-danger p-button-rounded"
                                            disabled={true}
                                          />

                                        }

                                      </>
                                    :<></>

                                    }

                                  </div>
                                </li>
                            </ul>
                    </div>
                </div>
              </div>
            }
        </>

    )
}

const mapStateToProps = state => {
    return {
      loading: state.auth.loading,
      error: state.auth.error,
      token: state.auth.token,
      user: state.auth.user,
      userRole: state.auth.userRole,
      countries: state.places.countries,
      provinces: state.places.provinces,
      districts: state.places.districts,

    };
  };



  export default connect(
    mapStateToProps,
    {
      createJobseeker,
      getCountries,
      getProvinces,
      getDistricts,

    }
  )(Register);

import axios from 'axios';
import {
    GET_CANDIDATE_BEST_JOB_MATCHES_START,
    GET_CANDIDATE_BEST_JOB_MATCHES_SUCCESS,
    GET_CANDIDATE_BEST_JOB_MATCHES_FAIL,
    GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_START,
    GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_SUCCESS,
    GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_FAIL,
    GET_CANDIDATE_PERMANENT_ALL_JOBS_START,
    GET_CANDIDATE_PERMANENT_ALL_JOBS_SUCCESS,
    GET_CANDIDATE_PERMANENT_ALL_JOBS_FAIL,
    GET_CANDIDATE_APPLIED_FOR_JOBS_START,
    GET_CANDIDATE_APPLIED_FOR_JOBS_SUCCESS,
    GET_CANDIDATE_APPLIED_FOR_JOBS_FAIL,



} from '../types/candidateMatchTypes';

import {
    candidatePermanentJobBestMatchesURL,
    candidatePermanentJobNearestNeighboursURL,
    allCandidatePermanentJobsURL,
    candidateAppliedForJobsURL,

} from '../constants';


const getPermanentJobBestMatchListStart = () => {
    return {
      type: GET_CANDIDATE_BEST_JOB_MATCHES_START
    };
  };
  
const getPermanentJobBestMatchListSuccess = candidatebestjobmatches => {
    return {
      type: GET_CANDIDATE_BEST_JOB_MATCHES_SUCCESS,
      candidatebestjobmatches
    };
};
  
const getPermanentJobBestMatchListFail = error => {
    return {
      type: GET_CANDIDATE_BEST_JOB_MATCHES_FAIL,
      error: error
    };
};

const getPermanentJobNearestNeighbourListStart = () => {
    return {
      type: GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_START
    };
};
  

const getPermanentJobNearestNeighbourListSuccess = candidatepermanentjobnearestneaighbours => {
    return {
      type: GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_SUCCESS,
      candidatepermanentjobnearestneaighbours,
    };
};
  

const getPermanentJobNearestNeighbourListFail = error => {
    return {
      type: GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_FAIL,
      error: error
    };
};


const getPermanentAllJobListStart = () => {
  return {
    type: GET_CANDIDATE_PERMANENT_ALL_JOBS_START
  };
};


const getPermanentAllJobListSuccess = candidateallpermanentjobs => {
  return {
    type: GET_CANDIDATE_PERMANENT_ALL_JOBS_SUCCESS,
    candidateallpermanentjobs,
  };
};


const getPermanentAllJobListFail = error => {
  return {
    type: GET_CANDIDATE_PERMANENT_ALL_JOBS_FAIL,
    error: error
  };
};



// APPLIED FOR JOBS
const getAppliedForJobListStart = () => {
  return {
    type: GET_CANDIDATE_APPLIED_FOR_JOBS_START
  };
};


const getAppliedForJobListSuccess = candidateappliedforjobs => {
  return {
    type: GET_CANDIDATE_APPLIED_FOR_JOBS_SUCCESS,
    candidateappliedforjobs,
  };
};


const getAppliedForJobListFail = error => {
  return {
    type: GET_CANDIDATE_APPLIED_FOR_JOBS_FAIL,
    error: error
  };
};


export const getCandidateAppliedForJobs = (params, token) => {
  return dispatch => {
      dispatch(getAppliedForJobListStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(candidateAppliedForJobsURL,{ params }, headers)
        .then(res => {
          const candidateappliedforjobs = res.data;
          dispatch(getAppliedForJobListSuccess(candidateappliedforjobs));
          })
        .catch(err => {
          dispatch(getAppliedForJobListFail(err));
        });
    };
};




export const getCandidatePermanentJobNearestNeighbours = (params, token) => {
    return dispatch => {
        dispatch(getPermanentJobNearestNeighbourListStart());
        let headers = axios.defaults.headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`
        };
        axios
          .get(candidatePermanentJobNearestNeighboursURL,{ params }, headers)
          .then(res => {
            const candidatepermanentjobnearestneaighbours = res.data;
            dispatch(getPermanentJobNearestNeighbourListSuccess(candidatepermanentjobnearestneaighbours));
            })
          .catch(err => {
            dispatch(getPermanentJobNearestNeighbourListFail(err));
          });
      };
};



export const getCandidatePermanentBestJobMatches = (params, token) => {
  return dispatch => {
      dispatch(getPermanentJobBestMatchListStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(candidatePermanentJobBestMatchesURL,{ params }, headers)
        .then(res => {
          const candidatebestjobmatches = res.data;
          dispatch(getPermanentJobBestMatchListSuccess(candidatebestjobmatches));
          })
        .catch(err => {
          dispatch(getPermanentJobBestMatchListFail(err));
        });
    };
};



export const getCandidatePermanentAllJobs = (params, token) => {

  return dispatch => {
      dispatch(getPermanentAllJobListStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(allCandidatePermanentJobsURL,{ params }, headers)
        .then(res => {
          const candidateallpermanentjobs = res.data;
          dispatch(getPermanentAllJobListSuccess(candidateallpermanentjobs));
          })
        .catch(err => {
          dispatch(getPermanentAllJobListFail(err));
        });
    };
};



import {
    GET_RECOMMENDATION_USERS,
    RECOMMEND,
} from '../types/recommendationTypes';



const initialState = {
    recommendationusers: [],

};



export default function recommendations(state = initialState, action){

    switch(action.type){
      case RECOMMEND:
          return {
              ...state,
             recommendation: [...state.recommendationusers, action.payload]
          }
        case GET_RECOMMENDATION_USERS:
            return {
                ...state,
                recommendationusers : action.payload
            };


        default:
            return state;
    }
}

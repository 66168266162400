import React, {useState} from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import {
    chargeCategoryOptions,
    visibilityStatusOptions,
    jobTypeOptions,
    jobFishingExpectedTimelineOptions,
    jobDurationOptions,
    prefferedSkillsetOptions,
    geographicalLocationOptions,
    locationOptions,
    compensationOptions,
    


} from '../data';

const JobDialog = (props) => {
    const {
        onInputChange,
        employerOptions,
        record,
    } = props;

    const [errors, setErrors] = useState({});
    // const [record, setRecord] = useState(initialValues);
    const navigate = useNavigate()

    const resetForm = () => {
        // setRecord(initialValues);
    }

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('compensation_type' in fieldValues)
            temp.compensation_type= fieldValues.compensation_type? "" : "Compensation type is Required"
        if ('location' in fieldValues)
            temp.location= fieldValues.location? "" : "Location is Required"
        if ('category' in fieldValues)
            temp.category= fieldValues.category? "" : "Category is Required"
        if ('charge_category' in fieldValues)
            temp.charge_category= fieldValues.charge_category? "" : "Charge category is Required"
        if ('country' in fieldValues)
            temp.country= fieldValues.country? "" : "Country is Required"
        if ('description' in fieldValues)
            temp.description= fieldValues.description? "" : "Description is Required"
        if ('duration_category' in fieldValues)
            temp.duration_category= fieldValues.duration_category? "" : "Duration category is Required"
        if ('expected_duration' in fieldValues)
            temp.expected_duration= fieldValues.expected_duration? "" : "Expected duration is Required"
        if ('fixed_price' in fieldValues)
            temp.fixed_price= fieldValues.fixed_price? "" : "Fixed price is Required"    
        if ('from_hourly_price' in fieldValues)
            temp.from_hourly_price= fieldValues.from_hourly_price? "" : "From hourly price is Required"
        if ('location_type' in fieldValues)
            temp.location_type= fieldValues.location_type? "" : "Geographical prefferences is Required"
        if ('required_skillset' in fieldValues)
            temp.required_skillset= fieldValues.required_skillset? "" : "preffered skillset is Required" 
        if ('to_hourly_price' in fieldValues)
            temp.to_hourly_price= fieldValues.to_hourly_price? "" : "to hourly price is Required"
            
        
        setErrors({
            ...temp
        })
    
        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(record)
        if (record.slug) {
            if (validate()){
                console.log(record)
                props.editManagementJob(record.slug, record, props.token);
                resetForm();
                navigate("/employ/my-published-jobs", {replace: true})
            }
                
        }
    }

  return (
    <div className="card">
        <form onSubmit={handleSubmit}>
            <div>
                <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>       
                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            COMPENSATION TYPE
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.compensation_type}
                                onChange={(e) => onInputChange(e, 'compensation_type')}
                                options={compensationOptions}
                                filter
                                showClear
                                filterBy="key"
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.compensation_type}</small> 
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            LOCATION
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.location}
                                onChange={(e) => onInputChange(e, 'location')}
                                options={locationOptions}
                                filter
                                showClear
                                filterBy="key"
                            
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.location}</small>  

                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            DURATION
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.duration_category}
                                onChange={(e) => onInputChange(e, 'duration_category')}
                                options={jobDurationOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='DURATION'
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.duration_category}</small>  

                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            CATEGORY
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.category}
                                onChange={(e) => onInputChange(e, 'category')}
                                options={jobTypeOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='CATEGORY'
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.category}</small>  

                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            VISIBILITY STATUS
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.visibility_status}
                                onChange={(e) => onInputChange(e, 'visibility_status')}
                                options={visibilityStatusOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='VISIBILITY STATUS'
                />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.visibility_status}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            CHARGE CATEGORY
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.charge_category}
                                onChange={(e) => onInputChange(e, 'charge_category')}
                                options={chargeCategoryOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='CHARGE CATEGORY'
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.charge_category}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            EXPECTED DURATION
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.expected_duration}
                                onChange={(e) => onInputChange(e, 'expected_duration')}
                                options={jobFishingExpectedTimelineOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='EXPECTED DURATION'
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.expected_duration}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            PREFFERED SKILLSET
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                            
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.required_skillset}
                                onChange={(e) => onInputChange(e, 'required_skillset')}
                                options={prefferedSkillsetOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='PREFFERED SKILLSET'

                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.required_skillset}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            GEOGRAPHICAL PREFFERENCES
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <Dropdown
                                optionLabel="key"
                                optionValue="key"
                                value={record.location_type}
                                onChange={(e) => onInputChange(e, 'location_type')}
                                options={geographicalLocationOptions}
                                filter
                                showClear
                                filterBy="key"
                                placeholder='GEOGRAPHICAL PREFFERENCES'
                            /> 
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.location_type}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                           PREFFERED COUNTRY 
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <Dropdown
                                optionLabel="name"
                                optionValue="name"
                                value={record.country}
                                onChange={(e) => onInputChange(e, 'country')}
                                options={props.countries}
                                filter
                                showClear
                                filterBy="name"
                                placeholder='COUNTRY'
                                
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.country}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            PREFFERED CITY
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <Dropdown
                                optionLabel="name"
                                optionValue="name"
                                value={record.preffered_city}
                                onChange={(e) => onInputChange(e, 'preffered_city')}
                                options={props.provinces}
                                filter
                                showClear
                                filterBy="name"
                                placeholder='PREFFERED CITY'
                                
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.preffered_city}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            PREFFERED DISTRICT
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <Dropdown
                                optionLabel="name"
                                optionValue="name"
                                value={record.preffered_district}
                                onChange={(e) => onInputChange(e, 'preffered_district')}
                                options={props.districts}
                                filter
                                showClear
                                filterBy="name"
                                placeholder='PREFFERED DISTRICT'
                                
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.preffered_district}</small>  
                        </div>
                    </li>
                    
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            CHOOSE NAME
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <span className="p-float-label p-input-icon-right">

                            <i className="pi pi-spin pi-spinner" />
                            <InputText
                                id="name"
                                tooltip="Choose Name"
                                value={record.title}
                                onChange={(e) => onInputChange(e, 'title')}
                                placeholder ='Job Title'
                            />
                            </span>
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.title}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            FIXED PRICE
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <InputNumber
                                inputId="horizontal"
                                showButtons
                                buttonLayout="horizontal"
                                step={0.25}
                                decrementButtonClassName="p-button-danger"
                                incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                mode="currency"
                                currency="USD"
                                value={record.fixed_price}
                                onChange={(e) => onInputChange(e, 'fixed_price')}
                                placeholder="FIXED PRICE"
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.fixed_price}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            FROM HOURLY PRICE
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <InputNumber
                                inputId="horizontal"
                                showButtons
                                buttonLayout="horizontal"
                                step={0.25}
                                decrementButtonClassName="p-button-danger"
                                incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                mode="currency"
                                currency="USD"
                                value={record.from_hourly_price}
                                onChange={(e) => onInputChange(e, 'from_hourly_price')}
                                placeholder="FROM HOURLY PRICE"
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.from_hourly_price}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            TO HOURLY PRICE
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <InputNumber
                                inputId="horizontal"
                                showButtons
                                buttonLayout="horizontal"
                                step={0.25}
                                decrementButtonClassName="p-button-danger"
                                incrementButtonClassName="p-button-success"
                                incrementButtonIcon="pi pi-plus"
                                decrementButtonIcon="pi pi-minus"
                                mode="currency"
                                currency="USD"
                                value={record.to_hourly_price}
                                onChange={(e) => onInputChange(e, 'to_hourly_price')}
                                placeholder="TO HOURLY PRICE"
                            />
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.to_hourly_price}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <div
                            className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                        >
                            DESCRIPTION
                        </div>
                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">   
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-spin pi-spinner" />
                                <InputTextarea
                                    rows={2}
                                    cols={30}
                                    autoResize
                                    value={record.description}
                                    onChange={(e) => onInputChange(e, 'description')}
                                    placeholder="DESCRIPTION"
                                />
                            </span>
                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.description}</small>  
                        </div>
                    </li>
                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                        <Button
                            type="submit"
                            label="Submit"
                            className="mt-2"

                        />
                    </li>
                </ul>
                    
            </div>

        </div>
    </form>
    </div>


  )
}

export default JobDialog
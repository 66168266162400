import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import {connect} from 'react-redux';
import {
    getManagementJobProposals

} from '../../../actions/jobs';
import { Chip } from 'primereact/chip';
import { useNavigate } from 'react-router-dom';
import ManagementLayout from '../../layout/ManagementLayout';
import { RadioButton } from 'primereact/radiobutton';
import ProposalContainer  from './ProposalContainer';
import { Badge } from 'primereact/badge';




const Jobs = (props) => {

      const [hour, setHour] = useState(null);

      const [type, setType] = useState('PENDING');
      const {
          lastName,
          firstName,
        } = props;
      const pendingProposals = props.records.filter((proposal) =>  proposal.acceptance_status==="PENDING");
      const calledForInterviewProposals = props.records.filter((proposal) =>  proposal.acceptance_status==="CALLED FOR INTERVIEW");
      const declinedProposals = props.records.filter((proposal) =>  proposal.acceptance_status==="DECLINED");
      const acceptedProposals = props.records.filter((proposal) =>  proposal.acceptance_status==="ACCEPTED");


      const onTypeChange = (e) => {
          setType(e.value);
        }

      const getHour = () => {
          const date = new Date();
          const hour = date.getHours()
          setHour(hour);
      }

      useEffect(() => {
          getHour();

      }, []) // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
          if (!props.fetched){
              props.getManagementJobProposals(props.token);
          }

      }, []); // eslint-disable-line react-hooks/exhaustive-deps



        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;




          const getHourDisplay = () => {

            if (hour < 12){
              return(
                <>
                  Good Morning!
                </>
              )
            }else if(hour < 18){
              return (
                <>Good Afternoon!!</>
              )
            }else{
              return(
                <>Good Evening!!</>
              );
            }
        }



      const getJobDisplay = () =>{
          if (type === "PENDING"){
              return(
                  <>
                      <ProposalContainer
                        status="PENDING"
                        path="pending-decision-application"
                        description='YOU NEED TO VIEW THESE APPLICATIONS & MAKE A DECISION'
                        title='MAKE A DECISION'
                        state='PENDING'
                        stateMessage='CURRENTLY YOU DO NOT HAVE ANY PROPOSALS IN NEED OF YOUR DECISION'
                      />
                  </>
              )

          }else if (type === "CALLED FOR INTERVIEW"){
              return(
                  <>
                      <ProposalContainer
                        status="CALLED FOR INTERVIEW"
                        path="called-for-interview-application"
                        description='THESE APPLICATIONS ARE UNDER INTERVIEW'
                        title='WAIT FOR A DECISION'
                        state='ON INTERVIEW'
                        stateMessage='CURRENTLY YOU DO NOT HAVE ANY APPLICATIONS ON INTERVIEW'
                      />
                  </>
              )
          }else if (type === "DECLINED"){
              return(
                  <>
                      <ProposalContainer
                        status="DECLINED"
                        path="rejected-application"
                        description='THESE APPLICATIONS WERE REJECTED'
                        title='REJECTED APPLICATIONS'
                        state='REJECTED'
                        stateMessage='CURRENTLY YOU DO NOT HAVE ANY APPLICATIONS THAT WERE REJECTED'
                      />
                  </>
              )
          }else if (type === "ACCEPTED"){
              return(
                  <>
                      <ProposalContainer
                        status="ACCEPTED"
                        path="accepted-application"
                        description='THESE APPLICATIONS WERE ACCEPTED'
                        title='ACCEPTED APPLICATIONS'
                        state='ACCEPTED '
                        stateMessage='CURRENTLY YOU DO NOT HAVE ANY APPLICATIONS THAT WERE ACCEPTED'
                      />
                  </>
              )
          }else{
              return(
                  <>
                      SOMETHING WRONG
                  </>
              )
          }
      }


    return (
        <ManagementLayout>
          <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                <div className="text-700 text-center" style={{fontFamily: 'cursive'}}>
                    <div className="text-900 font-bold text-5xl mb-3">{getHourDisplay()}</div>
                    <div className="text-700 text-2xl mb-5">{firstName} {lastName}</div>
                    <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-outlined p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
              </div>
              <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                  <h5>THESE ARE THE APPLICATIONS BASED ON THEIR CURRENT STATUS</h5>
                  <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                      <li  className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div
                          style={{textAlign: 'center'}}
                          className="field-radiobutton flex card box shadow-8 w-full md:w-3 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                        >
                            <RadioButton
                                inputId="category1"
                                name="category"
                                value="PENDING"

                                onChange={onTypeChange}
                                checked={type === "PENDING"}
                              />
                            <label className="" htmlFor="category1">
                              PENDING DECISION
                              <small style={{paddingLeft: '10px'}}>
                                {pendingProposals.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={pendingProposals.length} serverity='success'className="mr-2"/> }
                              </small>
                            </label>
                        </div>
                        <div className="field-radiobutton flex card box shadow-8 w-full md:w-3 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                            <RadioButton
                                inputId="category2"
                                name="category"
                                value="CALLED FOR INTERVIEW"
                                onChange={onTypeChange}
                                checked={type === "CALLED FOR INTERVIEW"}
                              />
                            <label className="" htmlFor="category2">
                              ON INTERVIEW
                              <small style={{paddingLeft: '10px'}}>
                                {calledForInterviewProposals.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={calledForInterviewProposals.length} serverity='success'className="mr-2"/> }
                              </small>
                            </label>
                        </div>
                        <div className="field-radiobutton flex card box shadow-8 w-full md:w-3 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                            <RadioButton
                                inputId="category2"
                                name="category"
                                value="DECLINED"
                                onChange={onTypeChange}
                                checked={type === "DECLINED"}
                              />
                            <label className="" htmlFor="category2">
                              DECLINED
                              <small style={{paddingLeft: '10px'}}>
                                {declinedProposals.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={declinedProposals.length} serverity='success'className="mr-2"/> }
                              </small>
                            </label>
                        </div>
                        <div className="field-radiobutton flex card box shadow-8 w-full md:w-3 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                            <RadioButton
                                inputId="category2"
                                name="category"
                                value="ACCEPTED"
                                onChange={onTypeChange}
                                checked={type === "ACCEPTED"}
                              />
                            <label className="" htmlFor="category2">
                              ACCEPTED
                              <small style={{paddingLeft: '10px'}}>
                                {acceptedProposals.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={acceptedProposals.length} serverity='success'className="mr-2"/> }
                              </small>
                          </label>
                        </div>

                      </li>
                    </ul>

              </div>

              {getJobDisplay()}

        </ManagementLayout>
    );
}




const mapStateToProps = state =>({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    records: state.jobs.managementjobproposals,

})

export default connect(
  mapStateToProps,
  {
    getManagementJobProposals,
} )
  (Jobs);

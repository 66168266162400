import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import PrimeReact from 'primereact/api';
import {connect} from 'react-redux';
import {Button} from 'primereact/button'
import { Accordion, AccordionTab } from 'primereact/accordion';
import {
    getCandidatePermanentAllJobs

} from '../../actions/candidatematches';
import { Chip } from 'primereact/chip';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { MultiSelect } from 'primereact/multiselect';
import { RiFlag2Fill} from "react-icons/ri";
import {
    locationOptions,
    prefferedSkillsetOptions,
    geographicalLocationOptions,
    chargeCategoryOptions,
} from './data';
import AOS from "aos";
import 'aos/dist/aos.css';
import logn from './login.mp4';




const Jobs = (props) => {
    const [layout, setLayout] = useState('grid');
    PrimeReact.ripple = true;
    const navigate = useNavigate();
    const [towns, setTowns] =useState([]);
    const initialParams = {
        location: '',
        requiredSkillset: '',
        country: '',
        geolocation: '',
        chargeCategory: '',
        prefferedTown: '',


    }
    const [params, setParams] = useState(initialParams);
    const {loading, countries, provinces, districts,} =props;
    const [filteredCountries, setFilteredCountries] = useState([]);
    const jobs = props.records.filter((job) => job.category === props.category)


    const getCountryOptions = () =>{
        if (params.geolocation === '' || params.geolocation === undefined){
            return countries;
        }else{
            return filteredCountries;
        }
    }



    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _params = {...params};
        _params[`${name}`] = val;
        setParams(_params);
    }


    const onTownsChange = (towns_) => {
        let _params = {...params};
         _params[`prefferedTown`] =towns_;
        setParams(_params);
    }




    useEffect(() =>{
        AOS.init({duration : 3000})

      }, []);



    const handleClick = slug =>{
        navigate(`/jobs/${slug}`)
    }



    useEffect(() => {
        if (!props.fetched){
            props.getCandidatePermanentAllJobs(params, props.token);

        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!props.fetched){
            props.getCandidatePermanentAllJobs(params, props.token);
        }

    }, [params]); // eslint-disable-line react-hooks/exhaustive-deps





    const getFilteredCountries = (countries, geolocation) =>{
        if (geolocation === '' || geolocation === undefined || geolocation === 'WORLDWIDE') {
            return countries;
        }else{
            return countries.filter((country) => country.continent === geolocation)
        }

    }

    useEffect(() =>{
        let countriesCopy = [...countries];
        let countries_ = getFilteredCountries(countriesCopy, params.geolocation);
        setFilteredCountries(countries_)

    },[params.geolocation]) // eslint-disable-line react-hooks/exhaustive-deps




    const getTownNames = (towns) => {
        const myTowns = towns.map(item => {
            let name = item.id;
            return name;
        });
        return myTowns;

      }

    useEffect(() =>{


        if (towns.length >= 1){
            let towns_ = `${getTownNames(towns)}`;
            onTownsChange(towns_)
        }else{
            onTownsChange('')
        }

    }, [towns]) // eslint-disable-line react-hooks/exhaustive-deps


    const renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
          (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
          // less than one minute ago
          prefix = "just now...";
        } else if (timeDiff < 60 && timeDiff > 1) {
          // less than sixty minutes ago
          prefix = `${timeDiff} minutes ago`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
          // less than 24 hours ago
          prefix = `${Math.round(timeDiff / 60)} hours ago`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
          // less than 7 days ago
          prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
        } else {
          prefix = `${new Date(timestamp)}`;
        }
        return prefix;
      };

      const truncateParagraph = (paragraph) =>{
        if (paragraph.length > 400){
            let paragraph_ = paragraph.slice(0, 399) + 'read more..'
            return paragraph_
        }else{
            return paragraph
        }
    }
    const renderGridItem = (data) => {
        return (

            <div
            data-aos="flip-left"
            className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
            style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
         >
                <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                    <div data-aos="fade-down" className="surface-1">
                        <ul data-aos="fade-down" className="list-none job_link p-0 m-0 align-items-center">
                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                    {data.title}

                                </div>
                                <div className=" col-12 md:w-12 font-medium">
                                    <Chip label={`Posted ${renderTimestamp(data.created_at)}`}  className="mr-2 mb-2  " />
                                </div>
                                <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                    {`EXPECTED DURATION: ${data.expected_duration}`}

                                </div>
                                <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                    {`BUDGET: ${data.charge_category}`}

                                </div>
                                {data.charge_category === "FIXED"
                                    ?<div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {`Estimated Budget: $${data.fixed_price}`}
                                    </div>
                                    :<>
                                        <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                            {`Estimated Hourly Budget: $${data.from_hourly_price} - $${data.to_hourly_price}`}
                                        </div>
                                    </>

                                }
                            </li>
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                    {truncateParagraph(data.description)}
                                </div>

                          </li>
                          <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                              <div  className=" col-12 md:w-12 font-medium">
                                  {data.applications} Applications
                              </div>

                          </li>

                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className=" col-12 md:w-12 font-medium">
                                    <Button
                                        onClick={() =>  handleClick(data.slug)}
                                        label="VIEW MORE & APPLY"
                                        className="p-button-outlined p-button-rounded p-button-warning"
                                    />
                                </div>

                            </li>

                        </ul>
                    </div>
                    </div>
            </div>


        );
    }







    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }

    const filterTemplateHeaderTemplate = (name) => {
        return (
          <div style={{textAlign: 'center'}}>
              <div style={{fontFamily: 'cursive'}} className='text-primary'>{name}</div>
          </div>

        );
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }



    const selectedCitiesTemplate = (option) => {
        if (option) {
            return (
                <div className="country-item card country-item-value">
                    <RiFlag2Fill/>
                    <div style={{fontFamily: 'cursive'}} className=''>{option.name}</div>
                </div>
            );
        }
      }

    const cityTemplate = (option) => {
        return (
            <div className="country-item card">
                <RiFlag2Fill/>
                <div style={{fontFamily: 'cursive'}} className=''>{option.name}</div>
            </div>
        );
    }




    const header = renderHeader();


    return (

        <div className="grid ripple-demo">
            <div className="col-12 md:col-3 card">
                <div className="col-12 px-5" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                  <span className="p-3 shadow-2 mb-3 inline-block" style={{ borderRadius: '10px' }}>
                       <Avatar icon="pi pi-user" className="text-primary" size="xlarge" shape="circle" />
                  </span>
                  <div className="text-primary mb-3 font-medium">{props.firstName} {props.lastName}</div>
                  <span className="text-primary text-sm line-height-3">
                      {`You are currently matched to ${props.records.length} Active Jobs based on your Skills and Qualifications`}
                  </span>
                </div>
                <Accordion activeIndex={0}>
                  <AccordionTab
                    data-aos="flip-left"
                    className="font-bold mb-3"
                    header={filterTemplateHeaderTemplate("FILTER JOBS BY TYPE")}>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li
                              className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap"
                              style={{paddingBottom: '30px'}}
                          >

                            <div
                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                            >
                                <Dropdown
                                    optionLabel="key"
                                    optionValue="key"
                                    value={params.location}
                                    onChange={(e) => onInputChange(e, 'location')}
                                    options={locationOptions}
                                    filter
                                    filterBy="key"
                                    showClear
                                />
                            </div>
                          </li>
                      </ul>
                  </AccordionTab >
                  <AccordionTab
                    data-aos="flip-left"
                    className="font-bold mb-3"
                    header={filterTemplateHeaderTemplate("FILTER JOBS REQUIRED SKILLSET")}>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li
                              className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap"
                              style={{paddingBottom: '30px'}}
                          >

                            <div
                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                            >
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={params.requiredSkillset}
                                  onChange={(e) => onInputChange(e, 'requiredSkillset')}
                                  options={prefferedSkillsetOptions}
                                  filter
                                  filterBy="key"
                                  showClear
                              />
                            </div>
                          </li>
                      </ul>
                  </AccordionTab >
                  <AccordionTab
                    data-aos="flip-left"
                    className="font-bold mb-3"
                    header={filterTemplateHeaderTemplate("FILTER JOBS BY GEOLOCATION")}>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li
                              className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap"
                              style={{paddingBottom: '30px'}}
                          >

                            <div
                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                            >
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={params.geolocation}
                                  onChange={(e) => onInputChange(e, 'geolocation')}
                                  options={geographicalLocationOptions}
                                  filter
                                  filterBy="key"
                                  showClear
                              />
                            </div>
                          </li>
                      </ul>
                  </AccordionTab >
                  <AccordionTab
                    data-aos="flip-left"
                    className="font-bold mb-3"
                    header={filterTemplateHeaderTemplate("FILTER JOBS BY COUNTRY")}>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li
                              className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap"
                              style={{paddingBottom: '30px'}}
                          >

                            <div
                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                            >
                              <Dropdown
                                  optionLabel="name"
                                  optionValue="name"
                                  value={params.country}
                                  onChange={(e) => onInputChange(e, 'country')}
                                  options={getCountryOptions()}
                                  filter
                                  filterBy="name"
                                  showClear
                              />
                            </div>
                          </li>
                      </ul>
                  </AccordionTab >
                  <AccordionTab
                    data-aos="flip-left"
                    className="font-bold mb-3"
                    header={filterTemplateHeaderTemplate("FILTER JOBS BY CHARGE CATEGORY")}>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li
                              className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap"
                              style={{paddingBottom: '30px'}}
                          >

                            <div
                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                            >
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={params.chargeCategory}
                                  onChange={(e) => onInputChange(e, 'chargeCategory')}
                                  options={chargeCategoryOptions}
                                  filter
                                  filterBy="key"
                                  showClear
                              />
                            </div>
                          </li>
                      </ul>
                  </AccordionTab >
                  <AccordionTab
                    data-aos="flip-left"
                    className="font-bold mb-3"
                    header={filterTemplateHeaderTemplate("FILTER JOBS BY CITY")}>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li
                              className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap"
                              style={{paddingBottom: '30px'}}
                          >

                            <div
                                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                style={{fontSize: '15px', fontFamily: 'cursive'}}
                            >
                              <MultiSelect
                                value={towns}
                                options={provinces}
                                optionLabel="name"
                                filter
                                className="multiselect-custom"
                                itemTemplate={cityTemplate}
                                selectedItemTemplate={selectedCitiesTemplate}
                                onChange={(e) => setTowns(e.target.value)}

                              />
                            </div>
                          </li>
                      </ul>
                  </AccordionTab >
                </Accordion>


            </div>
            {loading
                ?<div className="card col-12 md:col-9" style={{textAlign: 'center'}}>
                    <div className="grid formgrid">
                        <div className="field col-12 md:col-12">
                            <div className="custom-skeletonS p-4S"style={{paddingTop: '150px'}}>
                                <video style={{borderRadius: '60%', width: '70%'}} src={logn} controls={false} muted autoPlay={"autoplay"}  preLoad="auto" loop>video tag is not supported by your browser</video>
                            </div>
                        </div>
                    </div>
                </div>
                :<div className="dataview-demo col-12 md:col-9">
                  {jobs.length === 0
                    ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                        <div className="col-12">
                                <div className="card">
                                    <h5 className=''>EMPTY OPPORTUNITIES</h5>
                                    <p className=''>CURRENTLY YOU DO NOT HAVE ANY MATCHING OPPORTUNITIES UNDER THIS CATEGORY</p>
                                </div>
                         </div>
                    </div>
                    :<div className="card">
                        <DataView
                            value={jobs}
                            layout={layout}
                            header={header}
                            itemTemplate={itemTemplate}
                            paginator
                            rows={15}
                            emptyMessage="CURRENTLY THERE ARE NO JOBS MATCHING YOUR SKILLS AS PER YOUR PROFILE"
                        />
                    </div>
                  }
                </div>



            }




        </div>
    );
}



const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.candidatematches.candidateallpermanentjobs,
    loading: state.candidatematches.loading,
})

export default connect(
  mapStateToProps,
  {
    getCandidatePermanentAllJobs,

} )
  (Jobs);

import React, { Component, Fragment } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';


export class Alerts extends Component {
  

  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.msg.name) alert.error(`Name: ${error.msg.name.join()}`);
      if (error.msg.non_field_errors) alert.error(error.msg.non_field_errors.join());
      if (error.msg.username) alert.error(`USERNAME: ${error.msg.username.join()}`);
      if (error.msg.email) alert.error(`Email: ${error.msg.email.join()}`);
      if (error.msg.first_name) alert.error(`Lirst Name: ${error.msg.first_name.join()}`);
      if (error.msg.last_name) alert.error(`Last Name: ${error.msg.last_name.join()}`);
      if (error.msg.gender) alert.error(`Gender: ${error.msg.gender.join()}`);
      if (error.msg.midle_name) alert.error(`midle Name: ${error.msg.midle_name.join()}`);
      if (error.msg.title) alert.error(`Title ${error.msg.title.join()}`);
      if (error.msg.description) alert.error(`Description ${error.msg.description.join()}`);
      if (error.msg.job) alert.error(`Job ${error.msg.job.join()}`);
      if (error.msg.Candidate) alert.error(`Candidate ${error.msg.Candidate.join()}`);
      if (error.msg.created_by) alert.error(`Created by ${error.msg.created_by.join()}`);
      if (error.msg.location) alert.error(`Location ${error.msg.location.join()}`);
      if (error.msg.confirmed_time) alert.error(`Confirmed time ${error.msg.confirmed_time.join()}`);
      if (error.msg.status) alert.error(`Status ${error.msg.status.join()}`);
      if (error.msg. request_reminders_sent) alert.error(` Request reminders sent ${error.msg. request_reminders_sent.join()}`);
      if (error.msg.confirmation_reminders_sent) alert.error(`confirmation reminders sent ${error.msg.confirmation_reminders_sent.join()}`);
      if (error.msg.is_active) alert.error(`Is active ${error.msg.is_active.join()}`);
      if (error.msg.result) alert.error(`Result ${error.msg.result.join()}`);
      if (error.msg.last_modified) alert.error(`Last modified ${error.msg.last_modified.join()}`);
      if (error.msg.confirmed_time) alert.error(`Confirmed time ${error.msg.confirmed_time.join()}`);
      if (error.msg.time_start) alert.error(`Time start ${error.msg.time_start.join()}`);
      if (error.msg.time_end) alert.error(`Time end ${error.msg.time_end.join()}`);
      if (error.msg.channel_created) alert.error(`Channel_created ${error.msg.channel_created.join()}`);
      if (error.msg.created_at) alert.error(`Created at ${error.msg.created_at.join()}`);
      if (error.msg.proffesion) alert.error(`Proffesion ${error.msg.proffesion.join()}`);
      if (error.msg.compensation_type) alert.error(`Compensation type ${error.msg.compensation_type.join()}`);
      if (error.msg.location) alert.error(`Location ${error.msg.location.join()}`);
      if (error.msg.fixed_price) alert.error(`Fixed price ${error.msg.fixed_price.join()}`);
      if (error.msg.from_hourly_price) alert.error(`From hourly price ${error.msg.from_hourly_price.join()}`);
      if (error.msg.to_hourly_price) alert.error(`To hourly price ${error.msg.to_hourly_price.join()}`);
      if (error.msg.duration_category) alert.error(`Duration category ${error.msg.duration_category.join()}`);
      if (error.msg.category) alert.error(`Category ${error.msg.category.join()}`);
      if (error.msg.how_to_apply) alert.error(`how to apply ${error.msg.how_to_apply.join()}`);
      if (error.msg.visibility_status) alert.error(`Visibility status ${error.msg.visibility_status.join()}`);
      if (error.msg.charge_category) alert.error(`Charge category ${error.msg.charge_category.join()}`);
      if (error.msg.expected_duration) alert.error(`Expected duration ${error.msg.expected_duration.join()}`);
      if (error.msg.time_posted) alert.error(`Time posted ${error.msg.time_posted.join()}`);if (error.msg.time_posted) alert.error(`Time posted ${error.msg.time_posted.join()}`);
      if (error.msg.required_skillset) alert.error(`Required skillset ${error.msg.required_skillset.join()}`);
      if (error.msg.Country) alert.error(`Country ${error.msg.Country.join()}`);
      if (error.msg.location_type) alert.error(`Location type ${error.msg.location_type.join()}`);
      if (error.msg.preffered_city) alert.error(`Preffered city ${error.msg.preffered_city.join()}`);
      if (error.msg.preffered_district) alert.error(`Preffered district ${error.msg.preffered_district.join()}`);
      if (error.msg.acceptance_status) alert.error(`Ecceptance status ${error.msg.acceptance_status.join()}`);
      if (error.msg.employer) alert.error(`Employer ${error.msg.employer.join()}`);
      if (error.msg.cover_letter) alert.error(`Cover lette ${error.msg.cover_lettertter.join()}`);
      if (error.msg.job_duration) alert.error(`Job duration ${error.msg.job_duration.join()}`);
      if (error.msg.qualification) alert.error(`Qualification ${error.msg.qualification.join()}`);
      if (error.msg.skill) alert.error(`Skill ${error.msg.skill.join()}`);
      if (error.msg.years_of_experience) alert.error(`Years of experience ${error.msg.years_of_experience.join()}`);
      if (error.msg.competency) alert.error(`Competency ${error.msg.competency.join()}`);
      if (error.msg.participants) alert.error(`Participants ${error.msg.participants.join()}`);
      if (error.msg.creator) alert.error(`Creator ${error.msg.creator.join()}`);
      if (error.msg.contact) alert.error(`Contact ${error.msg.contact.join()}`);
      if (error.msg.attachment) alert.error(`Attachment ${error.msg.attachment.join()}`);
      if (error.msg.author) alert.error(`Author ${error.msg.author.join()}`);
      if (error.msg.channel) alert.error(`Channel ${error.msg.channel.join()}`);
      if (error.msg.body) alert.error(`Body ${error.msg.body.join()}`);
      if (error.msg.abbreviation) alert.error(`Abbreviation ${error.msg.abbreviation.join()}`);
      if (error.msg.isocode) alert.error(`Isocode ${error.msg.isocode.join()}`);
      if (error.msg.phone_code) alert.error(`Phone code ${error.msg.phone_code.join()}`);
      if (error.msg.province) alert.error(`Province ${error.msg.province.join()}`);
      if (error.msg.availability) alert.error(`Availability ${error.msg.availability.join()}`);
      if (error.msg.availability_category) alert.error(`Availability category ${error.msg.availability_category.join()}`);
      if (error.msg.owner) alert.error(`Owner ${error.msg.owner.join()}`);
      if (error.msg.faculty) alert.error(`Faculty ${error.msg.faculty.join()}`);
      if (error.msg.level_of_education) alert.error(`Level of education ${error.msg.level_of_education.join()}`);
      if (error.msg.institution) alert.error(`Institution ${error.msg.institution.join()}`);
      if (error.msg.start_date) alert.error(`Start date ${error.msg.start_date.join()}`);
      if (error.msg.end_date) alert.error(`End date ${error.msg.end_date.join()}`);
      if (error.msg.proficiency) alert.error(`Proficiency ${error.msg.proficiency.join()}`);
      if (error.msg.language) alert.error(`Language ${error.msg.language.join()}`);
      if (error.msg.url) alert.error(`Url ${error.msg.url.join()}`);
      if (error.msg.country_of_citizenship) alert.error(`Country of citizenship ${error.msg.country_of_citizenship.join()}`);
      if (error.msg.country_of_residence) alert.error(`Country of residence ${error.msg.country_of_residence.join()}`);
      if (error.msg.occupation) alert.error(`Occupation ${error.msg.occupation.join()}`);
      if (error.msg.entity) alert.error(`Entity ${error.msg.entity.join()}`);
      if (error.msg.city) alert.error(`City ${error.msg.city.join()}`);
      if (error.msg.from_month) alert.error(`From month ${error.msg.from_month.join()}`);
      if (error.msg.to_month) alert.error(`To month ${error.msg.to_month.join()}`);
      if (error.msg.from_year) alert.error(`From year ${error.msg.from_year.join()}`);
      if (error.msg.to_year) alert.error(`To year ${error.msg.to_year.join()}`);
      if (error.msg.still_working_there) alert.error(`Still working there ${error.msg.still_working_there.join()}`);
      if (error.msg.years_of_experience) alert.error(`Years of experience ${error.msg.years_of_experience.join()}`);
      if (error.msg.active) alert.error(`Active ${error.msg.active.join()}`);
      if (error.msg.staff) alert.error(`Staff ${error.msg.staff.join()}`);
      if (error.msg.is_superuser) alert.error(`Is superuser ${error.msg.is_superuser.join()}`);
      if (error.msg.admin) alert.error(`Admin ${error.msg.admin.join()}`);
      if (error.msg.type) alert.error(`Type ${error.msg.type.join()}`);
      if (error.msg.username) alert.error(`Username ${error.msg.username.join()}`);
      if (error.msg.gender) alert.error(`Gender ${error.msg.gender.join()}`);
      if (error.msg.id_number) alert.error(`Id number ${error.msg.id_number.join()}`);
      if (error.msg.phone_number) alert.error(`Phone number ${error.msg.phone_number.join()}`);
      if (error.msg.whatsapp_number) alert.error(`Whatsapp number ${error.msg.whatsapp_number.join()}`);
      if (error.msg.is_published) alert.error(`Is published ${error.msg.is_published.join()}`);

    }

    
    if (message !== prevProps.message) {
      if (message.taxDeleted) alert.success(message.taxDeleted);
    }
  }

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default connect(mapStateToProps)(withAlert()(Alerts));
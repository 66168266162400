import React, { useEffect} from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Avatar } from 'primereact/avatar';
import { Calendar } from 'primereact/calendar';



const genderOptions = [
    {key: "MALE"},
    {key: "FEMALE"},
]



const availabilityCategoryOptions = [
    {
        key: 'I AM CURRENTLY AVALIABLE FOR A JOB',
        value : 'I AM CURRENTLY AVALIABLE FOR A JOB'
    },
    {
        key: 'I AM LOOKING FOR A ONCE OFF TECHNICAL JOB FOR SERVICES I OFFER',
        value : 'I AM LOOKING FOR A ONCE OFF TECHNICAL JOB FOR SERVICES I OFFER'
    },
    {
        key: 'I AM AVALIABLE FOR A PARTTIME JOB',
        value : 'I AM AVALIABLE FOR A PARTTIME JOB'
    },
    {
        key: 'I AM OPEN FOR OFFERS',
        value : 'I AM OPEN FOR OFFERS'
    },
    {
        key: 'I AM JUST LOOKING FOR INTERNATIONAL OFFERS',
        value : 'I AM JUST LOOKING FOR INTERNATIONAL OFFERS'
    },
    {
        key: 'I AM AVAILABLE FOR A REMOTE JOB',
        value : 'I AM AVAILABLE FOR A REMOTE JOB'
    },
    {
        key: 'I AM OPEN TO ANYTHING AS LONG AS WITHIN MY AREA OF EXPERTISE',
        value : 'I AM OPEN TO ANYTHING AS LONG AS WITHIN MY AREA OF EXPERTISE'
    },

]

const availabilityOptions = [
    {
        key: 'I AM CURRENTLY AVALIABLE FOR AN INTERVIEW',
        value: 'I AM CURRENTLY AVALIABLE FOR AN INTERVIEW'
    },
    {
        key: 'I AM CURRENTLY UNAVALIABLE FOR AN INTERVIEW',
        value: 'I AM CURRENTLY UNAVALIABLE FOR AN INTERVIEW'
    },
]


const Details = (props) => {
  const {
    first_name,
    last_name,
    onInputChange,
    setProceedCleared,
    country_of_citizenship,
    country_of_residence,
    province,
    district,
    description,
    availability,
    availability_category,
    phone_number,
    dob,
    whatsapp_number,
    profile_picture,
    handleFileRead,
    countries,
    cityOptions,
    districtOptions,
    gender,
    middle_name,
  } =props;





  useEffect(()=>{
    const canProceed = Boolean(first_name !== '') &&
                       Boolean(last_name !== '');
                       Boolean(country_of_citizenship!== '') &&
                       Boolean(country_of_residence!== '') &&
                       Boolean(province!== '') &&
                       Boolean(district!== '') &&
                       Boolean(description!== '') &&
                       Boolean(availability!== '') &&
                       Boolean(availability_category!== '') &&
                       Boolean(phone_number!== '') &&
                       Boolean(dob!== '') &&
                       Boolean(whatsapp_number!== '');
    if (canProceed){
      setProceedCleared(true);
    }

  }, [first_name, last_name, country_of_citizenship, country_of_residence, province, district, description, availability,availability_category,phone_number, dob, whatsapp_number])


  const monthNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
          />
        );
  };

  const yearNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
          />
      )
  }




  return (
      <ul  style={{textAlign: 'center'}} className="list-none card grid p-fluid p-0 m-0" >
        <li className="flex grid card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
            <div
                className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px', fontFamily: 'cursive'}}
            >
            </div>
            <div
                className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px', fontFamily: 'cursive'}}
            >
                <InputText
                    accept="image/*"
                    type="file"
                    name='profile_picture'
                    placeholder='PROFILE PICTURE'
                    onChange={(evt) => handleFileRead(evt)}
                />
            </div>
            <div
                className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px', fontFamily: 'cursive'}}
            >
                {profile_picture !=null
                    ?<Avatar
                        image={profile_picture}
                        className="mr-2"
                        size="xlarge"
                        shape="circle"
                    />
                    :<></>

                }

            </div>

        </li>



        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

            <div
                className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px', fontFamily: 'cursive'}}
            >

                FIRST NAME
            </div>
            <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <InputText
                  id="first_name"
                  type="text"
                  name="first_name"
                  className="w-full mb-3"
                  value={first_name}
                  onChange={(e) => onInputChange(e,"first_name")}

              />

            </div>
        </li>

        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

            <div
                className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px', fontFamily: 'cursive'}}
            >

                MIDDLE NAME
            </div>
            <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
            <InputText
                id="middle_name"
                type="text"
                name="middle_name"
                className="w-full mb-3"
                value={middle_name}
                onChange={(e) => onInputChange(e,"middle_name")}

            />
            </div>
        </li>
        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

            <div
                className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px', fontFamily: 'cursive'}}
            >

                LAST NAME
            </div>
            <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <InputText
                  id="last_name"
                  type="text"
                  name="last_name"
                  className="w-full mb-3"
                  value={last_name}
                  onChange={(e) => onInputChange(e,"last_name")}

              />
            </div>
        </li>


        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              DATE OF BIRTH
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <Calendar
                  id="navigatorstemplate"
                  showIcon
                  value={dob}
                  onChange={(e) => onInputChange(e,"dob")}
                  monthNavigator
                  yearNavigator
                  dateFormat="yy-mm-dd"
                  yearRange="1900:2030"
                  monthNavigatorTemplate={monthNavigatorTemplate}
                  yearNavigatorTemplate={yearNavigatorTemplate}
              />

          </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              GENDER
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
          <Dropdown
              value={gender}
              optionLabel="key"
              optionValue="key"
              options={genderOptions}
              onChange={(e) => onInputChange(e, 'gender')}
              filter

              filterBy="key"
          />

          </div>
      </li>

      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              COUNTRY OF CITIZENSHIP
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
          <Dropdown
              value={country_of_citizenship}
              optionLabel="name"
              optionValue="name"
              options={countries}
              onChange={(e) => onInputChange(e, 'country_of_citizenship')}
              filter

              filterBy="name"
          />

          </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              COUNTRY OF RESIDENCE
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
          <Dropdown
              value={country_of_residence}
              optionLabel="name"
              optionValue="name"
              options={countries}
              onChange={(e) => onInputChange(e, 'country_of_residence')}
              filter

              filterBy="name"
          />

          </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              PROVINCE
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
          <Dropdown
              value={province}
              optionLabel="name"
              optionValue="name"
              options={cityOptions}
              onChange={(e) => onInputChange(e, 'province')}
              filter

              filterBy="name"
          />

          </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              DISTRICT
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <Dropdown
              value={district}
              optionLabel="name"
              optionValue="name"
              options={districtOptions}
              onChange={(e) => onInputChange(e, 'district')}
              filter

              filterBy="name"
          />
          </div>
      </li>


      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              WHATSAPP NUMBER
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <InputText
                  id="whatsapp_number"
                  type="text"
                  name="whatsapp_number"
                  className="w-full mb-3"
                  value={whatsapp_number}
                  onChange={(e) => onInputChange(e,"whatsapp_number")}

              />

          </div>
      </li>
       <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              AVAILABILITY
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <Dropdown
                  value={availability_category}
                  optionLabel="key"
                  optionValue="key"
                  options={availabilityCategoryOptions}
                  onChange={(e) => onInputChange(e, 'availability_category')}
                  filter

                  filterBy="key"
              />

          </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              AVAILABILITY CATEGORY
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <Dropdown
                  value={availability}
                  optionLabel="key"
                  optionValue="key"
                  options={availabilityOptions}
                  onChange={(e) => onInputChange(e, 'availability')}
                  filter

                  filterBy="key"
              />

          </div>
      </li>
      <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

          <div
              className="w-full  md:w-4 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px', fontFamily: 'cursive'}}
          >

              DESCRIPTION
          </div>
          <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
              <InputTextarea
                  rows={5}
                  cols={30}
                  autoResize
                  value={description}
                  onChange={(e) => onInputChange(e, 'description')}
              />

          </div>
      </li>


    </ul>
  )
}

export default Details;

import {
    GET_MANAGEMENT_JOBS,
    GET_MANAGEMENT_JOB,
    ADD_MANAGEMENT_JOB,
    EDIT_MANAGEMENT_JOB,
    DELETE_MANAGEMENT_JOB,
    GET_MANAGEMENT_INTERVIEW_INVITATIONS,
    GET_MANAGEMENT_INTERVIEW_INVITATION,
    ADD_MANAGEMENT_INTERVIEW_INVITATION,
    EDIT_MANAGEMENT_INTERVIEW_INVITATION,
    DELETE_MANAGEMENT_INTERVIEW_INVITATION,
    GET_MANAGEMENT_JOB_PROPOSALS,
    GET_MANAGEMENT_JOB_PROPOSAL,
    GET_ADHOC_ENQUIRIES,
    GET_ADHOC_ENQUIRY,
    ADD_ADHOC_ENQUIRY,
    EDIT_ADHOC_ENQUIRY,
    DELETE_ADHOC_ENQUIRY,
    GET_JOB_REQUIREMENTS,
    GET_JOB_REQUIREMENT,
    ADD_JOB_REQUIREMENT,
    EDIT_JOB_REQUIREMENT,
    DELETE_JOB_REQUIREMENT,
    GET_JOB_SATISFACTION_QUESTIONS,
    GET_JOB_SATISFACTION_QUESTION,
    ADD_JOB_SATISFACTION_QUESTION,
    EDIT_JOB_SATISFACTION_QUESTION,
    DELETE_JOB_SATISFACTION_QUESTION,
    GET_INTERVIEW_INVITATIONS,
    GET_INTERVIEW_INVITATION,
    ADD_INTERVIEW_INVITATION,
    EDIT_INTERVIEW_INVITATION,
    DELETE_INTERVIEW_INVITATION,
    GET_JOB_PROPOSALS,
    GET_JOB_PROPOSAL,
    ADD_JOB_PROPOSAL,
    EDIT_JOB_PROPOSAL,
    DELETE_JOB_PROPOSAL,
    GET_CANDIDATE_JOBS,
    GET_CANDIDATE_JOB,
    GET_RECRUITER_JOBS,
    GET_RECRUITER_JOB,
    ADD_RECRUITER_JOB,
    EDIT_RECRUITER_JOB,
    DELETE_RECRUITER_JOB,
    MANAGEMENT_PUBLISH_JOB,
    MANAGEMENT_UNPUBLISH_JOB,
    RECRUITER_PUBLISH_JOB,
    RECRUITER_UNPUBLISH_JOB,
    REJECT_JOB_PROPOSAL,
    CALL_FOR_INTERVIEW,

    GET_PROFILE_RESUME,
    GET_PROFILE_AVAILABILITY,
    GET_PROFILE_ACADEMIC_QUALIFICATIONS,
    GET_PROFILE_LANGUAGES,
    GET_PROFILE_LINKS,
    GET_PROFILE_ROLES,

    GET_JOB_SKILLS,
    EDIT_JOB_SKILL,
    DELETE_JOB_SKILL,
    GET_JOB_QUALIFICATIONS,
    EDIT_JOB_QUALIFICATION,
    DELETE_JOB_QUALIFICATION,
    ADD_JOB_QUALIFICATION,
    ADD_JOB_SKILL,
    GET_JOB_LEVELS,
    EDIT_JOB_LEVEL,
    DELETE_JOB_LEVEL,
    ADD_JOB_LEVEL,
    GET_RECRUITER_PROPOSALS,
    GET_RECRUITER_PROPOSAL,
    GET_PROFILE_SKILLS

} from '../types/jobTypes';


const initialState = {
    recruiterjobs: [],
    recruiterjob: {},
    managementjobs: [],
    managementjob: {},
    candidatejobs: [],
    candidatejob: {},
    managementinterviewinvitations: [],
    managementinterviewinvitation: {},
    candidateinterviewinvitations: [],
    candidateinterviewinvitation: {},
    managementjobproposals: [],
    managementjobproposal: {},
    candidatejobproposals: [],
    candidatejobproposal: {},
    jobsatisfactionquestions: [],
    jobsatisfactionquestion: {},
    jobrequirements: [],
    jobrequirement: {},
    adhocenquiries: [],
    adhocenquiry: {},
    profileresume: {},
    profileavailability: {},
    profilequalifications: [],
    profilelangauges: [],
    profileskills: [],
    profilelinks: [],
    profileroles: [],
    jobqualifications: [],
    jobskills: [],
    joblevels: [],
    recruiterproposal: {},
    recruiterproposals: [],

};




export default function places(state = initialState, action){

    switch(action.type){
        case GET_PROFILE_RESUME:
            return {
                ...state,
                profileresume : action.payload
            };
        case GET_PROFILE_AVAILABILITY:
            return {
                ...state,
                profileavailability: action.payload
            };

        case GET_PROFILE_ACADEMIC_QUALIFICATIONS:
            return {
                ...state,
                profilequalifications: action.payload
            };

        case GET_PROFILE_LANGUAGES:
            return {
                ...state,
                profilelangauges: action.payload
            };

        case GET_PROFILE_LINKS:
            return {
                ...state,
                profilelinks: action.payload
            };
        case GET_PROFILE_SKILLS:
            return {
                ...state,
                profileskills: action.payload
            };

        case GET_PROFILE_ROLES:
            return {
                ...state,
                profileroles: action.payload
            };

        case REJECT_JOB_PROPOSAL:
            return {
                ...state,
                proposal: [...state.managementjobproposals, action.payload]
            }

        case CALL_FOR_INTERVIEW:
            return {
                ...state,
                proposal: [...state.managementjobproposals, action.payload]
            }

        case GET_RECRUITER_JOBS:
            return {
                ...state,
                recruiterjobs : action.payload
            };
        
        case GET_RECRUITER_JOB:
            return {
                ...state,
                recruiterjob : action.payload
            };

        case ADD_RECRUITER_JOB:
            return {
                ...state,
                recruiterjob: [...state.recruiterjobs, action.payload]
            }
        case RECRUITER_PUBLISH_JOB:
            return {
                ...state,
                recruiterjob: [...state.recruiterjobs, action.payload]
            }
        
        case RECRUITER_UNPUBLISH_JOB:
            return {
                ...state,
                recruiterjob: [...state.recruiterjobs, action.payload]
            }

        case EDIT_RECRUITER_JOB:
            const recruiterjobArray = state.recruiterjobs;
            recruiterjobArray.splice(
                    recruiterjobArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                recruiterjobs: recruiterjobArray,
            };
        
        case DELETE_RECRUITER_JOB:
            return {
                ...state,
                recruiterjob: state.recruiterjobs.filter(recruiterjob=> recruiterjob.slug !== action.payload)
            };

        //management jobs
        case GET_MANAGEMENT_JOBS:
            return {
                ...state,
                managementjobs : action.payload
            };
        
        case GET_MANAGEMENT_JOB:
            return {
                ...state,
                managementjob : action.payload
            };

        case ADD_MANAGEMENT_JOB:
            return {
                ...state,
                managementjob: [...state.managementjobs, action.payload]
            }

        case EDIT_MANAGEMENT_JOB:
            const managementjobArray = state.managementjobs;
            managementjobArray.splice(
                    managementjobArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                managementjobs: managementjobArray,
            };
        
        case DELETE_MANAGEMENT_JOB:
            return {
                ...state,
                managementjob: state.managementjobs.filter(managementjob=> managementjob.slug !== action.payload)
            };

        case MANAGEMENT_PUBLISH_JOB:
            return {
                ...state,
                managementjob: [...state.managementjobs, action.payload]
            }
        
        case MANAGEMENT_UNPUBLISH_JOB:
            return {
                ...state,
                managementjob: [...state.managementjobs, action.payload]
            }

        //candidate jobs
        case GET_CANDIDATE_JOBS:
            return {
                ...state,
                candidatejobs : action.payload
            };
        
        case GET_CANDIDATE_JOB:
            return {
                ...state,
                candidatejob : action.payload
            };


        //MANAGEMENT JOB PROPOSALS
        //management jobs
        case GET_MANAGEMENT_JOB_PROPOSALS:
            return {
                ...state,
                managementjobproposals : action.payload
            };
        
        case GET_MANAGEMENT_JOB_PROPOSAL:
            return {
                ...state,
                managementjobproposal : action.payload
            };


        //CANDIDATE JOB PROPOSALS

        case GET_JOB_PROPOSALS:
            return {
                ...state,
                candidatejobproposals : action.payload
            };
        
        case GET_JOB_PROPOSAL:
            return {
                ...state,
                candidatejobproposal : action.payload
            };

        case ADD_JOB_PROPOSAL:
            return {
                ...state,
                candidatejobproposal: [...state.candidatejobproposals, action.payload]
            }

        case EDIT_JOB_PROPOSAL:
            const candidatejobproposalArray = state.candidatejobproposals;
            candidatejobproposalArray.splice(
                    candidatejobproposalArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidatejobproposals: candidatejobproposalArray,
            };
        
        case DELETE_JOB_PROPOSAL:
            return {
                ...state,
                candidatejobproposal: state.candidatejobproposals.filter(candidatejobproposal=> candidatejobproposal.id !== action.payload)
            };

        //MANAGEMENT INTERVIEW INVITATION

        case GET_MANAGEMENT_INTERVIEW_INVITATIONS:
            return {
                ...state,
                managementinterviewinvitations : action.payload
            };
        
        case GET_MANAGEMENT_INTERVIEW_INVITATION:
            return {
                ...state,
                managementinterviewinvitation : action.payload
            };

        case ADD_MANAGEMENT_INTERVIEW_INVITATION:
            return {
                ...state,
                managementinterviewinvitation: [...state.managementinterviewinvitations, action.payload]
            }

        case EDIT_MANAGEMENT_INTERVIEW_INVITATION:
            const managementinterviewinvitationArray = state.managementinterviewinvitations;
            managementinterviewinvitationArray.splice(
                    managementinterviewinvitationArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                managementinterviewinvitations: managementinterviewinvitationArray,
            };
        
        case DELETE_MANAGEMENT_INTERVIEW_INVITATION:
            return {
                ...state,
                managementinterviewinvitation: state.managementinterviewinvitations.filter(managementinterviewinvitation=> managementinterviewinvitation.slug !== action.payload)
            };


        //CANDIDATE INTERVIEW INVITATIONS

        case GET_INTERVIEW_INVITATIONS:
            return {
                ...state,
                candidateinterviewinvitations : action.payload
            };
        
        case GET_INTERVIEW_INVITATION:
            return {
                ...state,
                candidateinterviewinvitation : action.payload
            };

        case ADD_INTERVIEW_INVITATION:
            return {
                ...state,
                candidateinterviewinvitation: [...state.candidateinterviewinvitations, action.payload]
            }

        case EDIT_INTERVIEW_INVITATION:
            const candidateinterviewinvitationArray = state.candidateinterviewinvitations;
            candidateinterviewinvitationArray.splice(
                    candidateinterviewinvitationArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidateinterviewinvitations: candidateinterviewinvitationArray,
            };
        
        case DELETE_INTERVIEW_INVITATION:
            return {
                ...state,
                candidateinterviewinvitation: state.candidateinterviewinvitations.filter(candidateinterviewinvitation=> candidateinterviewinvitation.slug !== action.payload)
            };


        //JOB REQUIREMENTS

        case GET_JOB_REQUIREMENTS:
            return {
                ...state,
                jobrequirements : action.payload
            };
        
        case GET_JOB_REQUIREMENT:
            return {
                ...state,
                jobrequirement : action.payload
            };

        case ADD_JOB_REQUIREMENT:
            return {
                ...state,
                jobrequirement: [...state.jobrequirements, action.payload]
            }

        case EDIT_JOB_REQUIREMENT:
            const jobrequirementArray = state.jobrequirements;
            jobrequirementArray.splice(
                    jobrequirementArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                jobrequirements: jobrequirementArray,
            };
        
        case DELETE_JOB_REQUIREMENT:
            return {
                ...state,
                jobrequirement: state.jobrequirements.filter(jobrequirement=> jobrequirement.slug !== action.payload)
            };


        //JOB SATISFACTION QUESTION

        case GET_JOB_SATISFACTION_QUESTIONS:
            return {
                ...state,
                jobsatisfactionquestions : action.payload
            };
        
        case GET_JOB_SATISFACTION_QUESTION:
            return {
                ...state,
                jobsatisfactionquestion : action.payload
            };

        case ADD_JOB_SATISFACTION_QUESTION:
            return {
                ...state,
                jobsatisfactionquestion: [...state.jobsatisfactionquestions, action.payload]
            }

        case EDIT_JOB_SATISFACTION_QUESTION:
            const jobsatisfactionquestionArray = state.jobsatisfactionquestions;
            jobsatisfactionquestionArray.splice(
                    jobsatisfactionquestionArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                jobsatisfactionquestions: jobsatisfactionquestionArray,
            };
        
        case DELETE_JOB_SATISFACTION_QUESTION:
            return {
                ...state,
                jobsatisfactionquestion: state.jobsatisfactionquestions.filter(jobsatisfactionquestion=> jobsatisfactionquestion.slug !== action.payload)
            };


        //ADHOC ENQUIRIES 
        case GET_ADHOC_ENQUIRIES:
            return {
                ...state,
                adhocenquiries : action.payload
            };
        
        case GET_ADHOC_ENQUIRY:
            return {
                ...state,
                adhocenquiry : action.payload
            };

        case ADD_ADHOC_ENQUIRY:
            return {
                ...state,
                adhocenquiry: [...state.adhocenquiries, action.payload]
            }

        case EDIT_ADHOC_ENQUIRY:
            const adhocenquiryArray = state.adhocenquiries;
            adhocenquiryArray.splice(
                    adhocenquiryArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                adhocenquiries: adhocenquiryArray,
            };
        
        case DELETE_ADHOC_ENQUIRY:
            return {
                ...state,
                adhocenquiry: state.adhocenquiries.filter(adhocenquiry=> adhocenquiry.slug !== action.payload)
            };

        //skills
        case GET_JOB_SKILLS:
            return {
                ...state,
                jobskills : action.payload
            };

        case ADD_JOB_SKILL:
            return {
                ...state,
                jobskill: [...state.jobskills, action.payload]
            }

        case DELETE_JOB_SKILL:
            return {
                ...state,
                jobskill: [...state.jobskills, action.payload]
            }

        case EDIT_JOB_SKILL:
            const jobskillArray = state.jobskills;
            jobskillArray.splice(
                    jobskillArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                jobskills: jobskillArray,
            };

        //qualifications
        
        case GET_JOB_QUALIFICATIONS:
            return {
                ...state,
                jobqualifications : action.payload
            };
        
        case ADD_JOB_QUALIFICATION:
            return {
                ...state,
                jobqualification: [...state.jobqualifications, action.payload]
            }

        case DELETE_JOB_QUALIFICATION:
            return {
                ...state,
                jobqualification: [...state.jobqualifications, action.payload]
            }

        case EDIT_JOB_QUALIFICATION:
            const jobqualificationArray = state.jobqualifications;
            jobqualificationArray.splice(
                    jobqualificationArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                jobqualifications: jobqualificationArray,
            };

        //QUALIFICATIONS
        case GET_JOB_LEVELS:
            return {
                ...state,
                joblevels : action.payload
            };
        
        case ADD_JOB_LEVEL:
            return {
                ...state,
                joblevel: [...state.joblevels, action.payload]
            }

        case DELETE_JOB_LEVEL:
            return {
                ...state,
                joblevel: [...state.joblevels, action.payload]
            }

        case EDIT_JOB_LEVEL:
            const joblevelArray = state.joblevels;
            joblevelArray.splice(
                    joblevelArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                joblevels: joblevelArray,
            };

        //recruiter proposals
        case GET_RECRUITER_PROPOSALS:
            return {
                ...state,
                recruiterproposals : action.payload
            };
        
        case GET_RECRUITER_PROPOSAL:
            return {
                ...state,
                recruiterproposal : action.payload
            };

        default:
            return state;
    }
}

import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import AOS from "aos";
import 'aos/dist/aos.css';
import PublicLayout from '../layout/PublicLayout';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import dumba from './dumba.jpg';

export const Portfolios = (props) => {

    const [layout, setLayout] = useState('grid');
    const navigate = useNavigate();
    const initialValues = [
        {
            slug: 1,
            name: 'Bash Aguero'
        },
        {
            slug: 2,
            name: 'Bash Gosso'
        },
        {
            slug: 3,
            name: 'Nkosikhona Moyo'
        },
        {
            slug: 4,
            name: 'Baridzi Moyo'
        },
        {
            slug: 6,
            name: 'Stanford Moyo'
        },
        {
            slug: 6,
            name: 'Dumbalinyolo Gosso'
        },
    ]

    useEffect(() =>{
        AOS.init({duration : 3000})

    }, []);


    const handleClick = (slug, url) =>{
        navigate(`/${slug}/${url}/`)
    }



    const renderGridItem = (data) => {
        return (
            
            <div
                data-aos="flip-left"
                className="col-12 md:col-3 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">  
                            <ul className="list-none job_link p-0 m-0">
                                <div className=" col-12 md:w-12 font-medium">
                                    <Avatar 
                                        image={dumba} 
                                        className="mr-2" 
                                        size="xlarge" 
                                        shape="circle" 
                                    />
                                </div>
                                           
                                
                            
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    
                                    <div className=" col-12 md:w-12 font-medium">
                                        {data.name}
                                    </div>        
                                </li>
                                
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Button 
                                            className='p-button-rounded p-button-warning p-button-outlined' 
                                            label='VIEW MORE DETAILS'
                                            onClick={() =>handleClick(data.name, "portfolio")}
                                        />
                                    </div>        
                                </li> 
                                        
                        </ul>
                        
                    </div>
                    </div>
            </div>


        );
    }

    const renderListItem = (data) => {
        return (
            
            <div
                data-aos="flip-left"
                className="col-12 md:col-3 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">  
                            <ul className="list-none job_link p-0 m-0">
                            
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        NAME
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.name}  className="mr-2 mb-2  " />
                                    </div>        
                                </li>
                                
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Button 
                                            className='p-button-rounded p-button-warning p-button-outlined' 
                                            label='VIEW MORE DETAILS'
                                            onClick={() =>handleClick(data.name, "portfolio")}
                                        />
                                    </div>        
                                </li> 
                                        
                        </ul>
                        
                    </div>
                    </div>
            </div>


        );
    }

    const itemTemplate = (user, layout) => {
        if (!user) {
            return;
        }

        if (layout === 'list')
            return renderListItem(user);
        else if (layout === 'grid')
            return renderGridItem(user);
    }

    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();

    return (
        <PublicLayout>
            <div className="card dataview-demo">
                <DataView
                    value={initialValues}
                    layout={layout}
                    header={header}
                    itemTemplate={itemTemplate}
                    paginator 
                    rows={30}
                    
                />
            </div>
        </PublicLayout>
    );
};



const mapStateToProps = (state) => ({
    token: state.auth.token,
})



export default connect(mapStateToProps, {})(Portfolios);
import React, {useEffect} from 'react';
import { Button } from 'primereact/button';
import zimjobs from './zimjobs.png';
import { useNavigate } from 'react-router-dom';
import { Chip } from 'primereact/chip';
import {connect} from 'react-redux';
import {
    getFaculties,

} from '../../actions/skills';
import { Accordion, AccordionTab } from 'primereact/accordion';


const AppFooter = (props) => {
    const navigate = useNavigate();


    useEffect(() =>{
        props.getFaculties(null);
    }, [])

    const filterTemplateHeaderTemplate = (name) => {
        return (
          <div style={{textAlign: 'center'}}>
              <div  className='text-primary'>{name}</div>
          </div>

        );
    }


    return (
        <div className="card">
            <div className=" px-4 py-8 md:px-6 lg:px-8">
                <div className="text-700 text-center">
                    <div className="text-blue-600 font-bold mb-3">
                        <img
                            style={{width: '100px'}}
                            src={zimjobs}
                            alt="logo"
                        />
                    </div>

                    <div className="text-primary font-bold mb-3"
                        
                    >
                        &nbsp;Join Our Amazing Community
                    </div>
                    <div className="">

                        <ul className="list-none p-0 m-0">
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="WHY?"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/why')}
                                    />
                                </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="ABOUT US"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/about')}
                                    />
                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="OUR COMMUNITY"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/why')}
                                    />

                                </div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="INDUSTRIES"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/industries')}
                                    />
                                </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="HOW TO"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/why')}
                                    />
                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="FIND JOBS"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/register-account')}
                                    />

                                </div>
                            </li>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="APPLY AS A FREELANCER"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/register-account')}
                                    />
                                </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="HIRE TOP TALENT"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/register-account')}
                                    />
                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <Button
                                        style={{fontSize : '10px', fontFamily: 'cursive', paddingBottom: '10px'}}
                                        label="LOGIN"
                                        type="button"
                                        className="mr-1 mb-3 p-button-outlined"
                                        onClick={()=> navigate('/login')}
                                    />

                                </div>
                            </li>

                              <Accordion activeIndex={1}>
                                <AccordionTab header={filterTemplateHeaderTemplate("INDUSTRIES")}>
                                  <li className="flex align-items-center card py-3 px-2 border-top-1 surface-border flex-wrap">
                                      {props.records.map((record) => {
                                          return(

                                                  <div key={record.slug}  className=" w-full col-12 md:w-3 font-medium">
                                                      <Chip style={{fontFamily: 'cursive', fontSize: '10px'}} label={record.title}  className="mr-2 mb-2 text-primary" />
                                                  </div>

                                              );

                                          })

                                      }
                                  </li>
                                </AccordionTab>
                            </Accordion>
                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-500 w-6 md:w-2 font-medium">
                                    <button className="p-link layout-topbar-button" >
                                        <i className="pi pi-facebook"/>
                                    </button>
                                </div>
                                <div className="text-900 w-full md:w-2 md:flex-order-0 flex-order-1">
                                    <button className="p-link layout-topbar-button" >
                                        <i className="pi pi-google"/>
                                    </button>
                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <button className="p-link layout-topbar-button" >
                                        <i className="pi pi-youtube"/>
                                    </button>

                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <button className="p-link layout-topbar-button" >
                                        <i className="pi pi-linkedin"/>
                                    </button>

                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <button className="p-link layout-topbar-button" >
                                        <i className="pi pi-twitter"/>
                                    </button>

                                </div>
                                <div className="w-6 md:w-2 flex justify-content-end">
                                    <button className="p-link layout-topbar-button" >
                                        <i className="pi pi-whatsapp"/>
                                    </button>

                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="text-primary font-bold mb-3" >
                        &nbsp;DEVELOPED @ SYLCOUTECH
                    </div>

                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state =>({
    records: state.skills.faculties,
})


export default connect(mapStateToProps, {getFaculties}) (AppFooter);

import axios from 'axios';
import {
    GET_MANAGEMENT_JOBS,
    GET_MANAGEMENT_JOB,
    ADD_MANAGEMENT_JOB,
    EDIT_MANAGEMENT_JOB,
    DELETE_MANAGEMENT_JOB,
    GET_MANAGEMENT_INTERVIEW_INVITATIONS,
    GET_MANAGEMENT_INTERVIEW_INVITATION,
    ADD_MANAGEMENT_INTERVIEW_INVITATION,
    EDIT_MANAGEMENT_INTERVIEW_INVITATION,
    DELETE_MANAGEMENT_INTERVIEW_INVITATION,
    GET_MANAGEMENT_JOB_PROPOSALS,
    GET_MANAGEMENT_JOB_PROPOSAL,
    ADD_MANAGEMENT_JOB_PROPOSAL,
    EDIT_MANAGEMENT_JOB_PROPOSAL,
    DELETE_MANAGEMENT_JOB_PROPOSAL,
    GET_ADHOC_ENQUIRIES,
    GET_ADHOC_ENQUIRY,
    ADD_ADHOC_ENQUIRY,
    EDIT_ADHOC_ENQUIRY,
    DELETE_ADHOC_ENQUIRY,
    GET_JOB_REQUIREMENTS,
    GET_JOB_REQUIREMENT,
    ADD_JOB_REQUIREMENT,
    EDIT_JOB_REQUIREMENT,
    DELETE_JOB_REQUIREMENT,

    GET_JOB_SATISFACTION_QUESTIONS,
    GET_JOB_SATISFACTION_QUESTION,
    ADD_JOB_SATISFACTION_QUESTION,
    EDIT_JOB_SATISFACTION_QUESTION,
    DELETE_JOB_SATISFACTION_QUESTION,

    GET_INTERVIEW_INVITATIONS,
    GET_INTERVIEW_INVITATION,
    ADD_INTERVIEW_INVITATION,
    EDIT_INTERVIEW_INVITATION,
    DELETE_INTERVIEW_INVITATION,
    GET_JOB_PROPOSALS,
    GET_JOB_PROPOSAL,
    ADD_JOB_PROPOSAL,
    EDIT_JOB_PROPOSAL,
    DELETE_JOB_PROPOSAL,
    GET_CANDIDATE_JOBS,
    GET_CANDIDATE_JOB,
    GET_RECRUITER_JOBS,
    GET_RECRUITER_JOB,
    ADD_RECRUITER_JOB,
    EDIT_RECRUITER_JOB,
    DELETE_RECRUITER_JOB,
    MANAGEMENT_PUBLISH_JOB,
    MANAGEMENT_UNPUBLISH_JOB,
    RECRUITER_PUBLISH_JOB,
    RECRUITER_UNPUBLISH_JOB,
    REJECT_JOB_PROPOSAL,
    CALL_FOR_INTERVIEW,
    GET_PROFILE_RESUME,
    GET_PROFILE_AVAILABILITY,
    GET_PROFILE_ACADEMIC_QUALIFICATIONS,
    GET_PROFILE_LANGUAGES,
    GET_PROFILE_LINKS,
    GET_PROFILE_ROLES,
    GET_JOB_SKILLS,
    EDIT_JOB_SKILL,
    DELETE_JOB_SKILL,
    GET_JOB_QUALIFICATIONS,
    EDIT_JOB_QUALIFICATION,
    DELETE_JOB_QUALIFICATION,
    ADD_JOB_QUALIFICATION,
    ADD_JOB_SKILL,
    GET_JOB_LEVELS,
    EDIT_JOB_LEVEL,
    DELETE_JOB_LEVEL,
    ADD_JOB_LEVEL,
    GET_RECRUITER_PROPOSALS,
    GET_RECRUITER_PROPOSAL,
    GET_PROFILE_SKILLS,

} from '../types/jobTypes';
import {
    managementinterviewinvitationsURL,
    managementjobsURL,
    managementjobproposalsURL,
    adhocenquiriesURL,
    jobrequirementsURL,
    jobsatisfactionquestionsURL,
    candidateinterviewinvitationsURL,
    candidatejobproposalsURL,
    candidatejobsURL,
    recruiterjobsURL,
    jobskillsURL,
    jobqualificationsURL,
    joblevelsURL,
    recruiterproposalsURL,

} from '../constants';
import { returnErrors } from './messages';


export const getProfileResume = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/resume/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_RESUME,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const getProfileAvailability = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/availability/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_AVAILABILITY,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const getProfileAcademicQualifications = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/academic_qualifications/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_ACADEMIC_QUALIFICATIONS,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const getProfileSkills = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/skills/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_SKILLS,
                payload: res.data
            });
        }).catch(err => {
          console.log(err)
        });

}


export const getProfileLanguages = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/languages/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_LANGUAGES,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}



export const getProfileLinks = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/links/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_LINKS,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
    });

}


export const getProfileRoles = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.get(`${managementjobproposalsURL}${slug}/roles/`, headers)
        .then(res => {
            dispatch({
                type:GET_PROFILE_ROLES,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
    });

}



export const rejectJobProposal = (slug, proposal, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${managementjobproposalsURL}${slug}/reject/`, proposal, headers)
        .then(res => {
            dispatch({
                type:REJECT_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const callForJobInterview = (slug, proposal, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${managementjobproposalsURL}${slug}/call_for_interview/`, proposal, headers)
        .then(res => {
            dispatch({
                type:CALL_FOR_INTERVIEW,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}

export const publishManagementJob = (slug, job, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${managementjobsURL}${slug}/publish/`, job, headers)
        .then(res => {
            dispatch({
                type:MANAGEMENT_PUBLISH_JOB,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const unpublishManagementJob = (slug, job, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${managementjobsURL}${slug}/unpublish/`, job, headers)
        .then(res => {
            dispatch({
                type:MANAGEMENT_UNPUBLISH_JOB,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const publishRecruiterJob = (slug, job, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${recruiterjobsURL}${slug}/publish/`, job, headers)
        .then(res => {
            dispatch({
                type:RECRUITER_PUBLISH_JOB,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const unpublishRecruiterJob = (slug, job, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(`${recruiterjobsURL}${slug}/unpublish/`, job, headers)
        .then(res => {
            dispatch({
                type:RECRUITER_UNPUBLISH_JOB,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}



export const getManagementInterviewInvitations = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(managementinterviewinvitationsURL, headers)
        .then(res => {
            dispatch({
                type: GET_MANAGEMENT_INTERVIEW_INVITATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getManagementInterviewInvitation = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${managementinterviewinvitationsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_MANAGEMENT_INTERVIEW_INVITATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addManagementInterviewInvitation = (interviewinvitation, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(managementinterviewinvitationsURL, interviewinvitation, headers)
        .then(res => {
            dispatch({
                type: ADD_MANAGEMENT_INTERVIEW_INVITATION,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteManagementInterviewInvitation = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(managementinterviewinvitationsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_MANAGEMENT_INTERVIEW_INVITATION,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editManagementInterviewInvitation = (slug, interviewinvitation, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${managementinterviewinvitationsURL}${slug}/`, interviewinvitation, headers)
        .then(res => {
            dispatch({
                type: EDIT_MANAGEMENT_INTERVIEW_INVITATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}





export const getManagementJobs = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(managementjobsURL, headers)
        .then(res => {
            dispatch({
                type: GET_MANAGEMENT_JOBS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getManagementJob = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${managementjobsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_MANAGEMENT_JOB,
                payload: res.data
            });
        }).catch(err => console.log(err))
}





export const addManagementJob = (job, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(managementjobsURL, job, headers)
        .then(res => {
            dispatch({
                type: ADD_MANAGEMENT_JOB,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteManagementJob = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(managementjobsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_MANAGEMENT_JOB,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editManagementJob = (slug, job, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${managementjobsURL}${slug}/`, job, headers)
        .then(res => {
            dispatch({
                type: EDIT_MANAGEMENT_JOB,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getManagementJobProposals = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${managementjobproposalsURL}`, headers)
        .then(res => {
            dispatch({
                type: GET_MANAGEMENT_JOB_PROPOSALS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getManagementJobProposal = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${managementjobproposalsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_MANAGEMENT_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}





export const addManagementJobProposal = (proposal, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(managementjobproposalsURL, proposal, headers)
        .then(res => {
            dispatch({
                type: ADD_MANAGEMENT_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteManagementJobProposal = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(managementjobproposalsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_MANAGEMENT_JOB_PROPOSAL,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editManagementJobProposal = (slug, job, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${managementjobproposalsURL}${slug}/`, job, headers)
        .then(res => {
            dispatch({
                type: EDIT_MANAGEMENT_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//adhocs


export const getAdhocEnquiries = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${adhocenquiriesURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_ADHOC_ENQUIRIES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getAdhocEnquiry = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${adhocenquiriesURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_ADHOC_ENQUIRY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}





export const addAdhocEnquiry = (adhoc, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(adhocenquiriesURL, adhoc, headers)
        .then(res => {
            dispatch({
                type: ADD_ADHOC_ENQUIRY,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteAdhocEnquiry = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(adhocenquiriesURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_ADHOC_ENQUIRY,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editAdhocEnquiry = (slug, adhoc, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${adhocenquiriesURL}${slug}/`, adhoc, headers)
        .then(res => {
            dispatch({
                type: EDIT_ADHOC_ENQUIRY,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



//job requirements


export const getJobRequirements = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobrequirementsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_REQUIREMENTS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getJobRequirement = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobrequirementsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_REQUIREMENT,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addJobRequirement = (requirement, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(jobrequirementsURL, requirement, headers)
        .then(res => {
            dispatch({
                type: ADD_JOB_REQUIREMENT,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteJobRequirement = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(jobrequirementsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_JOB_REQUIREMENT,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editJobRequirement = (slug, requirement, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${jobrequirementsURL}${slug}/`, requirement, headers)
        .then(res => {
            dispatch({
                type: EDIT_JOB_REQUIREMENT,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



//JOB SATISFACTION

export const getJobSatisfactionQuestions = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobsatisfactionquestionsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_SATISFACTION_QUESTIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getJobSatisfactionQuestion = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobsatisfactionquestionsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_SATISFACTION_QUESTION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addJobSatisfactionQuestion = (requirement, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(jobsatisfactionquestionsURL, requirement, headers)
        .then(res => {
            dispatch({
                type: ADD_JOB_SATISFACTION_QUESTION,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteJobSatisfactionQuestion = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(jobsatisfactionquestionsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_JOB_SATISFACTION_QUESTION,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editJobSatisfactionQuestion = (slug, requirement, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${jobsatisfactionquestionsURL}${slug}/`, requirement, headers)
        .then(res => {
            dispatch({
                type: EDIT_JOB_SATISFACTION_QUESTION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}




//INTERVIEW INVITATIONS


export const getCandidateInterviewInvitations = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidateinterviewinvitationsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_INTERVIEW_INVITATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getCandidateInterviewInvitation = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidateinterviewinvitationsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_INTERVIEW_INVITATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateInterviewInvitation = (requirement, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidateinterviewinvitationsURL, requirement, headers)
        .then(res => {
            dispatch({
                type: ADD_INTERVIEW_INVITATION,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteCandidateInterviewInvitation = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(candidateinterviewinvitationsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_INTERVIEW_INVITATION,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editCandidateInterviewInvitation = (slug, requirement, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidateinterviewinvitationsURL}${slug}/`, requirement, headers)
        .then(res => {
            dispatch({
                type: EDIT_INTERVIEW_INVITATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}




//CANDIDATE JOB PROPOSAL


export const getCandidateJobProposals = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidatejobproposalsURL}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_PROPOSALS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getCandidateJobProposal = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidatejobproposalsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addCandidateJobProposal = (requirement, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(candidatejobproposalsURL, requirement, headers)
        .then(res => {
            dispatch({
                type: ADD_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteCandidateJobProposal = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(candidatejobproposalsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_JOB_PROPOSAL,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editCandidateJobProposal = (slug, requirement, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${candidatejobproposalsURL}${slug}/`, requirement, headers)
        .then(res => {
            dispatch({
                type: EDIT_JOB_PROPOSAL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



//CANDIDATE JOBS


export const getCandidateJobs = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(candidatejobsURL, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_JOBS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getCandidateJob = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${candidatejobsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_CANDIDATE_JOB,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



// RECRUITER JOBS


export const getRecruiterJobs = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(recruiterjobsURL, headers)
        .then(res => {
            dispatch({
                type: GET_RECRUITER_JOBS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getRecruiterJob = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${recruiterjobsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_RECRUITER_JOB,
                payload: res.data
            });
        }).catch(err => console.log(err))
}





export const addRecruiterJob = (job, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(recruiterjobsURL, job, headers)
        .then(res => {
            dispatch({
                type: ADD_RECRUITER_JOB,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const deleteRecruiterJob = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(recruiterjobsURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_RECRUITER_JOB,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editRecruiterJob = (slug, job, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${recruiterjobsURL}${slug}/`, job, headers)
        .then(res => {
            dispatch({
                type: EDIT_RECRUITER_JOB,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//SKILLS

export const getJobSkills = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobskillsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_SKILLS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const addJobSkill = (skill, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(jobskillsURL, skill, headers)
        .then(res => {
            dispatch({
                type: ADD_JOB_SKILL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}



export const deleteJobSkill = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${jobskillsURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_JOB_SKILL,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editJobSkill = (slug, skill, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${jobskillsURL}${slug}/`, skill, headers)
        .then(res => {
            dispatch({
                type: EDIT_JOB_SKILL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//QUALIFICATIONS
export const getJobQualifications = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${jobqualificationsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_QUALIFICATIONS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const addJobQualification = (qualification, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(jobqualificationsURL, qualification, headers)
        .then(res => {
            dispatch({
                type: ADD_JOB_QUALIFICATION,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}




export const deleteJobQualification = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${jobqualificationsURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_JOB_QUALIFICATION,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editJobQualification = (slug, qualification, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${jobqualificationsURL}${slug}/`, qualification, headers)
        .then(res => {
            dispatch({
                type: EDIT_JOB_QUALIFICATION,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


//LEVELS
export const getJobLevels = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${joblevelsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_JOB_LEVELS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const addJobLevel = (level, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(joblevelsURL, level, headers)
        .then(res => {
            dispatch({
                type: ADD_JOB_LEVEL,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}




export const deleteJobLevel = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(`${joblevelsURL}${slug}/delete/`, headers)
        .then(res => {
            dispatch({
                type: DELETE_JOB_LEVEL,
                payload: slug
            });
        }).catch(err => console.log(err))
}



export const editJobLevel = (slug, level, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${joblevelsURL}${slug}/`, level, headers)
        .then(res => {
            dispatch({
                type: EDIT_JOB_LEVEL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const getRecruiterProposals = (params, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${recruiterproposalsURL}`,{params}, headers)
        .then(res => {
            dispatch({
                type: GET_RECRUITER_PROPOSALS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}


export const getRecruiterProposal = (slug, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${recruiterproposalsURL}${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_RECRUITER_PROPOSAL,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

import React, { useEffect } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {connect} from 'react-redux';
import {getFaqs} from '../../actions/faqs';
import AOS from "aos";
import 'aos/dist/aos.css';


export const FrequentlyAskedQuestions = (props) => {

    useEffect(() =>{
        if(!props.fetched){
            props.getFaqs()
        }

    }, [])

    useEffect(() =>{
        AOS.init({duration : 4000})

      }, []);


    const filterTemplateHeaderTemplate = (name) => {
          return (
            <div style={{textAlign: 'center'}}>
                <div className='text-primary'>{name}</div>
            </div>

          );
      }



    return (
        <div className="accordion-demo grid" data-aos="zoom-out">
            <div className="col-12 md:col-1">

            </div>
            <div
                className="card col-12 md:col-12"
                data-aos="zoom-in"
                style={{textAlign: 'center', fontFamily: 'cursive'}}
            >
                <h5
                    className="text-primary font-bold mb-3"
                    data-aos="zoom-in"
                >
                    FREQUENTLY ASKED QUESTIONS
                </h5>
                <Accordion activeIndex={0}>
                    {props.faqs.map((faq) =>{
                            return(
                                <AccordionTab data-aos="flip-left" className="text-primary font-bold" key={faq.slug} header={filterTemplateHeaderTemplate(faq.title)}>
                                    <p className="text-primary font-bold"
                                      style={{textAlign: 'center', fontFamily: 'cursive'}}
                                    >
                                        {faq.description}
                                    </p>
                                </AccordionTab>

                            );
                        })
                    }
                </Accordion>
            </div>
            <div className="col-12 md:col-1">

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    faqs: state.faqs.faqs,

});


export default connect(
    mapStateToProps,
    {getFaqs},

    )
(FrequentlyAskedQuestions);

import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import ManagementLayout from '../layout/ManagementLayout';
import {connect} from 'react-redux';
import {
    getContinents, 
    addContinent,
    editContinent,
    
} from '../../actions/places';
import { InputText } from 'primereact/inputtext';
import { Chip } from 'primereact/chip';
import AOS from "aos";
import 'aos/dist/aos.css';



const Continents = (props) => {
    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    let initialValues = {
        name: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const sortOptions = [
        {label: 'Price High to Low', value: '!price'},
        {label: 'Price Low to High', value: 'price'},
    ];
    
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    


    useEffect(() => {
        if (!props.fetched){
            props.getContinents(props.token);
        }
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        props.getContinents(props.token);
        setIsSubmit(false);

    }, [isSubmit])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>{
        AOS.init({duration : 1000})
    
      }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();
        let _record = {...record};
        if (record.id) {
            if (validate()){
                setIsSubmit(true);
                props.editContinent(record.id, record, props.token);
                props.getContinents(props.token);
                resetForm();
                setDisplayMaximizable(false);

        }


        }
        else {
            if (validate()){
                setIsSubmit(true);
                console.log(errors)
                props.addContinent(record, props.token);
                props.getContinents(props.token);
                resetForm();
                setDisplayMaximizable(false);
        }
        }
        
    }

    
    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "NAME IS REQUIRED."
        setErrors({
            ...temp
        })
    
        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const editContinentButton = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }


    

    const renderListItem = (data) => {
        return (

            <div className="col-12 flex-grow-1 card border-50 border-round " style={{padding: '20px'}}>
                <ul  className="box shadow-8 list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                    <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                        <div className=" col-12 md:w-12 font-medium">
                            NAME
                        </div>
                        <div className=" col-12 md:w-12 font-medium">
                            <Chip label={data.name}  className="mr-2 mb-2  " />
                        </div>        
                    </li>
                    <li>
                        <div className='grid p-fluid'>
                            <div className="col-12 md:col-2">
                                <Button
                                    className="p-button-text p-button-warning"
                                    icon="pi pi-pencil"
                                    onClick={() => editContinentButton(data)}
                                />
                            </div>
                        </div>
                    </li>

                    
                </ul>

                
            </div>
        );
    }

    const renderGridItem = (data) => {
        return (
            <div data-aos="flip-left" className="col-12 flex-grow-1 md:col-4 card border-50 box border-round shadow-8" style={{padding: '20px'}}>
                <div data-aos="zoom-in" >
                <ul data-aos="fade-down" className="box shadow-8 list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                    <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                        <div className=" col-12 md:w-12 font-medium">
                            NAME
                        </div>
                        <div className=" col-12 md:w-12 font-medium">
                            <Chip label={data.name}  className="mr-2 mb-2  " />
                        </div>        
                    </li>
                    
                    <li>
                        <div className='grid p-fluid'>
                            <div className="col-12 md:col-2">
                                <Button
                                    className="p-button-text p-button-warning"
                                    icon="pi pi-pencil"
                                    onClick={() => editContinentButton(data)}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
                </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }

    const renderHeader = () => {
        return (
            <div data-aos="zoom-in" className="grid grid-nogutter" style={{fontFamily: 'cursive'}}>
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>
                    <Button
                        style={{fontFamily: 'cursive'}}
                        label="ADD NEW"
                        className="p-button-primary "
                        onClick={() => onClick('displayMaximizable')}
                    />
                </div>
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>
                    <DataViewLayoutOptions 
                        layout={layout} 
                        onChange={(e) => setLayout(e.value)} 
                    />
                </div>
            </div>
        );
    }


    const header = renderHeader();

    return (
        <ManagementLayout>
            <div className="dataview-demo" style={{fontFamily: 'cursive'}}>
                <div className="card">
                    <DataView 
                        value={props.records} 
                        layout={layout} 
                        header={header}
                        itemTemplate={itemTemplate} 
                        paginator rows={9}
                        sortOrder={sortOrder} 
                        sortField={sortField} 
                    />
                </div>
            </div>
            <Dialog
                header="CONTINENT FORM" 
                visible={displayMaximizable} 
                maximizable modal 
                style={{ width: '50vw' }} 
                onHide={() => onHide('displayMaximizable')}
            >
                    <div className="card" style={{fontFamily: 'cursive'}}>
                        <form onSubmit={onSubmit}>
                        <ul data-aos="flip-left"  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                                <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        ENTER CONTINENT
                                    </div>
                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="name"
                                        value={record.name}
                                        tooltip="Choose Name"
                                        onChange={(e) => onInputChange(e, 'name')}

                                    /> 
                                    <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.name}</small> 
                                    </div>
                                </li>
                                
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                                    <Button
                                        style={{fontFamily: 'cursive'}} 
                                        type="submit" 
                                        label="ADD CONTINENT" 
                                        className="p-button-outlined p-button-warning"
                                        
                                    />
                                </li>
                            </ul>

                        </form>    
                    </div>
                    
            </Dialog>
        </ManagementLayout>
    );
}



const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.places.continents,
})

export default connect(
  mapStateToProps,
  {getContinents, addContinent, editContinent} )
  (Continents);
   
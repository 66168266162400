import React, {useEffect, useState} from 'react';
import PublicLayout from '../layout/PublicLayout';
import feature2 from './images/feature-2.png';
import effective from "./images/illustrations/monitor.svg";
import { TabView, TabPanel } from 'primereact/tabview';
import team from "./images/illustrations/modern_professional.svg";
import communication from "./images/illustrations/social_share.svg";
import role from './images/illustrations/process.svg';
import power from './images/illustrations/powerful.svg';
import tree_swing from './images/illustrations/tree_swing.svg';
import presentation_blocks from './images/illustrations/presentation-blocks.svg';
import feat1 from './images/feat1.svg';
import feat2 from './images/feat2.svg';
import feat3 from './images/feat3.svg';
import feat4 from './images/feat4.svg';
import { Button } from 'primereact/button';
import AOS from "aos";
import 'aos/dist/aos.css';
import { RadioButton } from 'primereact/radiobutton';
import zimjobs from '../layout/zimjobs.png';
import bracelet from './bracelet.jpg';


const Why =() => {
  const [whyUser, setWhyUser] = useState("RECRUITER");
  const [howUser, setHowUser] = useState("RECRUITER");
  const [whatUser, setWhatUser] = useState("RECRUITER")

  const onWhyUserChange = (e) => {
      setWhyUser(e.value);
  }

  const onHowUserChange = (e) => {
      setHowUser(e.value);
  }

  const shuffle =(array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
}

const generateRandomPicture = (numberOfPictures) =>{
    const result = [];
    const sequence = [
        feat1,
        feat2,
        feat3,
        feat4,

    ];

    for(let i = 1; i < numberOfPictures; i++){
          result.push(shuffle(sequence))
      }

      return(result[0][0]);
  }



  useEffect(() =>{
    AOS.init({duration : 3000})

  }, []);

  const getWhyDisplay = () =>{
    if (whyUser ==="RECRUITER"){
      return(
        <div
          className="px-4 py-8 md:px-6 lg:px-8 overflow-hidden"
          

        >
          <div className="flex lg:justify-content-center mb-5">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={effective} alt="ImageS"
              className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}
              >
                1
              </span>
              <div className="h-full bg-blue-500" style={{width: '2px', minHeight: '4rem'}}>
            </div>
          </div>
          <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Reliability & Flexibility
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Although we hope you work with the provided talent long-term,
              you can scale your Hletipang team up or down within a matter of weeks,
              not months or years.
              We have worked with companies to build their initial teams,
              fill positions for specific projects, and expand team capabilities
              during “crunch-times”.
            </span>
            <div className="pt-3 border-top-1 border-300">
              <div className="mb-2 line-height-3">
                <span className="text-900 text-primary font-medium">
                  As you transition between these company milestones,
                </span>
                  you may also transition any Jobseeker to a part-time role or stop using their
                   services
                  just as quickly as you started using them.
              </div>
              <div className="line-height-3">
                <span className="text-900 text-primary font-medium">
                  We try to make this process
                </span>
                  as agile as your business.
              </div>
            </div>
            <img
              src={effective} alt="ImageS"
              className="w-full mt-3 block lg:hidden"/>
          </div>
        </div>
        <div className="flex justify-content-center mb-5">
          <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
             Quick Team Ramp-Up
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Hletipang Jobseeksers are well-vetted for specific Industrial Requirements.
            </span>
            <div className="pt-3 border-top-1 border-300">
              <div className="mb-2 line-height-3">
                <span className="text-900 text-primary font-medium">
                    They have the ability to smoothly
                </span>
                  and quickly adapt to those used
              </div>
              <div className="line-height-3">
                <span className="text-900 text-primary font-medium">
                  by your company or any task —even if they’re obscure.
                </span>

              </div>
            </div>
            <img src={team} alt="Images"
            className="w-full mt-3 block lg:hidden"/>
          </div>
          <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
            <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
              style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
            >
              2
            </span>
            <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
            </div>
          </div>
          <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
            <img src={team} alt="Images" className="w-full mr-8"/>
          </div>
        </div>
        <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={communication} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                3
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
                Painless Communication
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Our talent have proven experience in their functional areas and can
              convey complex concepts clearly to all stakeholders.
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={communication} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
          <div className="flex justify-content-center mb-5">
            <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
              <div className="text-900 text-primary text-xl mb-2 font-medium">
                Role-Specific Quality
                </div>
                <span className="block text-700 text-primary line-height-3 mb-3">
                  The vetted candidates have a tried and tested track record of brilliance.
                </span>
                <div className="pt-3 border-top-1 border-300">
                  <div className="mb-2 line-height-3">
                    <span className="text-900 text-primary font-medium">
                      We match your company with talent that have extensive
                    </span>
                      experience relevant to
                  </div>
                  <div className="line-height-3">
                    <span className="text-900 text-primary font-medium">
                      the role you’re trying to fill.
                    </span>

                  </div>
                </div>
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={role} alt="Images" className="w-full mr-8"/>
                </div>
                className="w-full mt-3 block lg:hidden"/>
              </div>
              <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
                  style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
                >
                  4
                </span>
                <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
                </div>
              </div>
              <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={role} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
          </div>
          <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={power} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                5
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Overall Quality
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Beyond their brilliant technical capabilities,
              our candidates are also screened on their soft skills including attitude,
              ethical values, energy, education, and Communication proficiency.
              We want to ensure they’re amazing teammates, not just amazing workers.
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={power} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
          <div className="flex justify-content-center mb-5">
            <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
              <div className="text-900 text-primary text-xl mb-2 font-medium">
                  No-Risk Trial
                </div>
                <span className="block text-700 text-primary line-height-3 mb-3">
                  Every time you work with a new recruitee,
                  you will have a trial period to decide whether they
                  meet your needs. If you don’t find them to be extraordinary,
                  you will not be charged (and you can even keep any work completed
                    during the trial).
                </span>
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={tree_swing} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
              <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
                  style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
                >
                  6
                </span>
                <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
                </div>
              </div>
              <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={tree_swing} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
          </div>
          <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={presentation_blocks} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                7
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Team Value
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Company value — especially for start-ups — is largely impacted by
              your ability to build an amazing team and product. Unfortunately,
              putting together a great team to build a great product takes
              substantial amounts of time and money. Whether a recruitee is
              hired to provide in-house teams with more bandwidth or to extend
              the team’s capabilities, they enable your company to build your
              team and product faster.
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={presentation_blocks} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
        </div>
      );
    }else if(whyUser ==="FREELANCER"){
      return(
        <div className="px-4 py-8 md:px-6 lg:px-8 overflow-hidden"
          
        >
          <div className="flex lg:justify-content-center mb-5">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={effective} alt="ImageS"
              className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}
              >
                1
              </span>
              <div className="h-full bg-blue-500" style={{width: '2px', minHeight: '4rem'}}>
            </div>
          </div>
          <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Visibility
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              We Offer you with an opportunity to signup with us and advertise your Industrial Skills
              to a pool of our Recruiters and 1000s of people visiting our site daily to hire technicians
              for jobs
            </span>
            <div className="pt-3 border-top-1 border-300">
              <div className="mb-2 line-height-3">
                <span className="text-900 text-primary font-medium">
                  Why?<br/>
                </span>
                <span className="text-900 text-primary font-medium">
                  Most freelancers find themselves having to create portfolio websites as means of advertising
                  their skills, which might be effective, but yet have shortcomings when it comes to being exposed
                  to opportunities
                </span>
              </div>
              <div className="line-height-3">
                <span className="text-900 text-primary font-medium">
                  We take away that burden from your shoulders, advertise your portfolio and match you
                  with the best jobs possibly
                </span>

              </div>
            </div>
            <img
              src={effective} alt="ImageS"
              className="w-full mt-3 block lg:hidden"/>
          </div>
        </div>
        <div className="flex justify-content-center mb-5">
          <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
             Freedom
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              We give you the platform and the freedom to be in control of your work schedule and in some
              instances you may be not interested in provided opportunities for reasons known to you
              and you may opt to turn down the opportunity
            </span>

            <img src={team} alt="Images"
            className="w-full mt-3 block lg:hidden"/>
          </div>
          <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
            <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
              style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
            >
              2
            </span>
            <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
            </div>
          </div>
          <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
            <img src={team} alt="Images" className="w-full mr-8"/>
          </div>
        </div>
        <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={communication} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                3
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
                Working from a Place of your Convinience
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Working from a place of you prefferance is an ideal solution for balancing work and
              family or private life,
               during which you can successfully make for a living and support yourself and your
               family. We offer you remote job opportunities, only if you are willing to work hard.
               The obvious advantages of such opportunities is that if you are responsible enough
               you can take as many as you can as long as you are  responsible and able to deleiver
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={communication} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
          <div className="flex justify-content-center mb-5">
            <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
              <div className="text-900 text-primary text-xl mb-2 font-medium">
                  Excellent Opportunity for Advancement
                </div>
                <span className="block text-700 text-primary line-height-3 mb-3">
                    If you are hardworking, smart and have the knowledge and skills, our opportunities
                    quickly help you advance both professionally and financially. We
                    offer you with great opportunities, to take your career to the next big level.
                </span>
                <div className="pt-3 border-top-1 border-300">
                  <div className="mb-2 line-height-3">
                    <span className="text-900 text-primary font-medium">
                      We match your talent with extensive contractors based on your qualifications,
                      skills, portfolio and services
                    </span>
                  </div>
                  <div className="line-height-3">


                  </div>
                </div>
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={role} alt="Images" className="w-full mr-8"/>
                </div>
                className="w-full mt-3 block lg:hidden"/>
              </div>
              <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
                  style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
                >
                  4
                </span>
                <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
                </div>
              </div>
              <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={role} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
          </div>
          <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={power} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                5
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Overall Quality
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Beyond their brilliant technical capabilities,
              our candidates are also screened on their soft skills including attitude,
              ethical values, energy, education, and Communication proficiency.
              We want to ensure they’re amazing teammates, not just amazing workers.
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={power} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
          <div className="flex justify-content-center mb-5">
            <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
              <div className="text-900 text-primary text-xl mb-2 font-medium">
                  Learning through Work
                </div>
                <span className="block text-700 text-primary line-height-3 mb-3">
                  Do not think about work like at a company where you work 20 years the same thing.
                  As a freelancer with Hletipang you will change jobs and employers on a
                  weekly basis, and many more you will learn what will be useful for future jobs.
                </span>
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={tree_swing} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
              <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
                  style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
                >
                  6
                </span>
                <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
                </div>
              </div>
              <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={tree_swing} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
          </div>
          <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={presentation_blocks} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                7
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Opportunity for all
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
            We increase employment of vulnerable groups such as mothers and fathers with
            young children, people with mobility problems and people living in remote areas.

            Perhaps one of the biggest advantages of our jobs is that you can take
            a vacation whenever you want and of course if you want you can work on holidays
             while this is not the case with the office work.

            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={presentation_blocks} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
        </div>
      );
    }else if(whyUser ==="JOBSEEKER"){
      return(
        <div className="px-4 py-8 md:px-6 lg:px-8 overflow-hidden"
          
        >
          <div className="flex lg:justify-content-center mb-5">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={effective} alt="ImageS"
              className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}
              >
                1
              </span>
              <div className="h-full bg-blue-500" style={{width: '2px', minHeight: '4rem'}}>
            </div>
          </div>
          <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Visibility
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              We Offer you with an opportunity to signup with us and advertise your Industrial Skills
              to a pool of our Recruiters and 1000s of people visiting our site daily to hire technicians
              for jobs
            </span>
            <div className="pt-3 border-top-1 border-300">

            </div>
            <img
              src={effective} alt="ImageS"
              className="w-full mt-3 block lg:hidden"/>
          </div>
        </div>
        <div className="flex justify-content-center mb-5">
          <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
               Professional Development and Training Opportunities
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Having to go through multiple recruitment opportunities often means that you
              have the opportunity
              to take part in professional development and training opportunities it provides.
              Many of these opportunities we provide have a continuing education program in
              which you are offered  courses, earn certifications and
              undergo other kinds of training related to your field.

            </span>

            <img src={team} alt="Images"
            className="w-full mt-3 block lg:hidden"/>
          </div>
          <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
            <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
              style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
            >
              2
            </span>
            <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
            </div>
          </div>
          <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
            <img src={team} alt="Images" className="w-full mr-8"/>
          </div>
        </div>
        <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={communication} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                3
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
                Hustle Free
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              This site cuts the man in the middle proccess. Once you have followed all the steps
              to create a profile and vetted. Our AI will always match you with opportunities that
              created everyday and allow you to make applications and you shall be vetted based on
              your ability to present yourself as an ideal candidate to the job, rather than knowing
              someone, greasing some hands or even being exploited in any way that degrades you as a
              person
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={communication} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
          <div className="flex justify-content-center mb-5">
            <div className="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
              <div className="text-900 text-primary text-xl mb-2 font-medium">
                  Excellent Opportunity for Advancement
                </div>
                <span className="block text-700 text-primary line-height-3 mb-3">
                    If you are hardworking, smart and have the knowledge and skills, our opportunities
                    quickly help you advance both professionally and financially. We
                    offer you with great opportunities, to take your career to the next big level.
                </span>
                <div className="pt-3 border-top-1 border-300">
                  <div className="mb-2 line-height-3">
                    <span className="text-900 text-primary font-medium">
                      We match your talent with extensive contractors based on your qualifications,
                      skills, portfolio and services
                    </span>
                  </div>
                  <div className="line-height-3">


                  </div>
                </div>
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={role} alt="Images" className="w-full mr-8"/>
                </div>
                className="w-full mt-3 block lg:hidden"/>
              </div>
              <div className="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
                <span className="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle"
                  style={{minWidth: '2.5rem', minHeight: "2.5rem"}}
                >
                  4
                </span>
                <div className="h-full bg-yellow-500" style={{width: '2px', minHeight: '4rem'}}>
                </div>
              </div>
              <div className="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
                <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
                  <img src={role} alt="Images" className="w-full mr-8"/>
                </div>
              </div>
          </div>
          <div className="flex justify-content-center">
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={power} alt="Images" className="w-full mr-8"/>
            </div>
            <div className="flex flex-column align-items-center w-2rem">
              <span className="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle"
                style={{minWidth: '2.5rem', minHeight: '2.5rem'}}>
                5
              </span>
              <div className="h-full bg-cyan-500" style={{width: '2px', minHeight: "4rem"}}>
              </div>
            </div>
            <div className="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div className="text-900 text-primary text-xl mb-2 font-medium">
              Overall Quality
            </div>
            <span className="block text-700 text-primary line-height-3 mb-3">
              Beyond their brilliant technical capabilities,
              our candidates are also screened on their soft skills including attitude,
              ethical values, energy, education, and Communication proficiency.
              We want to ensure that you are an amazing teammate, not just amazing worker.
            </span>
            <div data-aos="zoom-in" className="py-3 pr-8 pl-3 w-30rem hidden lg:block">
              <img src={power} alt="Images" className="w-full mr-8"/>
            </div>
            </div>
          </div>
        </div>

      );
    }
  }

  const getHowDisplay = () =>{
    if (howUser ==="RECRUITER"){
      return(
        <div data-aos="zoom-in" className="grid p-fluid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4">
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                  Tell us what kind of Talent you need
              </div>
              <span className="text-700 text-primary line-height-3">
                What kind of talent do you need?
                <br/>
                What’s your tech stack?

                <br/>
                  What specific skills do you need?
                <br/>

                Submit a job to let us know what you need—the more details the better.
                Whether it’s a single freelancer or a cross-functional team of ten,
                Hletipang can handle it. After we get the job description, our internal
                team of experts will review it and connect with you in order to answer
                any questions and to get an even better understanding of your exact needs.
              </span>
          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                We’ll find you the Perfect Match
            </div>
            <span className="text-700 text-primary line-height-3">
                We'll notify you with a status report concerning your request within a
                few days.

                We have someone in our network who is ready to work with you immediately.
                There is a candidate being evaluated who matches your qualifications,
                and we will notify you upon the completion of the screening process.
                We currently do not have a match for your request, so our team will
                work tirelessly to find one.
            </span>


          </div>
          <div data-aos="zoom-in" style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
              They become part of your Team
            </div>
            <span className="text-700 text-primary line-height-3">
                We’ll introduce you to your Recruitees, and they’ll ramp up and start
                working as soon as you say go.

                As soon as you review and sign-off on our recommended freelancer(s),
                they’ll be ready to integrate into your team—just like an in-house
                employee.
                 While Hletipang talent usually work remotely, arrangements can be made
                 to have those who are required to work onsite and be permanent employees.
            </span>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Work with them at No-Risk
            </div>
            <span className="text-700 text-primary line-height-3">
              If you're not 100% satisfied after a trial working with a Recruitee,
              we'll start the process all over again at absolutely no cost.

              Our no-risk trial period lets companies work with our talent first before
              deciding if they’re 100% confident in moving forward. If, for whatever
              reason,
              you are not completely satisfied with our talent you have been paired with,
              you will not be liable for any payment, and we can either part
               ways or we will
              restart the entire cycle with you at absolutely no cost.
            </span>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Recruit For Yourself
            </div>
            <span className="text-700 text-primary line-height-3">
              Our System helps match you with the recruitees matching your needs based on the specified
              required skills and qualifications. We make it possible for you to recruit people you may
              be interested in as you have an opportunity to do an onsite interview and request for
              demonstrations
            </span>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Sign Up
            </div>
            <span className="text-700 text-primary line-height-3">
              Sign Up as a Recruiter and then you shall get an Account Activattion Email. After clicking it it shall
              take to a Hletipang Login Page where you shall login and access your Dashboard. In the Dashboard
              you shall have access to your reports, jobs you create and published, applications against your jobs,
              industries and profiles of recruitees matching Industries we offer prospective candidates from
            </span>


          </div>

      </div>
      );
    }else if(howUser ==="FREELANCER"){
      return(
        <div data-aos="zoom-in" className="grid p-fluid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
          <div data-aos="zoom-in" style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4">
            <div className='card'>
                <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
                <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                    Account Setup
                </div>
                <span className="text-700 text-primary line-height-3">

                  Signup as a Freelancer and you shall receive an email for you to activate your account.
                  Upon Activation of your Account you shall be taken to your Dashboard. In the Dashboard
                  you shall see Profile. Update your profile by adding your Academic Qualifications, Skills,
                  Portfolio, Services, Links, Experiences, Langauges & Privacy Policy
                </span>
            </div>
          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4" >
            <div className='card'>
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                  Messages
              </div>
              <span className="text-700 text-primary line-height-3">
                  Messages appear as a result of you being invited for an interview after successfully
                  submitting an application to a job you have taken an interest in. Each job has its own chat room
                  and upon the recruiter having chosen his/her preffered candidate/s for the job a job is closed and
                  and so is the chatroom
              </span>
            </div>
          </div>
          <div data-aos="zoom-in" style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4" >
            <div className='card'>
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Jobs
              </div>
              <span className="text-700 text-primary line-height-3">
                Best Matches
                <br/>
                These are the Best Matches Based on the Qualifications, Skills, Portfolio, Langauges,
                Links, Services/Products, Experiences you added and a verified from your Profile. Our Artificial
                Intelligence always matches you with the jobs you are likely to win and you can always filter them
                & upon clicking VIEW MORE & APPLY you can send an application with with what you are suppose to
                write specified in the form
                <br/>
                  Nearest Neighbours
                <br/>
                These are the Matches Based on a second level of your score plus Best Matches, based the Qualifications, Skills, Portfolio, Langauges,
                Links, Services/Products, Experiences you added and a verified from your Profile. Our Artificial
                Intelligence always matches you with the jobs you are likely to win and you can always filter them
                & upon clicking VIEW MORE & APPLY you can send an application with with what you are suppose to
                write specified in the form
                <br/>
                  All Jobs
                <br/>
                These are all jobs that are currently active, not based the Qualifications, Skills, Portfolio, Langauges,
                Links, Services/Products, Experiences you added and a verified from your Profile.
                You can always apply but make sure you understand the job requirements first
                <br/>
                  Applied For Jobs
                <br/>
                  Those are the Jobs you made applications for

                <br/>



              </span>
            </div>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4" >
            <div className='card'>
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                  Reports
              </div>
              <span className="text-700 text-primary line-height-3">
                These are your Reports Based on the Applications you have made, those you have won,
                those that are pending decision, those you did not win and those on Interview

              </span>
            </div>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4" >
            <div className='card'>
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                  My Proposals
              </div>
              <span className="text-700 text-primary line-height-3">
                Those are the Applications you have made using the Hletipang Recruitment Platform Based on their status
              </span>
            </div>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4" >
            <div className='card'>
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                  Opportunities
              </div>
              <span className="text-700 text-primary line-height-3">
                Our opportunities are two fold. There are those the systems grades you and you are notified whenever
                a new job is created in the system and those you can always follow up and make Applications.

              </span>
            </div>


          </div>

      </div>
      );
    }else if(howUser ==="JOBSEEKER"){
      return(
        <div data-aos="zoom-in" className="grid p-fluid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
          <div data-aos="zoom-in" style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 shadow-8 md:col-4">
            <div className='card'>
              <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
              <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                  Account Setup
              </div>
              <span className="text-700 text-primary line-height-3">

                Signup as a Freelancer and you shall receive an email for you to activate your account.
                Upon Activation of your Account you shall be taken to your Dashboard. In the Dashboard
                you shall see Profile. Update your profile by adding your Academic Qualifications, Skills,
                Portfolio, Services, Links, Experiences, Langauges & Privacy Policy
              </span>
            </div>
          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Messages
            </div>
            <span className="text-700 text-primary line-height-3">
                Messages appear as a result of you being invited for an interview after successfully
                submitting an application to a job you have taken an interest in. Each job has its own chat room
                and upon the recruiter having chosen his/her preffered candidate/s for the job a job is closed and
                and so is the chatroom
            </span>
          </div>
          <div data-aos="zoom-in" style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
              Jobs
            </div>
            <span className="text-700 text-primary line-height-3">
              Best Matches
              <br/>
              These are the Best Matches Based on the Qualifications, Skills, Portfolio, Langauges,
              Links, Services/Products, Experiences you added and a verified from your Profile. Our Artificial
              Intelligence always matches you with the jobs you are likely to win and you can always filter them
              & upon clicking VIEW MORE & APPLY you can send an application with with what you are suppose to
              write specified in the form
              <br/>
                Nearest Neighbours
              <br/>
              These are the Matches Based on a second level of your score plus Best Matches, based the Qualifications, Skills, Portfolio, Langauges,
              Links, Services/Products, Experiences you added and a verified from your Profile. Our Artificial
              Intelligence always matches you with the jobs you are likely to win and you can always filter them
              & upon clicking VIEW MORE & APPLY you can send an application with with what you are suppose to
              write specified in the form
              <br/>
                All Jobs
              <br/>
              These are all jobs that are currently active, not based the Qualifications, Skills, Portfolio, Langauges,
              Links, Services/Products, Experiences you added and a verified from your Profile.
              You can always apply but make sure you understand the job requirements first
              <br/>
                Applied For Jobs
              <br/>
                Those are the Jobs you made applications for

              <br/>



            </span>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Reports
            </div>
            <span className="text-700 text-primary line-height-3">
              These are your Reports Based on the Applications you have made, those you have won,
              those that are pending decision, those you did not win and those on Interview

            </span>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                My Proposals
            </div>
            <span className="text-700 text-primary line-height-3">
              Those are the Applications you have made using the Hletipang Recruitment Platform Based on their status
            </span>


          </div>
          <div style={{border: '1px solid #9CCBE7'}} data-aos="zoom-in" className="col-12 card shadow-8 md:col-4" >
            <img src={generateRandomPicture(4)} alt="ImageS" className="w-full"/>
            <div className="mt-3 mb-2 font-medium text-900 text-primary text-xl">
                Opportunities
            </div>
            <span className="text-700 text-primary line-height-3">
              Our opportunities are two fold. There are those the systems grades you and you are notified whenever
              a new job is created in the system and those you can always follow up and make Applications.

            </span>


          </div>

      </div>
      );
    }
  }


  const getWhatDisplay = () =>{
    if (whatUser ==="RECRUITER"){
      return(
        <>what RECRUITER</>
      );
    }else if(whatUser ==="FREELANCER"){
      return(
          <>what FREELANCER</>
      );
    }else if(whatUser ==="JOBSEEKER"){
      return(
          <>what JOBSEEKER</>
      );
    }
  }



  return (
    <PublicLayout>

        <div className="">
          <div data-aos="zoom-in" className="card px-4 py-8 md:px-6 lg:px-9">
            <div className="grid">
                <div className="md:col-6">
                  <img src={feature2} alt="Images" className="w-full"/>
                </div>
                <div className="col-12 md:col-6 md:pl-6" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                <div
                  className="text-primary font-bold mb-3"
                  
                >
                  <i className="pi pi-check text-green-600 mr-3">
                    </i>
                  &nbsp;POWERED BY ARTFICIAL INTELLIGENCE
                </div>
                <div
                  className="text-primary font-bold mb-3"
                  
                >
                  <i className="pi pi-check text-green-600 mr-3">
                    </i>
                  &nbsp;REDIFINED POSSIBILITES
                </div>
                  <div  className="text-700 text-primary text-primary mb-5 line-height-3 mb-5">
                    Many companies — both large and small & people looking for technicians be it for their business or
                    household tasks — face challenges with finding
                    top talent, from candidate qualifications, to team dynamics,
                    to economics that fit their financial scale. Our unique solution
                    for helping you hire elite independent contractors  and people looking for permanent jobs,
                    addresses all of these concerns.
                  </div>
                  <div
                    className="text-primary font-bold mb-3"
                    
                  >
                    <i className="pi pi-check text-green-600 mr-3">
                    </i>
                    &nbsp;ACCESS TO TOP TALENT
                  </div>
                  <div
                    className="text-primary font-bold mb-3"
                    
                  >
                    <i className="pi pi-check text-green-600 mr-3">
                    </i>
                    &nbsp;ACCESS TO REMOTE JOBS
                  </div>
                  <div
                    className="text-primary font-bold mb-3"
                    
                  >
                    <i className="pi pi-check text-green-600 mr-3">
                    </i>
                    &nbsp;EFFICIENT MATCHING ALGORITHMS
                  </div>
                  <div
                    className="text-primary font-bold mb-3"
                    
                  >
                    <i className="pi pi-check text-green-600 mr-3">
                    </i>
                    &nbsp;ACCESS TO GLOBAL JOB OPPORTUNITIES
                  </div>
                  <div
                    className="text-primary font-bold mb-3"
                    
                  >
                    <i className="pi pi-check text-green-600 mr-3">
                    </i>
                    &nbsp;MINIMIZE HR HEADACHES
                  </div>
              </div>
          </div>
        </div>
        <div data-aos="zoom-in" style={{textAlign: 'center', fontFamily: 'cursive'}} className="card">
            <TabView >
              <TabPanel className="flex-1" header='WHY'>
                <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                    <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                      <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                        <div className=" col-12 md:w-4 font-medium">
                          <RadioButton
                              inputId="category1"
                              style={{paddingRight: '20px'}}
                              name="category"
                              value="RECRUITER"
                              onChange={onWhyUserChange}
                              checked={whyUser === "RECRUITER"}
                            />
                          <label className="text-primary" htmlFor="category1">
                            NEED TO RECRUIT
                          </label>
                        </div>
                        <div className=" col-12 md:w-4 font-medium">
                          <RadioButton
                              inputId="category1"
                              style={{paddingRight: '20px'}}
                              name="category"
                              value="FREELANCER"
                              onChange={onWhyUserChange}
                              checked={whyUser === "FREELANCER"}
                            />
                          <label className="text-primary" htmlFor="category1">
                            NEED TO FREELANCE
                          </label>
                        </div>
                        <div className=" col-12 md:w-4 font-medium">
                          <RadioButton
                              inputId="category1"
                              style={{paddingRight: '20px'}}
                              name="category"
                              value="JOBSEEKER"
                              onChange={onWhyUserChange}
                              checked={whyUser === "JOBSEEKER"}
                            />
                          <label className="text-primary" htmlFor="category1">
                            LOOKING FOR A JOB
                          </label>
                        </div>
                      </li>
                    </ul>
                </div>

                {getWhyDisplay()}


              </TabPanel>
              <TabPanel className="flex-1" header='HOW'>
                <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                    <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                      <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                        <div className=" col-12 md:w-4 font-medium">
                          <RadioButton
                              inputId="category1"
                              style={{paddingRight: '20px'}}
                              name="category"
                              value="RECRUITER"
                              onChange={onHowUserChange}
                              checked={howUser === "RECRUITER"}
                            />
                          <label className="text-primary" htmlFor="category1">
                            NEED TO RECRUIT
                          </label>
                        </div>
                        <div className=" col-12 md:w-4 font-medium">
                          <RadioButton
                              inputId="category1"
                              style={{paddingRight: '20px'}}
                              name="category"
                              value="FREELANCER"
                              onChange={onHowUserChange}
                              checked={howUser === "FREELANCER"}
                            />
                          <label className="text-primary" htmlFor="category1">
                            NEED TO FREELANCE
                          </label>
                        </div>
                        <div className=" col-12 md:w-4 font-medium">
                          <RadioButton
                              inputId="category1"
                              style={{paddingRight: '20px'}}
                              name="category"
                              value="JOBSEEKER"
                              onChange={onHowUserChange}
                              checked={howUser === "JOBSEEKER"}
                            />
                          <label className="text-primary" htmlFor="category1">
                            LOOKING FOR A JOB
                          </label>
                        </div>
                      </li>
                    </ul>
                </div>

                {getHowDisplay()}

              </TabPanel>
              <TabPanel className="flex-1" header='WHAT'>
                <div data-aos="zoom-in" className='card  shadow-8' style={{paddingTop: '30px'}}>
                  <div className="block-contentss">
                      <div>
                          <div>
                            <div
                              style={{height: '200px', backgroundImage: `url(${bracelet}`}}
                            >
                            </div>
                            <div className="px-4 py-5 md:px-6 lg:px-8">
                              <div className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative" style={{marginTop: '-2rem', top: '-70px', marginBottom: '-70px'}}>
                                <div>
                                  <div
                                    className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
                                    style={{width: "140px", height: "140px", borderRadius: "10px"}}
                                  >
                                    <img
                                        src={zimjobs}
                                        alt="ImageS"
                                        style={{width: "70px",  height: "70px"}}

                                    />

                                  </div>
                                    <div className="text-900 text-primary text-3xl font-medium text-primary mb-3">

                                    </div>
                                  <p className="mt-0 mb-3 text-700 text-primary text-primary text-xl">

                                    </p>
                                  <div className="text-600 font-medium text-primary">
                                    <span>

                                      </span>
                                    </div>
                                  </div>
                                  <div className="mt-3 lg:mt-0">
                                    <button className="p-button p-component p-button-outlined mr-2">
                                      <span className="p-button-icon p-c p-button-icon-left pi pi-plus-circle">
                                      </span>
                                      <span className="p-button-label p-c">
                                        Follow
                                      </span>
                                      <span className="p-ink" style={{height: '96px', width: '96px', top: '-24px', left: "1.125px"}}>
                                      </span>
                                    </button>
                                    <button className="p-button p-component mr-2">
                                      <span className="p-button-icon p-c p-button-icon-left pi pi-link">
                                      </span>
                                      <span className="p-button-label p-c">
                                        Website
                                      </span>
                                      <span className="p-ink">
                                        </span>
                                      </button>
                                      <button className="p-button p-component p-button-outlined p-button-rounded p-button-icon-only">
                                        <span className="p-button-icon p-c pi pi-ellipsis-v">
                                        </span>
                                        <span className="p-button-label p-c">
                                          &nbsp;
                                        </span>
                                        <span className="p-ink"
                                          style={{height: '38px', width: '38px', top: '-4.29688px', left: "-0.296875px"}}>
                                        </span>
                                      </button>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                </div>
                <div clasName="grid p-fluid flex"
                  style={{paddingBottom: '30px', paddingLeft: '60px', paddingRight: '60px', marginTop: '-5rem', top: '-90px'}}
                >

                  <div
                    data-aos="zoom-in"
                    className="card  col-12 md:col-12"
                    style={{textAlign: 'center', fontFamily: 'cursive'}}

                  >

                      <div className="text-500 text-primary mb-5">
                        Hletipang is an exclusive network of the top freelancers, jobseekers,
                        and recruiters from various places in the world.
                        Top companies hire Hletipang Freelancers & Jobseeksers for their most important projects.
                      </div>
                      <ul className="list-none p-0 m-0 border-top-1 border-300">


                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                          <div className="text-500 w-full md:w-2 font-medium text-primary">
                            Who uses Hletipang
                          </div>
                          <div className="text-900 text-primary w-full md:w-10">
                            <span className="p-tag p-component p-tag-rounded mr-2">
                              <span className="p-tag-value">
                                RECRUITERS
                              </span>
                              <span>
                              </span>
                            </span>
                            <span className="p-tag p-component p-tag-success p-tag-rounded mr-2">
                              <span className="p-tag-value">
                              FREELANCERS
                            </span>
                            <span>
                            </span>
                            </span>
                            <span className="p-tag p-component p-tag-danger p-tag-rounded mr-2">
                            <span className="p-tag-value">
                              JOBSEEKERS
                            </span>
                            <span>
                            </span>
                            </span>
                            <span className="p-tag p-component p-tag-warning p-tag-rounded">
                              <span className="p-tag-value">
                                INTERNS
                              </span>
                              <span>
                              </span>
                            </span>
                          </div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                          <div className="text-500 w-full md:w-2 font-medium text-primary">
                            WITH HLETIPANG
                          </div>
                          <div className="text-900 text-primary w-full md:w-10">
                            <div className="grid mt-0 mr-0">
                              <div className="col-12 md:col-6">
                                <div className="p-3 border-1 border-300 border-round card">
                                  <div className="text-900 text-primary mb-2">
                                    <i className="pi pi-users text-primary mr-2">
                                    </i>
                                    <span className="font-medium text-primary">
                                      RECRUITERS
                                    </span>
                                  </div>
                                  <div className="text-700 text-primary text-primary">
                                    GET ACCESS TO OUR TOP TALENT
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 md:col-6 ">
                                <div className="p-3 border-1 border-300 border-round card">
                                  <div className="text-900 text-primary mb-2">
                                    <i className="pi pi-users text-primary mr-2">
                                    </i>
                                    <span className="font-medium text-primary">
                                      FREELANCERS
                                    </span>
                                  </div>
                                  <div className="text-700 text-primary text-primary">
                                    AN OPPORTUNITY TO ADVERTISE YOUR SKILLS
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 md:col-6 ">
                                <div className="p-3 border-1 border-300 border-round card">
                                <div className="text-900 text-primary mb-2">
                                  <i className="pi pi-users text-primary mr-2"></i>
                                <span className="font-medium text-primary">
                                  JOB SEEKERS
                                </span>
                              </div>
                              <div className="text-700 text-primary text-primary">
                                GAIN ACCESS TO JOB OPPORTUNITIES
                              </div>
                            </div>
                          </div>
                          <div className="col-12 md:col-6 ">
                            <div className="p-3 border-1 border-300 border-round card">
                              <div className="text-900 text-primary mb-2">
                                <i className="pi pi-users text-primary mr-2"></i>
                                  <span className="font-medium text-primary">
                                    INTERNS
                                  </span>
                              </div>
                              <div className="text-700 text-primary text-primary">
                                FIND HUSTLE FREE INTERNSHIP
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </TabPanel>

          </TabView>
        </div>
      </div>
    </PublicLayout>
  )
}

export default Why;

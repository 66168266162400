import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { InputText } from 'primereact/inputtext';
import {
  validateEmail,
} from '../../../actions/validations';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';


const Email = (props) => {
  const {
    email,
    onInputChange,
    emailValidation,
    emailClear,
    setEmailClear,
    setProceedCleared,
  } =props;
  const [checking, setChecking] = useState(false);
  const [payload, setPayload] = useState({});
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (email !== ''){
      const timer = setTimeout(() => {
        props.validateEmail(email)
        setChecking(true);
        setEmailClear(false);
      }, 3000)

      return () => clearTimeout(timer)
    }



  }, [email])


  useEffect(() =>{
    setChecking(false);
    if (emailValidation.feedback !==undefined){
      setPayload(
        {
          error: emailValidation.error,
          feedback: emailValidation.feedback,
          unique_pass: emailValidation.unique_pass,
        }
      )
      if (!emailValidation.error && emailValidation.unique_pass){
        setProceedCleared(true)
      }
    }else{
      setPayload({

      })

    }

  }, [emailValidation])


  const displayMessage = (payload) =>{
    if (payload.error==true){
      return (
        <>
            <small
              id="username-help"
              className="p-error">
                {payload.feedback}
            </small>
        </>
      );


    }else if(!payload.error){
      if (payload.unique_pass){
        return (
            <small
              id="username-help"
              style={{color: 'green'}}
              className="p-info">
                Email is Authentice. Proceed..
            </small>
        );
      }else if (payload.unique_pass ==false){
        return (
          <small
            id="username-help"
            className="p-error">
              Email is Taken...
          </small>
        )
      }else{
        return;
      }
    }else{
      return;
    }
  }


  return (
      <ul  style={{textAlign: 'center'}} className="list-none card grid p-fluid p-0 m-0" >

        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
          <div
              className="w-full mb-3 grid  md:w-12 md:flex-order-0 flex-order-1"
              style={{fontSize: '15px'}}
          >

            <div
                className="w-full mb-3 col-12 md:w-12 md:flex-order-0 flex-order-1"
                style={{textAlign: 'center'}}
            >
              {checking
                ?<div className="col-12 md:col-4">
                      <ProgressSpinner
                        style={{width: '50px', height: '50px'}}
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                      Checking...
                  </div>
                :<>
                  {!emailClear
                    ?<>{displayMessage(payload)}</>
                    :<></>
                  }

                </>
              }
            </div>
          </div>
            <div
                className="w-full mb-3 md:w-12 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px'}}
            >
                EMAIL
            </div>


            <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
              <InputText
                  id="email"
                  type="email"
                  name="email"
                  className="w-full mb-3"
                  value={email}
                  onChange={(e) => onInputChange(e,"email")}

              />
            </div>
        </li>
    </ul>


  )
}

const mapStateToProps = state =>({
  emailValidation: state.validations.emailValidation,
  loading: state.validations.loading,
})

export default connect(mapStateToProps, {validateEmail}) (Email);

import React, { useEffect } from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import ManagementLayout from '../layout/ManagementLayout';
import {getJobSeekerExperiences} from '../../actions/candidates';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Chip } from 'primereact/chip';



const Experiences = (props) => {

  const {records, token} = props;
  const {slug} = useParams();




    useEffect(() => {
          props.getJobSeekerExperiences(slug, token);

    }, []); // eslint-disable-line react-hooks/exhaustive-deps




  return (
    <ManagementLayout>
      <div className="datatable-filter-demo" style={{textAlign: 'center'}}>
            <div className="card">
                <div style={{textAlign: 'center'}} className="text-primary">
                    <Accordion activeIndex={0}>
                        {records.map((records) =>{
                                return(
                                    <AccordionTab data-aos="flip-left" className=" text-primary font-bold mb-3" key={records.slug} header={`${records.occupation} AT ${records.entity}`}>
                                        <ul className="list-none p-0 m-0">
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">TITLE</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.occupation}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">CATEGORY</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.category}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">COMPANY</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.entity}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">COUNTRY</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.country}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">CITY</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.city}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">FROM MONTH :</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.from_month}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">FROM YEAR :</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={records.from_year}  className="mr-2 mb-2 text-primary" />
                                                </div>
                                                
                                            </li>
                                            {records.still_working_there
                                                ?<li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className="text-primary w-6 md:w-4 font-medium">I AM STILL EMPLOYED THERE</div>
                                                    
                                                
                                                </li>
                                                :<>
                                                    <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                        <div className="text-primary w-6 md:w-4 font-medium">TO MONTH :</div>
                                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                            <Chip label={records.to_month}  className="mr-2 mb-2 text-primary" />
                                                        </div>
                                                        
                                                    </li>
                                                    <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                        <div className="text-primary w-6 md:w-4 font-medium">TO YEAR :</div>
                                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                            <Chip label={records.to_year}  className="mr-2 mb-2 text-primary" />
                                                        </div>
                                                        
                                                    </li>

                                                </>

                                            }
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">DESCRIPTION :</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    {records.description}
                                                </div>
                                                
                                            </li>

                                        </ul>
                                    </AccordionTab>

                                );
                            })     
                        }     
                    </Accordion>
                </div>
            </div>

          </div>
      </ManagementLayout>

    );
}

const mapStateToProps = (state) => ({
      token: state.auth.token,
      records: state.candidates.jobseekerexperiences,

})

export default connect(
    mapStateToProps,
    {getJobSeekerExperiences})
    (Experiences);

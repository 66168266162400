import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { Calendar } from 'primereact/calendar';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { RadioButton } from 'primereact/radiobutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';




const Services = (props) => {
    const {
        token,
        addCandidateService,
        editCandidateService,
        deleteCandidateService,
        getCandidateServices,
        records,
        addServiceImage

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');

    const initialValues = {
        title: '',
        description: '',
        category:'PRODUCT',
        charge_category:'FIXED',
        image:null,
        product_fee: 0.0,
        service_fee: 0.0,
        from_hourly_fee :0.0,
        to_hourly_fee:0.0,

    }

    const imageInitialValues = {
        image: null,
    }
    const [imageRecord, setImageRecord] = useState(imageInitialValues);
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [layout, setLayout] = useState('grid');
    const [imageDialog, setImageDialog] = useState(false);
    const [isImageSubmit, setIsImageSubmit] = useState(false);
    const [portfolio, setPortfolio] = useState('');
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    useEffect(() => {
        getCandidateServices(token);
        setIsSubmit(false);
        setIsImageSubmit(false);

    }, [isSubmit, isImageSubmit]);





    const deleteQual = (slug, data) =>{
        deleteCandidateService(slug, data, token)
        setIsSubmit(true);

    }


    const editImage = (record) => {
        setImageRecord({...record});
        setImageDialog(true);
    }

    const hideImageDialog = () => {
        setImageDialog(false);
        setImageRecord(imageInitialValues);
    }

    const openImageDialog = (slug) => {
        setPortfolio(slug)
        setImageDialog(true);
    }





    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title= fieldValues.title? "" : "Title of Education is Required"
        if ('image' in fieldValues)
            temp.image= fieldValues.image? "" : "Image is Required"
        if ('description' in fieldValues)
            temp.description= fieldValues.description? "" : "Description is Required"

        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }


      const validateImage = (fieldValues = imageRecord) => {
        let temp = { ...errors }
        if ('image' in fieldValues)
            temp.image= fieldValues.image? "" : "Image is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === imageRecord)
            return Object.values(temp).every(x => x === "")
      }




    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
          resetForm();
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                setErrors(validate(record));
                setIsSubmit(true);
                editCandidateService(record.slug, record, token);
                getCandidateServices(token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){

                setIsSubmit(true);
                addCandidateService(record, token);
                getCandidateServices(token);

                resetForm();
                setDisplayMaximizable(false);
            }

          }
        //
    }

    const handleImageSubmit = (e) => {
        e.preventDefault();
        if (validateImage()){
            let _record = {...imageRecord}
            addServiceImage(portfolio, _record, props.token);
            setIsImageSubmit(true);
            getCandidateServices(token);
            hideImageDialog();

        }
    }


    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }

    const onCategoryChange = (e) => {
        let _record = {...record};
        _record['category'] = e.value;
        setRecord(_record);
        setErrors({})
    }

    const onChargeCategoryChange = (e) => {
        let _record = {...record};
        _record['charge_category'] = e.value;
        setRecord(_record);
        setErrors({})
    }





    const editAcademicQualification = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file)
          fileReader.onload = () => {
            resolve(fileReader.result);
          }
          fileReader.onerror = (error) => {
            reject(error);
          }
        })
    }


    const handleImageRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        onImageChange(base64)

    }


    const onImageChange = (data) => {
        let _record = {...record};
        _record[`image`] = data;
        setRecord(_record);
    }

    const handleFileRead = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        onFileChange(base64)

    }


    const onFileChange = (data) => {
        let _record = {...imageRecord};
        _record[`image`] = data;
        setImageRecord(_record);
    }

    const dialogServicesHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Services Form</span>
              </div>
          )
      }




    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD NEW'

                    className="p-button-primary p-button-success p-button-outlined"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>



            </React.Fragment>
        )
    }


    const portFolioHeader = (image) => {
        return <img style={{height: '600px', width: '80%'}} alt="Card" src={image} />;
    }


    const imageTemplate = (data) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mbddddddd-3">
                        <img
                            src={data.image}
                            style={{height: '400px'}}
                            alt='NADA'
                            className="product-image"
                        />
                    </div>
                </div>
            </div>
        );
    }



    const renderGridItem = (data) => {
        return (


                <Card
                    data-aos="zoom-in"
                    className="col-12 md:col-12 border-50  mb-3 box shadow-8"
                    header={portFolioHeader(data.image)}
                    style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
                >

                        <div className="surface-1">
                            <ul className="list-none job_link p-0 m-0">
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.title}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        DESCRIPTION
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {data.description}
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CATEGORY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.category}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CHARGE CATEGORY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.charge_category}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                {data.category ==="PRODUCT"
                                  ?<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                      <div className=" col-12 md:w-12 font-medium">
                                          PRICE
                                      </div>
                                      <div className=" col-12 md:w-12 font-medium">
                                          <Chip label={`$${data.product_fee}`}  className="mr-2 mb-2 text-primary" />

                                      </div>
                                  </li>
                                  :<></>
                              }

                              {data.category === "SERVICE"
                                ?<>
                                  {data.charge_category === "FIXED"
                                      ?<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            SERVICE FEE
                                        </div>
                                        <div className=" col-12 md:w-12 font-medium">
                                            <Chip label={`$${data.service_fee}`}  className="mr-2 mb-2 text-primary" />

                                        </div>
                                    </li>
                                    :<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            HOURLY CHARGE
                                        </div>
                                        <div className=" col-12 md:w-12 font-medium">
                                            <Chip label={`$${data.from_hourly_fee}  - $${data.to_hourly_fee}`} className="mr-2 mb-2 text-primary" />

                                        </div>
                                    </li>
                                  }
                                </>
                                :<> </>
                              }

                                {data.images && data.images.length > 0
                                    ?<li style={{textAlign:'center'}} className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className="carousel-demo">
                                            <div className="card" >
                                                <Carousel
                                                    value={data.images}
                                                    numVisible={3}
                                                    numScroll={1}
                                                    responsiveOptions={responsiveOptions}
                                                    className="custom-carousel"
                                                    circular
                                                    autoplayInterval={3000}
                                                    itemTemplate={imageTemplate}

                                                />
                                            </div>

                                        </div>

                                    </li>
                                    :<></>
                                }
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-pencil"
                                            label='UPDATE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => editAcademicQualification(data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-trash"
                                            label='DELETE'
                                            className="p-button-rounded p-button-outlined p-button-danger"
                                            onClick={() => deleteQual(data.slug, data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-3 font-medium">
                                        <Button
                                            icon="pi pi-plus"
                                            label='ADD IMAGE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => openImageDialog(data.slug)}
                                        />
                                    </div>

                                </li>

                        </ul>

                    </div>
            </Card>
        );
    }


    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();






    return (
        <>

            <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}

            />
            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY SERVICES</h5>
                                            <p className='text-primary'>CURRENTLY THERE ARE NO SERVICES ATTACHED TO YOUR PROFILE</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO SERVICES ATTACHED TO YOUR PROFILE"
                                />
                            </div>
                        }
                    </div>
                </div>


            </div>
                <Dialog header={dialogServicesHeader()}
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '1000px' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <InputText
                                                accept="image/*"
                                                type="file"
                                                name='image'

                                                onChange={(evt) => handleImageRead(evt)}
                                            />
                                            <small id="username2-help"  className="p-error block">{errors.image}</small>
                                        </div>
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            {record.image != null
                                                ?<Avatar
                                                    image={record.image}
                                                    className="mr-2"
                                                    size="xlarge"
                                                    shape="circle"
                                                />
                                                :<div
                                                        className="w-full text-primary md:w-6 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        ADD IMAGE
                                                </div>

                                            }

                                        </div>

                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive', paddingBottom: '20px'}}
                                        >
                                            CATEGORY
                                        </div>
                                        <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
                                          <div className="formgrid grid" style={{textAlign: 'center'}}>
                                            <div className="field-radiobutton col-4">

                                            </div>
                                            <div className="field-radiobutton col-4">
                                                <RadioButton
                                                    inputId="category1"
                                                    name="category"
                                                    value="PRODUCT"
                                                    onChange={onCategoryChange}
                                                    checked={record.category === "PRODUCT"}
                                                  />
                                                <label className="" htmlFor="category1">PRODUCT</label>
                                            </div>
                                            <div className="field-radiobutton col-4">
                                                <RadioButton
                                                    inputId="category2"
                                                    name="category"
                                                    value="SERVICE"
                                                    onChange={onCategoryChange}
                                                    checked={record.category === "SERVICE"}
                                                  />
                                                <label className="" htmlFor="category2">SERVICE</label>
                                            </div>
                                          </div>
                                        </div>
                                    </li>

                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            TITLE
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">

                                            <InputText
                                                id="title"
                                                value={record.title}
                                                onChange={(e) => onInputChange(e, 'title')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.title}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            DESCRIPTION
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputTextarea
                                                rows={1}
                                                cols={30}
                                                autoResize
                                                value={record.description}
                                                onChange={(e) => onInputChange(e, 'description')}
                                            />
                                          <small id="username2-help" className="p-error block">{errors.description}</small>


                                        </div>
                                    </li>
                                    {record.category === 'PRODUCT'
                                        ?<>
                                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                <div
                                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                >
                                                    PRODUCT PRICE
                                                </div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">

                                                    <InputNumber
                                                        inputId="horizontal"
                                                        showButtons
                                                        buttonLayout="horizontal"
                                                        step={0.25}
                                                        decrementButtonClassName="p-button-danger"
                                                        incrementButtonClassName="p-button-success"
                                                        incrementButtonIcon="pi pi-plus"
                                                        decrementButtonIcon="pi pi-minus"
                                                        mode="currency"
                                                        currency="USD"
                                                        value={record.product_fee}
                                                        onChange={(e) => onInputNumberChange(e, 'product_fee')}
                                                    />

                                                </div>
                                            </li>
                                        </>
                                        :<>
                                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                <div
                                                    className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                                    style={{fontSize: '15px', fontFamily: 'cursive', paddingBottom: '20px'}}
                                                >
                                                    CHARGE CATEGORY
                                                </div>
                                                <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
                                                <div className="formgrid grid" style={{textAlign: 'center'}}>
                                                    <div className="field-radiobutton col-4">

                                                    </div>
                                                    <div className="field-radiobutton col-4">
                                                        <RadioButton
                                                            inputId="category1"
                                                            name="category"
                                                            value="FIXED"
                                                            onChange={onChargeCategoryChange}
                                                            checked={record.category === "FIXED"}
                                                        />
                                                        <label className="" htmlFor="category1">FIXED</label>
                                                    </div>
                                                    <div className="field-radiobutton col-4">
                                                        <RadioButton
                                                            inputId="category2"
                                                            name="category"
                                                            value="HOURLY"
                                                            onChange={onChargeCategoryChange}
                                                            checked={record.category === "HOURLY"}
                                                        />
                                                        <label className="" htmlFor="category2">HOURLY</label>
                                                    </div>
                                                </div>
                                                </div>
                                            </li>
                                            {record.charge_category === "FIXED"
                                                ?<>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                        <div
                                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                        >
                                                            SERVICE PRICE
                                                        </div>
                                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">

                                                        <InputNumber
                                                            inputId="horizontal"
                                                            showButtons
                                                            buttonLayout="horizontal"
                                                            step={0.25}
                                                            decrementButtonClassName="p-button-danger"
                                                            incrementButtonClassName="p-button-success"
                                                            incrementButtonIcon="pi pi-plus"
                                                            decrementButtonIcon="pi pi-minus"
                                                            mode="currency"
                                                            currency="USD"
                                                            value={record.service_fee}
                                                            onChange={(e) => onInputNumberChange(e, 'service_fee')}
                                                        />

                                                        </div>
                                                    </li>
                                                </>
                                                :<>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                        <div
                                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                        >
                                                            FROM HOURLY PRICE
                                                        </div>
                                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">

                                                        <InputNumber
                                                            inputId="horizontal"
                                                            showButtons
                                                            buttonLayout="horizontal"
                                                            step={0.25}
                                                            decrementButtonClassName="p-button-danger"
                                                            incrementButtonClassName="p-button-success"
                                                            incrementButtonIcon="pi pi-plus"
                                                            decrementButtonIcon="pi pi-minus"
                                                            mode="currency"
                                                            currency="USD"
                                                            value={record.from_hourly_fee}
                                                            onChange={(e) => onInputNumberChange(e, 'from_hourly_fee')}
                                                        />

                                                        </div>
                                                    </li>
                                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                        <div
                                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                        >
                                                            TO HOURLY PRICE
                                                        </div>
                                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">

                                                        <InputNumber
                                                            inputId="horizontal"
                                                            showButtons
                                                            buttonLayout="horizontal"
                                                            step={0.25}
                                                            decrementButtonClassName="p-button-danger"
                                                            incrementButtonClassName="p-button-success"
                                                            incrementButtonIcon="pi pi-plus"
                                                            decrementButtonIcon="pi pi-minus"
                                                            mode="currency"
                                                            currency="USD"
                                                            value={record.to_hourly_fee}
                                                            onChange={(e) => onInputNumberChange(e, 'to_hourly_fee')}
                                                        />

                                                        </div>
                                                    </li>
                                                </>

                                            }
                                        </>

                                    }



                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <Button
                                            type="submit"
                                            label="SUBMIT"
                                            className="p-button-outlined p-button-warning"
                                        />
                                    </li>
                                </ul>


                            </div>

                        </form>
                    </div>

                </Dialog>
                <Dialog
                    header="IMAGE FORM"
                    maximizable
                    modal
                    style={{ width: '700px' }}
                    visible={imageDialog}
                    className="p-fluid"
                    onHide={hideImageDialog}
                >
                    <div className="card">
                        <form onSubmit={handleImageSubmit}>
                            <div className="l">
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <InputText
                                                accept="image/*"
                                                type="file"
                                                name='image'

                                                onChange={(evt) => handleFileRead(evt)}
                                            />
                                            <small id="username2-help"  className="p-error block">{errors.image}</small>
                                        </div>
                                        <div
                                            className="w-full  md:w-6 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            {imageRecord.image !=null
                                                ?<Avatar
                                                    image={imageRecord.image}
                                                    className="mr-2"
                                                    size="xlarge"
                                                    shape="circle"
                                                />
                                                :<div
                                                        className="w-full text-primary md:w-6 md:flex-order-0 flex-order-1"
                                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                    >
                                                        ADD IMAGE
                                                </div>

                                            }

                                        </div>

                                    </li>
                                </ul>



                                <div className="col-12 md:col-4">
                                    <Button
                                        type="submit"
                                        label="Submit"
                                        className="mt-2"

                                    />
                                    <label htmlFor="horizontal"></label>
                                </div>

                            </div>
                        </form>
                    </div>
                </Dialog>

        </>
    );
}


export default Services;

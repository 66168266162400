import React, {useState} from 'react';
import PublicLayout from '../layout/PublicLayout';
import contact from './images/contact-1.png';
import { InputTextarea } from 'primereact/inputtextarea';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
  createEnquiry
} from '../../actions/feedback';



const ContactUs = (props) => {

  const initialValues = {
      name: '',
      email: '',
      subject: '',
      message: '',
  }
  const [record, setRecord] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues = record) => {
      let temp = { ...errors }
      if ('email' in fieldValues)
          temp.email= fieldValues.email? "" : "Email is Required"
      if ('name' in fieldValues)
          temp.name= fieldValues.name? "" : "Name is Required"
      if ('subject' in fieldValues)
          temp.subject= fieldValues.subject? "" : "Subject is Required"
      if ('message' in fieldValues)
          temp.message= fieldValues.message? "" : "Message is Required"

      setErrors({
          ...temp
      })

      if (fieldValues === record)
          return Object.values(temp).every(x => x === "")
    }


  const resetForm = () =>{
      setRecord(initialValues)
  }



  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _record = {...record};
      _record[`${name}`] = val;
      setRecord(_record);
  }

  const handleSubmit = (e) => {
      e.preventDefault();
      if (validate()){
          console.log(record)
          props.createEnquiry(record);
          resetForm();

      }

  }



  return(
    <PublicLayout>
      <div className="card px-4 py-8 md:px-6 lg:px-10" style={{textAlign: 'center'}}>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="p-fluid pr-0 md:pr-6">
              <div className="field">
                <label for="name" className="font-medium text-primary">
                  Name
                </label>
                  <InputText
                      id="name"
                      name="name"
                      className="w-full mb-3"
                      value={record.name}
                      onChange={(e) => onInputChange(e,"name")}

                  />
                <small id="username2-help"  className="p-error block">{errors.name}</small>
              </div>
              <div className="field">
                <label for="email1" className="font-medium text-primary">
                  Email
                </label>
                <InputText
                    id="email"
                    type="email"
                    name="email"
                    className="w-full mb-3"
                    value={record.email}
                    onChange={(e) => onInputChange(e,"email")}

                />
              <small id="username2-help"  className="p-error block">{errors.email}</small>
              </div>
              <div className="field">
                <label for="company" className="font-medium text-primary">
                  Subject
                </label>
                <InputText
                    id="subject"
                    type="subject"
                    name="subject"
                    className="w-full mb-3"
                    value={record.subject}
                    onChange={(e) => onInputChange(e,"subject")}

                />
              <small id="username2-help"  className="p-error block">{errors.subject}</small>
              </div>
              <div className="field">
                <label for="message" className="font-medium text-primary">
                  Message
                </label>
                <InputTextarea
                    rows={5}
                    cols={30}
                    autoResize
                    value={record.message}
                    onChange={(e) => onInputChange(e, 'message')}
                />
                <small id="username2-help"  className="p-error block">{errors.message}</small>
              </div>

              <Button
                  label="Send Message"
                  icon="pi pi pi-send"
                  className="p-button-outlined"
                  onClick={handleSubmit}
              />
            </div>
          </div>
          <div className="col-12 md:col-6 bg-no-repeat bg-right-bottom"
            style={{backgroundImage: `url(${contact}`}}
          >
            <div className="text-900 text-primary text-2xl font-medium text-primary mb-6">
              Contact Us
            </div>
            <div className="text-700 text-primary line-height-3 mb-6">
              Get in touch with us
            </div>
            <ul className="list-none p-0 m-0 mt-6 text-700">
              <li className="flex align-items-center mb-3">
                <i className="pi pi-twitter text-primary mr-2"></i>
                <span className="text-primary mb-2">@zimjobscenter</span>
              </li>
              <li className="flex align-items-center">
                <i className="pi pi-inbox text-primary mr-2"></i>
                <span className="text-primary mb-2">ceo@sylcoutech.global</span>
              </li>
              <li className="flex align-items-center">
                <i className="pi pi-inbox text-primary mr-2"></i>
                <span className="text-primary mb-2">admin@sylcoutech.global</span>
              </li>
              <li className="flex align-items-center">
                <i className="pi pi-inbox text-primary mr-2"></i>
                <span className="text-primary mb-2">sales@sylcoutech.global</span>
              </li>
              <li className="flex align-items-center">
                <i className="pi  pi-inbox text-primary mr-2"></i>
                <span className="text-primary mb-2">+263715145704/+263476975</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </PublicLayout>

  );
}

const mapStateToProps = state =>({
  token: state.auth.token,
})

export default connect(mapStateToProps, {createEnquiry}) (ContactUs);

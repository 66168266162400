import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import PrimeReact from 'primereact/api';
import {connect} from 'react-redux';
import {getCandidateJobProposals} from '../../actions/jobs';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import AOS from "aos";
import 'aos/dist/aos.css';


export const ProposalContainer = (props) => {

    const [layout, setLayout] = useState('grid');
    PrimeReact.ripple = true;
    const filteredProposals = props.proposals.filter((proposal) => proposal.acceptance_status === props.status)

    useEffect(() =>{
        if (!props.fetched){
            props.getCandidateJobProposals(props.token);
        }    
    }, [])

    useEffect(() =>{
        AOS.init({duration : 3000})

      }, []);

    const renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
          (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
          // less than one minute ago
          prefix = "just now...";
        } else if (timeDiff < 60 && timeDiff > 1) {
          // less than sixty minutes ago
          prefix = `${timeDiff} minutes ago`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
          // less than 24 hours ago
          prefix = `${Math.round(timeDiff / 60)} hours ago`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
          // less than 7 days ago
          prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
        } else {
          prefix = `${new Date(timestamp)}`;
        }
        return prefix;
    };

      const truncateParagraph = (paragraph) =>{
        if (paragraph.length > 400){
            let paragraph_ = paragraph.slice(0, 399) + 'read more..'
            return paragraph_
        }else{
            return paragraph
        }
    }

    const renderGridItem = (data) => {
        return (
            
            <div
                data-aos="flip-left"
                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">  
                            <ul className="list-none job_link p-0 m-0">
                            
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        JOB TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.job_title}  className="mr-2 mb-2  " />
                                    </div>        
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        APPLIED 
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {renderTimestamp(data.created_at)}
                                    </div>        
                                </li> 
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        JOB
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {truncateParagraph(data.job_description)}
                                    </div>        
                                </li> 
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        MY APPLICATION LETTER
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {truncateParagraph(data.cover_letter)}
                                    </div>        
                                </li> 
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Button 
                                            className='p-button-rounded p-button-warning p-button-outlined' 
                                            label='VIEW MORE DETAILS'
                                        />
                                    </div>        
                                </li> 
                                        
                        </ul>
                        
                    </div>
                    </div>
            </div>


        );
    }

    const renderListItem = (data) => {
        return(
            <div className="col-12 md:col-12 border-50 box shadow-8 flex-grow-1 " style={{textAlign: 'center', padding: '40px'}} >
                    
                <div className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                    <div data-aos="zoom-in" className="surface-1">  
                        <ul className="list-none job_link p-0 m-0">
                            
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        JOB TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.job_title}  className="mr-2 mb-2  " />
                                    </div>        
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        APPLIED 
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {renderTimestamp(data.created_at)}
                                    </div>        
                                </li> 
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        JOB
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {truncateParagraph(data.job_description)}
                                    </div>        
                                </li> 
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        MY APPLICATION LETTER
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        {truncateParagraph(data.cover_letter)}
                                    </div>        
                                </li> 
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Button 
                                            className='p-button-rounded p-button-warning p-button-outlined' 
                                            label='VIEW MORE DETAILS'
                                        />
                                    </div>        
                                </li> 
                                        
                        </ul>
                        
                    </div>
                    </div>
            </div>


        );

    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();




    return (
        <>
            {filteredProposals.length === 0
                ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                    <div className="col-12">
                            <div className="card">
                                <h5 className=''>EMPTY APPLICATIONS</h5>
                                <p className=''>CURRENTLY YOU DO NOT HAVE JOB APPLICATIONS WITH THIS STATUS</p>
                            </div>
                     </div>
                </div>
                : <div className="card dataview-demo">
                    <DataView
                        value={filteredProposals}
                        layout={layout}
                        header={header}
                        itemTemplate={itemTemplate}
                        paginator 
                        rows={30}
                        
                    />
                </div>
            }
        </>

    )
    
}


const mapStateToProps = (state) => ({
    token: state.auth.token,
    proposals: state.jobs.candidatejobproposals,

})



export default connect(mapStateToProps, {
    getCandidateJobProposals
})(ProposalContainer)
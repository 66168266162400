import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import EmployerLayout from '../../layout/EmployerLayout';
import {connect} from 'react-redux';
import {
    getRecruiterProposal,
    getAdhocEnquiries,
    getProfileAcademicQualifications,
    getProfileAvailability,
    getProfileLanguages,
    getProfileLinks,
    getProfileResume,
    getProfileRoles,
    callForJobInterview,
    rejectJobProposal,
    getProfileSkills,

} from '../../../actions/jobs';
import { useParams } from 'react-router-dom';
import Detail from "./Detail";
import { RadioButton } from 'primereact/radiobutton';



const Proposal = (props) => {

    const [hour, setHour] = useState(null);
    const {
        lastName,
        firstName,
        token,
        proposal,
        profileresume,
        profileavailability,
        profilequalifications,
        profilelangauges,
        profilelinks,
        profileroles,

        callForJobInterview,
        rejectJobProposal,
        profileskills,


    } = props;
    let { slug } = useParams();
    const [type, setType] = useState('PROPOSAL');

    const getHour = () => {
       const date = new Date();
       const hour = date.getHours()
       setHour(hour);
    }

    const onTypeChange = (e) => {
        setType(e.value);
      }


    useEffect(() => {
      getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>{
        if(!props.fetched){
            props.getRecruiterProposal(slug, token);
            props.getAdhocEnquiries(slug, token);
            props.getProfileAcademicQualifications(slug, token);
            props.getProfileAvailability(slug, token);
            props.getProfileLanguages(slug, token);
            props.getProfileLinks(slug, token);
            props.getProfileResume(slug, token);
            props.getProfileRoles(slug, token);
            props.getProfileSkills(slug, token);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;


    const recentHeaderTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <i className="pi pi-bell mr-2" />
                {options.titleElement}
            </button>
        );
    };

    const matchedHeaderTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <i className="pi pi-thumbs-up mr-2" />
                {options.titleElement}
            </button>
        );
    };


    const getProposalDisplay = () =>{
        if (type === "PROPOSAL"){
            return(
                <>
                  <Detail

                      proposal={proposal}
                      enquiries={props.enquiries}
                      profileresume ={profileresume}
                      profileavailability = {profileavailability}
                      profilequalifications = {profilequalifications}
                      profilelangauges = {profilelangauges }
                      profilelinks = {profilelinks}
                      profileroles = {profileroles}
                      callForJobInterview={callForJobInterview}
                      rejectJobProposal={rejectJobProposal}
                      token={token}
                      profileskills={profileskills}
                  />
                </>
            )
        }else if (type === "INTERVIEWS"){
            return(
                <>

                </>
            )

        }else{
            return(
                <>
                    SOMETHING WRONG
                </>
            )
        }
    }





    return (
      <EmployerLayout>
        <div className="grid">
          <div className="col-12 md:col-12">
            <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center">
                  <div className="text-900 font-bold text-5xl mb-3">{hour < 12 ? "Good Morning" : "Good Afternoon"}</div>
                  <div className="text-700 text-2xl mb-5">{firstName} {lastName}</div>
                  <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
              </div>
            </div>
              <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                  <h5>THESE ARE THE CANDIDATE JOB APPLICATIONS BASED ON THEIR CURRENT STATUS</h5>
                  <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                      <li  className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                        <div
                          style={{textAlign: 'center'}}
                          className="field-radiobutton flex card box shadow-8 w-full md:w-6 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                        >
                            <RadioButton
                                inputId="category1"
                                name="category"
                                value="PROPOSAL"

                                onChange={onTypeChange}
                                checked={type === "PROPOSAL"}
                              />
                            <label className="text-primary" htmlFor="category1">PROPOSAL</label>
                        </div>
                        <div className="field-radiobutton flex card box shadow-8 w-full md:w-6 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                            <RadioButton
                                inputId="category2"
                                name="category"
                                value="INTERVIEWS"
                                onChange={onTypeChange}
                                checked={type === "INTERVIEWS"}
                              />
                            <label className="text-primary" htmlFor="category2">INTERVIEW</label>
                        </div>


                      </li>
                    </ul>

              </div>
              {getProposalDisplay()}


              </div>

            </div>

        </EmployerLayout>
    )
}

const mapStateToProps = state =>({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    proposal: state.jobs.recruiterproposal,
    enquiries: state.jobs.adhocenquiries,
    profileresume: state.jobs.profileresume,
    profileavailability: state.jobs.profileavailability,
    profilequalifications: state.jobs.profilequalifications,
    profilelangauges: state.jobs.profilelangauges,
    profilelinks: state.jobs.profilelinks,
    profileroles: state.jobs.profileroles,
    profileskills: state.jobs.profileskills,


})

export default connect(
  mapStateToProps,
  {

    getRecruiterProposal,
    getAdhocEnquiries,
    getProfileAcademicQualifications,
    getProfileAvailability,
    getProfileLanguages,
    getProfileLinks,
    getProfileResume,
    getProfileRoles,
    callForJobInterview,
    rejectJobProposal,
    getProfileSkills,
} )
  (Proposal);

import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Avatar } from 'primereact/avatar';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { Chip } from 'primereact/chip';


const Portifolios = (props) => {
    const {
        records,
    } = props;


    const [layout, setLayout] = useState('grid');
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];



    const portFolioHeader = (image) => {
        return <img style={{height: '600px', width: '80%'}} alt="Card" src={image} />;
    }

    const imageTemplate = (data) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="mbddddddd-3">
                        <img
                            src={data.image}
                            style={{height: '400px'}}
                            alt='NADA'
                            className="product-image"
                        />
                    </div>
                </div>
            </div>
        );
    }




    const renderGridItem = (data) => {
        return (


                <Card
                    data-aos="zoom-in"
                    className="col-12 md:col-12 border-50  mb-3 box shadow-8 flex-grow-1"
                    header={portFolioHeader(data.image)}
                    style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
                >


                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">


                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.title}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        ROLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.role}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CHALLENGE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.challenge}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        SOLUTION
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.solution}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        START DATE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.start_date}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                {data.end_end
                                    ?<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            END DATE
                                        </div>
                                        <div className=" col-12 md:w-12 font-medium">
                                            <Chip label={data.end_date}  className="mr-2 mb-2 text-primary" />

                                        </div>
                                    </li>
                                    :<></>
                                }

                                {data.skills && data.skills.length > 0
                                    ?<li style={{textAlign:'center'}} className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            SKILLS USED
                                        </div>
                                        {data.skills.map((skill) => {
                                                return(

                                                    <div key={skill.id} className=" w-full col-12 md:w-3 font-medium">
                                                        <Chip label={skill.skill}  className="mr-2 mb-2  " />
                                                    </div>

                                                );

                                            })

                                        }

                                    </li>
                                    :<></>
                                }
                                {data.images && data.images.length > 0
                                    ?<li style={{textAlign:'center'}} className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className="carousel-demo">
                                            <div className="card" >
                                                <Carousel
                                                    value={data.images}
                                                    numVisible={3}
                                                    numScroll={1}
                                                    responsiveOptions={responsiveOptions}
                                                    className="custom-carousel"
                                                    circular
                                                    autoplayInterval={3000}
                                                    itemTemplate={imageTemplate}

                                                />
                                            </div>

                                        </div>

                                    </li>
                                    :<></>
                                }


                        </ul>

                    </div>
            </Card>
        );
    }


    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();






    return (
        <>
            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY PORTFOLIO</h5>
                                            <p className='text-primary'>CURRENTLY THERE ARE NO PORTFOLIOS ATTACHED TO YOUR PROFILE</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO PORTFOLIOS ATTACHED TO YOUR PROFILE"
                                />
                            </div>
                        }
                    </div>
                </div>

            </div>


        </>
    );
}


export default Portifolios;

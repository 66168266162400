import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import EmployerLayout from '../../layout/EmployerLayout';
import usermale from './images/user-with-tie-svgrepo-com.svg';
import userfemale from './images/user-svgrepo-com.svg';
import { TabView, TabPanel } from 'primereact/tabview';
import 'aos/dist/aos.css';
import {
    FaAccusoft
} from "react-icons/fa";
import 'primeicons/primeicons.css';
import { BsLayersFill } from "react-icons/bs";
import {
    MdOutlineCastForEducation,
    MdLanguage,
    MdHomeRepairService,
} from "react-icons/md";
import {
    FiSettings,
    FiActivity,
} from "react-icons/fi";
import { TiLink } from "react-icons/ti";
import {
    GiSkills,
    GiFlagObjective,
} from "react-icons/gi";
import { GrUserExpert } from "react-icons/gr";
import { SiTransportforlondon } from "react-icons/si";
import { FiPocket } from "react-icons/fi";
import {connect} from 'react-redux';
import {
  getMatchedUserPortfolios,
  getMatchedUserServices,
  getMatchedUserAcademicQualifications,
  getMatchedUserLanguages,
  getMatchedUserLinks,
  getMatchedUserExperiences,
  getMatchedUserSkills,
  getMatchedUserProfile

} from '../../../actions/matches';
import feat4 from './images/feature-4.png';
import Profile from './Profile';
import Qualifications from './Qualifications';
import Languages from './Languages';
import Links from './Links';
import Experiences from './Experiences';
import Skills from './Skills';
import Portfolios from './Portfolios';
import Services from './Services';

const MatchedUser = (props) => {
    const {slug} = useParams();
    const [params, setParams] = useState({candidate: slug});
    const {
      getMatchedUserPortfolios,
      getMatchedUserServices,
      getMatchedUserAcademicQualifications,
      getMatchedUserLanguages,
      getMatchedUserLinks,
      getMatchedUserExperiences,
      getMatchedUserSkills,
      token,
      getMatchedUserProfile,
      matchedUserPortfolios,
      matchedUserServices,
      matchedUserAcademicQualifications,
      matchedUserLanguages,
      matchedUserLinks,
      matchedUserExperiences,
      matchedUserSkills,
      matchedUserProfile,

    } = props;


    useEffect(() =>{
      getMatchedUserPortfolios(params, token)
      getMatchedUserServices(params, token)
      getMatchedUserAcademicQualifications(params, token)
      getMatchedUserLanguages(params, token)
      getMatchedUserLinks(params, token)
      getMatchedUserExperiences(params, token)
      getMatchedUserSkills(params, token)
      getMatchedUserProfile(params, token)

    }, [])

    const tabHeaderITemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FaAccusoft />
                    {options.titleElement}
            </button>
        );
    };

    const tabQualificationsTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <MdOutlineCastForEducation />
                    {options.titleElement}
            </button>
        );
    };

    const tabSettingsTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FiSettings />
                    {options.titleElement}
            </button>
        );
    };

    const tabLanguagesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <MdLanguage />
                    {options.titleElement}
            </button>
        );
    };

    const tabLinksTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <TiLink />
                    {options.titleElement}
            </button>
        );
    };

    const tabSkillsTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <GiSkills />
                    {options.titleElement}
            </button>
        );
    };

    const tabExperienceTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <GrUserExpert />
                    {options.titleElement}
            </button>
        );
    };

    const tabPortfolioTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <SiTransportforlondon />
                    {options.titleElement}
            </button>
        );
    };

    const tabMissionTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FiPocket />
                    {options.titleElement}
            </button>
        );
    };

    const tabObjectivesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <GiFlagObjective />
                    {options.titleElement}
            </button>
        );
    };

    const tabServicesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <MdHomeRepairService />
                    {options.titleElement}
            </button>
        );
    };

    const tabActivitiesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FiActivity />
                    {options.titleElement}
            </button>
        );
    };



    return (
        <EmployerLayout>
          <div className='card text-primary shadow-8' style={{paddingTop: '30px' ,fontFamily: 'cursive'}}>
            <div className="block-contentss">
                <div>
                    <div>
                      <div
                        style={{height: '290px',  backgroundImage: `url(${feat4}`}}
                      >
                      </div>
                      <div className="px-4 py-5 md:px-6 lg:px-8">
                        <div className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative" style={{marginTop: '-2rem', top: '-70px', marginBottom: '-70px'}}>
                          <div>
                            <div
                              className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
                              style={{width: "150px", height: "150px", borderRadius: "10px"}}
                            >
                              {matchedUserProfile.gender === 'MALE'
                                ?
                                <img src={usermale} alt="logo" width="150" height="150"/>
                                :<img src={userfemale} alt="logo" width="150" height="150"/>
                              }


                            </div>
                              <div className="text-900 text-3xl font-medium mb-3">
                                <h1>Lambda CDN</h1>
                              </div>
                            <p className="mt-0 mb-3 text-700 text-xl">
                              HLETIPANG
                              </p>
                            <div className="text-600 font-medium">
                              <span>
                                We Bring The Best For You
                                </span>
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
          </div>
          <div className="tabview-demo" style={{fontFamily: 'cursive'}}>
              <div className="card">
                  <TabView scrollable>
                      <TabPanel className='flex-1' className='flex-1' header="ABOUT" headerTemplate={tabHeaderITemplate}>

                          <Profile profile={matchedUserProfile}/>
                      </TabPanel>
                      <TabPanel className='flex-1'  header="QUALIFICATIONS" headerTemplate={tabQualificationsTemplate}>
                          <Qualifications records={matchedUserAcademicQualifications}/>
                      </TabPanel>
                      <TabPanel className='flex-1' header="LANGUAGES" headerTemplate={tabLanguagesTemplate}>
                          <Languages records={matchedUserLanguages}/>
                      </TabPanel>
                      <TabPanel className='flex-1' header="LINKS" headerTemplate={tabLinksTemplate}>
                          <Links records={matchedUserLinks}/>

                      </TabPanel>
                      <TabPanel className='flex-1' header="EXPERIENCE" headerTemplate={tabExperienceTemplate}>
                          <Experiences records={matchedUserExperiences}/>

                      </TabPanel>
                      <TabPanel className='flex-1' header="SKILLS" headerTemplate={tabSkillsTemplate}>
                          <Skills records={matchedUserSkills}/>
                      </TabPanel>
                      <TabPanel className='flex-1' header="PORTFOLIO" headerTemplate={tabPortfolioTemplate}>
                          <Portfolios records={matchedUserPortfolios}/>
                      </TabPanel>

                      <TabPanel className='flex-1' header="SERVICES" headerTemplate={tabServicesTemplate}>

                          <Services records={matchedUserServices}/>

                      </TabPanel>


                  </TabView>
              </div>
          </div>
        </EmployerLayout>

    );
}

const matchStateToProps = state =>({
    token: state.auth.token,
    matchedUserPortfolios: state.matches.matchedUserPortfolios,
    matchedUserServices: state.matches.matchedUserServices,
    matchedUserAcademicQualifications: state.matches.matchedUserAcademicQualifications,
    matchedUserLanguages: state.matches.matchedUserLanguages,
    matchedUserLinks: state.matches.matchedUserLinks,
    matchedUserExperiences: state.matches.matchedUserExperiences,
    matchedUserSkills: state.matches.matchedUserSkills,
    matchedUserProfile: state.matches.matchedUserProfile,


})

export default connect(
  matchStateToProps,
  {
  getMatchedUserPortfolios,
  getMatchedUserServices,
  getMatchedUserAcademicQualifications,
  getMatchedUserLanguages,
  getMatchedUserLinks,
  getMatchedUserExperiences,
  getMatchedUserSkills,
  getMatchedUserProfile,
}) (MatchedUser);

import React  from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {logout} from '../../actions/auth';
import {connect } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import zimjobs from './zimjobs.png';




const AppTopbar = (props) => {
    const navigate = useNavigate();

    const changeStatus = () => {
        props.logout()
        navigate('/login', { replace: true });
    };





    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={zimjobs } alt="logo"/>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars"/>
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-calendar"/>
                            <span>Events</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                            <i className="pi pi-cog"/>
                            <span>Settings</span>
                        </button>
                    </li>
                    <li>
                        <button className="p-link layout-topbar-button" >
                            <i className="pi pi-user"/>
                            <span>Profile</span>
                        </button>
                    </li>
                    <li>

                      <button className="p-link layout-topbar-button" onClick={changeStatus}>
                            <i className="pi pi pi-power-off"/>
                            <span>Profile</span>
                        </button>
                    </li>

                </ul>

        </div>
    );
}



const mapStateToProps = state =>({
    token: state.auth.token,
  })


  export default connect(
    mapStateToProps,
    {logout} )
  ( AppTopbar);

import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import JobSeekerLayout from '../layout/JobSeekerLayout';
import {connect} from 'react-redux';
import PermanentBestMatches from './BestMatches';
import PermanentNearestNeighbours from './NearestNeighbours';
import { RadioButton } from 'primereact/radiobutton';
import {
  getCountries,
  getProvinces,
  getDistricts

} from '../../actions/places';
import AllJobs from './AllJobs';
import AppliedJobs from './AppliedJobs';


const Jobs = (props) => {

    const [hour, setHour] = useState(null);
    const [oneTimeCategory, setOneTimeCategory] = useState('oneTimeBestMatches');
    const [permanentCategory, setPermanentCategory] = useState('permanentBestMatches');
    const [internshipCategory, setInternshipCategory] = useState('internshipBestMatches');
    const [contractualCategory, setContractualCategory] = useState('contractualBestMatches');
    const [type, setType] = useState('PERMANENT JOB');
    const {
      lastName,
      firstName,
      countries,
      provinces,
      districts,
    } = props;


    useEffect(() =>{
        props.getCountries(props.token);
        props.getProvinces(props.token);
        props.getDistricts(props.token);
    }, [])

    const getHour = () => {
       const date = new Date();
       const hour = date.getHours()
       setHour(hour);
    }

    const onOneTimeCategoryChange = (e) => {
      setOneTimeCategory(e.value);
    }

    const onPermanentCategoryChange = (e) => {
      setPermanentCategory(e.value);
    }

    const onInternshipCategoryChange = (e) => {
      setInternshipCategory(e.value);
    }

    const onContractualCategoryChange = (e) => {
      setContractualCategory(e.value);
    }


    const onTypeChange = (e) => {
      setType(e.value);
    }


    useEffect(() => {
      getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;




      const getHourDisplay = () => {

        if (hour < 12){
          return(
            <span style={{fontFamily: 'cursive'}}>
              Good Morning!
            </span>
          )
        }else if(hour < 18){
          return (
            <span style={{fontFamily: 'cursive'}}>Good Afternoon!!</span>
          )
        }else{
          return(
            <span style={{fontFamily: 'cursive'}}>Good Evening!!</span>
          );
        }
    }

    const getTypeDisplay = () => {
      if (type==="PERMANENT JOB"){
        return(
          <>
            <div className="card" style={{textAlign: 'center',  fontFamily: 'cursive'}}>
                <h5 className='text-primary'>THESE JOBS ARE AVALIABLE SEEKING FOR PERMANENT EMPLOYMENT</h5>
                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                    <li  className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="permanentBestMatches"

                              onChange={onPermanentCategoryChange}
                              checked={permanentCategory === "permanentBestMatches"}
                            />
                          <label className="text-primary" htmlFor="category1">BEST MATCHES</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="permanentNearestNeighbours"
                              onChange={onPermanentCategoryChange}
                              checked={permanentCategory === "permanentNearestNeighbours"}
                            />
                          <label className="text-primary" htmlFor="category2">NEAREST NEIGHBOURS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="permanentAllJobs"
                              onChange={onPermanentCategoryChange}
                              checked={permanentCategory === "permanentAllJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">ALL JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="permanentAppliedForJobs"
                              onChange={onPermanentCategoryChange}
                              checked={permanentCategory === "permanentAppliedForJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">JOBS YOU APPLIED FOR</label>
                      </div>

                    </li>
                  </ul>

              </div>
          </>
        );
      }else if (type==="CONTRACTUAL JOB"){
        return(
          <>
            <div className="card" style={{textAlign: 'center',  fontFamily: 'cursive'}}>
                <h5 className='text-primary'>THESE JOBS ARE AVAILABLE FOR CONTRACTORS</h5>
                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                    <li  className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="contractualBestMatches"

                              onChange={onContractualCategoryChange}
                              checked={contractualCategory === "contractualBestMatches"}
                            />
                          <label className="text-primary" htmlFor="category1">BEST MATCHES</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="contractualNearestNeighbours"
                              onChange={onContractualCategoryChange}
                              checked={contractualCategory === "contractualNearestNeighbours"}
                            />
                          <label className="text-primary" htmlFor="category2">NEAREST NEIGHBOURS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="contractualAllJobs"
                              onChange={onContractualCategoryChange}
                              checked={contractualCategory === "contractualAllJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">ALL JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="contractualAppliedForJobs"
                              onChange={onContractualCategoryChange}
                              checked={contractualCategory === "contractualAppliedForJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">JOBS YOU APPLIED FOR</label>
                      </div>

                    </li>
                  </ul>

              </div>
          </>
        );
      }else if (type==="INTERNSHIP JOB"){
        return(
          <>
            <div className="card" style={{textAlign: 'center',  fontFamily: 'cursive'}}>
                <h5>THESE JOBS ARE AVAILABLE FOR THOSE LOOKING FOR INTERNSHIPS</h5>
                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                    <li  className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="internshipBestMatches"

                              onChange={onInternshipCategoryChange}
                              checked={internshipCategory === "internshipBestMatches"}
                            />
                          <label className="text-primary" htmlFor="category1">BEST MATCHES</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="internshipNearestNeighbours"
                              onChange={onInternshipCategoryChange}
                              checked={internshipCategory === "internshipNearestNeighbours"}
                            />
                          <label className="text-primary" htmlFor="category2">NEAREST NEIGHBOURS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="internshipAllJobs"
                              onChange={onInternshipCategoryChange}
                              checked={internshipCategory === "internshipAllJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">ALL JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="internshipAppliedForJobs"
                              onChange={onInternshipCategoryChange}
                              checked={internshipCategory === "internshipAppliedForJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">JOBS YOU APPLIED FOR</label>
                      </div>

                    </li>
                  </ul>

              </div>
          </>
        );
      }else if (type==="ONE TIME JOB"){
        return(
          <>
            <div className="card" style={{textAlign: 'center',  fontFamily: 'cursive'}}>
                <h5>THESE JOBS ARE ONE TIME JOBS FOR FREELANCERS & ENTREPRENEURS </h5>
                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                    <li  className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="oneTimeBestMatches"

                              onChange={onOneTimeCategoryChange}
                              checked={oneTimeCategory === "oneTimeBestMatches"}
                            />
                          <label className="text-primary" htmlFor="category1">BEST MATCHES</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="oneTimeNearestNeighbours"
                              onChange={onOneTimeCategoryChange}
                              checked={oneTimeCategory === "oneTimeNearestNeighbours"}
                            />
                          <label className="text-primary" htmlFor="category2">NEAREST NEIGHBOURS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="oneTimeAllJobs"
                              onChange={onOneTimeCategoryChange}
                              checked={oneTimeCategory === "oneTimeAllJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">ALL JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              style={{paddingRight: '20px'}}
                              value="oneTimeAppliedForJobs"
                              onChange={onOneTimeCategoryChange}
                              checked={oneTimeCategory === "oneTimeAppliedForJobs"}
                            />
                          <label className="text-primary" htmlFor="category2">JOBS YOU APPLIED FOR</label>
                      </div>

                    </li>
                  </ul>

              </div>
          </>
        );
      }else{
        return;
      }


    }


    const getJobsDisplay = () =>{
      if (type === "PERMANENT JOB"){
        if (permanentCategory ==="permanentBestMatches"){
          return (
              <><PermanentBestMatches
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="PERMANENT JOB"
                /></>
          );

        }else if (permanentCategory ==="permanentNearestNeighbours"){
          return (
              <><PermanentNearestNeighbours
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="PERMANENT JOB"
                  /></>
          );
        }else if (permanentCategory ==="permanentAllJobs"){
          return (
              <><AllJobs
                    lastName={lastName}
                    firstName={firstName}
                    countries={countries}
                    provinces={provinces}
                    districts={districts}
                    category="PERMANENT JOB"
                  />
                </>
          );
        }else if (permanentCategory ==="permanentAppliedForJobs"){
          return (
              <>
                <AppliedJobs
                      lastName={lastName}
                      firstName={firstName}
                      countries={countries}
                      provinces={provinces}
                      districts={districts}
                      category="PERMANENT JOB"

                  />
                </>
          );
        }

      }else if (type === "CONTRACTUAL JOB"){
        if (contractualCategory ==="contractualBestMatches"){
          return (
              <><PermanentBestMatches
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="CONTRACTUAL JOB"
                  /></>
          );

        }else if (contractualCategory ==="contractualNearestNeighbours"){
          return (
              <><PermanentNearestNeighbours
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="CONTRACTUAL JOB"
                  /></>
          );
        }else if (contractualCategory ==="contractualAllJobs"){
          return (
              <><AllJobs
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="CONTRACTUAL JOB"
                  /></>
          );
        }else if (contractualCategory ==="contractualAppliedForJobs"){
          return (
              <><AppliedJobs
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="CONTRACTUAL JOB"
                  /></>
          );
        }

      }else if (type === "INTERNSHIP JOB"){
        if (internshipCategory ==="internshipBestMatches"){
          return (
              <><PermanentBestMatches
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="INTERNSHIP JOB"
                  />
                </>
          );

        }else if (internshipCategory ==="internshipNearestNeighbours"){
          return (
              <><PermanentNearestNeighbours
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="INTERNSHIP JOB"
                  />
                </>
          );
        }else if (internshipCategory ==="internshipAllJobs"){
          return (
              <><AllJobs
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="INTERNSHIP JOB"
                  />
                </>
          );
        }else if (internshipCategory ==="internshipAppliedForJobs"){
          return (
              <><AppliedJobs
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="INTERNSHIP JOB"
                />
              </>
          );
        }

      }else if (type === "ONE TIME JOB"){
        if (oneTimeCategory ==="oneTimeBestMatches"){
          return (
              <><PermanentBestMatches
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="ONE TIME JOB"
                  /></>
          );

        }else if (oneTimeCategory ==="oneTimeNearestNeighbours"){
          return (
              <><PermanentNearestNeighbours
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="ONE TIME JOB"
                /></>
          );
        }else if (oneTimeCategory ==="oneTimeAllJobs"){
          return (
              <><AllJobs
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="ONE TIME JOB"
                /></>
          );
        }else if (oneTimeCategory ==="oneTimeAppliedForJobs"){
          return (
              <><AppliedJobs
                  lastName={lastName}
                  firstName={firstName}
                  countries={countries}
                  provinces={provinces}
                  districts={districts}
                  category="ONE TIME JOB"
                /></>
          );
        }

      }else{
        return(
          <>
           SOMETHING WRONG
          </>
        )
      }
    }






    return (
      <JobSeekerLayout>

            <div style={{fontFamily: 'cursive'}} className=" card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center">
                  <div className="text-900 text-primary font-bold text-5xl mb-3">{getHourDisplay()}</div>
                  <div className="text-primary text-2xl mb-5">{firstName} {lastName}</div>
                  <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
              </div>
            </div>
            <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
              <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                    <div className=" col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              value="PERMANENT JOB"

                              onChange={onTypeChange}
                              checked={type === "PERMANENT JOB"}
                            />
                          <label className="text-primary" htmlFor="category1">PERMANENT JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="CONTRACTUAL JOB"
                              onChange={onTypeChange}
                              checked={type === "CONTRACTUAL JOB"}
                            />
                          <label className="text-primary" htmlFor="category2">CONTRACTUAL JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="INTERNSHIP JOB"
                              onChange={onTypeChange}
                              checked={type === "INTERNSHIP JOB"}
                            />
                          <label className="text-primary" htmlFor="category2">INTERNSHIP JOBS</label>
                      </div>
                      <div className="col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="ONE TIME JOB"
                              onChange={onTypeChange}
                              checked={type === "ONE TIME JOB"}
                            />
                          <label className="text-primary" htmlFor="category2">ONE TIME JOBS</label>
                      </div>

                    </li>
                  </ul>

            </div>

            {getTypeDisplay()}

            {getJobsDisplay()}





        </JobSeekerLayout>
    )
}

const mapStateToProps = state =>({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    countries: state.places.countries,
    provinces: state.places.provinces,
    districts: state.places.districts,

})

export default connect(
  mapStateToProps,
  {
    getCountries,
    getProvinces,
    getDistricts,
} )
  (Jobs);

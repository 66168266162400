import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';
import AOS from "aos";
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import dumba from './dumba.jpg';
import feat1 from './images/feat1.svg';
import feat2 from './images/feat2.svg';
import feat3 from './images/feat3.svg';
import feat4 from './images/feat4.svg';
import feature from './images/feature-4.png';
import {
  getUsersMatchedByIndustry

} from '../../actions/matches';

export const MatchedUsers = (props) => {
    const {
      queryParam,
      industrySlug,
      getUsersMatchedByIndustry,
      usersMatchedByIndustry,
      token,
      emptyTitle,
      emptyPlea,
      stingRay
    } = props;

    const [layout, setLayout] = useState('grid');
    const [params, setParams] = useState({filterType:'NEAREST NEIGHBOURS', industry:industrySlug  })
    const navigate = useNavigate();

    const handleClick = (slug) =>{
        navigate(`/employ/industry-matches/${slug}`)
    }

    useEffect(() =>{
        AOS.init({duration : 3000})

    }, []);

    useEffect(() =>{
      props.getUsersMatchedByIndustry(params, token)

    }, [params])




    const shuffle =(array) => {
      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
  }

  const generateRandomPicture = (numberOfPictures) =>{
      const result = [];
      const sequence = [
          feat1,
          feat2,
          feat3,
          feat4,

      ];

      for(let i = 1; i < numberOfPictures; i++){
            result.push(shuffle(sequence))
        }

        return(result[0][0]);
    }




    const renderGridItem = (data) => {
        return (
            <div
                data-aos="zoom-in"
                className="col-12 md:col-4 border-50 shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <img src={generateRandomPicture(4)}  alt="ImageS" className="w-full"/>
                      <div style={{marginTop: '-2rem', top: '-100px'}} className=" col-12 md:w-12 font-medium">
                          <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                      </div>

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                    <div className=" col-12 md:w-12 font-medium">
                                        {data.first_name} {data.last_name}
                                    </div>
                                </li>

                              <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                  <div className=" col-12 md:w-12 font-medium">
                                      <Button
                                          className='p-button-rounded p-button-warning p-button-outlined'
                                          label='VIEW PROFILE'
                                          onClick={() =>handleClick(data.slug)}
                                      />
                                  </div>
                              </li>
                        </ul>
                    </div>
                  </div>
            </div>


        );
    }



    const itemTemplate = (user, layout) => {
        if (!user) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(user);
        else if (layout === 'grid')
            return renderGridItem(user);
    }

    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();

    return (
        <>
          {usersMatchedByIndustry.length === 0
            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                <div className="col-12">
                        <div className="card">
                            <h5 className='text-primary'>
                              {emptyTitle}
                            </h5>
                            <p className='text-primary'>
                              {emptyPlea}
                            </p>
                        </div>
                 </div>
            </div>
            :<div className="card dataview-demo">
                  <DataView
                      value={usersMatchedByIndustry}
                      layout={layout}
                      header={header}
                      itemTemplate={itemTemplate}
                      paginator
                      rows={30}

                  />
              </div>
          }
        </>
    );
};


const mapStateToProps = (state) => ({
    token: state.auth.token,
    usersMatchedByIndustry: state.matches.usersMatchedByIndustry,
})



export default connect(mapStateToProps, {getUsersMatchedByIndustry})(MatchedUsers);

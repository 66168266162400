import React, {useEffect} from 'react';
import JobSeekerLayout from '../layout/JobSeekerLayout';
import {connect} from 'react-redux';
import 'primeicons/primeicons.css';
import usermale from './images/user-with-tie-svgrepo-com.svg';
import userfemale from './images/user-svgrepo-com.svg';
import { TabView, TabPanel } from 'primereact/tabview';
import 'aos/dist/aos.css';
import {
    FaAccusoft
} from "react-icons/fa";
import 'primeicons/primeicons.css';
import { BsLayersFill } from "react-icons/bs";
import {
    MdOutlineCastForEducation,
    MdLanguage,
    MdHomeRepairService,
} from "react-icons/md";
import {
    FiSettings,
    FiActivity,
} from "react-icons/fi";
import { TiLink } from "react-icons/ti";
import {
    GiSkills,
    GiFlagObjective,
} from "react-icons/gi";
import { GrUserExpert } from "react-icons/gr";
import { SiTransportforlondon } from "react-icons/si";
import { FiPocket } from "react-icons/fi";
import bracelet from '../../public/home/bracelet.jpg';



import {
    getCandidateAcademicQualifications,
    getCandidateAvailability,
    getCandidateLangauges,
    getCandidateLinks,
    getCandidateResume,
    getCandidateRoles,
    editCandidateAcademicQualification,
    editCandidateAvailability,
    editCandidateLangauge,
    editCandidateLink,
    editCandidateResume,
    editCandidateRole,
    addCandidateAcademicQualification,
    addCandidateAvailability,
    addCandidateLangauge,
    addCandidateLink,
    addCandidateResume,
    addCandidateRole,
    deleteCandidateAcademicQualification,
    deleteCandidateAvailability,
    deleteCandidateLangauge,
    deleteCandidateLink,
    deleteCandidateResume,
    deleteCandidateRole,
    getCandidateSkills,
    addCandidateSkill,
    editCandidateSkill,
    deleteCandidateSkill,
} from '../../actions/resumes';
import {
    getCountries,
    getProvinces,
    getDistricts

} from '../../actions/places';
import {
    getLanguages

} from '../../actions/languages';
import AcademicQualifications from './AcademicQualifications';
import Languages from './Languages';
import Links from './Links';
import Roles from './Roles';
import Skills from './Skills';
import {
    getFaculties,
    getSkills,

} from '../../actions/skills';
import {getUserProfile} from '../../actions/candidates';
import UserProfile from './UserProfile';
import {
    getCandidateMissions,
    addCandidateMission,
    editCandidateMission,
    deleteCandidateMission,
    getCandidatePortfolios,
    addCandidatePortfolio,
    editCandidatePortfolio,
    deleteCandidatePortfolio,
    addCandidateService,
    editCandidateService,
    deleteCandidateService,
    getCandidateServices,
    getCandidateObjectives,
    deleteCandidateObjective,
    addCandidateObjective,
    editCandidateObjective,
    addPortfolioImage,
    addPortfolioSkill,
    addServiceImage,
    addUserPrivacyAgreement,
    editUserPrivacyAgreement,
    getUserPrivacyAgreement



} from '../../actions/portfolio';
import Portifolios from './Portfolios';
import Services from './Services';
import Configurations from './Configurations';
import feat1 from './images/feat1.svg';
import feat2 from './images/feat2.svg';
import feat3 from './images/feat3.svg';
import feat4 from './images/feature-4.png';



const Profile = (props) => {
    const {
        getCandidateAcademicQualifications,
        getCandidateAvailability,
        getCandidateLangauges,
        getCandidateLinks,
        getCandidateResume,
        getCandidateRoles,
        editCandidateAcademicQualification,
        editCandidateLangauge,
        editCandidateLink,
        editCandidateRole,
        addCandidateAcademicQualification,
        addCandidateLangauge,
        addCandidateLink,
        addCandidateRole,
        deleteCandidateAcademicQualification,
        deleteCandidateLangauge,
        deleteCandidateLink,
        deleteCandidateRole,
        getCountries,
        getProvinces,
        getDistricts,
        getLanguages,
        candidateacademicqualifications,
        candidatelanguages,
        candidatelinks,
        candidateroles,
        languages,
        countries,
        provinces,
        districts,
        firstName,
        lastName,
        token,
        faculties,
        getFaculties,
        getCandidateSkills,
        addCandidateSkill,
        editCandidateSkill,
        deleteCandidateSkill,
        candidateskills,
        skills,
        getSkills,
        getUserProfile,
        profile,
        getCandidateMissions,
        addCandidateMission,
        editCandidateMission,
        deleteCandidateMission,
        getCandidatePortfolios,
        addCandidatePortfolio,
        editCandidatePortfolio,
        deleteCandidatePortfolio,
        addCandidateService,
        editCandidateService,
        deleteCandidateService,
        getCandidateServices,
        getCandidateObjectives,
        deleteCandidateObjective,
        addCandidateObjective,
        editCandidateObjective,
        candidatePortfolios,
        candidateServices,
        candidateMissions,
        candidateObjectives,
        addPortfolioImage,
        addPortfolioSkill,
        addServiceImage,
        addUserPrivacyAgreement,
        editUserPrivacyAgreement,
        getUserPrivacyAgreement,
        privacy,

    } = props;

    useEffect(() =>{
        if(!props.fetched){
            getCandidateAcademicQualifications(props.token);
            getCandidateAvailability(props.token);
            getCandidateLangauges(props.token);
            getCandidateLinks(props.token);
            getCandidateResume(props.token);
            getCandidateRoles(props.token);
            getLanguages(props.token);
            getCountries(props.token);
            getProvinces(props.token);
            getDistricts(props.token);
            getFaculties(props.token);
            getSkills(props.token);
            getCandidateSkills(props.token);
            getUserProfile(props.token);
            getCandidateMissions(props.token);
            getCandidatePortfolios(props.token);
            getCandidateServices(props.token);
            getCandidateObjectives(props.token);
            getUserPrivacyAgreement(props.token);
        };


    }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const shuffle =(array) => {
      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
  }

  const generateRandomPicture = (numberOfPictures) =>{
      const result = [];
      const sequence = [
          feat1,
          feat2,
          feat3,
          feat4,

      ];

      for(let i = 1; i < numberOfPictures; i++){
          result.push(shuffle(sequence))
      }

      return(result[0][0]);
  }




    const tabHeaderITemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FaAccusoft />
                    {options.titleElement}
            </button>
        );
    };

    const tabQualificationsTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <MdOutlineCastForEducation />
                    {options.titleElement}
            </button>
        );
    };

    const tabSettingsTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FiSettings />
                    {options.titleElement}
            </button>
        );
    };

    const tabLanguagesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <MdLanguage />
                    {options.titleElement}
            </button>
        );
    };

    const tabLinksTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <TiLink />
                    {options.titleElement}
            </button>
        );
    };

    const tabSkillsTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <GiSkills />
                    {options.titleElement}
            </button>
        );
    };

    const tabExperienceTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <GrUserExpert />
                    {options.titleElement}
            </button>
        );
    };

    const tabPortfolioTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <SiTransportforlondon />
                    {options.titleElement}
            </button>
        );
    };

    const tabMissionTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FiPocket />
                    {options.titleElement}
            </button>
        );
    };

    const tabObjectivesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <GiFlagObjective />
                    {options.titleElement}
            </button>
        );
    };

    const tabServicesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <MdHomeRepairService />
                    {options.titleElement}
            </button>
        );
    };

    const tabActivitiesTemplate = (options) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                <FiActivity />
                    {options.titleElement}
            </button>
        );
    };


    return (
        <JobSeekerLayout>

            <div className='card text-primary shadow-8' style={{paddingTop: '30px' ,fontFamily: 'cursive'}}>
              <div className="block-contentss">
                  <div>
                      <div>
                        <div
                          style={{height: '290px',  backgroundImage: `url(${feat4}`}}
                        >
                        </div>
                        <div className="px-4 py-5 md:px-6 lg:px-8">
                          <div className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative" style={{marginTop: '-2rem', top: '-70px', marginBottom: '-70px'}}>
                            <div>
                              <div
                                className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
                                style={{width: "150px", height: "150px", borderRadius: "10px"}}
                              >
                                {profile.gender === 'MALE'
                                  ?
                                  <img src={usermale} alt="logo" width="150" height="150"/>
                                  :<></>
                                }
                                {profile.gender === 'FEMALE'
                                  ?
                                  <img src={userfemale} alt="logo" width="150" height="150"/>
                                  :<></>
                                }

                              </div>
                                <div className="text-900 text-3xl font-medium mb-3">
                                  <h1>{firstName} {lastName}</h1>
                                </div>
                              <p className="mt-0 mb-3 text-700 text-xl">
                                ZimJobsCenter
                                </p>
                              <div className="text-600 font-medium">
                                <span>
                                  We Bring The Best For You
                                  </span>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div className="tabview-demo" style={{fontFamily: 'cursive'}}>
                <div className="card">
                    <TabView scrollable>
                        <TabPanel className='flex-1' className='flex-1' header="ABOUT" headerTemplate={tabHeaderITemplate}>
                            <UserProfile
                                profile={profile}
                                getUserProfile={getUserProfile}
                                countries = {countries}
                                provinces = {provinces}
                                districts = {districts}
                            />

                        </TabPanel>
                        <TabPanel className='flex-1'  header="QUALIFICATIONS" headerTemplate={tabQualificationsTemplate}>
                            <AcademicQualifications
                                token = {token}
                                addCandidateAcademicQualification= {addCandidateAcademicQualification}
                                editCandidateAcademicQualification ={editCandidateAcademicQualification}
                                getCandidateAcademicQualifications={getCandidateAcademicQualifications}
                                records = {candidateacademicqualifications}
                                faculties={faculties}
                                getFaculties={getFaculties}
                                deleteQualification={deleteCandidateAcademicQualification}


                            />
                        </TabPanel>
                        <TabPanel className='flex-1' header="LANGUAGES" headerTemplate={tabLanguagesTemplate}>
                            <Languages
                                token = {token}
                                addCandidateLanguage= {addCandidateLangauge}
                                editCandidateLanguage ={editCandidateLangauge}
                                getCandidateLanguages={getCandidateLangauges}
                                records = {candidatelanguages}
                                languages = {languages}
                                deleteCandidateLangauge={deleteCandidateLangauge}

                            />

                        </TabPanel>
                        <TabPanel className='flex-1' header="LINKS" headerTemplate={tabLinksTemplate}>
                            <Links
                                token = {token}
                                addCandidateLink= {addCandidateLink}
                                editCandidateLink ={editCandidateLink}
                                getCandidateLinks={getCandidateLinks}
                                records = {candidatelinks}
                                deleteCandidateLink={deleteCandidateLink}

                            />

                        </TabPanel>
                        <TabPanel className='flex-1' header="EXPERIENCE" headerTemplate={tabExperienceTemplate}>
                            <Roles
                                token = {token}
                                addCandidateRole= {addCandidateRole}
                                editCandidateRole ={editCandidateRole}
                                getCandidateRoles={getCandidateRoles}
                                records = {candidateroles}
                                deleteCandidateRole={deleteCandidateRole}
                                countries = {countries}
                                provinces = {provinces}

                            />

                        </TabPanel>
                        <TabPanel className='flex-1' header="SKILLS" headerTemplate={tabSkillsTemplate}>
                            <Skills
                                token = {token}
                                getCandidateSkills ={getCandidateSkills}
                                addCandidateSkill  = {addCandidateSkill }
                                editCandidateSkill  ={editCandidateSkill}
                                deleteCandidateSkill  ={deleteCandidateSkill}
                                records  ={candidateskills}
                                faculties={faculties}
                                getFaculties={getFaculties}


                            />

                        </TabPanel>
                        <TabPanel className='flex-1' header="PORTFOLIO" headerTemplate={tabPortfolioTemplate}>
                            <Portifolios
                                token = {token}
                                getCandidatePortfolios ={getCandidatePortfolios}
                                addCandidatePortfolio  = {addCandidatePortfolio }
                                editCandidatePortfolio  ={editCandidatePortfolio}
                                deleteCandidatePortfolio  ={deleteCandidatePortfolio}
                                records  ={candidatePortfolios}
                                skills  = {candidateskills}
                                addPortfolioSkill={addPortfolioSkill}
                                addPortfolioImage={addPortfolioImage}
                            />

                        </TabPanel>

                        <TabPanel className='flex-1' header="SERVICES" headerTemplate={tabServicesTemplate}>
                            <Services
                                token = {token}
                                addCandidateService={addCandidateService}
                                editCandidateService={editCandidateService}
                                deleteCandidateService={deleteCandidateService}
                                getCandidateServices={getCandidateServices}
                                records={candidateServices}
                                addServiceImage={addServiceImage}
                            />


                        </TabPanel>

                        <TabPanel className='flex-1' header="SETTINGS" headerTemplate={tabSettingsTemplate}>
                            <Configurations
                              addUserPrivacyAgreement={addUserPrivacyAgreement}
                              editUserPrivacyAgreement={editUserPrivacyAgreement}
                              getUserPrivacyAgreement={getUserPrivacyAgreement}
                              privacy={privacy}
                              token={token}

                            />

                        </TabPanel>

                    </TabView>
                </div>
            </div>

        </JobSeekerLayout>

    );
}



const mapStateToProps = state =>({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    candidateresume: state.resumes.candidateresume,
    candidateavailability: state.resumes.candidateavailability,
    candidateacademicqualifications: state.resumes.candidateacademicqualifications,
    candidatelanguages: state.resumes.candidatelanguages,
    candidatelinks: state.resumes.candidatelinks,
    candidateroles: state.resumes.candidateroles,
    candidateskills: state.resumes.candidateskills,
    languages: state.languages.languages,
    countries: state.places.countries,
    provinces: state.places.provinces,
    districts: state.places.districts,
    skills: state.skills.skills,
    faculties: state.skills.faculties,
    profile: state.candidates.userprofile,
    candidatePortfolios:  state.portfolio.candidatePortfolios,
    candidateServices:  state.portfolio.candidateServices,
    candidateMissions:  state.portfolio.candidateMissions,
    candidateObjectives:  state.portfolio.candidateObjectives,
    privacy: state.portfolio.userPrivacyAgreement,
  })

  export default connect(
    mapStateToProps,
    {
        getCandidateAcademicQualifications,
        getCandidateAvailability,
        getCandidateLangauges,
        getCandidateLinks,
        getCandidateResume,
        getCandidateRoles,
        editCandidateAcademicQualification,
        editCandidateAvailability,
        editCandidateLangauge,
        editCandidateLink,
        editCandidateResume,
        editCandidateRole,
        addCandidateAcademicQualification,
        addCandidateAvailability,
        addCandidateLangauge,
        addCandidateLink,
        addCandidateResume,
        addCandidateRole,
        deleteCandidateAcademicQualification,
        deleteCandidateAvailability,
        deleteCandidateLangauge,
        deleteCandidateLink,
        deleteCandidateResume,
        deleteCandidateRole,
        getCountries,
        getProvinces,
        getDistricts,
        getLanguages,
        getFaculties,
        getCandidateSkills,
        addCandidateSkill,
        editCandidateSkill,
        deleteCandidateSkill,
        getSkills,
        getUserProfile,
        getCandidateMissions,
        addCandidateMission,
        editCandidateMission,
        deleteCandidateMission,
        getCandidatePortfolios,
        addCandidatePortfolio,
        editCandidatePortfolio,
        deleteCandidatePortfolio,
        addCandidateService,
        editCandidateService,
        deleteCandidateService,
        getCandidateServices,
        getCandidateObjectives,
        deleteCandidateObjective,
        addCandidateObjective,
        editCandidateObjective,
        addPortfolioImage,
        addPortfolioSkill,
        addServiceImage,
        addUserPrivacyAgreement,
        editUserPrivacyAgreement,
        getUserPrivacyAgreement
    } )
  (Profile);

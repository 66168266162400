import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Chip } from 'primereact/chip';
import { Calendar } from 'primereact/calendar';


const levelOfEducationOptions = [
    {
        key: 'BACHELORS', value: 'BACHELORS'
    },
    {
        key: 'MBA', value: 'MBA'
    },
    {
        key: 'DIPLOMA', value: 'DIPLOMA'
    },
    {
        key: 'DOCTORATE', value: 'DOCTORATE'
    },
    {
        key: 'MASTERS', value: 'MASTERS'
    },
    {
        key: 'SECONDARY OR HIGH SCHOOL', value: 'SECONDARY OR HIGH SCHOOL'
    },
    {
        key: 'CERTIFICATE', value: 'CERTIFICATE'
    },

]


const AcademicQualifications = (props) => {
    const {
        token,
        addCandidateAcademicQualification,
        editCandidateAcademicQualification,
        getCandidateAcademicQualifications,
        records,
        faculties,
        deleteQualification,

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    let initialValues = {
        level_of_education: '',
        institution: '',
        start_date: '',
        end_date: '',
        faculty:'',
        qualification:'',

    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [layout, setLayout] = useState('grid');


    useEffect(() => {
        getCandidateAcademicQualifications(token);
        setIsSubmit(false);

    }, [isSubmit]);



    const deleteQual = (slug) =>{
        deleteQualification(slug, token)
        setIsSubmit(true);

    }


    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('level_of_education' in fieldValues)
            temp.level_of_education= fieldValues.level_of_education? "" : "Level of Education is Required"
        if ('qualification' in fieldValues)
            temp.qualification= fieldValues.qualification? "" : "Name is Required"
        if ('faculty' in fieldValues)
            temp.faculty= fieldValues.faculty? "" : "Faculty is Required"
        if ('institution' in fieldValues)
            temp.institution= fieldValues.institution? "" : "Institution is Required"
        if ('start_date' in fieldValues)
            temp.start_date= fieldValues.start_date? "" : "Start Date is Required"
        if ('end_date' in fieldValues)
            temp.end_date= fieldValues.end_date? "" : "End Date is Required"

        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                setErrors(validate(record));
                setIsSubmit(true);
                editCandidateAcademicQualification(record.slug, record, token);
                getCandidateAcademicQualifications(token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                addCandidateAcademicQualification(record, token);
                getCandidateAcademicQualifications(token);
                resetForm();
                setDisplayMaximizable(false);
            }

          }
        //
    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }

    const editAcademicQualification = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }






    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD NEW'

                    className="p-button-primary p-button-success p-button-outlined"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>



            </React.Fragment>
        )
    }


    const monthNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
          />
        );
    };

    const yearNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
          />
      )
    }

    const dialogAcademicQualificationHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>
  
                <span className='text-primary'>Academic Qualification Form</span>
              </div>
          )
      }



    const renderGridItem = (data) => {
        return (

            <div
                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">

                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        PROFFESSION
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.faculty}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        NAME
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.qualification}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        INSTITUTION
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.institution}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        START DATE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.start_date}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        END DATE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.end_date}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                    <div className="col-12 md:w-6 font-medium">
                                        <Button
                                            icon="pi pi-pencil"
                                            label='UPDATE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => editAcademicQualification(data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-6 font-medium">
                                        <Button
                                            icon="pi pi-trash"
                                            label='DELETE'
                                            className="p-button-rounded p-button-outlined p-button-danger"
                                            onClick={() => deleteQual(data.slug)}
                                        />
                                    </div>
                                </li>

                        </ul>

                    </div>
                    </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();



    return (
        <>

            <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}

            />
            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY OPPORTUNITIES</h5>
                                            <p className='text-primary'>CURRENTLY YOU DO NOT HAVE ANY MATCHING OPPORTUNITIES UNDER THIS CATEGORY</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO JOBS MATCHING YOUR SKILLS AS PER YOUR PROFILE"
                                />
                            </div>
                        }
                    </div>
                </div>


            </div>
                <Dialog header={dialogAcademicQualificationHeader()}
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '1000px' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT THE FACULTY
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.faculty}
                                                optionLabel="title"
                                                optionValue="title"
                                                options={faculties}
                                                onChange={(e) => onInputChange(e, 'faculty')}
                                                filter
                                                filterBy="title"
                                            />
                                            <small id="username2-help"  className="p-error block">{errors.faculty}</small>
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT THE ACADEMIC LEVEL
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.level_of_education}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={levelOfEducationOptions}
                                                onChange={(e) => onInputChange(e, 'level_of_education')}
                                                filter
                                                filterBy="key"
                                            />
                                            <small id="username2-help"  className="p-error block">{errors.level_of_education}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHAT WAS THE NAME OF YOUR COURSE ?
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="start_date"
                                                value={record.qualification}
                                                onChange={(e) => onInputChange(e, 'qualification')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.qualification}</small>


                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHAT WAS THE NAME OF THE INSTITUTION ?
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="institution"
                                                value={record.institution}

                                                onChange={(e) => onInputChange(e, 'institution')}

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.institution}</small>


                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHEN DID YOU START ?
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                             {record.slug !== undefined
                                               ?<InputText
                                                   id="start_date"
                                                   type="date"
                                                   name="start_date"
                                                   className="w-full mb-3"
                                                   value={record.start_date}
                                                   onChange={(e) => onInputChange(e,"start_date")}

                                               />
                                               :<Calendar
                                                  id="navigatorstemplate"
                                                  showIcon
                                                  value={record.start_date}
                                                  onChange={(e) => onInputChange(e,"start_date")}
                                                  monthNavigator
                                                  yearNavigator
                                                  dateFormat="yy-mm-dd"
                                                  yearRange="1900:2030"
                                                  monthNavigatorTemplate={monthNavigatorTemplate}
                                                  yearNavigatorTemplate={yearNavigatorTemplate}
                                              />
                                            }
                                            <small id="username2-help"  className="p-error block">{errors.start_date}</small>


                                        </div>
                                    </li>
                                     <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHEN DID YOUR COURSE END ?
                                        </div>
                                        <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1">
                                          {record.slug !== undefined
                                              ?<InputText
                                                  id="end_date"
                                                  type="date"
                                                  name="end_date"
                                                  className="w-full mb-3"
                                                  value={record.end_date}
                                                  onChange={(e) => onInputChange(e,"end_date")}
                                              />
                                              :<Calendar
                                                  id="navigatorstemplate"
                                                  showIcon
                                                  value={record.end_date}
                                                  onChange={(e) => onInputChange(e,"end_date")}
                                                  monthNavigator
                                                  yearNavigator
                                                  dateFormat="yy-mm-dd"
                                                  yearRange="1900:2030"
                                                  monthNavigatorTemplate={monthNavigatorTemplate}
                                                  yearNavigatorTemplate={yearNavigatorTemplate}
                                              />
                                            }
                                            <small id="username2-help"  className="p-error block">{errors.end_date}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <Button
                                            type="submit"
                                            label="ADD QUALIFICATION"
                                            className="p-button-outlined p-button-warning"
                                        />
                                    </li>
                                </ul>

                            </div>
                        </form>
                    </div>

                </Dialog>


        </>
    );
}


export default AcademicQualifications;

import React from 'react';
import { Button } from 'primereact/button';
import PublicLayout from './public/layout/PublicLayout';
import { useNavigate } from 'react-router-dom';


const UnAuthorized = () => {
    const navigate = useNavigate()

    return(
        <PublicLayout>
            <div className="surface-0 text-700 text-center">
                <div className="text-blue-600 font-bold mb-3">
                    <i className="pi pi-discord"></i>
                    &nbsp;POWERED BY HLETIPANG
                </div>
                <div className="text-900 font-bold text-5xl mb-3">
                    UNAUTHORIZED REQUEST
                </div>
                <div className="text-700 text-2xl mb-5">
                    YOU ARE NOT AUTHORIZED TO VIEW THAT PAGE
                </div>
                <Button 
                    label="LOGIN" 
                    icon="pi pi-discord"
                    onClick={() =>{navigate('/login')}}
                    className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap" />
            </div>
        </PublicLayout>

    );
}


export default UnAuthorized;

import React, {useState} from 'react';
import { Button } from 'primereact/button';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Chip } from 'primereact/chip';



const Roles = (props) => {
    const {
        records,
    } = props;
    const [layout, setLayout] = useState('grid');




    const renderGridItem = (data) => {
        return (

            <div
                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">

                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.occupation}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CATEGORY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.category}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        COMPANY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.entity}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        COUNTRY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.country}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CITY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.city}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        FROM MONTH
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.from_month}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        FROM
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.from_year}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>

                                {data.still_working_there

                                    ?<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            I AM STILL EMPLOYED THERE
                                        </div>

                                    </li>
                                    :
                                    <>
                                        <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className=" col-12 md:w-12 font-medium">
                                                To MONTH
                                            </div>
                                            <div className=" col-12 md:w-12 font-medium">
                                                <Chip label={data.to_month}  className="mr-2 mb-2 text-primary" />

                                            </div>
                                        </li>
                                        <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className=" col-12 md:w-12 font-medium">
                                                To YEAR
                                            </div>
                                            <div className=" col-12 md:w-12 font-medium">
                                                <Chip label={data.to_year}  className="mr-2 mb-2 text-primary" />

                                            </div>
                                        </li>
                                    </>
                                }


                        </ul>

                    </div>
                    </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();

    return (
        <div>
            <div className="card">
              <div style={{textAlign: 'center'}} className="text-primary">
                  <div className="grid ripple-demo">

                      <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                          {records.length === 0
                              ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                  <div className="col-12">
                                          <div className="card">
                                              <h5 className='text-primary'>EMPTY LANGUAGES</h5>
                                              <p className='text-primary'>CURRENTLY THERE ARE NO LANGAUGES</p>
                                          </div>
                                  </div>
                              </div>
                              :<div className="dataview-demo">
                                  <DataView
                                      value={records}
                                      layout={layout}
                                      header={header}
                                      itemTemplate={itemTemplate}
                                      paginator
                                      rows={15}
                                      emptyMessage="CURRENTLY THERE ARE NO LANGAUGES"
                                  />
                              </div>
                          }
                      </div>
                  </div>
              </div>

            </div>
        </div>
    );
}


export default Roles;

import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import JobSeekerLayout from '../layout/JobSeekerLayout';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import DailyApplicationReports from './DailyApplicationReports';
import WeeklyApplicationReports from './WeeklyApplicationReports';
import MonthlyApplicationReports from './MonthlyApplicationReports';
import YearlyApplicationReports from './YearlyApplicationReports';
import AOS from "aos";
import 'aos/dist/aos.css';

export const Reports = (props) => {

    const [hour, setHour] = useState(null);
    const [type, setType] = useState('DAILY');

    const {
        lastName,
        firstName,
      } = props;

    const onTypeChange = (e) => {
        setType(e.value);
    }



    const getHour = () => {
        const date = new Date();
        const hour = date.getHours()
        setHour(hour);
    }

    useEffect(() => {
        getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>{
      AOS.init({duration : 3000})

    }, []);

      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;


        const getHourDisplay = () => {

          if (hour < 12){
            return(
              <>
                Good Morning!
              </>
            )
          }else if(hour < 18){
            return (
              <>Good Afternoon!!</>
            )
          }else{
            return(
              <>Good Evening!!</>
            );
          }
      }



    const getReportsDisplay = () =>{
        if (type === "DAILY"){
            return(
                <>
                  <DailyApplicationReports />
                </>
            )
        }else if (type === "WEEKLY"){
            return(
                <>
                  <WeeklyApplicationReports />
                </>
            )
        }else if (type === "MONTHLY"){
            return(
                <>
                  <MonthlyApplicationReports />
                </>
            )
        }else if (type === "YEARLY"){
            return(
                <>
                  <YearlyApplicationReports />
                </>
            )
        }else if (type === "BY FILTERS"){
            return(
                <>
                  </>
            )
        }else{
          return(
              <>
                  THERE IS A PROBLEM
              </>
          )
        }
    }




  return (
    <JobSeekerLayout>
        <div data-aos="zoom-in" className="surface-section card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center" style={{fontFamily: 'cursive'}}>
                  <div className="text-900 font-bold text-5xl mb-3">{getHourDisplay()}</div>
                  <div className="text-700 text-2xl mb-5">{firstName} {lastName}</div>
                  <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
              </div>
            </div>
            <div data-aos="zoom-in" className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                  <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                        <div className=" col-12 md:w-1 font-medium">
                        </div>
                        <div className=" col-12 md:w-2 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              value="DAILY"

                              onChange={onTypeChange}
                              checked={type === "DAILY"}
                            />
                          <label className="" htmlFor="category1">DAILY</label>
                      </div>
                      <div className=" col-12 md:w-2 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="WEEKLY"
                              onChange={onTypeChange}
                              checked={type === "WEEKLY"}
                            />
                          <label className="" htmlFor="category2">WEEKLY</label>
                      </div>
                      <div className=" col-12 md:w-2 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="MONTHLY"
                              onChange={onTypeChange}
                              checked={type === "MONTHLY"}
                            />
                          <label className="" htmlFor="category2">MONTHLY</label>
                      </div>
                      <div className=" col-12 md:w-2 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="YEARLY"
                              onChange={onTypeChange}
                              checked={type === "YEARLY"}
                            />
                          <label className="" htmlFor="category2">YEARLY</label>
                      </div>
                      <div className=" col-12 md:w-2 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="BY FILTERS"
                              onChange={onTypeChange}
                              checked={type === "BY FILTERS"}
                            />
                          <label className="" htmlFor="category2">BY FILTERS</label>
                      </div>

                    </li>
                  </ul>

            </div>
            {getReportsDisplay()}

    </JobSeekerLayout>
  )
}



const mapStateToProps = (state) => ({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,

})


export default connect(mapStateToProps, {})
(Reports)

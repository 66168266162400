import {
  VALIDATE_EMAIL_START,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAIL,
  VALIDATE_USERNAME_START,
  VALIDATE_USERNAME_SUCCESS,
  VALIDATE_USERNAME_FAIL,
  VALIDATE_PASSWORD_START,
  VALIDATE_PASSWORD_SUCCESS,
  VALIDATE_PASSWORD_FAIL,
  PHONE_NUMBER_UNIQUE_VALIDATION_START,
  PHONE_NUMBER_UNIQUE_VALIDATION_SUCCESS,
  PHONE_NUMBER_UNIQUE_VALIDATION_FAIL,
  EMAIL_EXISTS_VALIDATION_START,
  EMAIL_EXISTS_VALIDATION_SUCCESS,
  EMAIL_EXISTS_VALIDATION_FAIL,
  TOKEN_EXPIRY_VALIDATION_START,
  TOKEN_EXPIRY_VALIDATION_SUCCESS,
  TOKEN_EXPIRY_VALIDATION_FAIL,

} from '../types/validationTypes';
import {updateObject} from './utility';

const initialState ={
  emailValidation: {},
  usernameValidation: {},
  passwordValidation: {},
  error: null,
  loading: false,
  phoneNumberValidation: {},
  emailExistsValidation: {},
  tokenExpiryValidation: {},
}

const validateEmailStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const validateEmailSuccess = (state, action) => {
  return updateObject(state, {
    emailValidation: action.emailValidation,
    error: null,
    loading: false
  });
};


const validateEmailFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const validateUsernameStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const validateUsernameSuccess = (state, action) => {
  return updateObject(state, {
    usernameValidation: action.usernameValidation,
    error: null,
    loading: false
  });
};


const validateUsernameFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};



const validatePasswordStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const validatePasswordSuccess = (state, action) => {
  return updateObject(state, {
    passwordValidation: action.passwordValidation,
    error: null,
    loading: false
  });
};


const validatePasswordFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const phoneNumberValidationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const phoneNumberValidationSuccess = (state, action) => {
  return updateObject(state, {
    phoneNumberValidation: action.phoneNumberValidation,
    error: null,
    loading: false
  });
};


const phoneNumberValidationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const emailExistsValidationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const emailExistsValidationSuccess = (state, action) => {
  return updateObject(state, {
    emailExistsValidation: action.emailExistsValidation,
    error: null,
    loading: false
  });
};


const emailExistsValidationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};


const tokenExpiryValidationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};


const tokenExpiryValidationSuccess = (state, action) => {
  return updateObject(state, {
    tokenExpiryValidation: action.tokenExpiryValidation,
    error: null,
    loading: false
  });
};


const tokenExpiryValidationFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};






export default function validations(state = initialState, action){
    switch(action.type){
      case VALIDATE_EMAIL_START:
    		return validateEmailStart(state, action);
    	case VALIDATE_EMAIL_SUCCESS:
    		return validateEmailSuccess(state, action);
    	case VALIDATE_EMAIL_FAIL:
    		return validateEmailFail(state, action);
      //
      case VALIDATE_USERNAME_START:
    		return validateUsernameStart(state, action);
    	case VALIDATE_USERNAME_SUCCESS:
    		return validateUsernameSuccess(state, action);
    	case VALIDATE_USERNAME_FAIL:
    		return validateUsernameFail(state, action);
      //
      case VALIDATE_PASSWORD_START:
    		return validatePasswordStart(state, action);
    	case VALIDATE_PASSWORD_SUCCESS:
    		return validatePasswordSuccess(state, action);
    	case VALIDATE_PASSWORD_FAIL:
    		return validatePasswordFail(state, action);
      //
      case PHONE_NUMBER_UNIQUE_VALIDATION_START:
    		return phoneNumberValidationStart(state, action);
    	case PHONE_NUMBER_UNIQUE_VALIDATION_SUCCESS:
    		return phoneNumberValidationSuccess(state, action);
    	case PHONE_NUMBER_UNIQUE_VALIDATION_FAIL:
    		return phoneNumberValidationFail(state, action);
      //
      case EMAIL_EXISTS_VALIDATION_START:
    		return emailExistsValidationStart(state, action);
    	case EMAIL_EXISTS_VALIDATION_SUCCESS:
    		return emailExistsValidationSuccess(state, action);
    	case EMAIL_EXISTS_VALIDATION_FAIL:
    		return emailExistsValidationFail(state, action);

      //
      case TOKEN_EXPIRY_VALIDATION_START:
    		return tokenExpiryValidationStart(state, action);
    	case TOKEN_EXPIRY_VALIDATION_SUCCESS:
    		return tokenExpiryValidationSuccess(state, action);
    	case TOKEN_EXPIRY_VALIDATION_FAIL:
    		return tokenExpiryValidationFail(state, action);
      default:
          return state;
    }
}

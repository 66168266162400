import React, {useEffect} from 'react';
import { Button } from 'primereact/button';
import PublicLayout from '../layout/PublicLayout';
import zimjobs from '../layout/zimjobs.png';
import bracelet from '../home/bracelet.jpg';
import AOS from "aos";
import 'aos/dist/aos.css';



const About = () =>{

    useEffect(() =>{
      AOS.init({duration : 3000})

    }, []);
    return(
        <PublicLayout>
          <div data-aos="zoom-in" className='card  shadow-8' style={{paddingTop: '30px'}}>
            <div className="block-contentss">
                <div>
                    <div>
                      <div
                        style={{height: '200px', backgroundImage: `url(${bracelet}`}}
                      >
                      </div>
                      <div className="px-4 py-5 md:px-6 lg:px-8">
                        <div className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative" style={{marginTop: '-2rem', top: '-70px', marginBottom: '-70px'}}>
                          <div>
                            <div
                              className="mb-3 surface-card shadow-2 flex align-items-center justify-content-center"
                              style={{width: "140px", height: "140px", borderRadius: "10px"}}
                            >
                              <img
                                  src={zimjobs}
                                  alt="ImageS"
                                  style={{width: "70px",  height: "70px"}}

                              />

                            </div>
                              <div className="text-900 text-3xl font-medium text-primary mb-3">

                              </div>
                            <p className="mt-0 mb-3 text-700 text-primary text-xl">

                              </p>
                            <div className="text-600 font-medium text-primary">
                              <span>

                                </span>
                              </div>
                            </div>
                            <div className="mt-3 lg:mt-0">
                              <button className="p-button p-component p-button-outlined mr-2">
                                <span className="p-button-icon p-c p-button-icon-left pi pi-plus-circle">
                                </span>
                                <span className="p-button-label p-c">
                                  Follow
                                </span>
                                <span className="p-ink" style={{height: '96px', width: '96px', top: '-24px', left: "1.125px"}}>
                                </span>
                              </button>
                              <button className="p-button p-component mr-2">
                                <span className="p-button-icon p-c p-button-icon-left pi pi-link">
                                </span>
                                <span className="p-button-label p-c">
                                  Website
                                </span>
                                <span className="p-ink">
                                  </span>
                                </button>
                                <button className="p-button p-component p-button-outlined p-button-rounded p-button-icon-only">
                                  <span className="p-button-icon p-c pi pi-ellipsis-v">
                                  </span>
                                  <span className="p-button-label p-c">
                                    &nbsp;
                                  </span>
                                  <span className="p-ink"
                                    style={{height: '38px', width: '38px', top: '-4.29688px', left: "-0.296875px"}}>
                                  </span>
                                </button>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
          </div>
          <div clasName="grid p-fluid flex"
            style={{paddingBottom: '30px', paddingLeft: '60px', paddingRight: '60px', marginTop: '-5rem', top: '-90px'}}
          >

            <div
              data-aos="zoom-in"
              className="card  col-12 md:col-12"
              style={{textAlign: 'center', fontFamily: 'cursive'}}

            >

                <div className="text-500 text-primary mb-5">
                  Zim Jobs Center is an exclusive network of the top freelancers, jobseekers,
                  and recruiters from various places in the world.
                  Top companies hire Hletipang Freelancers & Jobseeksers for their most important projects.
                </div>
                <ul className="list-none p-0 m-0 border-top-1 border-300">


                  <li className="flex align-items-center py-3 px-2 flex-wrap">
                    <div className="text-500 w-full md:w-2 font-medium text-primary">
                      Who uses Zim Jobs Center
                    </div>
                    <div className="text-900 w-full md:w-10">
                      <span className="p-tag p-component p-tag-rounded mr-2">
                        <span className="p-tag-value">
                          RECRUITERS
                        </span>
                        <span>
                        </span>
                      </span>
                      <span className="p-tag p-component p-tag-success p-tag-rounded mr-2">
                        <span className="p-tag-value">
                        FREELANCERS
                      </span>
                      <span>
                      </span>
                      </span>
                      <span className="p-tag p-component p-tag-danger p-tag-rounded mr-2">
                      <span className="p-tag-value">
                        JOBSEEKERS
                      </span>
                      <span>
                      </span>
                      </span>
                      <span className="p-tag p-component p-tag-warning p-tag-rounded">
                        <span className="p-tag-value">
                          INTERNS
                        </span>
                        <span>
                        </span>
                      </span>
                    </div>
                  </li>
                  <li className="flex align-items-center py-3 px-2 flex-wrap">
                    <div className="text-500 w-full md:w-2 font-medium text-primary">
                      With Zim Jobs Center
                    </div>
                    <div className="text-900 w-full md:w-10">
                      <div className="grid mt-0 mr-0">
                        <div className="col-12 md:col-6">
                          <div className="p-3 border-1 border-300 border-round card">
                            <div className="text-900 mb-2">
                              <i className="pi pi-users text-primary mr-2">
                              </i>
                              <span className="font-medium text-primary">
                                RECRUITERS
                              </span>
                            </div>
                            <div className="text-700 text-primary">
                              GET ACCESS TO OUR TOP TALENT
                            </div>
                          </div>
                        </div>
                        <div className="col-12 md:col-6 ">
                          <div className="p-3 border-1 border-300 border-round card">
                            <div className="text-900 mb-2">
                              <i className="pi pi-users text-primary mr-2">
                              </i>
                              <span className="font-medium text-primary">
                                FREELANCERS
                              </span>
                            </div>
                            <div className="text-700 text-primary">
                              AN OPPORTUNITY TO ADVERTISE YOUR SKILLS
                            </div>
                          </div>
                        </div>
                        <div className="col-12 md:col-6 ">
                          <div className="p-3 border-1 border-300 border-round card">
                          <div className="text-900 mb-2">
                            <i className="pi pi-users text-primary mr-2"></i>
                          <span className="font-medium text-primary">
                            JOB SEEKERS
                          </span>
                        </div>
                        <div className="text-700 text-primary">
                          GAIN ACCESS TO JOB OPPORTUNITIES
                        </div>
                      </div>
                    </div>
                    <div className="col-12 md:col-6 ">
                      <div className="p-3 border-1 border-300 border-round card">
                        <div className="text-900 mb-2">
                          <i className="pi pi-users text-primary mr-2"></i>
                            <span className="font-medium text-primary">
                              INTERNS
                            </span>
                        </div>
                        <div className="text-700 text-primary">
                          FIND HUSTLE FREE INTERNSHIP
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </li>
              </ul>
            </div>

          </div>

        </PublicLayout>

    );
}


export default About;

import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import {connect} from 'react-redux';
import {
    getEmployerJobs
    
} from '../../actions/recruiters';
import { Chip } from 'primereact/chip';
import { useNavigate } from 'react-router-dom';
import ManagementLayout from '../layout/ManagementLayout';
import { useParams } from 'react-router-dom';




const Jobs = (props) => {
    const [layout, setLayout] = useState('list');
    const navigate = useNavigate();
    const unpublishedJobs = props.records.filter((job) => job.visibility_status ==="VISIBLE")
    const {slug} = useParams()

    const handleClick = slug =>{
        navigate(`/management/published-jobs/${slug}`)
    }
    


    useEffect(() => {
        if (!props.fetched){
            props.getEmployerJobs(slug, props.token);
        }
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    
    

    const renderListItem = (data) => {
        return (
            <div className="col-12">
                <div className="product-list-items card">
                     <div className="surface-1">
                        <ul className="list-none p-0 m-0">
                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium">TITLE</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    <Chip label={data.title}  className="mr-2 mb-2" />
                                </div>
                                
                            </li>
                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium">DATE, DURATION EXPECTATION</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    <Chip label={data.created_at} icon='pi pi-calendar'  className="mr-2 mb-2" />
                                    <Chip label={data.expected_duration} icon='pi pi-calendar'  className="mr-2 mb-2" />
                                </div>
                                
                            </li>
                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium">JOB TYPE & REQUIRED SKILLS</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    <Chip label={data.category} icon="pi pi-check" className="mr-2 mb-2" />
                                    <Chip label={data.required_skillset} icon="pi pi-check" className="mr-2 mb-2" />
                                </div>
                                
                            </li>
                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium">
                                    LOCATION,  JOB TYPE, PREFERED CITY, COUNTRY & PREFFERED DISTRICT
                                </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    <Chip label={data.location_type} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                                    <Chip label={data.location} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                                    <Chip label={data.country} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                                    <Chip label={data.preffered_city} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                                    <Chip label={data.preffered_district} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                                </div>
                                
                            </li>

                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium">
                                    PAYMENT TYPE, FIXED PRICE BUDGET, FROM HOURLY PRICE BUDGET & FROM HOURLY PRICE BUDGET
                                </div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    <Chip label={data.charge_category} className="mr-2 mb-2" />
                                    <Chip label={data.fixed_price} icon="pi pi-dollar" className="mr-2 mb-2" />
                                    <Chip label={data.from_hourly_price} icon="pi pi-dollar" className="mr-2 mb-2" />---
                                    <Chip label={data.to_hourly_price} icon="pi pi-dollar" className="mr-2 mb-2" /> PER HOUR
                                </div>
                                
                            </li>

                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium">DESCRIPTION</div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    
                                    {data.description}
                                </div>
                                
                            </li>
                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                <div className="text-500 w-6 md:w-4 font-medium"></div>
                                <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                    
                                    <Button 
                                        label="VIEW MORE DETAILS & PUBLISH THIS JOB" 
                                        className="p-button-outlined" 
                                        onClick={() =>handleClick(data.slug)}
                                    />   
                                </div>
                                
                                
                            </li>
                        </ul>
                        
                    </div>
                </div>
                   
            </div>
        );
    }


    const renderGridItem = (data) => {
        return (
            <div className="col-12 md:col-4">
                <div 
                    className="card job_grid"
                    
                >
                    <div className="flex align-items-center justify-content-between mb-4">
                        <h5>{data.title}</h5>
                    </div>
                    <span className="block text-600 font-medium mb-3 ">JOB TYPE & REQUIRED SKILSS</span>
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                        <Chip label={data.category} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                        <Chip label={data.required_skillset} icon="pi pi-flag-fill" className="mr-2 mb-2" />

                        </li>             
                    </ul>
                    <span className="block text-600 font-medium mb-3">LOCATION</span>
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <Chip label={data.location_type} icon="pi pi-flag-fill" className="mr-2 mb-2" />
                            <Chip label={data.location} icon="pi pi-flag-fill" className="mr-2 mb-2" />

                        </li>
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                           
                            <Chip label={data.country} icon="pi pi-flag-fill" className="mr-2 mb-2" />

                        </li>              
                    </ul>
                    <span className="block text-600 font-medium mb-3">CHARGE CATEGORY & BUDGET</span>
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <Chip label={data.charge_category} icon="pi pi-dollar" className="mr-2 mb-2" />
                            <Chip label={data.fixed_price} icon="pi pi-dollar" className="mr-2 mb-2" />
                        </li>             
                    </ul>

                    <span className="block text-600 font-medium mb-3">FROM-TO HOURLY BUDGETS</span>
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <Chip label={data.from_hourly_price} icon="pi pi-dollar" className="mr-2 mb-2" />--
                            <Chip label={data.to_hourly_price} icon="pi pi-dollar" className="mr-2 mb-2" />
                        </li> 
                                    
                    </ul>
                    <span className="block text-600 font-medium mb-3">DURATION</span>
                    <ul className="p-0 mx-0 mt-0 mb-4 list-none">
                        <li className="flex align-items-center py-2 border-bottom-1 surface-border">
                            <Chip label={data.duration_category} icon="pi pi-calendar" className="mr-2 mb-2" />--
                            <Chip label={data.expected_duration} icon="pi pi-calendar-minus" className="mr-2 mb-2" />
                        </li>            
                    </ul>
                    <Button 
                        label="VIEW MORE DETAILS" 
                        className="p-button-outlined" 
                        onClick={() =>handleClick(data.slug)}
                    />
                    
                </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>
                    
                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions 
                        layout={layout} 
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }








    const header = renderHeader();
    

    return (
        <ManagementLayout>
            <div className="dataview-demo">
                <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                <div className="text-700 text-center">
                    <div className="text-500 font-bold text-5xl mb-3">VERIFY & PUBLISH THESE JOBS</div>
                    <div className="text-500 text-2xl mb-5"> YOU NEED TO PUBLISH THESE JOBS FOR THEM TO BE VISIBLE TO PROSPECTIVE CANDIDATES</div>
                    <Button label={`${unpublishedJobs.length} UNPUBLISHED JOBS`} disabled icon="pi pi-lock" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
                </div>
                <div className="card">
                    <DataView 
                        value={unpublishedJobs} 
                        layout={layout} 
                        header={header}
                        itemTemplate={itemTemplate} 
                        paginator rows={30}
                    />
                </div>
            </div>
            
            
        </ManagementLayout>
    );
}




const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.recruiters.employerjobs,
})

export default connect(
  mapStateToProps,
  {getEmployerJobs
} )
  (Jobs);
   
import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import {connect} from 'react-redux';
import {
    getRecruiterJobs

} from '../../actions/jobs';
import { Chip } from 'primereact/chip';
import { useNavigate } from 'react-router-dom';
import EmployerLayout from '../layout/EmployerLayout';
import { RadioButton } from 'primereact/radiobutton';
import PublishedContainer  from './PublishedContainer';
import { Badge } from 'primereact/badge';
import AOS from "aos";
import 'aos/dist/aos.css';




const Jobs = (props) => {

      const [hour, setHour] = useState(null);

      const [type, setType] = useState('PERMANENT JOB');
      const {
          lastName,
          firstName,
        } = props;
      const publishedPermanentJobs = props.records.filter((job) => job.visibility_status ==="VISIBLE" && job.category==="PERMANENT JOB");
      const publishedContractualJobs = props.records.filter((job) => job.visibility_status ==="VISIBLE" && job.category==="CONTRACTUAL JOB");
      const publishedInternshipJobs = props.records.filter((job) => job.visibility_status ==="VISIBLE" && job.category==="INTERNSHIP JOB");
      const publishedTemporaryJobs = props.records.filter((job) => job.visibility_status ==="VISIBLE" && job.category==="ONE TIME JOB");


      const onTypeChange = (e) => {
          setType(e.value);
        }

      const getHour = () => {
          const date = new Date();
          const hour = date.getHours()
          setHour(hour);
      }

      useEffect(() => {
          getHour();

      }, []) // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() => {
          if (!props.fetched){
              props.getRecruiterJobs(props.token);
          }

      }, []); // eslint-disable-line react-hooks/exhaustive-deps

      useEffect(() =>{
        AOS.init({duration : 3000})

      }, []);



        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;




          const getHourDisplay = () => {

            if (hour < 12){
              return(
                <>
                  Good Morning!
                </>
              )
            }else if(hour < 18){
              return (
                <>Good Afternoon!!</>
              )
            }else{
              return(
                <>Good Evening!!</>
              );
            }
        }



      const getJobDisplay = () =>{
          if (type === "PERMANENT JOB"){
              return(
                  <>
                      <PublishedContainer category="PERMANENT JOB"/>
                  </>
              )
          }else if (type === "CONTRACTUAL JOB"){
              return(
                  <>
                      <PublishedContainer category="CONTRACTUAL JOB"/>
                  </>
              )
          }else if (type === "INTERNSHIP JOB"){
              return(
                  <>
                      <PublishedContainer category="INTERNSHIP JOB"/>
                  </>
              )
          }else if (type === "ONE TIME JOB"){
              return(
                  <>
                      <PublishedContainer category="ONE TIME JOB"/>
                  </>
              )
          }else{
              return(
                  <>
                      SOMETHING WRONG
                  </>
              )
          }
      }






    return (
        <EmployerLayout>
          <div data-aos="zoom-in" className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                <div className="text-700 text-center" style={{fontFamily: 'cursive'}}>
                    <div className="text-900 font-bold text-5xl mb-3">{getHourDisplay()}</div>
                    <div className="text-700 text-2xl mb-5">{firstName} {lastName}</div>
                    <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-outlined p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
              </div>
              <div data-aos="zoom-in" className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                  <h5>THESE ARE THE PUBLISHED JOBS BASED ON THEIR CURRENT STATUS</h5>
                      <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                        <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                          <div className=" col-12 md:w-3 font-medium">
                            <RadioButton
                                inputId="category1"
                                style={{paddingRight: '20px'}}
                                name="category"
                                value="PERMANENT JOB"
                                onChange={onTypeChange}
                                checked={type === "PERMANENT JOB"}
                              />

                            <label className="text-primary" htmlFor="category1">
                              PERMANENT JOBS
                              <small style={{paddingLeft: '10px'}}>
                                {publishedPermanentJobs.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={publishedPermanentJobs.length} serverity='success'className="mr-2"/> }
                              </small>
                            </label>
                          </div>
                          <div className=" col-12 md:w-3 font-medium">
                            <RadioButton
                                inputId="category2"
                                style={{paddingRight: '20px'}}
                                name="category"
                                value="CONTRACTUAL JOB"
                                onChange={onTypeChange}
                                checked={type === "CONTRACTUAL JOB"}
                              />
                            <label className="text-primary" htmlFor="category1">
                              CONTRACTUAL JOBS
                              <small style={{paddingLeft: '10px'}}>
                                {publishedContractualJobs.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={publishedTemporaryJobs.length} serverity='success'className="mr-2"/> }
                              </small>
                            </label>
                          </div>
                          <div className=" col-12 md:w-3 font-medium">
                              <RadioButton
                                inputId="category2"
                                style={{paddingRight: '20px'}}
                                name="category"
                                value="INTERNSHIP JOB"
                                onChange={onTypeChange}
                                checked={type === "INTERNSHIP JOB"}
                                />
                              <label className="text-primary" htmlFor="category2">
                                INTERNSHIP JOBS
                                <small style={{paddingLeft: '10px'}}>
                                  {publishedInternshipJobs.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={publishedInternshipJobs.length} serverity='success'className="mr-2"/> }
                                </small>
                              </label>
                          </div>
                          <div className=" col-12 md:w-3 font-medium">
                            <RadioButton
                                inputId="category2"
                                style={{paddingRight: '20px'}}
                                name="category"
                                value="ONE TIME JOB"
                                onChange={onTypeChange}
                                checked={type === "ONE TIME JOB"}
                              />
                            <label className="text-primary" htmlFor="category2">
                              ONE TIME JOB
                              <small style={{paddingLeft: '10px'}}>
                                {publishedTemporaryJobs.length === 0 ? <Badge value="0" severity='danger'className="mr-2"/>: <Badge value={publishedTemporaryJobs.length} serverity='success'className="mr-2"/> }
                              </small>
                          </label>
                          </div>
                          </li>
                      </ul>

              </div>

              {getJobDisplay()}

        </EmployerLayout>
    );
}




const mapStateToProps = state =>({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,
    records: state.jobs.recruiterjobs,

})

export default connect(
  mapStateToProps,
  {
    getRecruiterJobs,
} )
  (Jobs);

export const VALIDATE_EMAIL_START = "VALIDATE_EMAIL_START";
export const VALIDATE_EMAIL_SUCCESS = "VALIDATE_EMAIL_SUCCESS";
export const VALIDATE_EMAIL_FAIL = "VALIDATE_EMAIL_FAIL";

export const VALIDATE_USERNAME_START = "VALIDATE_USERNAME_START";
export const VALIDATE_USERNAME_SUCCESS = "VALIDATE_USERNAME_SUCCESS";
export const VALIDATE_USERNAME_FAIL = "VALIDATE_USERNAME_FAIL";

export const VALIDATE_PASSWORD_START = "VALIDATE_PASSWORD_START";
export const VALIDATE_PASSWORD_SUCCESS = "VALIDATE_PASSWORD_SUCCESS";
export const VALIDATE_PASSWORD_FAIL = "VALIDATE_PASSWORD_FAIL";

export const PHONE_NUMBER_UNIQUE_VALIDATION_START = "PHONE_NUMBER_UNIQUE_VALIDATION_START";
export const PHONE_NUMBER_UNIQUE_VALIDATION_SUCCESS = "PHONE_NUMBER_UNIQUE_VALIDATION_SUCCESS";
export const PHONE_NUMBER_UNIQUE_VALIDATION_FAIL = "PHONE_NUMBER_UNIQUE_VALIDATION_FAIL";
export const EMAIL_EXISTS_VALIDATION_START = "EMAIL_EXISTS_VALIDATION_START";
export const EMAIL_EXISTS_VALIDATION_SUCCESS = "EMAIL_EXISTS_VALIDATION_SUCCESS";
export const EMAIL_EXISTS_VALIDATION_FAIL = "EMAIL_EXISTS_VALIDATION_FAIL";
export const TOKEN_EXPIRY_VALIDATION_START = "TOKEN_EXPIRY_VALIDATION_START";
export const TOKEN_EXPIRY_VALIDATION_SUCCESS = "TOKEN_EXPIRY_VALIDATION_SUCCESS";
export const TOKEN_EXPIRY_VALIDATION_FAIL = "TOKEN_EXPIRY_VALIDATION_FAIL";

import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';




const AcademicQualifications = (props) => {
    const {
        records,
    } = props;


    const [layout, setLayout] = useState('grid');


    const renderGridItem = (data) => {
        return (

            <div
                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">

                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        INDUSTRY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.faculty}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        NAME
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.qualification}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        INSTITUTION
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.institution}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        START DATE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.start_date}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        END DATE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.end_date}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>

                        </ul>

                    </div>
                    </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();



    return (
        <>


            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY OPPORTUNITIES</h5>
                                            <p className='text-primary'>CURRENTLY YOU DO NOT HAVE ANY MATCHING OPPORTUNITIES UNDER THIS CATEGORY</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO JOBS MATCHING YOUR SKILLS AS PER YOUR PROFILE"
                                />
                            </div>
                        }
                    </div>
                </div>


            </div>


        </>
    );
}


export default AcademicQualifications;

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';



export const FORGOT_START = "FORGOT_START";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_FAIL = "FORGOT_FAIL";
export const RESET_START = "RESET_START";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_FAIL = "RESET_FAIL";

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';

export const CREATE_JOBSEEKER_START = 'CREATE_JOBSEEKER_START';
export const CREATE_JOBSEEKER_SUCCESS = 'CREATE_JOBSEEKER_SUCCESS';
export const CREATE_JOBSEEKER_FAIL = 'CREATE_JOBSEEKER_FAIL';
export const CREATE_EMPLOYER_START = 'CREATE_EMPLOYER_START';
export const CREATE_EMPLOYER_SUCCESS = 'CREATE_EMPLOYER_SUCCESS';
export const CREATE_EMPLOYER_FAIL = 'CREATE_EMPLOYER_FAIL';
export const CREATE_FREELANCER_START = 'CREATE_FREELANCER_START';
export const CREATE_FREELANCER_SUCCESS = 'CREATE_FREELANCER_SUCCESS';
export const CREATE_FREELANCER_FAIL = 'CREATE_FREELANCER_FAIL';

export const ACTIVATE_USER_START = 'ACTIVATE_USER_START';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS ';
export const ACTIVATE_USER_FAIL = 'ACTIVATE_USER_FAIL';

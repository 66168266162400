import React from 'react';

const AppFooter = (props) => {

    return (
      <div className="text-primary font-bold mb-3" style={{ textAlign: 'center'}}>
          &nbsp;DEVELOPED @ SYLCOUTECH
      </div>
    );
}


export default AppFooter;

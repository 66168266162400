import axios from "axios";
import {
  VALIDATE_EMAIL_START,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAIL,
  VALIDATE_USERNAME_START,
  VALIDATE_USERNAME_SUCCESS,
  VALIDATE_USERNAME_FAIL,
  VALIDATE_PASSWORD_START,
  VALIDATE_PASSWORD_SUCCESS,
  VALIDATE_PASSWORD_FAIL,
  PHONE_NUMBER_UNIQUE_VALIDATION_START,
  PHONE_NUMBER_UNIQUE_VALIDATION_SUCCESS,
  PHONE_NUMBER_UNIQUE_VALIDATION_FAIL,
  EMAIL_EXISTS_VALIDATION_START,
  EMAIL_EXISTS_VALIDATION_SUCCESS,
  EMAIL_EXISTS_VALIDATION_FAIL,
  TOKEN_EXPIRY_VALIDATION_START,
  TOKEN_EXPIRY_VALIDATION_SUCCESS,
  TOKEN_EXPIRY_VALIDATION_FAIL,

} from '../types/validationTypes';
import { returnErrors } from './messages';
import {
  emailValidateURL,
  usernameValidateURL,
  passwordValidateURL,
  phoneNumberUniqueValidationURL,
  emailExistsValidationURL,
  tokenExpiryValidationURL,
} from '../constants';

const validateEmailStart = () => {
  return {
    type: VALIDATE_EMAIL_START
  };
};


const validateEmailSuccess = emailValidation => {
  return {
    type: VALIDATE_EMAIL_SUCCESS,
    emailValidation
  };
};


const validateEmailFail = error => {
  return {
    type: VALIDATE_EMAIL_FAIL,
    error: error
  };
};


const validateUsernameStart = () => {
  return {
    type: VALIDATE_USERNAME_START
  };
};


const validateUsernameSuccess = usernameValidation => {
  return {
    type: VALIDATE_USERNAME_SUCCESS,
    usernameValidation
  };
};


const validateUsernameFail = error => {
  return {
    type: VALIDATE_USERNAME_FAIL,
    error: error
  };
};




const validatePasswordStart = () => {
  return {
    type: VALIDATE_PASSWORD_START
  };
};


const validatePasswordSuccess = passwordValidation => {
  return {
    type: VALIDATE_PASSWORD_SUCCESS,
    passwordValidation
  };
};


const validatePasswordFail = error => {
  return {
    type: VALIDATE_PASSWORD_FAIL,
    error: error
  };
};


const phoneNumberValidationStart = () => {
  return {
    type: PHONE_NUMBER_UNIQUE_VALIDATION_START
  };
};


const phoneNumberValidationSuccess = phoneNumberValidation => {
  return {
    type: PHONE_NUMBER_UNIQUE_VALIDATION_SUCCESS,
    phoneNumberValidation
  };
};


const phoneNumberValidationFail = error => {
  return {
    type: PHONE_NUMBER_UNIQUE_VALIDATION_FAIL,
    error: error
  };
};


const emailExistsValidationStart = () => {
  return {
    type: EMAIL_EXISTS_VALIDATION_START
  };
};


const emailExistsValidationSuccess = emailExistsValidation => {
  return {
    type: EMAIL_EXISTS_VALIDATION_SUCCESS,
    emailExistsValidation
  };
};


const emailExistsValidationFail = error => {
  return {
    type: EMAIL_EXISTS_VALIDATION_FAIL,
    error: error
  };
};

const tokenExpiryValidationStart = () => {
  return {
    type: TOKEN_EXPIRY_VALIDATION_START
  };
};


const tokenExpiryValidationSuccess = tokenExpiryValidation => {
  return {
    type: TOKEN_EXPIRY_VALIDATION_SUCCESS,
    tokenExpiryValidation
  };
};


const tokenExpiryValidationFail = error => {
  return {
    type: TOKEN_EXPIRY_VALIDATION_FAIL,
    error: error
  };
};



export const validateEmail = (email) => {
  return dispatch => {
      dispatch(validateEmailStart());
      axios
        .get(`${emailValidateURL}?email=${email}`)
        .then(res => {
          const emailValidation = res.data;
          dispatch(validateEmailSuccess(emailValidation));
          })
        .catch(err => {
          dispatch(validateEmailFail(err));
        });
    };
};


export const validateUsername = (username) => {
  return dispatch => {
      dispatch(validateUsernameStart());
      axios
        .get(`${usernameValidateURL}?username=${username}`)
        .then(res => {
          const usernameValidation = res.data;
          dispatch(validateUsernameSuccess(usernameValidation));
          })
        .catch(err => {
          dispatch(validateUsernameFail(err));
        });
    };
};

export const validatePassword = (params) => {
  return dispatch => {
      dispatch(validatePasswordStart());
      axios
        .get(passwordValidateURL, {params})
        .then(res => {
          const passwordValidation = res.data;
          dispatch(validatePasswordSuccess(passwordValidation));
          })
        .catch(err => {
          dispatch(validatePasswordFail(err));
        });
    };
};


export const phoneNumberValidation = (phone_number) => {
  return dispatch => {
      dispatch(phoneNumberValidationStart());
      axios
        .get(`${phoneNumberUniqueValidationURL}?phone_number=${phone_number}`)
        .then(res => {
          const phoneNumberValidation = res.data;
          dispatch(phoneNumberValidationSuccess(phoneNumberValidation));
          })
        .catch(err => {
          dispatch(phoneNumberValidationFail(err));
        });
    };
};

export const emailExistsValidation = (email) => {
  return dispatch => {
      dispatch(emailExistsValidationStart());
      axios
        .get(`${emailExistsValidationURL}?email=${email}`)
        .then(res => {
          const emailExistsValidation = res.data;
          dispatch(emailExistsValidationSuccess(emailExistsValidation));
          })
        .catch(err => {
          dispatch(emailExistsValidationFail(err));
        });
    };
};


export const tokenExpiryValidation = (params) => {
  return dispatch => {
      dispatch(tokenExpiryValidationStart());
      axios
        .get(tokenExpiryValidationURL, {params})
        .then(res => {
          const tokenExpiryValidation = res.data;
          dispatch(tokenExpiryValidationSuccess(tokenExpiryValidation));
          })
        .catch(err => {
          dispatch(tokenExpiryValidationFail(err));
        });
    };
};

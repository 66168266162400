import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import ManagementLayout from '../layout/ManagementLayout';
import { getUsers } from '../../actions/auth';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';


export const EmployerDetails = (props) => {
    const employerOptions = props.users.filter((user) => user.type ==="employer");
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [gender, setGender] = useState('');
    const [id, setId] = useState('');
    const [slug, setSlug] = useState('');
    const navigate = useNavigate();


    const goToPendingJobs = slug =>{
        navigate(`/management/employer-details/unpublished-jobs/${slug}`, {replace:false})
    }

    const goToPublishedJobs = slug =>{
        navigate(`/management/employer-details/published-jobs/${slug}`, {replace:false})
    }


    const goToClosedJobs = slug =>{
        navigate(`/management/employer-details/closed-jobs/${slug}`, {replace:false})
    }



    const getEmployee = (employees, username) =>{
        let emps = employees.filter((emp) => emp.username === username)
        return emps[0]
    }



    useEffect(() =>{
        if (!props.fetched){
            props.getUsers(props.token);
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        let employeesCopy = [...employerOptions];
        let employee = getEmployee(employeesCopy, username);
        if (employee !== undefined){
            setEmail(employee.email);
            setFirstName(employee.first_name);
            setLastName(employee.last_name);
            setGender(employee.gender);
            setId(employee.id);
            setSlug(employee.slug);
        }      

    }, [username]) // eslint-disable-line react-hooks/exhaustive-deps

    


    
    

    return (
        <ManagementLayout>
            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <div className="col-12 md:col-8">
                    <div className="surface-section   card px-4 py-8 md:px-6 lg:px-8">
                        Employer Details
                        
                        <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    USER
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        optionLabel="username"
                                        optionValue="username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        options={employerOptions}
                                        filter
                                        filterBy="username"
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    ID
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="name"
                                        value={id}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    SYSTEM ID
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="slug"
                                        value={slug}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    FIRST NAME
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="first_name"
                                        value={first_name}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    LAST NAME
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="last_name"
                                        value={last_name}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    EMAIL
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="email"
                                        value={email}
                                        disabled
                                    />

                                </div>
                            </li>
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-2 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    GENDER
                                </div>
                                <div className=" w-full md:w-10 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="gender"
                                        value={gender}
                                        disabled
                                    />

                                </div>
                            </li>
                        </ul>
                        
                    </div>
                </div>
                <div className="col-12 md:col-4 ">
                    <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                        Option Details


                        <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                            {username !== undefined &&  username !== ''  
                                ?<>
                                
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='CREATE JOB'
                                                className="p-button-outlined p-button-primary"
                                                
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='VIEW PUBLISHED JOBS'
                                                className="p-button-outlined p-button-primary"
                                                onClick ={() => goToPublishedJobs(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='VIEW UNPUBLISHED JOBS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToPendingJobs(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='VIEW CLOSED JOBS'
                                                className="p-button-outlined p-button-primary"
                                                onClick={() =>goToClosedJobs(slug)}
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='VIEW PROFILE'
                                                className="p-button-outlined p-button-primary"
                                                
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='VIEW COMMUNICATIONS'
                                                className="p-button-outlined p-button-primary"
                                                
                                            />
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                        <div
                                            className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            <Button 
                                                label='VIEW REPORTS'
                                                className="p-button-outlined p-button-primary"
                                                
                                            />
                                        </div>
                                    </li>
                                </>
                                :<>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='CREATE JOB'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='VIEW PUBLISHED JOBS'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='VIEW UNPUBLISHED JOBS'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='VIEW CLOSED JOBS'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='VIEW PROFILE'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='VIEW COMMUNICATIONS'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-12 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        <Button 
                                            label='VIEW REPORTS'
                                            className="p-button-outlined p-button-primary"
                                            disabled
                                            
                                        />
                                    </div>
                                </li>
                            </>
                            }
                        </ul>
                    </div>
                </div>

            </div>
        </ManagementLayout>
    )
}



const mapStateToProps = (state) => ({
    token: state.auth.token,
    users: state.auth.people,
})



export default connect(mapStateToProps, {getUsers})(EmployerDetails);
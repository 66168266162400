import {
    GET_CONTINENTS,
    EDIT_CONTINENT,
    DELETE_CONTINENT,
    ADD_CONTINENT,
    GET_COUNTRIES,
    EDIT_COUNTRY,
    DELETE_COUNTRY,
    ADD_COUNTRY,
    GET_PROVINCES,
    EDIT_PROVINCE,
    DELETE_PROVINCE,
    ADD_PROVINCE,
    GET_DISTRICTS,
    EDIT_DISTRICT,
    DELETE_DISTRICT,
    ADD_DISTRICT,

} from '../types/placeTypes';


const initialState = {
    continents: [],
    countries: [],
    provinces: [],
    districts: [],

};



export default function places(state = initialState, action){

    switch(action.type){

        case GET_CONTINENTS:
            return {
                ...state,
                continents : action.payload
            };

        case ADD_CONTINENT:
            return {
                ...state,
                continent: [...state.continents, action.payload]
            }

        case EDIT_CONTINENT:
            const continentArray = state.continents;
            continentArray.splice(
                    continentArray.findIndex(item => item.id === action.payload.data.id), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                continents: continentArray,
            };
        
        case DELETE_CONTINENT:
            return {
                ...state,
                continent: state.continents.filter(continent=> continent.id !== action.payload)
            };
        
        case GET_COUNTRIES:
            return {
                ...state,
                countries : action.payload
            };

        case ADD_COUNTRY:
            return {
                ...state,
                country: [...state.countries, action.payload]
            }

        case EDIT_COUNTRY:
            const countryArray = state.countries;
            countryArray.splice(
                    countryArray.findIndex(item => item.id === action.payload.data.id), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                countrys: countryArray,
            };
        
        case DELETE_COUNTRY:
            return {
                ...state,
                country: state.countrys.filter(country=> country.id !== action.payload)
            };
        
        case GET_PROVINCES:
            return {
                ...state,
                provinces : action.payload
            };

        case ADD_PROVINCE:
            return {
                ...state,
                province: [...state.provinces, action.payload]
            }

        case EDIT_PROVINCE:
            const provinceArray = state.provinces;
            provinceArray.splice(
                    provinceArray.findIndex(item => item.id === action.payload.data.id), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                provinces: provinceArray,
            };
        
        case DELETE_PROVINCE:
            return {
                ...state,
                province: state.provinces.filter(province=> province.id !== action.payload)
            };

        case GET_DISTRICTS:
            return {
                ...state,
                districts : action.payload
            };

        case ADD_DISTRICT:
            return {
                ...state,
                district: [...state.districts, action.payload]
            }

        case EDIT_DISTRICT:
            const districtArray = state.districts;
            districtArray.splice(
                    districtArray.findIndex(item => item.id === action.payload.data.id), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                districts: districtArray,
            };
        
        case DELETE_DISTRICT:
            return {
                ...state,
                district: state.districts.filter(district=> district.id !== action.payload)
            };

        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import {
    authLogin,
    forgotPassword,
    resetPassword
} from "../../actions/auth";
import PublicLayout from '../layout/PublicLayout';
import silas from '../../images/illustrations/presentation-blocks.svg';
import { Skeleton } from 'primereact/skeleton';
import logn from './login.mp4';
import { Password } from 'primereact/password';
import bgcover from '../home/images/feature.png';


const Login = (props) => {
  const {token, userRole, loading} = props;
  const navigate = useNavigate();
  const location = useLocation();
  const fromManagement = location.state?.from?.pathname || "/management";
  const fromJobSeeker = location.state?.from?.pathname || "/jobs";
  const fromEmployer = location.state?.from?.pathname || "/employ";

  const [password, setPassword] = useState('')
  const [email, setUsername] = useState('')

  const goToRegistration = () =>{
    console.log(30)
  }



  useEffect(() => {
    if (token){
          if (userRole === 'admin'){
              navigate(fromManagement, { replace: true });
              console.log(fromManagement)
          }
          else if(userRole === 'employer'){
              navigate(fromEmployer, { replace: true });
              console.log(fromEmployer)

          }
          else if(userRole === 'jobseeker'){
              navigate(fromJobSeeker, { replace: true });
              console.log(fromJobSeeker)

          }
      }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        if (token){
            if (userRole === 'admin'){
                navigate(fromManagement, { replace: true });
                console.log(fromManagement)
            }
            else if(userRole === 'employer'){
                navigate(fromEmployer, { replace: true });
                console.log(fromEmployer)

            }
            else if(userRole === 'jobseeker'){
                navigate(fromJobSeeker, { replace: true });
                console.log(fromJobSeeker)

            }
        }
    }, [loading]);// eslint-disable-line react-hooks/exhaustive-deps






  const handleSubmit = (e) => {
      e.preventDefault();
      props.authLogin(email, password);

      if (token){
          if (userRole === 'admin'){
              navigate(fromManagement, { replace: true });
              console.log(fromManagement)
          }
          else if(userRole === 'employer'){
              navigate(fromEmployer, { replace: true });
              console.log(fromEmployer)

          }
          else if(userRole === 'jobseeker'){
              navigate(fromJobSeeker, { replace: true });
              console.log(fromJobSeeker)

          }
      }else{
          console.log('no token')
      }
  }

  const canSave = Boolean(email !=='') && Boolean(password !== '');



    return (
        <PublicLayout>
              <div className='grid' style={{textAlign: 'center'}}>
                  <div className="col-12 md:w-4"
                  >
                  </div>
                  <div className="col-12 md:w-4 card"
                  >
                    <ul  style={{textAlign: 'center'}} className="list-none  grid p-fluid p-0 m-0" >
                      <li className="flex align-items-center col-12  col-12 md:col-12 py-3 px-2 flex-wrap">
                        <div className="text-center mb-3 col-12 md:w-12" style={{textAlign: 'center'}}>
                            <span style={{textAlign: 'center'}} className="text-600 font-medium line-height-3">Have an account?</span>
                              <Button
                                label="Register"
                                className="p-button-text"
                                onClick={goToRegistration}
                              />

                        </div>
                        <div className=" col-12 md:w-12 font-medium">
                          EMAIL
                        </div>
                        <div className=" col-12 md:w-12 font-medium">
                          <InputText
                              id="email"
                              type="text"
                              name="email"
                              className="w-full mb-3"
                              onChange={(e) => setUsername(e.target.value)}
                              value={email}
                          />
                        </div>
                          <div
                              className="w-full mb-3  md:w-12 md:flex-order-0 flex-order-1"
                              style={{fontSize: '15px'}}
                          >
                              PASSWORD
                          </div>
                          <div className=" col-12  md:w-12 md:flex-order-0 flex-order-1">
                            <Password
                                className="w-full mb-3"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}

                                toggleMask
                                feedback={false}
                            />

                          </div>
                        </li>
                    </ul>
                    <div className='grid mb-3'>
                      <div className='col-12 md:col-3'>

                      </div>
                      <div className='col-12 md:col-6'>
                        <div className=" text-700 text-center">
                            <Button
                              label="Login"
                              className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                              onClick={(e) =>handleSubmit(e)}
                              disabled={!canSave}
                            />
                        </div>
                      </div>
                    </div>
                    <div className='grid'>
                      <div className='col-12 md:col-3'>

                      </div>
                      <div className='col-12 md:col-6'>
                        <div className=" text-700 text-center">
                          <Link
                              className="font-medium no-underline ml-2 mr-3 text-blue-500 cursor-pointer"
                              to='/forgot-password'
                          >
                                  Forgot Password ?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>


        </PublicLayout>

    )
}

const mapStateToProps = state => {
    return {
      loading: state.auth.loading,
      error: state.auth.error,
      userRole: state.auth.userRole,
      token: state.auth.token,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
        authLogin: (email, password) =>dispatch(authLogin(email, password)),
        forgotPassword : (user) => dispatch(forgotPassword(user)),
        resetPassword: (user) => dispatch(resetPassword(user)),
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Login);


export const GET_CANDIDATE_BEST_JOB_MATCHES_START = 'GET_CANDIDATE_BEST_JOB_MATCHES_START';
export const GET_CANDIDATE_BEST_JOB_MATCHES_SUCCESS = 'GET_CANDIDATE_BEST_JOB_MATCHES_SUCCESS';
export const GET_CANDIDATE_BEST_JOB_MATCHES_FAIL = 'GET_CANDIDATE_BEST_JOB_MATCHES_FAIL';

export const GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_START = 'GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_START';
export const GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_SUCCESS = 'GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_SUCCESS';
export const GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_FAIL = 'GET_CANDIDATE_PERMANENT_JOB_NEAREST_NEIGHBOUR_FAIL';


export const GET_CANDIDATE_PERMANENT_ALL_JOBS_START = 'GET_CANDIDATE_PERMANENT_ALL_JOBS_START';
export const GET_CANDIDATE_PERMANENT_ALL_JOBS_SUCCESS = 'GET_CANDIDATE_PERMANENT_ALL_JOBS_SUCCESS';
export const GET_CANDIDATE_PERMANENT_ALL_JOBS_FAIL = 'GET_CANDIDATE_PERMANENT_ALL_JOBS_FAIL';


export const GET_CANDIDATE_APPLIED_FOR_JOBS_START = 'GET_CANDIDATE_APPLIED_FOR_JOBS_START';
export const GET_CANDIDATE_APPLIED_FOR_JOBS_SUCCESS = 'GET_CANDIDATE_APPLIED_FOR_JOBS_SUCCESS';
export const GET_CANDIDATE_APPLIED_FOR_JOBS_FAIL = 'GET_CANDIDATE_APPLIED_FOR_JOBS_FAIL';


import {
    GET_JOBSEEKER_EXPERIENCES,
    GET_JOBSEEKER_LINKS,
    GET_JOBSEEKER_LANGUAGES,
    GET_JOBSEEKER_SKILLS,
    GET_JOBSEEKER_JOB_APPLICATIONS,
    GET_JOBSEEKER_RESUME,
    GET_JOBSEEKER_AVAILABILITY,
    GET_JOBSEEKER_ACADEMIC_APPLICATIONS,
    GET_USERNAMES,
    GET_EMAILS,
    GET_USER_PROFILE,
} from '../types/candidateTypes';



const initialState = {
    jobseekerexperiences: [],
    jobseekerlinks: [],
    jobseekerlanguages: [],
    jobseekerskills: [],
    jobseekerjobapplications: [],
    jobseekeracademicqualifications: [],
    usernames: [],
    emails: [],
    jobseekerresume: {},
    jobseekeravailability: {},
    userprofile: {},
};



export default function candidates(state = initialState, action){

    switch(action.type){
        case GET_USER_PROFILE:
            return {
                ...state,
                userprofile : action.payload
            };
        case GET_EMAILS:
            return {
                ...state,
               emails : action.payload
            };
        case GET_USERNAMES:
            return {
                ...state,
               usernames : action.payload
            };
        case GET_JOBSEEKER_SKILLS:
            return {
                ...state,
               jobseekerskills : action.payload
            };

        case GET_JOBSEEKER_EXPERIENCES:
            return {
                ...state,
               jobseekerexperiences : action.payload
            };
        case GET_JOBSEEKER_LINKS:
            return {
                ...state,
               jobseekerlinks : action.payload
            };
        case GET_JOBSEEKER_LANGUAGES:
            return {
                ...state,
               jobseekerlanguages : action.payload
            };
        case GET_JOBSEEKER_JOB_APPLICATIONS:
            return {
                ...state,
               jobseekerjobapplications : action.payload
            };
        case GET_JOBSEEKER_RESUME:
            return {
                ...state,
               jobseekerresume : action.payload
            };
        case GET_JOBSEEKER_AVAILABILITY:
            return {
                ...state,
               jobseekeravailability : action.payload
            };

        case GET_JOBSEEKER_ACADEMIC_APPLICATIONS:
            return {
                ...state,
               jobseekeracademicqualifications : action.payload
            };


        default:
            return state;
    }
}

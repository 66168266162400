import axios from 'axios';
import {
    GET_DAILY_JOB_REPORTS_START,
    GET_DAILY_JOB_REPORTS_SUCCESS,
    GET_DAILY_JOB_REPORTS_FAIL,
    GET_MANAGEMENT_JOB_WEEKLY_REPORTS_START,
    GET_MANAGEMENT_JOB_WEEKLY_REPORTS_SUCCESS,
    GET_MANAGEMENT_JOB_WEEKLY_REPORTS_FAIL,

    GET_MANAGEMENT_JOB_MONTHLY_REPORTS_START,
    GET_MANAGEMENT_JOB_MONTHLY_REPORTS_SUCCESS,
    GET_MANAGEMENT_JOB_MONTHLY_REPORTS_FAIL,

    GET_MANAGEMENT_JOB_YEARLY_REPORTS_START,
    GET_MANAGEMENT_JOB_YEARLY_REPORTS_SUCCESS,
    GET_MANAGEMENT_JOB_YEARLY_REPORTS_FAIL,

    GET_RECRUITER_DAILY_JOB_REPORTS_START,
    GET_RECRUITER_DAILY_JOB_REPORTS_SUCCESS,
    GET_RECRUITER_DAILY_JOB_REPORTS_FAIL,
    GET_RECRUITER_JOB_WEEKLY_REPORTS_START,
    GET_RECRUITER_JOB_WEEKLY_REPORTS_SUCCESS,
    GET_RECRUITER_JOB_WEEKLY_REPORTS_FAIL,
    GET_RECRUITER_JOB_MONTHLY_REPORTS_START,
    GET_RECRUITER_JOB_MONTHLY_REPORTS_SUCCESS,
    GET_RECRUITER_JOB_MONTHLY_REPORTS_FAIL,
    GET_RECRUITER_JOB_YEARLY_REPORTS_START,
    GET_RECRUITER_JOB_YEARLY_REPORTS_SUCCESS,
    GET_RECRUITER_JOB_YEARLY_REPORTS_FAIL,

    GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START,
    GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    GET_EMPLOYER_DAILY_JOB_REPORTS_START,
    GET_EMPLOYER_DAILY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_DAILY_JOB_REPORTS_FAIL,
    GET_EMPLOYER_WEEKLY_JOB_REPORTS_START,
    GET_EMPLOYER_WEEKLY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_WEEKLY_JOB_REPORTS_FAIL,
    GET_EMPLOYER_MONTHLY_JOB_REPORTS_START,
    GET_EMPLOYER_MONTHLY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL,
    GET_EMPLOYER_YEARLY_JOB_REPORTS_START,
    GET_EMPLOYER_YEARLY_JOB_REPORTS_SUCCESS,
    GET_EMPLOYER_YEARLY_JOB_REPORTS_FAIL,
    GET_MANAGEMENT_STATS_START,
    GET_MANAGEMENT_STATS_SUCCESS,
    GET_MANAGEMENT_STATS_FAIL,
    GET_MANAGEMENT_MONTHLY_JOB_DATA_START,
    GET_MANAGEMENT_MONTHLY_JOB_DATA_SUCCESS,
    GET_MANAGEMENT_MONTHLY_JOB_DATA_FAIL,
    GET_RECRUITER_STATS_START,
    GET_RECRUITER_STATS_SUCCESS,
    GET_RECRUITER_STATS_FAIL,
    GET_RECRUITER_MONTHLY_JOB_DATA_START,
    GET_RECRUITER_MONTHLY_JOB_DATA_SUCCESS,
    GET_RECRUITER_MONTHLY_JOB_DATA_FAIL,




} from '../types/reportTypes';

import {
    jobDailyReportsURL,
    managementJobWeeklyReportsURL,
    managementJobMonthlyReportsURL,
    managementJobYearlyReportsURL,
    recruiterJobDailyReportsURL,
    recruiterJobWeeklyReportsURL,
    recruiterJobMonthlyReportsURL,
    recruiterJobYearlyReportsURL,
    managementDailyJobApplicationReportsURL,
    managementWeeklyJobApplicationReportsURL,
    managementMonthlyJobApplicationReportsURL,
    managementYearlyJobApplicationReportsURL,
    recruiterDailyJobApplicationReportsURL,
    recruiterWeeklyJobApplicationReportsURL,
    recruiterMonthlyJobApplicationReportsURL,
    recruiterYearlyJobApplicationReportsURL,
    candidateDailyJobApplicationReportsURL,
    candidateWeeklyJobApplicationReportsURL,
    candidateMonthlyJobApplicationReportsURL,
    candidateYearlyJobApplicationReportsURL,
    managementDailyCandidateJobApplicationReportsURL,
    managementWeeklyCandidateJobApplicationReportsURL,
    managementMonthlyCandidateJobApplicationReportsURL,
    managementYearlyCandidateJobApplicationReportsURL,
    employerDailyJobReportsURL,
    employerWeeklyJobReportsURL,
    employerMonthlyJobReportsURL,
    employerYearlyJobReportsURL,
    managementStatsURL,
    managementMonthlyJobDataURL,
    recruiterStatsURL,
    recruiterMonthlyJobDataURL,

} from '../constants';


const getDailyJobReportsStart = () => {
    return {
      type: GET_DAILY_JOB_REPORTS_START
    };
  };

const getDailyJobReportsSuccess = dailyJobReports => {
    return {
      type: GET_DAILY_JOB_REPORTS_SUCCESS,
      dailyJobReports
    };
};

const getDailyJobReportsFail = error => {
    return {
      type: GET_DAILY_JOB_REPORTS_FAIL,
      error: error
    };
};


const getManagementJobWeeklyReportsStart = () => {
  return {
    type: GET_MANAGEMENT_JOB_WEEKLY_REPORTS_START
  };
};

const getManagementJobWeeklyReportsSuccess = managementJobWeeklyReports => {
  return {
    type: GET_MANAGEMENT_JOB_WEEKLY_REPORTS_SUCCESS,
    managementJobWeeklyReports
  };
};

const getManagementJobWeeklyReportsFail = error => {
  return {
    type: GET_MANAGEMENT_JOB_WEEKLY_REPORTS_FAIL,
    error: error
  };
};



const getManagementJobMonthlyReportsStart = () => {
  return {
    type: GET_MANAGEMENT_JOB_MONTHLY_REPORTS_START
  };
};

const getManagementJobMonthlyReportsSuccess = managementJobMonthlyReports => {
  return {
    type: GET_MANAGEMENT_JOB_MONTHLY_REPORTS_SUCCESS,
    managementJobMonthlyReports
  };
};

const getManagementJobMonthlyReportsFail = error => {
  return {
    type: GET_MANAGEMENT_JOB_MONTHLY_REPORTS_FAIL,
    error: error
  };
};


const getManagementJobYearlyReportsStart = () => {
  return {
    type: GET_MANAGEMENT_JOB_YEARLY_REPORTS_START
  };
};

const getManagementJobYearlyReportsSuccess = managementJobYearlyReports => {
  return {
    type: GET_MANAGEMENT_JOB_YEARLY_REPORTS_SUCCESS,
    managementJobYearlyReports
  };
};

const getManagementJobYearlyReportsFail = error => {
  return {
    type: GET_MANAGEMENT_JOB_YEARLY_REPORTS_FAIL,
    error: error
  };
};


//RECRUITER
const getRecruiterDailyJobReportsStart = () => {
  return {
    type: GET_RECRUITER_DAILY_JOB_REPORTS_START
  };
};

const getRecruiterDailyJobReportsSuccess = recruiterDailyJobReports => {
  return {
    type: GET_RECRUITER_DAILY_JOB_REPORTS_SUCCESS,
    recruiterDailyJobReports
  };
};

const getRecruiterDailyJobReportsFail = error => {
  return {
    type: GET_RECRUITER_DAILY_JOB_REPORTS_FAIL,
    error: error
  };
};


const getRecruiterJobWeeklyReportsStart = () => {
  return {
    type: GET_RECRUITER_JOB_WEEKLY_REPORTS_START
  };
};

const getRecruiterJobWeeklyReportsSuccess = recruiterJobWeeklyReports => {
  return {
    type: GET_RECRUITER_JOB_WEEKLY_REPORTS_SUCCESS,
    recruiterJobWeeklyReports
  };
};

const getRecruiterJobWeeklyReportsFail = error => {
  return {
    type: GET_RECRUITER_JOB_WEEKLY_REPORTS_FAIL,
    error: error
  };
};



const getRecruiterJobMonthlyReportsStart = () => {
  return {
    type: GET_RECRUITER_JOB_MONTHLY_REPORTS_START
  };
};

const getRecruiterJobMonthlyReportsSuccess = recruiterJobMonthlyReports => {
  return {
    type: GET_RECRUITER_JOB_MONTHLY_REPORTS_SUCCESS,
    recruiterJobMonthlyReports
  };
};

const getRecruiterJobMonthlyReportsFail = error => {
  return {
    type: GET_RECRUITER_JOB_MONTHLY_REPORTS_FAIL,
    error: error
  };
};


const getRecruiterJobYearlyReportsStart = () => {
  return {
    type: GET_RECRUITER_JOB_YEARLY_REPORTS_START
  };
};

const getRecruiterJobYearlyReportsSuccess = recruiterJobYearlyReports => {
  return {
    type: GET_RECRUITER_JOB_YEARLY_REPORTS_SUCCESS,
    recruiterJobYearlyReports
  };
};

const getRecruiterJobYearlyReportsFail = error => {
  return {
    type: GET_RECRUITER_JOB_YEARLY_REPORTS_FAIL,
    error: error
  };
};


//APPLICATIONS
const getManagementDailyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementDailyJobApplicationReportsSuccess = managementDailyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementDailyJobApplicationReports
  };
};

const getManagementDailyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};

//WEEKLY
const getManagementWeeklyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementWeeklyJobApplicationReportsSuccess = managementWeeklyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementWeeklyJobApplicationReports
  };
};

const getManagementWeeklyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getManagementMonthlyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementMonthlyJobApplicationReportsSuccess = managementMonthlyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementMonthlyJobApplicationReports
  };
};

const getManagementMonthlyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getManagementYearlyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementYearlyJobApplicationReportsSuccess = managementYearlyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementYearlyJobApplicationReports
  };
};

const getManagementYearlyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//RECRUITER
const getRecruiterDailyJobApplicationReportsStart = () => {
  return {
    type: GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_START
  };
};

const getRecruiterDailyJobApplicationReportsSuccess = recruiterDailyJobApplicationReports => {
  return {
    type: GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    recruiterDailyJobApplicationReports
  };
};

const getRecruiterDailyJobApplicationReportsFail = error => {
  return {
    type: GET_RECRUITER_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};

//WEEKLY
const getRecruiterWeeklyJobApplicationReportsStart = () => {
  return {
    type: GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_START
  };
};

const getRecruiterWeeklyJobApplicationReportsSuccess = recruiterWeeklyJobApplicationReports => {
  return {
    type: GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    recruiterWeeklyJobApplicationReports
  };
};

const getRecruiterWeeklyJobApplicationReportsFail = error => {
  return {
    type: GET_RECRUITER_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getRecruiterMonthlyJobApplicationReportsStart = () => {
  return {
    type: GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_START
  };
};

const getRecruiterMonthlyJobApplicationReportsSuccess = recruiterMonthlyJobApplicationReports => {
  return {
    type: GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    recruiterMonthlyJobApplicationReports
  };
};

const getRecruiterMonthlyJobApplicationReportsFail = error => {
  return {
    type: GET_RECRUITER_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getRecruiterYearlyJobApplicationReportsStart = () => {
  return {
    type: GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_START
  };
};

const getRecruiterYearlyJobApplicationReportsSuccess = recruiterYearlyJobApplicationReports => {
  return {
    type: GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    recruiterYearlyJobApplicationReports
  };
};

const getRecruiterYearlyJobApplicationReportsFail = error => {
  return {
    type: GET_RECRUITER_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//CANDIDATE
const getCandidateDailyJobApplicationReportsStart = () => {
  return {
    type: GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START
  };
};

const getCandidateDailyJobApplicationReportsSuccess = candidateDailyJobApplicationReports => {
  return {
    type: GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    candidateDailyJobApplicationReports
  };
};

const getCandidateDailyJobApplicationReportsFail = error => {
  return {
    type: GET_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};

//WEEKLY
const getCandidateWeeklyJobApplicationReportsStart = () => {
  return {
    type: GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START
  };
};

const getCandidateWeeklyJobApplicationReportsSuccess = candidateWeeklyJobApplicationReports => {
  return {
    type: GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    candidateWeeklyJobApplicationReports
  };
};

const getCandidateWeeklyJobApplicationReportsFail = error => {
  return {
    type: GET_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getCandidateMonthlyJobApplicationReportsStart = () => {
  return {
    type: GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START
  };
};

const getCandidateMonthlyJobApplicationReportsSuccess = candidateMonthlyJobApplicationReports => {
  return {
    type: GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    candidateMonthlyJobApplicationReports
  };
};

const getCandidateMonthlyJobApplicationReportsFail = error => {
  return {
    type: GET_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getCandidateYearlyJobApplicationReportsStart = () => {
  return {
    type: GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START
  };
};

const getCandidateYearlyJobApplicationReportsSuccess = candidateYearlyJobApplicationReports => {
  return {
    type: GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    candidateYearlyJobApplicationReports
  };
};

const getCandidateYearlyJobApplicationReportsFail = error => {
  return {
    type: GET_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MANAGEMENT
const getManagementCandidateDailyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementCandidateDailyJobApplicationReportsSuccess = managementCandidateDailyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementCandidateDailyJobApplicationReports
  };
};

const getManagementCandidateDailyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_DAILY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};

//WEEKLY
const getManagementCandidateWeeklyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementCandidateWeeklyJobApplicationReportsSuccess = managementCandidateWeeklyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementCandidateWeeklyJobApplicationReports
  };
};

const getManagementCandidateWeeklyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_WEEKLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//MONTLHY
const getManagementCandidateMonthlyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_START
  };
};

const getManagementCandidateMonthlyJobApplicationReportsSuccess = managementCandidateMonthlyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementCandidateMonthlyJobApplicationReports
  };
};

const getManagementCandidateMonthlyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_MONTHLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//YEARLY
const getManagementCandidateYearlyJobApplicationReportsStart = () => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_START
  };
};


const getManagementCandidateYearlyJobApplicationReportsSuccess = managementCandidateYearlyJobApplicationReports => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_SUCCESS,
    managementCandidateYearlyJobApplicationReports
  };
};


const getManagementCandidateYearlyJobApplicationReportsFail = error => {
  return {
    type: GET_MANAGEMENT_CANDIDATE_YEARLY_JOB_APPLICATION_REPORTS_FAIL,
    error: error
  };
};


//EMPLOYER
const getEmployerDailyJobReportsStart = () => {
  return {
    type: GET_EMPLOYER_DAILY_JOB_REPORTS_START
  };
};

const getEmployerDailyJobReportsSuccess = employerDailyJobReports => {
  return {
    type: GET_EMPLOYER_DAILY_JOB_REPORTS_SUCCESS,
    employerDailyJobReports
  };
};

const getEmployerDailyJobReportsFail = error => {
  return {
    type: GET_EMPLOYER_DAILY_JOB_REPORTS_FAIL,
    error: error
  };
};

const getEmployerWeeklyJobReportsStart = () => {
  return {
    type: GET_EMPLOYER_WEEKLY_JOB_REPORTS_START
  };
};

const getEmployerWeeklyJobReportsSuccess = employerWeeklyJobReports => {
  return {
    type: GET_EMPLOYER_WEEKLY_JOB_REPORTS_SUCCESS,
    employerWeeklyJobReports
  };
};

const getEmployerWeeklyJobReportsFail = error => {
  return {
    type: GET_EMPLOYER_WEEKLY_JOB_REPORTS_FAIL,
    error: error
  };
};


const getEmployerMonthlyJobReportsStart = () => {
  return {
    type: GET_EMPLOYER_MONTHLY_JOB_REPORTS_START
  };
};

const getEmployerMonthlyJobReportsSuccess = employerMonthlyJobReports => {
  return {
    type: GET_EMPLOYER_MONTHLY_JOB_REPORTS_SUCCESS,
    employerMonthlyJobReports
  };
};

const getEmployerMonthlyJobReportsFail = error => {
  return {
    type: GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL,
    error: error
  };
};


const getEmployerYearlyJobReportsStart = () => {
  return {
    type: GET_EMPLOYER_YEARLY_JOB_REPORTS_START
  };
};

const getEmployerYearlyJobReportsSuccess = employerYearlyJobReports => {
  return {
    type: GET_EMPLOYER_YEARLY_JOB_REPORTS_SUCCESS,
    employerYearlyJobReports
  };
};

const getEmployerYearlyJobReportsFail = error => {
  return {
    type: GET_EMPLOYER_YEARLY_JOB_REPORTS_FAIL,
    error: error
  };
};


// const getEmployerMonthlyJobReportsFail = error => {
//   return {
//     type: GET_EMPLOYER_MONTHLY_JOB_REPORTS_FAIL,
//     error: error
//   };
// };


const getManagementStatsStart = () => {
  return {
    type: GET_MANAGEMENT_STATS_START
  };
};

const getManagementStatsSuccess = managementStats => {
  return {
    type: GET_MANAGEMENT_STATS_SUCCESS,
    managementStats
  };
};

const getManagementStatsFail = error => {
  return {
    type: GET_MANAGEMENT_STATS_FAIL,
    error: error
  };
};


const getManagementMonthlyJobDataStart = () => {
  return {
    type: GET_MANAGEMENT_MONTHLY_JOB_DATA_START
  };
};

const getManagementMonthlyJobDataSuccess = managementMonthlyJobData => {
  return {
    type: GET_MANAGEMENT_MONTHLY_JOB_DATA_SUCCESS,
    managementMonthlyJobData
  };
};

const getManagementMonthlyJobDataFail = error => {
  return {
    type: GET_MANAGEMENT_MONTHLY_JOB_DATA_FAIL,
    error: error
  };
};


const getRecruiterStatsStart = () => {
  return {
    type: GET_RECRUITER_STATS_START
  };
};

const getRecruiterStatsSuccess = recruiterStats => {
  return {
    type: GET_RECRUITER_STATS_SUCCESS,
    recruiterStats
  };
};

const getRecruiterStatsFail = error => {
  return {
    type: GET_RECRUITER_STATS_FAIL,
    error: error
  };
};


const getRecruiterMonthlyJobDataStart = () => {
  return {
    type: GET_RECRUITER_MONTHLY_JOB_DATA_START
  };
};

const getRecruiterMonthlyJobDataSuccess = recruiterMonthlyJobData => {
  return {
    type: GET_RECRUITER_MONTHLY_JOB_DATA_SUCCESS,
    recruiterMonthlyJobData
  };
};

const getRecruiterMonthlyJobDataFail = error => {
  return {
    type: GET_RECRUITER_MONTHLY_JOB_DATA_FAIL,
    error: error
  };
};


export const getDailyJobReports = (params, token) => {
  return dispatch => {
      dispatch(getDailyJobReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(jobDailyReportsURL,{ params }, headers)
        .then(res => {
          const dailyJobReports = res.data;
          dispatch(getDailyJobReportsSuccess(dailyJobReports));
          })
        .catch(err => {
          dispatch(getDailyJobReportsFail(err));
        });
    };
};


export const getManagementJobWeeklyReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementJobWeeklyReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementJobWeeklyReportsURL,{ params }, headers)
        .then(res => {
          const managementJobWeeklyReports = res.data;
          dispatch(getManagementJobWeeklyReportsSuccess(managementJobWeeklyReports));
          })
        .catch(err => {
          dispatch(getManagementJobWeeklyReportsFail(err));
        });
    };
};


export const getManagementJobMonthlyReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementJobMonthlyReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementJobMonthlyReportsURL,{ params }, headers)
        .then(res => {
          const managementJobMonthlyReports = res.data;
          dispatch(getManagementJobMonthlyReportsSuccess(managementJobMonthlyReports));
          })
        .catch(err => {
          dispatch(getManagementJobMonthlyReportsFail(err));
        });
    };
};



export const getManagementJobYearlyReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementJobYearlyReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementJobYearlyReportsURL,{ params }, headers)
        .then(res => {
          const managementJobYearlyReports = res.data;
          dispatch(getManagementJobYearlyReportsSuccess(managementJobYearlyReports));
          })
        .catch(err => {
          dispatch(getManagementJobYearlyReportsFail(err));
        });
    };
};



//RECRUITER


export const getRecruiterDailyJobReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterDailyJobReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

      axios
        .get(recruiterJobDailyReportsURL,{ params }, headers)
        .then(res => {
          const recruiterDailyJobReports  = res.data;
          dispatch(getRecruiterDailyJobReportsSuccess(recruiterDailyJobReports));
          })
        .catch(err => {
          dispatch(getRecruiterDailyJobReportsFail(err));
        });
    };
};


export const getRecruiterJobWeeklyReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterJobWeeklyReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterJobWeeklyReportsURL,{ params }, headers)
        .then(res => {
          const recruiterJobWeeklyReports = res.data;
          dispatch(getRecruiterJobWeeklyReportsSuccess(recruiterJobWeeklyReports));
          })
        .catch(err => {
          dispatch(getRecruiterJobWeeklyReportsFail(err));
        });
    };
};


export const getRecruiterJobMonthlyReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterJobMonthlyReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterJobMonthlyReportsURL,{ params }, headers)
        .then(res => {
          const recruiterJobMonthlyReports = res.data;
          dispatch(getRecruiterJobMonthlyReportsSuccess(recruiterJobMonthlyReports));
          })
        .catch(err => {
          dispatch(getRecruiterJobMonthlyReportsFail(err));
        });
    };
};



export const getRecruiterJobYearlyReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterJobYearlyReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterJobYearlyReportsURL,{ params }, headers)
        .then(res => {
          const recruiterJobYearlyReports = res.data;
          dispatch(getRecruiterJobYearlyReportsSuccess(recruiterJobYearlyReports));
          })
        .catch(err => {
          dispatch(getRecruiterJobYearlyReportsFail(err));
        });
    };
};



//MANAGEMENT APPLICATIONS
export const getManagementDailyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementDailyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementDailyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementDailyJobApplicationReports = res.data;
          dispatch(getManagementDailyJobApplicationReportsSuccess(managementDailyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementDailyJobApplicationReportsFail(err));
        });
    };
};

export const getManagementWeeklyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementWeeklyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementWeeklyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementWeeklyJobApplicationReports = res.data;
          dispatch(getManagementWeeklyJobApplicationReportsSuccess(managementWeeklyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementWeeklyJobApplicationReportsFail(err));
        });
    };
};


export const getManagementMonthlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementMonthlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementMonthlyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementMonthlyJobApplicationReports = res.data;
          dispatch(getManagementMonthlyJobApplicationReportsSuccess(managementMonthlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementMonthlyJobApplicationReportsFail(err));
        });
    };
};

export const getManagementYearlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementYearlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementYearlyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementYearlyJobApplicationReports = res.data;
          dispatch(getManagementYearlyJobApplicationReportsSuccess(managementYearlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementYearlyJobApplicationReportsFail(err));
        });
    };
};


//RECRUITER APPLICATIONS
export const getRecruiterDailyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterDailyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterDailyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const recruiterDailyJobApplicationReports = res.data;
          dispatch(getRecruiterDailyJobApplicationReportsSuccess(recruiterDailyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getRecruiterDailyJobApplicationReportsFail(err));
        });
    };
};

export const getRecruiterWeeklyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterWeeklyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterWeeklyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const recruiterWeeklyJobApplicationReports = res.data;
          dispatch(getRecruiterWeeklyJobApplicationReportsSuccess(recruiterWeeklyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getRecruiterWeeklyJobApplicationReportsFail(err));
        });
    };
};


export const getRecruiterMonthlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterMonthlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterMonthlyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const recruiterMonthlyJobApplicationReports = res.data;
          dispatch(getRecruiterMonthlyJobApplicationReportsSuccess(recruiterMonthlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getRecruiterMonthlyJobApplicationReportsFail(err));
        });
    };
};

export const getRecruiterYearlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getRecruiterYearlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterYearlyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const recruiterYearlyJobApplicationReports = res.data;
          dispatch(getRecruiterYearlyJobApplicationReportsSuccess(recruiterYearlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getRecruiterYearlyJobApplicationReportsFail(err));
        });
    };
};


//CANDIDATE APPLICATIONS
export const getCandidateDailyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getCandidateDailyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(candidateDailyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const candidateDailyJobApplicationReports = res.data;
          dispatch(getCandidateDailyJobApplicationReportsSuccess(candidateDailyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getCandidateDailyJobApplicationReportsFail(err));
        });
    };
};

export const getCandidateWeeklyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getCandidateWeeklyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(candidateWeeklyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const candidateWeeklyJobApplicationReports = res.data;
          dispatch(getCandidateWeeklyJobApplicationReportsSuccess(candidateWeeklyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getCandidateWeeklyJobApplicationReportsFail(err));
        });
    };
};


export const getCandidateMonthlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getCandidateMonthlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(candidateMonthlyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const candidateMonthlyJobApplicationReports = res.data;
          dispatch(getCandidateMonthlyJobApplicationReportsSuccess(candidateMonthlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getCandidateMonthlyJobApplicationReportsFail(err));
        });
    };
};

export const getCandidateYearlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getCandidateYearlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(candidateYearlyJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const candidateYearlyJobApplicationReports = res.data;
          dispatch(getCandidateYearlyJobApplicationReportsSuccess(candidateYearlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getCandidateYearlyJobApplicationReportsFail(err));
        });
    };
};


//MANAGEMENT
export const getManagementCandidateDailyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementCandidateDailyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementDailyCandidateJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementCandidateDailyJobApplicationReports = res.data;
          dispatch(getManagementCandidateDailyJobApplicationReportsSuccess(managementCandidateDailyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementCandidateDailyJobApplicationReportsFail(err));
        });
    };
};

export const getManagementCandidateWeeklyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementCandidateWeeklyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementWeeklyCandidateJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementCandidateWeeklyJobApplicationReports = res.data;
          dispatch(getManagementCandidateWeeklyJobApplicationReportsSuccess(managementCandidateWeeklyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementCandidateWeeklyJobApplicationReportsFail(err));
        });
    };
};


export const getManagementCandidateMonthlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementCandidateMonthlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementMonthlyCandidateJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementCandidateMonthlyJobApplicationReports = res.data;
          dispatch(getManagementCandidateMonthlyJobApplicationReportsSuccess(managementCandidateMonthlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementCandidateMonthlyJobApplicationReportsFail(err));
        });
    };
};

export const getManagementCandidateYearlyJobApplicationReports = (params, token) => {
  return dispatch => {
      dispatch(getManagementCandidateYearlyJobApplicationReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementYearlyCandidateJobApplicationReportsURL,{ params }, headers)
        .then(res => {
          const managementCandidateYearlyJobApplicationReports = res.data;
          dispatch(getManagementCandidateYearlyJobApplicationReportsSuccess(managementCandidateYearlyJobApplicationReports));
          })
        .catch(err => {
          dispatch(getManagementCandidateYearlyJobApplicationReportsFail(err));
        });
    };
};


//EMPLOYER
export const getEmployerDailyJobReports = (params, token) => {
  return dispatch => {
      dispatch(getEmployerDailyJobReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(employerDailyJobReportsURL,{ params }, headers)
        .then(res => {
          const employerDailyJobReports = res.data;
          dispatch(getEmployerDailyJobReportsSuccess(employerDailyJobReports));
          })
        .catch(err => {
          dispatch(getEmployerDailyJobReportsFail(err));
        });
    };
};

export const getEmployerWeeklyJobReports = (params, token) => {
  return dispatch => {
      dispatch(getEmployerWeeklyJobReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(employerWeeklyJobReportsURL,{ params }, headers)
        .then(res => {
          const employerWeeklyJobReports = res.data;
          dispatch(getEmployerWeeklyJobReportsSuccess(employerWeeklyJobReports));
          })
        .catch(err => {
          dispatch(getEmployerWeeklyJobReportsFail(err));
        });
    };
};

export const getEmployerMonthlyJobReports = (params, token) => {
  return dispatch => {
      dispatch(getEmployerMonthlyJobReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(employerMonthlyJobReportsURL,{ params }, headers)
        .then(res => {
          const employerMonthlyJobReports = res.data;
          dispatch(getEmployerMonthlyJobReportsSuccess(employerMonthlyJobReports));
          })
        .catch(err => {
          dispatch(getEmployerMonthlyJobReportsFail(err));
        });
    };
};


export const getEmployerYearlyJobReports = (params, token) => {
  return dispatch => {
      dispatch(getEmployerYearlyJobReportsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(employerYearlyJobReportsURL,{ params }, headers)
        .then(res => {
          const employerYearlyJobReports = res.data;
          dispatch(getEmployerYearlyJobReportsSuccess(employerYearlyJobReports));
          })
        .catch(err => {
          dispatch(getEmployerYearlyJobReportsFail(err));
        });
    };
};



export const getManagementStats = (token) => {
  return dispatch => {
      dispatch(getManagementStatsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementStatsURL, headers)
        .then(res => {
          const managementStats = res.data;
          dispatch(getManagementStatsSuccess(managementStats));
          })
        .catch(err => {
          dispatch(getManagementStatsFail(err));
        });
    };
};


export const getManagementMonthlyJobData = (token) => {
  return dispatch => {
      dispatch(getManagementMonthlyJobDataStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(managementMonthlyJobDataURL, headers)
        .then(res => {
          const managementMonthlyJobData = res.data;
          dispatch(getManagementMonthlyJobDataSuccess(managementMonthlyJobData));
          })
        .catch(err => {
          dispatch(getManagementMonthlyJobDataFail(err));
        });
    };
};




export const getRecruiterStats = (token) => {
  return dispatch => {
      dispatch(getRecruiterStatsStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterStatsURL, headers)
        .then(res => {
          const recruiterStats = res.data;
          dispatch(getRecruiterStatsSuccess(recruiterStats));
          })
        .catch(err => {
          dispatch(getRecruiterStatsFail(err));
        });
    };
};


export const getRecruiterMonthlyJobData = (token) => {
  return dispatch => {
      dispatch(getRecruiterMonthlyJobDataStart());
      let headers = axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };
      axios
        .get(recruiterMonthlyJobDataURL, headers)
        .then(res => {
          const recruiterMonthlyJobData = res.data;
          dispatch(getRecruiterMonthlyJobDataSuccess(recruiterMonthlyJobData));
          })
        .catch(err => {
          dispatch(getRecruiterMonthlyJobDataFail(err));
        });
    };
};

export const GET_CONTINENTS = 'GET_CONTINENTS';
export const EDIT_CONTINENT = 'EDIT_CONTINENT';
export const DELETE_CONTINENT = 'DELETE_CONTINENT';
export const ADD_CONTINENT = 'ADD_CONTINENT';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const EDIT_COUNTRY = 'EDIT_COUNTRY';
export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const ADD_COUNTRY = 'ADD_COUNTRY';
export const GET_PROVINCES = 'GET_PROVINCES';
export const EDIT_PROVINCE = 'EDIT_PROVINCE';
export const DELETE_PROVINCE = 'DELETE_PROVINCE';
export const ADD_PROVINCE = 'ADD_PROVINCE';
export const GET_DISTRICTS = 'GET_DISTRICTS';
export const EDIT_DISTRICT = 'EDIT_DISTRICT';
export const DELETE_DISTRICT = 'DELETE_DISTRICT';
export const ADD_DISTRICT = 'ADD_DISTRICT';
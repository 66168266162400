import React from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Chip } from 'primereact/chip';




const Links = (props) => {
    const {
        records,
    } = props;


    return (
        <>
            <div className="card">

                <div style={{textAlign: 'center'}} className="">
                        <Accordion activeIndex={0}>
                            {records.map((record) =>{
                                    return(
                                        <AccordionTab data-aos="flip-left" className="  font-bold mb-3" key={record.slug} header={record.url}>
                                            <ul className="list-none p-0 m-0">
                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">URL</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={record.url}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>
                                                 <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className=" w-6 md:w-4 font-medium">CATEGORY</div>
                                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={record.category}  className="mr-2 mb-2 " />
                                                    </div>

                                                </li>
                                            </ul>
                                        </AccordionTab>

                                        );
                                    }
                                )
                            }

                        </Accordion>
                    </div>
                </div>

        </>
    );
}


export default Links;

import axios from 'axios';
import {
    GET_LANGUAGES,
    ADD_LANGUAGE,
    EDIT_LANGUAGE,
    DELETE_LANGUAGE
} from '../types/languageTypes';

import {
    languagesURL

} from '../constants';
import { createMessage, returnErrors } from './messages';




export const getLanguages = (token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(languagesURL, headers)
        .then(res => {
            dispatch({
                type: GET_LANGUAGES,
                payload: res.data
            });
        }).catch(err => console.log(err))
}



export const addLanguage = (languages, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(languagesURL, languages, headers)
        .then(res => {
            dispatch({
                type: ADD_LANGUAGE,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });
        
}


export const deleteLanguage = (slug, token) => dispatch => {
    let headers =axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      };

    axios.delete(languagesURL, slug, headers)
        .then(res => {
            dispatch({
                type: DELETE_LANGUAGE,
                payload: slug
            });
        }).catch(err => console.log(err))
}




export const editLanguage = (slug, language, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    JSON.stringify(slug, null, 3)

    axios.patch(`${languagesURL}${slug}/`, language, headers)
        .then(res => {
            dispatch({
                type: EDIT_LANGUAGE,
                payload: res.data
            });
        }).catch(err => console.log(err))
}
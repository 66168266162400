import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import JobSeekerLayout from '../layout/JobSeekerLayout';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import ProposalContainer from './ProposalContainer';



export const Proposals = (props) => {

    const [hour, setHour] = useState(null);

    const [type, setType] = useState('PENDING');
    const {
        lastName,
        firstName,
      } = props;

    const onTypeChange = (e) => {
        setType(e.value);
      }

    const getHour = () => {
        const date = new Date();
        const hour = date.getHours()
        setHour(hour);
    }

    useEffect(() => {
        getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

        const getHourDisplay = () => {

          if (hour < 12){
            return(
              <>
                Good Morning!
              </>
            )
          }else if(hour < 18){
            return (
              <>Good Afternoon!!</>
            )
          }else{
            return(
              <>Good Evening!!</>
            );
          }
      }

    const getProposalDisplay = () =>{
        if (type === "CALLED FOR INTERVIEW"){
            return(
                <>
                    <ProposalContainer status='CALLED FOR INTERVIEW'/>
                </>
            )
        }else if (type === "ACCEPTED"){
            return(
                <>
                    <ProposalContainer status='ACCEPTED'/>
                </>
            )
        }else if (type === "DECLINED"){
            return(
                <>
                    <ProposalContainer status='DECLINED'/>
                </>
            )
        }else if (type === "PENDING"){
            return(
                <>
                    <ProposalContainer status='PENDING'/>
                </>
            )
        }else{
            return(
                <>
                    SOMETHING WRONG
                </>
            )
        }
    }


  return (
    <JobSeekerLayout>
        <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center" style={{fontFamily: 'cursive'}}>
                  <div className="text-900 font-bold text-5xl mb-3">{getHourDisplay()}</div>
                  <div className="text-700 text-2xl mb-5">{firstName} {lastName}</div>
                  <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
              </div>
            </div>
            <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <h5>THESE ARE YOUR JOB APPLICATIONS BASED ON THEIR CURRENT STATUS</h5>
                  <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                    <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                        <div className=" col-12 md:w-3 font-medium">
                          <RadioButton
                              inputId="category1"
                              name="category"
                              value="PENDING"

                              onChange={onTypeChange}
                              checked={type === "PENDING"}
                            />
                          <label className="" htmlFor="category1">PENDING DECISION</label>
                      </div>
                      <div className=" col-12 md:w-3 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="CALLED FOR INTERVIEW"
                              onChange={onTypeChange}
                              checked={type === "CALLED FOR INTERVIEW"}
                            />
                          <label className="" htmlFor="category2">CALLED FOR INTERVIEW</label>
                      </div>
                      <div className=" col-12 md:w-3 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="ACCEPTED"
                              onChange={onTypeChange}
                              checked={type === "ACCEPTED"}
                            />
                          <label className="" htmlFor="category2">WON</label>
                      </div>
                      <div className=" col-12 md:w-3 font-medium">
                        <RadioButton
                              inputId="category2"
                              name="category"
                              value="DECLINED"
                              onChange={onTypeChange}
                              checked={type === "DECLINED"}
                            />
                          <label className="" htmlFor="category2">NOT WON</label>
                      </div>

                    </li>
                  </ul>

            </div>
            {getProposalDisplay()}
    </JobSeekerLayout>
  )
}



const mapStateToProps = (state) => ({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,

})


export default connect(mapStateToProps, {})
(Proposals)

import {
    GET_LANGUAGES,
    ADD_LANGUAGE,
    EDIT_LANGUAGE,
    DELETE_LANGUAGE
} from '../types/languageTypes';



const initialState = {
    languages: [],
};





export default function languages(state = initialState, action){

    switch(action.type){

        case GET_LANGUAGES:
            return {
                ...state,
               languages : action.payload
            };

        case ADD_LANGUAGE:
            return {
                ...state,
               language: [...state.languages, action.payload]
            }

        case EDIT_LANGUAGE:
            const languageArray = state.languages;
           languageArray.splice(
                   languageArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
               languages:languageArray,
            };
        
        case DELETE_LANGUAGE:
            return {
                ...state,
               language: state.languages.filter(language=>language.slug !== action.payload)
            };
    
        default:
            return state;
    }
}

import React, { useState, useEffect } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Chip } from 'primereact/chip';
import { RadioButton } from 'primereact/radiobutton';


const Configurations = (props) => {
    const {
        token,
        addUserPrivacyAgreement,
        editUserPrivacyAgreement,
        getUserPrivacyAgreement,
        privacy,

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    let initialValues = {
        academic_qualifications: 'PUBLIC',
        contact_details: 'PUBLIC',
        skills: 'PUBLIC',
        profile: 'PUBLIC',
        languages: 'PUBLIC',
        links: 'PUBLIC',
        experiences: 'PUBLIC',
        portfolio: 'PUBLIC',
        activities: 'PUBLIC',
        services: 'PUBLIC',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);


    const onQualificationChange = (e) => {
        let _record = {...record};
        _record['academic_qualifications'] = e.value;
        setRecord(_record);
    }

    const onContactChange = (e) => {
        let _record = {...record};
        _record['contact_details'] = e.value;
        setRecord(_record);
    }

    const onSkillChange = (e) => {
        let _record = {...record};
        _record['skills'] = e.value;
        setRecord(_record);
    }

    const onProfileChange = (e) => {
        let _record = {...record};
        _record['profile'] = e.value;
        setRecord(_record);
    }


    const onLangaugeChange = (e) => {
        let _record = {...record};
        _record['languages'] = e.value;
        setRecord(_record);
    }

    const onLinkChange = (e) => {
        let _record = {...record};
        _record['links'] = e.value;
        setRecord(_record);
    }

    const onExperienceChange = (e) => {
        let _record = {...record};
        _record['experiences'] = e.value;
        setRecord(_record);
    }

    const onPortfolioChange = (e) => {
        let _record = {...record};
        _record['portfolio'] = e.value;
        setRecord(_record);
    }

    const onActivityChange = (e) => {
        let _record = {...record};
        _record['activities'] = e.value;
        setRecord(_record);
    }

    const onServiceChange = (e) => {
        let _record = {...record};
        _record['services'] = e.value;
        setRecord(_record);
    }



    useEffect(() => {
        getUserPrivacyAgreement(token);
        setIsSubmit(false);

    }, [isSubmit]);




    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
          resetForm();
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
              setIsSubmit(true);
              editUserPrivacyAgreement(record.slug, record, token);
              getUserPrivacyAgreement(token);
              resetForm();
              setDisplayMaximizable(false);
        }
        else {
            setIsSubmit(true);
            addUserPrivacyAgreement(record, token);
            resetForm();
            getUserPrivacyAgreement(token);
            resetForm();
            setDisplayMaximizable(false);

          }

    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }


    const editPrivacy = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }






    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD YOUR PRIVACY POLICY'
                    className="p-button-primary p-button-outlined p-button-success"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>

              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>

                </span>
              </div>
            </React.Fragment>
        )
    }

    const dialogHeader = () => {
        return (
            <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

              <span className='text-primary'>Privacy Configurations Form</span>
            </div>
        )
    }






    return (
        <>
            <div className="card">
              {privacy.has_privacy == false
                ?<Toolbar
                    className="mb-4"
                    left={leftToolbarTemplate}
                    right={rightToolbarTemplate}

                />

                :<div style={{textAlign: 'center', fontFamily: 'cursive'}} className="">
                    <ul className="list-none p-0 m-0">
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">ACADEMIC QUALIFICATIONS PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.academic_qualifications}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">CONTACT DETAILS PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.contact_details}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">PROFILE PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.profile}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">SKILLS PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.skills}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">PORTFOLIO PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.portfolio}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">LANGUAGES PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.languages}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">LINKS PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.links}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">ACTIVITIES PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.activities}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">SERVICES PRIVACY STATUS </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={privacy.services}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                            <div className="col-12 md:w-3 font-medium">
                                <Button
                                    icon="pi pi-pencil"
                                    label='UPDATE'
                                    className="p-button-rounded p-button-outlined  p-button-success"
                                    onClick={() => editPrivacy(privacy)}
                                />
                            </div>
                        </li>

                    </ul>

                  </div>
                }
            </div>

            <Dialog
              header={dialogHeader()}
              visible={displayMaximizable}
              maximizable
              modal
              style={{ width: '70%' }}
              onHide={() => onHide('displayMaximizable')}
            >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <div style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        ACADEMIC QUALIFICATIONS STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onQualificationChange}
                                            checked={record.academic_qualifications === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onQualificationChange}
                                            checked={record.academic_qualifications=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                                      <div className=" col-12 md:w-12 font-medium">
                                        CONTACT DETAILS STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onContactChange}
                                            checked={record.contact_details === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onContactChange}
                                            checked={record.contact_details=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        SKILLS STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onSkillChange}
                                            checked={record.skills === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onSkillChange}
                                            checked={record.skills=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        PROFILE STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onProfileChange}
                                            checked={record.profile === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onProfileChange}
                                            checked={record.profile=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        PORTFOLIO STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onPortfolioChange}
                                            checked={record.portfolio === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onPortfolioChange}
                                            checked={record.portfolio=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        LANGUAGE STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onLangaugeChange}
                                            checked={record.languages === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onLangaugeChange}
                                            checked={record.languages=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        LINKS STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onLinkChange}
                                            checked={record.links === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onLinkChange}
                                            checked={record.links=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        ACTIVITIES STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onActivityChange}
                                            checked={record.activities === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onActivityChange}
                                            checked={record.activities=== "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        SERVICES STATUS
                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PUBLIC"
                                            onChange={onServiceChange}
                                            checked={record.services  === "PUBLIC"}
                                          />
                                        <label className="" htmlFor="category1">PUBLIC</label>

                                      </div>
                                      <div className=" col-12 md:w-6 font-medium">
                                        <RadioButton
                                            style={{paddingRight: '20px'}}
                                            inputId="category2"
                                            name="category"
                                            value="PRIVATE"
                                            onChange={onServiceChange}
                                            checked={record.services === "PRIVATE"}
                                          />
                                        <label className="" htmlFor="category1">PRIVATE</label>
                                      </div>
                                  </li>
                                  <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">

                                      <div className=" col-12 md:w-12 font-medium">
                                        <Button className='p-button-outlined' type='submit' label='SUBMIT' />
                                      </div>


                                  </li>

                                </ul>
                            </div>
                        </form>
                    </div>
                </Dialog>
        </>
    );
}


export default Configurations;

import React, { useState, useEffect } from 'react';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Chip } from 'primereact/chip';

const categoryOptions = [
    {key: 'SELF EMPLOYED'},
    {key: 'UNEMPLOYED'},
    {key: 'PART TIME EMPLOYED'},
    {key: 'STUDENT'},
    {key: 'FULLTIME EMPLOYED'},

]

const monthOptions = [
    {key: 'JANUARY'},
    {key: 'FEBRUARY'},
    {key: 'MARCH'},
    {key: 'APRIL'},
    {key: 'MAY'},
    {key: 'JUNE'},
    {key: 'JULY'},
    {key: 'AUGUST'},
    {key: 'SEPTEMBER'},
    {key: 'OCTOBER'},
    {key: 'NOVEMBER'},
    {key: 'DECEMBER'},
]


const yearOptions = [
      {key: 1960, value: 1960},
      {key: 1961, value: 1961},
      {key: 1962, value: 1962},
      {key: 1963, value: 1963},
      {key: 1964, value: 1964},
      {key: 1965, value: 1965},
      {key: 1966, value: 1966},
      {key: 1967, value: 1967},
      {key: 1968, value: 1968},
      {key: 1969, value: 1969},
      {key: 1970, value: 1970},
      {key: 1971, value: 1971},
      {key: 1972, value: 1972},
      {key: 1973, value: 1973},
      {key: 1974, value: 1974},
      {key: 1975, value: 1975},
      {key: 1976, value: 1976},
      {key: 1977, value: 1977},
      {key: 1978, value: 1978},
      {key: 1979, value: 1979},
      {key: 1980, value: 1980},
      {key: 1981, value: 1981},
      {key: 1982, value: 1982},
      {key: 1983, value: 1983},
      {key: 1984, value: 1984},
      {key: 1985, value: 1985},
      {key: 1988, value: 1988},
      {key: 1988, value: 1988},
      {key: 1988, value: 1988},
      {key: 1989, value: 1989},
      {key: 1990, value: 1990},
      {key: 1991, value: 1991},
      {key: 1992, value: 1992},
      {key: 1993, value: 1993},
      {key: 1994, value: 1994},
      {key: 1995, value: 1995},
      {key: 1999, value: 1999},
      {key: 1999, value: 1999},
      {key: 1998, value: 1998},
      {key: 1999, value: 1979},
      {key: 2000, value: 2000},
      {key: 2001, value: 2001},
      {key: 2002, value: 2002},
      {key: 2003, value: 2003},
      {key: 2004, value: 2004},
      {key: 2005, value: 2005},
      {key: 2006, value: 2006},
      {key: 2007, value: 2007},
      {key: 2008, value: 2008},
      {key: 2009, value: 2009},
      {key: 2010, value: 2010},
      {key: 2011, value: 2011},
      {key: 2012, value: 2012},
      {key: 2013, value: 2013},
      {key: 2014, value: 2014},
      {key: 2015, value: 2015},
      {key: 2016, value: 2016},
      {key: 2017, value: 2017},
      {key: 2018, value: 2018},
      {key: 2019, value: 2019},
      {key: 2020, value: 2020},
      {key: 2021, value: 2021},
      {key: 2022, value: 2022},
]


const Roles = (props) => {
    const {
        token,
        addCandidateRole,
        editCandidateRole,
        getCandidateRoles,
        records,
        deleteCandidateRole,
        countries,
        provinces,

    } = props;

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    let initialValues = {
        occupation: '',
        entity: '',
        description: '',
        category: '',
        city: '',
        country: '',
        from_month: '',
        to_month: '',
        from_year: '',
        to_year: '',
        still_working_there: false,
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [layout, setLayout] = useState('grid');

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if (!record.still_working_there){
            if ('to_year' in fieldValues)
                temp.to_year= fieldValues.to_year? "" : "To Year is Required"
            if ('from_year' in fieldValues)
                temp.from_year= fieldValues.from_year? "" : "From Year is Required"
            if ('to_month' in fieldValues)
                temp.to_month= fieldValues.to_month? "" : "To Month is Required"
            if ('from_month' in fieldValues)
                temp.from_month= fieldValues.from_month? "" : "From Month is Required"
            if ('country' in fieldValues)
                temp.country= fieldValues.country? "" : "Country is Required"
            if ('city' in fieldValues)
                temp.city= fieldValues.city? "" : "City is Required"
            if ('occupation' in fieldValues)
                temp.occupation= fieldValues.occupation? "" : "Occupation is Required"
            if ('entity' in fieldValues)
                temp.entity= fieldValues.entity? "" : "Institution is Required"
            if ('description' in fieldValues)
                temp.description= fieldValues.description? "" : "Description is Required"
            if ('category' in fieldValues)
                temp.category= fieldValues.category? "" : "Category is Required"
            setErrors({
                ...temp
            })

            if (fieldValues === record){
                return Object.values(temp).every(x => x === "")
            }
        }else{

            if ('from_year' in fieldValues)
                temp.from_year= fieldValues.from_year? "" : "From Year is Required"
            if ('from_month' in fieldValues)
                temp.from_month= fieldValues.from_month? "" : "From Month is Required"
            if ('country' in fieldValues)
                temp.country= fieldValues.country? "" : "Country is Required"
            if ('city' in fieldValues)
                temp.city= fieldValues.city? "" : "City is Required"
            if ('occupation' in fieldValues)
                temp.occupation= fieldValues.occupation? "" : "Occupation is Required"
            if ('entity' in fieldValues)
                temp.entity= fieldValues.entity? "" : "Institution is Required"
            if ('description' in fieldValues)
                temp.description= fieldValues.description? "" : "Description is Required"
            if ('category' in fieldValues)
                temp.category= fieldValues.category? "" : "Category is Required"
            setErrors({
                ...temp
            })

            if (fieldValues === record){
                return Object.values(temp).every(x => x === "")
            }


        }


      }



    useEffect(() => {
        getCandidateRoles(token);
        setIsSubmit(false);

    }, [isSubmit]);


    const deleteRole = (slug) =>{
        deleteCandidateRole(slug, token);
        setIsSubmit(true);

    }



    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }

    const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);
          setErrors({})

          if (position) {
              setPosition(position);
          }
    }

    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
          resetForm();
          setErrors({})
    }


    const onSubmit = (e) => {
        e.preventDefault();
        if (record.slug) {
            if (validate()){
                setErrors(validate(record));
                setIsSubmit(true);
                editCandidateRole(record.slug, record, token);
                getCandidateRoles(token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                addCandidateRole(record, token);
                getCandidateRoles(token);
                resetForm();
                setDisplayMaximizable(false);
            }

          }

    }



    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
          const val = (e.target && e.target.value) || '';
          let _record = {...record};
          _record[`${name}`] = val;
          setRecord(_record);
    }

    const onStillWorkingThereChange = (e) => {
        let _record = {...record};
        _record['still_working_there'] = e.value;
        setRecord(_record);
        setErrors({})
    }


    const editRole = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }




    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search"  placeholder="Search..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button

                    label='ADD ROLE'
                    className="p-button-primary p-button-outlined p-button-success"
                    onClick={() => onClick('displayMaximizable')}

                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
            </React.Fragment>
        )
    }

    const dialogExperienceHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Experience Form</span>
              </div>
          )
      }


    const renderGridItem = (data) => {
        return (

            <div
                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div data-aos="zoom-in" className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div data-aos="fade-down" className="surface-1">
                            <ul className="list-none job_link p-0 m-0">

                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        TITLE
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.occupation}  className="mr-2 mb-2  " />
                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CATEGORY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.category}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        COMPANY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.entity}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        COUNTRY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.country}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        CITY
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.city}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        FROM MONTH
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.from_month}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        FROM
                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={data.from_year}  className="mr-2 mb-2 text-primary" />

                                    </div>
                                </li>

                                {data.still_working_there

                                    ?<li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                        <div className=" col-12 md:w-12 font-medium">
                                            I AM STILL EMPLOYED THERE
                                        </div>

                                    </li>
                                    :
                                    <>
                                        <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className=" col-12 md:w-12 font-medium">
                                                To MONTH
                                            </div>
                                            <div className=" col-12 md:w-12 font-medium">
                                                <Chip label={data.to_month}  className="mr-2 mb-2 text-primary" />

                                            </div>
                                        </li>
                                        <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className=" col-12 md:w-12 font-medium">
                                                To YEAR
                                            </div>
                                            <div className=" col-12 md:w-12 font-medium">
                                                <Chip label={data.to_year}  className="mr-2 mb-2 text-primary" />

                                            </div>
                                        </li>
                                    </>
                                }
                                <li className="flex card col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">

                                    <div className="col-12 md:w-6 font-medium">
                                        <Button
                                            icon="pi pi-pencil"
                                            label='UPDATE'
                                            className="p-button-rounded p-button-outlined  p-button-success"
                                            onClick={() => editRole(data)}
                                        />
                                    </div>
                                    <div className="col-12 md:w-6 font-medium">
                                        <Button
                                            icon="pi pi-trash"
                                            label='DELETE'
                                            className="p-button-rounded p-button-outlined p-button-danger"
                                            onClick={() => deleteRole(data.slug)}
                                        />
                                    </div>
                                </li>

                        </ul>

                    </div>
                    </div>
            </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderGridItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }

    const header = renderHeader();

    return (
        <div>
            <div className="card">

                <Toolbar
                className="mb-4"
                left={leftToolbarTemplate}
                right={rightToolbarTemplate}

            />
            <div style={{textAlign: 'center'}} className="text-primary">
                <div className="grid ripple-demo">

                    <div className="col-12 md:col-12 " style={{padding: '30px'}}>
                        {records.length === 0
                            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                                <div className="col-12">
                                        <div className="card">
                                            <h5 className='text-primary'>EMPTY LANGUAGES</h5>
                                            <p className='text-primary'>CURRENTLY THERE ARE NO LANGAUGES</p>
                                        </div>
                                </div>
                            </div>
                            :<div className="dataview-demo">
                                <DataView
                                    value={records}
                                    layout={layout}
                                    header={header}
                                    itemTemplate={itemTemplate}
                                    paginator
                                    rows={15}
                                    emptyMessage="CURRENTLY THERE ARE NO LANGAUGES"
                                />
                            </div>
                        }
                    </div>
                </div>


            </div>

                <Dialog header={dialogExperienceHeader()}
                    visible={displayMaximizable}
                    maximizable
                    modal
                    style={{ width: '70%' }}
                    onHide={() => onHide('displayMaximizable')}
                    >
                    <div className="cards">
                        <form onSubmit={onSubmit}>
                            <div className="grid" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                                <ul  className="list-none card grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                    <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHAT WAS YOUR JOB TITLE
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="occupation"
                                                value={record.occupation}
                                                onChange={(e) => onInputChange(e, 'occupation')}
                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.occupation}</small>
                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHO WERE YOU WORKING FOR?
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <InputText
                                                id="entity"
                                                value={record.entity}
                                                onChange={(e) => onInputChange(e, 'entity')}
                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.entity}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            SELECT THE TYPE OF ROLE YOU WERE PLAYING
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.category}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={categoryOptions}
                                                onChange={(e) => onInputChange(e, 'category')}
                                                filter
                                                showClear
                                                filterBy="key"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                              {errors.category}
                                            </small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHICH CITY WAS THE JOB BASED?
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <Dropdown
                                                value={record.city}
                                                optionLabel="name"
                                                optionValue="name"
                                                options={provinces}
                                                onChange={(e) => onInputChange(e, 'city')}
                                                filter
                                                showClear
                                                filterBy="name"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                                {errors.category}
                                            </small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                             WHICH COUNTRY WAS THE JOB BASED?
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.country}
                                                optionLabel="name"
                                                optionValue="name"
                                                options={countries}
                                                onChange={(e) => onInputChange(e, 'country')}
                                                filter
                                                showClear
                                                filterBy="name"

                                            />
                                            <small id="username2-help"  className="p-error block">{errors.country}</small>


                                        </div>
                                    </li>
                                     <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            DESCRIBE YOUR ROLE, HOW LONG IT TOOK TO FINISH THE JOB & ANY OTHER THINGS YOU WOULD WANT TO INCLUDE
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <InputTextarea
                                              rows={1}
                                              cols={30}
                                              autoResize
                                              value={record.description}
                                              onChange={(e) => onInputChange(e, 'description')}
                                          />
                                          <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">{errors.description}</small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            WHICH MONTH DID YOU START WORKING THERE?
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                           <Dropdown
                                                value={record.from_month}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={monthOptions}
                                                onChange={(e) => onInputChange(e, 'from_month')}
                                                filter
                                                filterBy="key"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                              {errors.from_month}
                                            </small>

                                        </div>
                                    </li>
                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                             WHICH YEAR DID YOU START WORKING THERE?
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                            <Dropdown
                                                value={record.from_year}
                                                optionLabel="key"
                                                optionValue="key"
                                                options={yearOptions}
                                                onChange={(e) => onInputChange(e, 'from_year')}
                                                filter
                                                showClear
                                                filterBy="key"

                                            />
                                            <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                              {errors.from_year}
                                            </small>


                                        </div>
                                    </li>
                                     <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                        <div
                                            className="text-primary w-full  md:w-4 md:flex-order-0 flex-order-1"
                                            style={{fontSize: '15px', fontFamily: 'cursive'}}
                                        >
                                            ARE YOU STILL WORKING THERE?
                                        </div>
                                        <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                          <div className="formgrid grid">
                                            <div className="field-radiobutton col-6">
                                                <RadioButton
                                                    inputId="category1"
                                                    name="category"
                                                    value={true}
                                                    onChange={onStillWorkingThereChange}
                                                    checked={record.category === true}
                                                  />
                                                <label className="" htmlFor="category1">YES</label>
                                            </div>
                                            <div className="field-radiobutton col-6">
                                                <RadioButton
                                                    inputId="category2"
                                                    name="category"
                                                    value={false}
                                                    onChange={onStillWorkingThereChange}
                                                    checked={record.category === false}
                                                  />
                                                <label className="" htmlFor="category2">NO</label>
                                            </div>
                                          </div>
                                        </div>
                                    </li>


                                    {record.still_working_there
                                        ?<></>
                                        :<>
                                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                <div
                                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                >
                                                     WHICH MONTH DID YOU STOP WORKING THERE?
                                                </div>
                                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                                    <Dropdown
                                                        value={record.to_month}
                                                        optionLabel="key"
                                                        optionValue="key"
                                                        options={monthOptions}
                                                        onChange={(e) => onInputChange(e, 'to_month')}
                                                        filter
                                                        showClear
                                                        filterBy="key"

                                                    />
                                                    <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                                    {errors.to_month}
                                                    </small>


                                                </div>
                                            </li>
                                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                                <div
                                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                                >
                                                     WHICH YEAR DID YOU STOP WORKING THERE?
                                                </div>
                                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                                    <Dropdown
                                                        value={record.to_year}
                                                        optionLabel="key"
                                                        optionValue="key"
                                                        options={yearOptions}
                                                        onChange={(e) => onInputChange(e, 'to_year')}
                                                        filter
                                                        showClear
                                                        filterBy="key"

                                                    />
                                                    <small id="username2-help" style={{paddingBottom: '20px'}}  className="p-error block">
                                                    {errors.to_year}
                                                    </small>


                                                </div>
                                            </li>
                                        </>

                                    }

                                    <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <Button
                                          type="submit"
                                          label="ADD ROLE"
                                          className="p-button-outlined p-button-warning"

                                        />
                                    </li>
                                </ul>

                            </div>
                        </form>
                    </div>

                </Dialog>

            </div>
        </div>
    );
}


export default Roles;

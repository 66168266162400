import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import ManagementLayout from '../layout/ManagementLayout';
import { getRecommendationUsers, recommendPeople } from '../../actions/recommendations';
import {getManagementJobs} from '../../actions/jobs';
import Recommend from './Recomend';



export const EmployerDetails = (props) => {
    const {
        candidates,
        jobs,
        getRecommendationUsers,
        token

    } =props;
    const initialParams = {
        job: '',
        geolocation: '',
        skill: '',
        cityLocations: '',
        qualification: '',
    }
    const [params, setParams] = useState(initialParams)
    const jobOptions = jobs.filter((job) => job.status==='AVAILABLE');
    const candidateOptions = candidates.filter((cnd) =>cnd.active ===true);


    useEffect(() =>{
        if (!props.fetched){
            props.getRecommendationUsers(params, props.token);
            props.getManagementJobs(props.token);
        }


    }, []) // eslint-disable-line react-hooks/exhaustive-deps





    return (
        <ManagementLayout>
            <Recommend
                params={params}
                setParams={setParams}
                jobOptions={jobOptions}
                candidateOptions={candidateOptions}
                getRecommendationUsers={getRecommendationUsers}
                token={token}
                recommendPeople={props.recommendPeople}
                initialParams={initialParams}

            />
        </ManagementLayout>
    )
}



const mapStateToProps = (state) => ({
    token: state.auth.token,
    candidates: state.recommendations.recommendationusers,
    jobs: state.jobs.managementjobs,
})



export default connect(
    mapStateToProps,
    {
        getRecommendationUsers,
        getManagementJobs,
        recommendPeople
    })
    (EmployerDetails);

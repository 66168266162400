import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import ManagementLayout from '../layout/ManagementLayout';
import {connect} from 'react-redux';
import {
    getFaculties,
    addFaculty,
    editFaculty,
    deleteFaculty

} from '../../actions/skills';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Chip } from 'primereact/chip';





const Faculties = (props) => {


    const [filters2, setFilters2] = useState({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'title': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const [globalFilterValue2, setGlobalFilterValue2] = useState('');
    const [loading2, setLoading2] = useState(false);
    const {records, token} = props;

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    let initialValues = {
        title: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const sortOptions = [
        {label: 'Price High to Low', value: '!price'},
        {label: 'Price Low to High', value: 'price'},
    ];

    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [tableDialog, setTableDialog] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    const [globalFilter, setGlobalFilter] = useState(null);




    const deletingFaculty = (data) => {
        props.deleteFaculty(data.slug, props.token);
        setIsSubmit(true);
    }



    useEffect(() => {
        if (!props.fetched){
            props.getFaculties(props.token);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        props.getFaculties(props.token);
        console.log('Call this nigga!!!');
        setIsSubmit(false);


    }, [isSubmit])// eslint-disable-line react-hooks/exhaustive-deps


    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();
        let _record = {...record};
        if (record.slug) {
            if (validate()){
                setIsSubmit(true);
                props.editFaculty(record.slug, record, props.token);
                props.getFaculties(props.token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                console.log(errors)
                props.addFaculty(record, props.token);
                props.getFaculties(props.token);
                resetForm();
                setDisplayMaximizable(false);
            }

        }

    }


    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('title' in fieldValues)
            temp.title= fieldValues.title? "" : "Name is Required"
        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
        resetForm();
    }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
        'tableDialog': setTableDialog,
    }

    const editContinentButton = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }




    const renderListItem = (data) => {
        return (
            <div className="col-12">
                <div className="product-list-item">

                    <div className="product-list-detail">
                        <div className="product-name">{data.title}</div>
                    </div>


                    <div className='grid p-fluid'>
                        <div className="col-12 md:col-2">
                            <Button
                                className="p-button-rounded p-button-warning p-button-outlined"
                                icon="pi pi-pencil"
                                onClick={() => editContinentButton(data)}
                            />
                        </div>
                        <div className="col-12 md:col-8">
                        </div>
                        <div className="col-12 md:col-2">
                            <Button
                                icon="pi pi-trash"
                                className="p-button-rounded p-button-danger p-button-outlined"
                                onClick={() => deletingFaculty(data)}
                            />
                        </div>

                    </div>
                </div>

            </div>
        );
    }

    const renderGridItem = (data) => {
        return (
            <div className="col-12 flex-grow-1 md:col-4 card border-50 box border-round shadow-8" style={{padding: '20px', fontFamily: 'cursive'}}>
                <div className="box shadow-8">
                    <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.title}  className="mr-2 mb-2 " />
                            </div>       
                        </li>
                    </ul>
                    <div className='grid p-fluid'>
                        <div className="col-12 md:col-2">
                                <Button
                                    className="p-button-text p-button-warning "
                                    icon="pi pi-pencil"
                                    onClick={() => editContinentButton(data)}
                                />
                            </div>
                            <div className="col-12 md:col-8">
                                
                            </div>
                            <div className="col-12 md:col-2">
                                <Button
                                    icon="pi pi-trash"
                                    className="p-button-text p-button-danger "
                                    onClick={() => deletingFaculty(data)}
                                />
                            </div>
                        </div>
                    </div>
            </div>

        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }

    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                
                <div className="col-4" style={{textAlign: 'center', padding: '20px'}}>
                    <Button
                        label="ADD NEW"
                        className="p-button-text "
                        onClick={() => onClick('displayMaximizable')}
                    />
                </div>
                <div className="col-2" style={{textAlign: 'center', padding: '20px'}}>
                    <Button
                        label="TABLE"
                        className="p-button-text "
                        onClick={() => onClick('tableDialog')}
                    />
                </div>
                <div className="col-2" style={{textAlign: 'center', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                    />
                </div>
            </div>
        );
    }

    const renderFooter = (name) => {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
            </div>
        );
    }





    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                    label='ADD FACULTY'    
                    icon='pi pi-plus'
                    className="p-button-text "
                    onClick={() => onClick('displayMaximizable')}
                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>

                <Button icon="pi pi-upload" className="p-button-text"/>
            </React.Fragment>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-pencil"
                    className="p-button-text p-button-warning "
                    onClick={() => editContinentButton(rowData)}
                />
                <Button
                    icon="pi pi-trash"
                    className="p-button-text p-button-danger"
                    onClick={() => deletingFaculty(rowData)}

                />
            </React.Fragment>
        );
    }

    const header = renderHeader();


    return (
        <ManagementLayout>
            <div className="dataview-demo">
                <div className="card">
                    <DataView
                        value={props.records}
                        layout={layout}
                        header={header}
                        itemTemplate={itemTemplate}
                        paginator rows={30}
                        sortOrder={sortOrder}
                        sortField={sortField}
                    />
                </div>
            </div>
            <Dialog
                header="FACULTY FORM"
                visible={displayMaximizable}
                maximizable modal
                style={{ width: '50vw' }}
                onHide={() => onHide('displayMaximizable')}
            >
                    <div className="card">
                        <form onSubmit={onSubmit}>
                            <ul  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                                <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                    <div
                                        className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                                    >
                                        PUT NAME
                                    </div>
                                    <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <InputText
                                            id="name"
                                            value={record.title}
                                            tooltip="Choose Name"
                                            onChange={(e) => onInputChange(e, 'title')}  
                                        /> 
                                        <small id="username2-help"  className="p-error block">{errors.title}</small> 
                                    </div>
                                </li>
                                
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                                    <Button
                                        type="submit"
                                        label="Submit"
                                        className="p-button-outlined p-button-warning"

                                    /> 
                                </li>
                            </ul>
                        </form>
                    </div>

            </Dialog>
            <Dialog
                header="FACULTY"
                visible={tableDialog}
                maximizable modal
                style={{ width: '1200px' }}
                onHide={() => onHide('tableDialog')}
            >
                <div className="card">
                    <Toolbar
                        className="mb-4"
                        left={leftToolbarTemplate}
                        right={rightToolbarTemplate}
                    />
                    <DataTable
                        value={props.records}
                        responsiveLayout="scroll"
                        dataKey="slug"
                        paginator
                        globalFilter={globalFilter}
                        filterDisplay="row"
                        filters={filters2}
                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                        rows={30}
                        header={tableHeader}
                        globalFilterFields={['title',]}
                        rowsPerPageOptions={[5, 10, 25]}
                        showGridlines
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} FACULTIES"
                    >
                      
                        <Column
                            field="title"
                            header="NAME"
                            sortable
                            style={{fontSize: '15px'}}
                            filter
                        />
                         <Column
                            body={actionBodyTemplate}
                            exportable={false}
                            style={{ minWidth: '8rem' }}
                        />
                    </DataTable>
                </div>


            </Dialog>
        </ManagementLayout>
    );
}


const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.skills.faculties,
})

export default connect(
  mapStateToProps,
  {
    getFaculties,
    addFaculty,
    editFaculty,
    deleteFaculty
} )
  (Faculties);

import {
    GET_CANDIDATE_ACADEMIC_QUALIFICATIONS,
    ADD_CANDIDATE_ACADEMIC_QUALIFICATION,
    EDIT_CANDIDATE_ACADEMIC_QUALIFICATION,
    DELETE_CANDIDATE_ACADEMIC_QUALIFICATION,
    GET_CANDIDATE_AVAILABILITY,
    ADD_CANDIDATE_AVAILABILITY,
    EDIT_CANDIDATE_AVAILABILITY,
    DELETE_CANDIDATE_AVAILABILITY,
    GET_CANDIDATE_RESUME,
    ADD_CANDIDATE_RESUME,
    EDIT_CANDIDATE_RESUME,
    DELETE_CANDIDATE_RESUME,
    GET_CANDIDATE_LANGUAGES,
    ADD_CANDIDATE_LANGUAGE,
    EDIT_CANDIDATE_LANGUAGE,
    DELETE_CANDIDATE_LANGUAGE,
    GET_CANDIDATE_ROLES,
    ADD_CANDIDATE_ROLE,
    EDIT_CANDIDATE_ROLE,
    DELETE_CANDIDATE_ROLE,
    GET_CANDIDATE_LINKS,
    ADD_CANDIDATE_LINK,
    EDIT_CANDIDATE_LINK,
    DELETE_CANDIDATE_LINK,
    GET_CANDIDATE_SKILLS,
    ADD_CANDIDATE_SKILL,
    EDIT_CANDIDATE_SKILL,
    DELETE_CANDIDATE_SKILL,

} from '../types/resumeTypes';



const initialState = {
    candidateresume: {},
    candidateavailability: {},
    candidateacademicqualifications: [],
    candidatelinks: [],
    candidatelanguages: [],
    candidateroles: [],
    candidateskills: [],
};





export default function resumes(state = initialState, action){

    switch(action.type){

        case GET_CANDIDATE_RESUME:
            return {
                ...state,
               candidateresume : action.payload
            };

        case ADD_CANDIDATE_RESUME:
            return {
                ...state,
                candidateresume: [...state.candidateresumes, action.payload]
            }

        case EDIT_CANDIDATE_RESUME:
            const candidateresumeArray = state.resumes;
            candidateresumeArray.splice(
                candidateresumeArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidateresumes:candidateresumeArray,
            };
        
        case DELETE_CANDIDATE_RESUME:
            return {
                ...state,
                candidateresume: state.candidateresumes.filter(candidateresume=>candidateresume.slug !== action.payload)
            };

        //CANDIDATE AVAILABILITY

        case GET_CANDIDATE_AVAILABILITY:
            return {
                ...state,
                candidateavailability : action.payload
            };

        case ADD_CANDIDATE_AVAILABILITY:
            return {
                ...state,
                candidateavailability: [...state.candidateavailabilitys, action.payload]
            }

        case EDIT_CANDIDATE_AVAILABILITY:
            const candidateavailabilityArray = state.candidateavailabilitys;
            candidateavailabilityArray.splice(
                    candidateavailabilityArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidateavailabilitys:candidateavailabilityArray,
            };
        
        case DELETE_CANDIDATE_AVAILABILITY:
            return {
                ...state,
                candidateavailability: state.candidateavailabilitys.filter(candidateavailability=>candidateavailability.slug !== action.payload)
            };

        //CANDIDATE ACADEMIC QUALIFICATIONS
        case GET_CANDIDATE_ACADEMIC_QUALIFICATIONS:
            return {
                ...state,
                candidateacademicqualifications : action.payload
            };

        case ADD_CANDIDATE_ACADEMIC_QUALIFICATION:
            return {
                ...state,
                candidateacademicqualification: [...state.candidateacademicqualifications, action.payload]
            }

        case EDIT_CANDIDATE_ACADEMIC_QUALIFICATION:
            const candidateacademicqualificationArray = state.candidateacademicqualifications;
            candidateacademicqualificationArray.splice(
                    candidateacademicqualificationArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidateacademicqualifications:candidateacademicqualificationArray,
            };
        
        case DELETE_CANDIDATE_ACADEMIC_QUALIFICATION:
            return {
                ...state,
                candidateacademicqualification: state.candidateacademicqualifications.filter(candidateacademicqualification=>candidateacademicqualification.slug !== action.payload)
            };


        //CANDIDATE LANGUAGE
        case GET_CANDIDATE_LANGUAGES:
            return {
                ...state,
                candidatelanguages : action.payload
            };

        case ADD_CANDIDATE_LANGUAGE:
            return {
                ...state,
                candidatelanguage: [...state.candidatelanguages, action.payload]
            }

        case EDIT_CANDIDATE_LANGUAGE:
            const candidatelanguageArray = state.candidatelanguages;
            candidatelanguageArray.splice(
                    candidatelanguageArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidatelanguages:candidatelanguageArray,
            };
        
        case DELETE_CANDIDATE_LANGUAGE:
            return {
                ...state,
                candidatelanguage: state.candidatelanguages.filter(candidatelanguage=>candidatelanguage.slug !== action.payload)
            };

        //CANDIDATE LINKS 
        case GET_CANDIDATE_LINKS:
            return {
                ...state,
                candidatelinks : action.payload
            };

        case ADD_CANDIDATE_LINK:
            return {
                ...state,
                candidatelink: [...state.candidatelinks, action.payload]
            }

        case EDIT_CANDIDATE_LINK:
            const candidatelinkArray = state.candidatelinks;
            candidatelinkArray.splice(
                    candidatelinkArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidatelinks:candidatelinkArray,
            };
        
        case DELETE_CANDIDATE_LINK:
            return {
                ...state,
                candidatelink: state.candidatelinks.filter(candidatelink=>candidatelink.slug !== action.payload)
            };

        //CANDIDATE ROLES
        case GET_CANDIDATE_ROLES:
            return {
                ...state,
                candidateroles : action.payload
            };

        case ADD_CANDIDATE_ROLE:
            return {
                ...state,
                candidaterole: [...state.candidateroles, action.payload]
            }

        case EDIT_CANDIDATE_ROLE:
            const candidateroleArray = state.candidateroles;
            candidateroleArray.splice(
                    candidateroleArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidateroles:candidateroleArray,
            };
        
        case DELETE_CANDIDATE_ROLE:
            return {
                ...state,
                candidaterole: state.candidateroles.filter(candidaterole=>candidaterole.slug !== action.payload)
            };

        //SKILLS
        case GET_CANDIDATE_SKILLS:
            return {
                ...state,
                candidateskills : action.payload
            };

        case ADD_CANDIDATE_SKILL:
            return {
                ...state,
                candidateskill: [...state.candidateskills, action.payload]
            }

        case EDIT_CANDIDATE_SKILL:
            const candidateskillArray = state.candidateskills;
            candidateskillArray.splice(
                    candidateskillArray.findIndex(item => item.slug === action.payload.data.slug), 
                    1, 
                    action.payload.data
                );
            return {
                ...state,
                candidateskills:candidateskillArray,
            };
        
        case DELETE_CANDIDATE_SKILL:
            return {
                ...state,
                candidateskill: state.candidateskills.filter(candidateskill=>candidateskill.slug !== action.payload)
            };
    
    
        default:
            return state;
    }
}

import React, {useEffect}  from 'react';
import classNames from 'classnames';
import { useNavigate, Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import {logout} from '../../actions/auth';
import {connect } from 'react-redux';
import zimjobs from './zimjobs.png';
import {getUserMessages} from '../../actions/chats';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';






const AppTopbar = (props) => {

  const navigate = useNavigate();
  const {messages} =props;


    const changeStatus = () => {
        props.logout()
        navigate('/login', { replace: true });
    };



    useEffect(() =>{
      props.getUserMessages(props.token)
    }, [])



    return (
        <div className="layout-topbar">
            <Link to="/jobs" className="layout-topbar-logo">
                <img src={zimjobs} alt="logo"/>
            </Link>



            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        label="MY PROFILE"
                        type="button"
                        className="mr-1 p-button-text"
                        onClick={()=> navigate('/jobs/profile')}
                      />
                    </li>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        label="MY PROPOSALS"
                        type="button"
                        className="mr-1 p-button-text"
                        onClick={()=> navigate('/jobs/proposals')}
                      />
                    </li>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        type="button"
                        className="mr-1 p-button-text"
                        onClick={()=> navigate('/jobs/chats')}
                      >
                        MESSAGES
                        {messages.new_messages > 0
                          ?<Badge severity="danger" className='p-button-text' value={messages.new_messages}/>
                          :<></>
                        }
                      </Button>
                    </li>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        label="REPORTS"
                        type="button"
                        className="mr-1 p-button-text"
                        onClick={()=> navigate('/jobs/reports')}
                      />
                    </li>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        label="FIND JOBS"
                        type="button"
                        className="mr-1 p-button-text"
                      />
                    </li>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        label="LOGOUT"
                        type="button"
                        className="mr-1 p-button-text"
                        onClick={changeStatus}
                      />
                    </li>
                </ul>
        </div>
    );
}

const mapStateToProps = state =>({
  token: state.auth.token,
  messages: state.chats.usermessages,
})


export default connect(
  mapStateToProps,
  {logout, getUserMessages} )
( AppTopbar);

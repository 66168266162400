import React  from 'react';
import classNames from 'classnames';
import { useNavigate, Link } from 'react-router-dom';
import {logout} from '../../actions/auth';
import {connect } from 'react-redux';
import zimjobs from './zimjobs.png';
import { Button } from 'primereact/button';





const AppTopbar = (props) => {

  const navigate = useNavigate();


    const changeStatus = () => {
        props.logout()
        navigate('/login', { replace: true });
    };



    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img src={zimjobs} alt="logo"/>
            </Link>





            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

                <ul className={classNames("layout-topbar-menu lg:flex origin-top", {'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>

                    <li>
                      <Button
                        style={{fontSize : '10px',  paddingBottom: '10px'}}
                        label="WHY?"
                        type="button"
                        className="mr-1 mb-3 p-button-text"
                        onClick={()=> navigate('/why')}
                      />
                    </li>

                    <li>
                      <Button
                          style={{fontSize : '10px'}}
                          label="ABOUT US"
                          type="button"
                          className="mr-1 mb-3 p-button-text"
                          onClick={()=> navigate('/about')}
                        />
                    </li>
                    <li>
                      <Button
                          style={{fontSize : '10px'}}
                          label="INDUSTRIES"
                          type="button"
                          className="mr-1 mb-3 p-button-text"
                          onClick={()=> navigate('/industries')}
                        />
                    </li>

                    <li>
                      <Button
                          style={{fontSize : '10px'}}
                          label="CONTACT US"
                          type="button"
                          className="mr-1 mb-3 p-button-text"
                          onClick={()=> navigate('/contact-us')}
                        />
                    </li>
                    <li>
                      <Button
                          style={{fontSize : '10px'}}
                          label="FIND JOBS"
                          type="button"
                          className="mr-1 mb-3 p-button-text"
                          onClick={()=> navigate('/register-account')}
                      />
                    </li>
                    <li>
                      <Button
                          style={{fontSize : '10px'}}
                          label="APPLY AS A FREELANCER"
                          type="button"
                          className="mr-1 mb-3 p-button-text"
                          onClick={()=> navigate('/register-account')}
                      />
                    </li>
                    <li>
                      <Button
                        style={{fontSize : '10px'}}
                        label="HIRE TOP TALENT"
                        type="button"
                        className="mr-1 mb-3 p-button-text"
                        onClick={()=> navigate('/register-account')}
                      />

                    </li>
                    <li>
                      <Button
                          style={{fontSize : '10px'}}
                          label="LOGIN"
                          type="button"
                          className="mr-1 mb-3 p-button-text"
                          onClick={()=> navigate('/login')}
                      />
                    </li>


                </ul>
        </div>
    );
}

const mapStateToProps = state =>({
  token: state.auth.token,
})


export default connect(
  mapStateToProps,
  {logout} )
( AppTopbar);

import React, {useEffect,useState} from 'react';
import { Chip } from 'primereact/chip';
import 'aos/dist/aos.css';
import AOS from "aos";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import {connect} from 'react-redux';
import {
    userInitialValues,
    availabilityCategoryOptions,
    availabilityOptions,
    genderOptions,
    userTitleOptions
} from './data';
import {getEmails, getUsernames} from '../../actions/candidates';
import {editUser} from '../../actions/users';
import { Calendar } from 'primereact/calendar';


const UserProfile = (props) => {
	const {
        profile,
        getUserProfile,
        countries,
        provinces,
        districts,
        token,
        usernames,
        emails,
        } = props;
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    const [errors, setErrors] = useState({})
    const [record, setRecord] = useState(userInitialValues)
    const [cityOptions, setCityOptions] = useState([]);
    const [districtOptions, setDistrictOptions] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
	const {
		email,
		slug,
		username,
		title,
		gender,
		first_name,
		middle_name,
		last_name,
		phone_number,
		whatsapp_number,
		start_date,
		dob,
		country_of_citizenship,
		country_of_residence,
		province,
		district,
		description,
		availability,
		availability_category,

	}= profile;

    useEffect(() =>{
        AOS.init({duration : 3000})

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() =>{
        getUserProfile(token);
        setIsSubmit(false);
    }, [isSubmit]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCityOptions = (cities, country) =>{
        return cities.filter((city) => city.country === country);
     }

     useEffect(() =>{
           let citiesCopy = [...provinces]
           let cities = getCityOptions(citiesCopy, record.country_of_residence);
           setCityOptions(cities);

     }, [record.country_of_residence])  // eslint-disable-line react-hooks/exhaustive-deps

     const getDistrictOptions = (districts, province) =>{
       return districts.filter((ds) => ds.province === province);
     }


     useEffect(() =>{
           let districtsCopy = [...districts]
           let districts_ = getDistrictOptions(districtsCopy, record.province);
           setDistrictOptions(districts_);

     }, [record.province]) // eslint-disable-line react-hooks/exhaustive-deps



   const handleSubmit = (e) => {
       e.preventDefault();
       if (validate()){
            props.editUser(slug, record, token);
            setIsSubmit(true);
           onHide('displayMaximizable');
       }

   }

    const validate = (fieldValues = record) => {
        let temp = { ...errors }

        if ('gender' in fieldValues)
            temp.gender= fieldValues.gender? "" : "Gender is Required"
        if ('first_name' in fieldValues)
            temp.first_name= fieldValues.first_name? "" : "First Name is Required"
        if ('last_name' in fieldValues)
            temp.last_name= fieldValues.last_name? "" : "Last Name is Required"
        if ('title' in fieldValues)
            temp.title= fieldValues.title? "" : "Title is Required"
        if ('phone_number' in fieldValues)
            temp.phone_number= fieldValues.phone_number? "" : "Phone number is Required"
        if ('country_of_citizenship' in fieldValues)
            temp.country_of_citizenship= fieldValues.country_of_citizenship? "" : "Country of citizenship is Required"
        if ('country_of_residence' in fieldValues)
            temp.country_of_residence= fieldValues.country_of_residence? "" : "Country of residence is Required"
        if ('province' in fieldValues)
            temp.province= fieldValues.province? "" : "Province is Required"
        if ('district' in fieldValues)
            temp.district= fieldValues.district? "" : "District is Required"
        if ('description' in fieldValues)
            temp.description= fieldValues.description? "" : "Description is Required"
        if ('availability' in fieldValues)
            temp.availability= fieldValues.availability? "" : "Availability is Required"
        if ('availability_category' in fieldValues)
            temp.availability_category= fieldValues.availability_category? "" : "Availability category is Required"
        if ('dob' in fieldValues)
            temp.dob= fieldValues.dob? "" : "Date of Birth is Required"

        setErrors({
            ...temp
        })

        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

	const renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
          (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
          // less than one minute ago
          prefix = "just now...";
        } else if (timeDiff < 60 && timeDiff > 1) {
          // less than sixty minutes ago
          prefix = `${timeDiff} minutes ago`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
          // less than 24 hours ago
          prefix = `${Math.round(timeDiff / 60)} hours ago`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
          // less than 7 days ago
          prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
        } else {
          prefix = `${new Date(timestamp)}`;
        }
        return prefix;
      };



      const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
    }


    const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
          setErrors({})
    }

    const editProfile = () => {
        setRecord({...profile});
        setDisplayMaximizable(true);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }


    const monthNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
          />
        );
    };

    const yearNavigatorTemplate = (e) => {
        return (
          <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
          />
      )
    }

    const dialogProfileHeader = () => {
          return (
              <div style={{fontFamily: 'cursive', textAlign: 'center'}}>

                <span className='text-primary'>Profile Form</span>
              </div>
          )
      }


	return (
        <>
            <div data-aos="fade-up-right" div className='card'>

                <div style={{textAlign: 'center'}} className="">
                    <ul className="list-none p-0 m-0">
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">Registered @ ZimJobsCenter </div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={renderTimestamp(start_date)}  className="mr-2 mb-2 " />
                            </div>

                        </li>

                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid">
                            <div className=" col-12 md:w-12 font-medium">EMAIL ADDRESS</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={email}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">SYSTEM ID</div>
                            <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                {slug}
                            </div>

                        </li>
                        {title

                            ?
                                <>
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                                    <div className=" col-12 md:w-12 font-medium">TITLE</div>
                                    <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                        <Chip label={title}  className="mr-2 mb-2 " />
                                    </div>

                                </li>
                                </>
                            :<></>
                        }

                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">USERNAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={username}  className="mr-2 mb-2 " />
                            </div>

                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">FIRST NAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={first_name}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">FIRST NAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={first_name}  className="mr-2 mb-2 " />
                            </div>
                        </li>

                        {middle_name

                            ?
                                <>
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                                    <div className=" col-12 md:w-12 font-medium">MIDDLE NAME</div>
                                    <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                        <Chip label={middle_name}  className="mr-2 mb-2 " />
                                    </div>
                                </li>

                                </>
                            :<></>
                        }

                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">LAST NAME</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={last_name}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">GENDER</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={gender}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">DOB</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={dob}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">PHONE NUMBER</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={phone_number}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        {whatsapp_number

                            ?
                                <>
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                                    <div className=" col-12 md:w-12 font-medium">WHATSAPP NUMBER</div>
                                    <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                        <Chip label={whatsapp_number}  className="mr-2 mb-2 " />
                                    </div>
                                </li>

                                </>
                            :<></>
                        }
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">COUNTRY OF CITIZENSHIP</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={country_of_citizenship}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">COUNTRY OF RESIDENCE</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={country_of_residence}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">CITY</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={province}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">TOWNSHIP</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={district}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">DESCRIPTION</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                {description}
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">AVAILABILITY</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={availability}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" col-12 md:w-12 font-medium">AVAILABILITY CATEGORY</div>
                            <div className=" col-12 md:w-12 font-medium md:flex-order-0 flex-order-1 line-height-3">
                                <Chip label={availability_category}  className="mr-2 mb-2 " />
                            </div>
                        </li>
                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap grid fluid ">
                            <div className=" w-6 md:w-5 font-medium">

                            </div>
                            <div className=" w-full md:w-2 md:flex-order-0 flex-order-1 line-height-3">
                                <Button
                                    type="submit"
                                    label="UPDATE PROFILE"
                                    className="p-button-outlined p-button-warning p-button-rounded"
                                    onClick = {editProfile}
                                />
                            </div>
                            <div className=" w-6 md:w-5 font-medium">

                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <Dialog header={dialogProfileHeader()}
                visible={displayMaximizable}
                maximizable
                modal
                style={{ width: '60%' }}
                onHide={() => onHide('displayMaximizable')}
            >
                 <form onSubmit={handleSubmit}>
                    <ul  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    TITLE
                                </div>
                                <div className="w-full md:w-8 md:flex-order-0  md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        value={record.title}
                                        optionLabel="key"
                                        optionValue="key"
                                        options={userTitleOptions}
                                        onChange={(e) => onInputChange(e, 'title')}
                                        filter

                                        filterBy="key"
                                    />
                                    <small id="username2-help" className="p-error block">{errors.title}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR FIRSTNAME ?
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="first_name"
                                    type="text"
                                    name="first_name"
                                    className="w-full mb-3"
                                    value={record.first_name}
                                    onChange={(e) => onInputChange(e,"first_name")}

                                />
                                <small id="username2-help" className="p-error block">{errors.first_name}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR MIDDLE ?
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="middle_name"
                                    type="text"
                                    name="middle_name"
                                    className="w-full mb-3"
                                    value={record.middle_name}
                                    onChange={(e) => onInputChange(e,"middle_name")}

                                />
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR LAST NAME ?
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="last_name"
                                    type="text"
                                    name="last_name"
                                    className="w-full mb-3"
                                    value={record.last_name}
                                    onChange={(e) => onInputChange(e,"last_name")}

                                />
                                <small id="username2-help" className="p-error block">{errors.last_name}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR DATE OF BIRTH
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                {record.slug !== undefined
                                  ?<InputText
                                      id="dob"
                                      type="date"
                                      name="dob"
                                      className="w-full mb-3"
                                      value={record.dob}
                                      onChange={(e) => onInputChange(e,"dob")}

                                  />
                                  : <Calendar
                                          id="navigatorstemplate"
                                          showIcon
                                          value={record.dob}
                                          onChange={(e) => onInputChange(e,"dob")}
                                          monthNavigator
                                          yearNavigator
                                          dateFormat="yy-mm-dd"
                                          yearRange="1900:2030"
                                          monthNavigatorTemplate={monthNavigatorTemplate}
                                          yearNavigatorTemplate={yearNavigatorTemplate}
                                      />
                                  }
                                <small id="username2-help" className="p-error block">{errors.dob}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR GENDER ?
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <Dropdown
                                    value={record.gender}
                                    optionLabel="key"
                                    optionValue="key"
                                    options={genderOptions}
                                    onChange={(e) => onInputChange(e, 'gender')}
                                    filter

                                    filterBy="key"
                                />
                                <small id="username2-help" className="p-error block">{errors.gender}</small>
                                </div>
                            </li>

                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    SELECT COUNTRY OF CITIZENSHIP
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <Dropdown
                                    value={record.country_of_citizenship}
                                    optionLabel="name"
                                    optionValue="name"
                                    options={countries}
                                    onChange={(e) => onInputChange(e, 'country_of_citizenship')}
                                    filter

                                    filterBy="name"
                                />
                                <small id="username2-help"  className="p-error block">{errors.country_of_citizenship}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    SELECT COUNTRY OF RESIDENCE
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <Dropdown
                                    value={record.country_of_residence}
                                    optionLabel="name"
                                    optionValue="name"
                                    options={countries}
                                    onChange={(e) => onInputChange(e, 'country_of_residence')}
                                    filter

                                    filterBy="name"
                                />
                                <small id="username2-help"  className="p-error block">{errors.country_of_residence}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    SELECT PROVINCE
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <Dropdown
                                    value={record.province}
                                    optionLabel="name"
                                    optionValue="name"
                                    options={cityOptions}
                                    onChange={(e) => onInputChange(e, 'province')}
                                    filter

                                    filterBy="name"
                                />
                                <small id="username2-help"  className="p-error block">{errors.province}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    SELECT DISTRICT
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                    value={record.district}
                                    optionLabel="name"
                                    optionValue="name"
                                    options={districtOptions}
                                    onChange={(e) => onInputChange(e, 'district')}
                                    filter

                                    filterBy="name"
                                />
                                <small id="username2-help"  className="p-error block">{errors.district}</small>
                                </div>
                            </li>

                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR PHONE NUMBER?
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <InputText
                                        value={record.phone_number}
                                        id="phone_number"
                                        type="text"
                                        name="phone_number"
                                        className="w-full mb-3"
                                        onChange={(e) => onInputChange(e,"phone_number")}

                                    />
                                    <small id="username2-help" className="p-error block">{errors.phone_number}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    WHAT'S YOUR WHATSAPP NUMBER?
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <InputText
                                        id="whatsapp_number"
                                        type="text"
                                        name="whatsapp_number"
                                        className="w-full mb-3"
                                        value={record.whatsapp_number}
                                        onChange={(e) => onInputChange(e,"whatsapp_number")}

                                    />

                                </div>
                            </li>
                             <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    AVAILABILITY CATEGORY
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        value={record.availability_category}
                                        optionLabel="key"
                                        optionValue="key"
                                        options={availabilityCategoryOptions}
                                        onChange={(e) => onInputChange(e, 'availability_category')}
                                        filter

                                        filterBy="key"
                                    />
                                    <small id="username2-help" className="p-error block">{errors.availability_category}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    AVAILABILITY
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <Dropdown
                                        value={record.availability}
                                        optionLabel="key"
                                        optionValue="key"
                                        options={availabilityOptions}
                                        onChange={(e) => onInputChange(e, 'availability')}
                                        filter

                                        filterBy="key"
                                    />
                                    <small id="username2-help" className="p-error block">{errors.availability}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                                <div
                                    className="w-full text-primary md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >

                                    PUT DESCRIPTION
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                    <InputTextarea
                                        rows={5}
                                        cols={30}
                                        autoResize
                                        value={record.description}
                                        onChange={(e) => onInputChange(e, 'description')}
                                    />
                                    <small id="username2-help"  className="p-error block">{errors.description}</small>
                                </div>
                            </li>

                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                <Button
                                    label="UPDATE ACCOUNT"
                                    icon="pi pi-user"
                                    className="w-full p-button-outlined"
                                />
                            </li>
                        </ul>
                    </form>

            </Dialog>
        </>

	);
}

const mapStateToProps = (state) =>({
    token: state.auth.token,
    usernames: state.candidates.usernames,
    emails: state.candidates.emails,
})

export default connect(mapStateToProps,{editUser, getEmails, getUsernames})(UserProfile);

import React, { useState, useEffect } from 'react';
import { FilterMatchMode} from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import ManagementLayout from '../layout/ManagementLayout';
import {getJobSeekerLanguages} from '../../actions/candidates';

const JobSeekerLangauges = (props) => {

  const [filters2, setFilters2] = useState({
        'language': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'proficiency': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });



  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const {records, token} = props;
  const {slug} = useParams();




    useEffect(() => {
          props.getJobSeekerLanguages(slug, token);

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onGlobalFilterChange2 = (e) => {
        const value = e.target.value;
        let _filters2 = { ...filters2 };
        _filters2['global'].value = value;

        setFilters2(_filters2);
        setGlobalFilterValue2(value);
    }

    const renderHeader2 = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue2} onChange={onGlobalFilterChange2} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }


    const header2 = renderHeader2();

  return (
    <ManagementLayout>
      <div className="datatable-filter-demo" style={{textAlign: 'center'}}>
            <div className="card">
                <DataTable
                  value={records}
                  paginator
                  className="p-datatable-customers"
                  showGridlines
                  rows={10}
                  dataKey="slug"
                  filters={filters2}
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  globalFilterFields={[
                        'language', 
                        'proficiency', 
                        
                  ]}
                  header={header2}
                  emptyMessage="No Qualifications found"
                >
                  <Column
                      field="language"
                      header="NAME"
                      filter
                      style={{ minWidth: '12rem', fontFamily: 'cursive' }}
                      className='text-primary'
                  />
                  <Column
                    header="PROFFICIENCY"
                    field="proficiency"
                    style={{ minWidth: '12rem', fontFamily: 'cursive' }}
                    filter
                    className='text-primary'

                  />
                  

                </DataTable>
            </div>

          </div>
      </ManagementLayout>

    );
}

const mapStateToProps = (state) => ({
      token: state.auth.token,
      records: state.candidates.jobseekerlanguages,

})

export default connect(
    mapStateToProps,
    {getJobSeekerLanguages})
    (JobSeekerLangauges);

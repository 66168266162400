import {
    GET_EMPLOYER_JOBS,
    

} from '../types/recruiterTypes';

const initialState = {
    employerjobs: [],

};




export default function recruiters(state = initialState, action){

    switch(action.type){
        case GET_EMPLOYER_JOBS:
            return {
                ...state,
                employerjobs : action.payload
            };
        default:
            return state;
    }
}

import React, {useEffect} from 'react';
import feat1 from './images/feat1.svg';
import feat2 from './images/feat2.svg';
import feat3 from './images/feat3.svg';
import feat4 from './images/feat4.svg';
import {connect} from 'react-redux';
import {
    getFaculties,

} from '../../actions/skills';
import { useNavigate } from 'react-router-dom';
import PublicLayout from '../layout/PublicLayout';
import feature2 from './images/feature.png';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import AOS from "aos";
import 'aos/dist/aos.css';


const Industries = (props) =>{
  const navigate = useNavigate();

  const shuffle =(array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
}

const generateRandomPicture = (numberOfPictures) =>{
    const result = [];
    const sequence = [
        feat1,
        feat2,
        feat3,
        feat4,

    ];

    for(let i = 1; i < numberOfPictures; i++){
        result.push(shuffle(sequence))
    }

    return(result[0][0]);
}


  const hireTopTalent = () =>{
    navigate("/register-account")
  }

  const applyAsFreelancer = () =>{
    navigate("/register-account")

  }

  const findJob = () =>{
    navigate("/register-account")
  }


  useEffect(() =>{
      props.getFaculties(null);
  }, [])

  useEffect(() =>{
    AOS.init({duration : 3000})

  }, []);

  return(
    <PublicLayout>

      <div data-aos="zoom-in" className="card">
        <TabView >
          <TabPanel className="flex-1" header='HIRE TOP TALENT'>
            <div className="grid p-fluid"
              style={{ textAlign: 'center',  display: 'flex',  alignSelf: 'end'}}
              >
              {props.records.map((rec) =>{
                return(
                  <div className="col-12 lg:col-3 p-3">
                    <div className="shadow-8 card mb-1 h-full flex-column justify-content-between flex">
                      <div className="p-4"><div className="" style={{textAlign: 'center',  }}>

                        <span className=" font-medium" style={{overflowWrap: 'break-word'}} >
                          {rec.title}
                        </span>
                      </div>

                    </div>
                    <div className="px-4 py-1 text-right">
                      <Button
                        label='HIRE' className='p-button-text'
                        onClick={hireTopTalent}
                        />

                    </div>
                  </div>
              </div>


                );

                })

              }
            </div>
          </TabPanel>
          <TabPanel className="flex-1" header='FREELANCE WITH US'>
            <div className="grid p-fluid"
              style={{ textAlign: 'center', alignSelf: 'end'}}
              >
              {props.records.map((rec) =>{
                return(
                  <div className="col-12 lg:col-3 p-3">
                    <div className="shadow-8 card mb-1 h-full flex-column justify-content-between flex">
                      <div className="p-4"><div className="" style={{textAlign: 'center',  }}>

                        <span className=" font-medium" style={{overflowWrap: 'break-word'}}>
                          {rec.title}
                        </span>
                      </div>

                    </div>
                    <div className="px-4 py-1 text-right">
                      <Button
                        label='FREELANCE' className='p-button-text'
                        onClick={applyAsFreelancer}
                        />

                    </div>
                  </div>
              </div>


                );

                })

              }
            </div>
          </TabPanel>
          <TabPanel className="flex-1" header='LOOKING FOR A JOB'>
            <div className="grid p-fluid"
              style={{ textAlign: 'center',  alignSelf: 'end'}}
              >
              {props.records.map((rec) =>{
                return(
                  <div className="col-12 lg:col-3 p-3">
                    <div className="shadow-8 card mb-1 h-full flex-column justify-content-between flex">
                      <div className="p-4"><div className="" style={{textAlign: 'center',  }}>

                        <span className=" font-medium" style={{overflowWrap: 'break-word'}}>
                          {rec.title}
                        </span>
                      </div>

                    </div>
                    <div className="px-4 py-1 text-right">
                      <Button
                        label='FIND JOB' className='p-button-text'
                        onClick={findJob}
                        />

                    </div>
                  </div>
              </div>


                );

                })

              }
            </div>
          </TabPanel>
        </TabView>
      </div>
    </PublicLayout>

  );


}

const mapStateToProps = state =>({
    records: state.skills.faculties,
})


export default connect(mapStateToProps, {getFaculties}) (Industries);

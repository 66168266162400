import axios from 'axios';
import {
    GET_RECOMMENDATION_USERS,
    RECOMMEND
} from '../types/recommendationTypes';

import {
    reccomendationusersURL,
    recommendURL

} from '../constants';
import {  returnErrors } from './messages';


export const recommendPeople = (recommendation, token) => dispatch => {
    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };

    axios.post(recommendURL, recommendation, headers)
        .then(res => {
            dispatch({
                type: RECOMMEND,
                payload: res.data
            });
        }).catch(err => {
          dispatch(returnErrors(err.response.data, err.response.status));
        });

}


export const getRecommendationUsers = (params, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };


    axios.get(`${reccomendationusersURL}`, { params }, headers)
        .then(res => {
            dispatch({
                type: GET_RECOMMENDATION_USERS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

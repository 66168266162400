export const genderOptions =[
    {key: 'MALE'},
    {key: 'FEMALE'},
    {key: 'ANY'},
]

export const chargeCategoryOptions = [
    { key: 'FIXED', value: 'FIXED'},
    { key: 'PER HOUR', value: 'PER HOUR'},

]

export const visibilityStatusOptions = [
    { key: 'VISIBLE', value: 'VISIBLE'},
    { key: 'INVISIBLE', value: 'INVISIBLE'},
    { key: 'DELETED', value: 'DELETED'}
]


export const booleanOptions = [
    { key: 'YES', value: 'YES'},
    { key: 'NO', value: 'NO'}
]


export const jobTypeOptions = [

    { key: "ONE TIME JOB", value:  "ONE TIME JOB"},
    { key: "PERMANENT JOB",  value: "PERMANENT JOB"},
    { key: "CONTRACTUAL JOB", value:  "CONTRACTUAL JOB"},
    { key: "INTERNSHIP JOB",  value: "INTERNSHIP JOB"},

]



export const jobFishingExpectedTimelineOptions = [
 
    { key: 'HOUR', value: 'HOUR'},
    { key: 'TWO HOURS', value: 'TWO HOURS'},
    { key: 'THREE HOURS', value: 'THREE HOURS'},
    { key: 'FOUR HOURS', value: 'FOUR HOURS'},
    { key: 'FIVE HOURS', value: 'FIVE HOURS'},
    { key: 'SIX HOURS', value: 'SIX HOURS'},
    { key: 'SEVEN HOURS', value: 'SEVEN HOURS'},
    { key: 'EIGHT HOURS', value: 'EIGHT HOURS'},
    { key: 'NINE HOURS', value: 'NINE HOURS'},
    { key: 'TEN HOURS', value: 'TEN HOURS'},
    { key: 'ELEVEN HOURS', value: 'ELEVEN HOURS'},
    { key: 'TWELVE HOURS', value: 'TWELVE HOURS'},
    { key: 'THIRTEEN HOURS', value: 'THIRTEEN HOURS'},
    { key: 'FOURTEEN HOURS', value: 'FOURTEEN HOURS'},
    { key: 'FIFTEEN HOURS', value: 'FIFTEEN HOURS'},
    { key: 'SIXTEEN HOURS', value: 'SIXTEEN HOURS'},
    { key: 'SEVENTEEN HOURS', value: 'SEVENTEEN HOURS'},
    { key: 'EIGHTEEN HOURS', value: 'EIGHTEEN HOURS'},
    { key: 'NINTEEN HOURS', value: 'NINTEEN HOURS'},
    { key: 'TWENTY HOURS', value: 'TWENTY HOURS'},
    { key: 'TWENTY ONE HOURS', value: 'TWENTY ONE HOURS'},
    { key: 'TWENTY TWO HOURS', value: 'TWENTY TWO HOURS'},
    { key: 'TWENTY THREE HOURS', value: 'TWENTY THREE HOURS'},
    { key: 'TWENTY FOUR HOURS', value: 'TWENTY FOUR HOURS'},
    { key: 'TWO DAYS', value: 'TWO DAYS'},
    { key: 'THREE DAYS', value: 'THREE DAYS'},
    { key: 'FOUR DAYS', value: 'FOUR DAYS'},
    { key: 'FIVE DAYS', value: 'FIVE DAYS'},
    { key: 'SIX DAYS', value: 'SIX DAYS'},
    { key: 'SEVEN DAYS', value: 'SEVEN DAYS'},
    { key: 'EIGHT DAYS', value: 'EIGHT DAYS'},
    { key: 'NINE DAYS', value: 'NINE DAYS'},
    { key: 'TEN DAYS', value: 'TEN DAYS'},
    { key: 'ELEVEN DAYS', value: 'ELEVEN DAYS'},
    { key: 'TWELVE DAYS', value: 'TWELVE DAYS'},
    { key: 'THIRTEEN DAYS', value: 'THIRTEEN DAYS'},
    { key: 'FOURTEEN DAYS', value: 'FOURTEEN DAYS'},
    { key: 'FIFTEEN DAYS', value: 'FIFTEEN DAYS'},
    { key: 'SIXTEEN DAYS', value: 'SIXTEEN DAYS'},
    { key: 'SEVENTEEN DAYS', value: 'SEVENTEEN DAYS'},
    { key: 'EIGHTEEN DAYS', value: 'EIGHTEEN DAYS'},
    { key: 'NINTEEN DAYS', value: 'NINTEEN DAYS'},
    { key: 'TWENTY DAYS', value: 'TWENTY DAYS'},
    { key: 'TWENTY ONE DAYS', value: 'TWENTY ONE DAYS'},
    { key: 'TWENTY TWO DAYS', value: 'TWENTY TWO DAYS'},
    { key: 'TWENTY THREE DAYS', value: 'TWENTY THREE DAYS'},
    { key: 'TWENTY FOUR DAYS', value: 'TWENTY FOUR DAYS'},
    { key: 'TWENTY FIVE DAYS', value: 'TWENTY FIVE DAYS'},
    { key: 'TWENTY SIX DAYS', value: 'TWENTY SIX DAYS'},
    { key: 'TWENTY SEVEN DAYS', value: 'TWENTY SEVEN DAYS'},
    { key: 'TWENTY EIGHT DAYS', value: 'TWENTY EIGHT DAYS'},
    { key: 'TWENTY NINE DAYS', value: 'TWENTY NINE DAYS'},
    { key: 'THIRTY DAYS', value: 'THIRTY DAYS'},
    { key: 'TWO MONTHS', value: 'TWO MONTHS'},
    { key: 'THREE MONTHS', value: 'THREE MONTHS'},
    { key: 'FOUR MONTHS', value: 'FOUR MONTHS'},
    { key: 'FIVE MONTHS', value: 'FIVE MONTHS'},
    { key: 'SIX MONTHS', value: 'SIX MONTHS'},
    { key: 'SEVEN MONTHS', value: 'SEVEN MONTHS'},
    { key: 'EIGHT MONTHS', value: 'EIGHT MONTHS'},
    { key: 'NINE MONTHS', value: 'NINE MONTHS'},
    { key: 'TEN MONTHS', value: 'TEN MONTHS'},
    { key: 'ELEVEN MONTHS', value: 'ELEVEN MONTHS'},
    { key: 'TWELVE MONTHS', value: 'TWELVE MONTHS'},
    { key: 'TWO YEARS', value: 'TWO YEARS'},
    { key: 'THREE YEARS', value: 'THREE YEARS'},
    { key: 'FOUR YEARS', value: 'FOUR YEARS'},

]



export const jobDurationOptions = [

    { key: 'HOURS', value: 'HOURS'},
    { key: 'DAYS', value: 'DAYS'},
    { key: 'MONTHS', value: 'MONTHS'},
    { key: 'YEARS', value: 'YEARS'},

]

export const prefferedSkillsetOptions = [
    { key: 'EXPERT REQUIRED', value: 'EXPERT REQUIRED'},
    { key: 'LEARNER REQUIRED', value: 'LEARNER REQUIRED'},
    { key: 'INTERMIDIATE SKILLS REQUIRED', value: 'INTERMIDIATE SKILLS REQUIRED'},
]


export const statusOptions = [
    { key: 'AVAILABLE', value: 'AVAILABLE'},
    { key: 'PENDING', value: 'PENDING'},
    { key: 'TAKEN', value: 'TAKEN'},
]

export const geographicalLocationOptions = [
    { key: 'LOCAL', value: 'LOCAL'},
    { key: 'WORLDWIDE', value: 'WORLDWIDE'},
    { key: 'AFRICA', value: 'AFRICA'},
    { key: 'EUROPE', value: 'EUROPE'},
    { key: 'NORTH AMERICA', value: 'NORTH AMERICA'},
    { key: 'SOUTH AMERICA', value: 'SOUTH AMERICA'},
    { key: 'ASIA', value: 'ASIA'},
]

export const locationOptions = [
    { key: 'ONSITE', value: 'ONSITE'},
    { key: 'REMOTE', value: 'REMOTE'},
]

export const compensationOptions = [
    { key: 'WEEKLY', value: 'WEEKLY'},
    { key: 'MONTHLY', value: 'MONTHLY'},
    { key: 'JOB END', value: 'JOB END'},
]


export const initialValues = {
    compensation_type: '',
    location: '', 
    fixed_price: '', 
    from_hourly_price: '', 
    to_hourly_price: '',
    duration_category: '',
    category: '', 
    title: '', 
    description: '', 
    visibility_status: '',
    charge_category: '', 
    expected_duration: '', 
    required_skillset: '', 
    country: '',
    location_type: '',
    preffered_city: '', 
    preffered_district: '',
    employer: '', 
    questions: '',
}


export const competencyOptions = [
    {key: "BEGINER"},
    {key: "EXPERIENCED"},
    {key: "ADVANCED"},
    {key: "EXPERT"},
]


export const levelOptions = [
    {name: "ORDINARY LEVEL CERTIFICATE"},
    {name: "ADVANCED LEVEL CERTIFICATE"},
    {name: "CERTIFICATE"},
    {name: "DIPLOMA"},
    {name: "BATCHELORS DEGREE"},
    {name: "HONORS DEGREE"},
    {name: "MASTERS DEGREE"},
    {name: "DOCTORATE DEGREE"},
    
]


export const skillValues = {
    skill: '',
    competency: '',
    years_of_experience: '',
}


export const qualificationValues = {
    qualification: '',
}

export const levelValues = {
    name: '',
}



import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import ManagementLayout from '../layout/ManagementLayout';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import DailyJobReports from './DailyJobReports';
import WeeklyJobReports from './WeeklyJobReports';
import MonthlyJobReports from './MonthlyJobReports';
import YearlyJobReports from './YearlyJobReports';
import DailyApplicationReports from './DailyApplicationReports';
import WeeklyApplicationReports from './WeeklyApplicationReports';
import MonthlyApplicationReports from './MonthlyApplicationReports';
import YearlyApplicationReports from './YearlyApplicationReports';
import EmployerDailyJobReports from './EmployerDailyJobReports';
import EmployerWeeklyJobReports from './EmployerWeeklyJobReports';
import EmployerMonthlyJobReports from './EmployerMonthlyJobReports';
import EmployerYearlyJobReports from './EmployerYearlyJobReports';
import DailyCandidateApplicationReports from './DailyCandidateApplicationReports';
import WeeklyCandidateApplicationReports from './WeeklyCandidateApplicationReports';
import MonthlyCandidateApplicationReports from './MonthlyCandidateApplicationReports';
import YearlyCandidateApplicationReports from './YearlyCandidateApplicationReports';


export const Reports = (props) => {

    const [hour, setHour] = useState(null);
    const [type, setType] = useState('DAILY');
    const [dailyFilter, setDailyFilter] = useState('JOBS');
    const [weeklyFilter, setWeeklyFilter] = useState('JOBS');
    const [monthlyFilter, setMonthlyFilter] = useState('JOBS');
    const [yearlyFilter, setYearlyFilter] = useState('JOBS');
    const [filter, setFilter] = useState('JOBS');


    const {
        lastName,
        firstName,
      } = props;

    const onTypeChange = (e) => {
        setType(e.value);
    }

    const onDailyChange = (e) => {
        setDailyFilter(e.value);
    }

    const onWeeklyChange = (e) => {
        setWeeklyFilter(e.value);
    }

    const onMonthlyChange = (e) => {
        setMonthlyFilter(e.value);
    }

    const onYearlyChange = (e) => {
        setYearlyFilter(e.value);
    }

    const onFilterChange = (e) => {
        setFilter(e.value);
    }

    const getHour = () => {
        const date = new Date();
        const hour = date.getHours()
        setHour(hour);
    }

    useEffect(() => {
        getHour();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

      const current = new Date();
      const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;


        const getHourDisplay = () => {

          if (hour < 12){
            return(
              <>
                Good Morning!
              </>
            )
          }else if(hour < 18){
            return (
              <>Good Afternoon!!</>
            )
          }else{
            return(
              <>Good Evening!!</>
            );
          }
      }



    const getReportsDisplay = () =>{
        if (type === "DAILY"){
            return(
                <>
                  <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                      <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li style={{display: 'flex',gap: '20px'}} className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                            >
                                <RadioButton
                                    inputId="category1"
                                    name="category"
                                    value="JOBS"

                                    onChange={onDailyChange}
                                    checked={dailyFilter === "JOBS"}
                                  />
                                <label className="" htmlFor="category1">JOBS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="APPLICATIONS"
                                    onChange={onDailyChange}
                                    checked={dailyFilter === "APPLICATIONS"}
                                  />
                                <label className="" htmlFor="category2">APPLICATIONS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="EMPLOYERS"
                                    onChange={onDailyChange}
                                    checked={dailyFilter === "EMPLOYERS"}
                                  />
                                <label className="" htmlFor="category2">EMPLOYERS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="CANDIDATES"
                                    onChange={onDailyChange}
                                    checked={dailyFilter === "CANDIDATES"}
                                  />
                                <label className="" htmlFor="category2">CANDIDATES</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="STATS"
                                    onChange={onDailyChange}
                                    checked={dailyFilter === "STATS"}
                                  />
                                <label className="" htmlFor="category2">STATS</label>
                            </div>

                          </li>
                        </ul>

                  </div>
                </>
            )
        }else if (type === "WEEKLY"){
            return(
                <>
                  <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                      <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li style={{display: 'flex',gap: '20px'}} className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                            >
                                <RadioButton
                                    inputId="category1"
                                    name="category"
                                    value="JOBS"
                                    onChange={onWeeklyChange}
                                    checked={weeklyFilter === "JOBS"}
                                  />
                                <label className="" htmlFor="category1">JOBS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="APPLICATIONS"
                                    onChange={onWeeklyChange}
                                    checked={weeklyFilter === "APPLICATIONS"}
                                  />
                                <label className="" htmlFor="category2">APPLICATIONS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="EMPLOYERS"
                                    onChange={onWeeklyChange}
                                    checked={weeklyFilter === "EMPLOYERS"}
                                  />
                                <label className="" htmlFor="category2">EMPLOYERS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="CANDIDATES"
                                    onChange={onWeeklyChange}
                                    checked={weeklyFilter === "CANDIDATES"}
                                  />
                                <label className="" htmlFor="category2">CANDIDATES</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="STATS"
                                    onChange={onWeeklyChange}
                                    checked={weeklyFilter === "STATS"}
                                  />
                                <label className="" htmlFor="category2">STATS</label>
                            </div>

                          </li>
                        </ul>

                  </div>
                </>
            )
        }else if (type === "MONTHLY"){
            return(
                <>
                  <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                      <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li style={{display: 'flex',gap: '20px'}} className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                            >
                                <RadioButton
                                    inputId="category1"
                                    name="category"
                                    value="JOBS"
                                    onChange={onMonthlyChange}
                                    checked={monthlyFilter === "JOBS"}
                                  />
                                <label className="" htmlFor="category1">JOBS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="APPLICATIONS"
                                    onChange={onMonthlyChange}
                                    checked={monthlyFilter === "APPLICATIONS"}
                                  />
                                <label className="" htmlFor="category2">APPLICATIONS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="EMPLOYERS"
                                    onChange={onMonthlyChange}
                                    checked={monthlyFilter === "EMPLOYERS"}
                                  />
                                <label className="" htmlFor="category2">EMPLOYERS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="CANDIDATES"
                                    onChange={onMonthlyChange}
                                    checked={monthlyFilter === "CANDIDATES"}
                                  />
                                <label className="" htmlFor="category2">CANDIDATES</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="STATS"
                                    onChange={onMonthlyChange}
                                    checked={monthlyFilter === "STATS"}
                                  />
                                <label className="" htmlFor="category2">STATS</label>
                            </div>

                          </li>
                        </ul>

                  </div>
                </>
            )
        }else if (type === "YEARLY"){
            return(
                <>
                  <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                      <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li style={{display: 'flex',gap: '20px'}} className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                            >
                                <RadioButton
                                    inputId="category1"
                                    name="category"
                                    value="JOBS"
                                    onChange={onYearlyChange}
                                    checked={yearlyFilter === "JOBS"}
                                  />
                                <label className="" htmlFor="category1">JOBS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="APPLICATIONS"
                                    onChange={onYearlyChange}
                                    checked={yearlyFilter === "APPLICATIONS"}
                                  />
                                <label className="" htmlFor="category2">APPLICATIONS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="EMPLOYERS"
                                    onChange={onYearlyChange}
                                    checked={yearlyFilter === "EMPLOYERS"}
                                  />
                                <label className="" htmlFor="category2">EMPLOYERS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="CANDIDATES"
                                    onChange={onYearlyChange}
                                    checked={yearlyFilter === "CANDIDATES"}
                                  />
                                <label className="" htmlFor="category2">CANDIDATES</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="STATS"
                                    onChange={onYearlyChange}
                                    checked={yearlyFilter === "STATS"}
                                  />
                                <label className="" htmlFor="category2">STATS</label>
                            </div>

                          </li>
                        </ul>

                  </div>
                </>
            )
        }else if (type === "BY FILTERS"){
            return(
                <>
                  <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                      <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                      <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                          <li style={{display: 'flex',gap: '20px'}} className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                            >
                                <RadioButton
                                    inputId="category1"
                                    name="category"
                                    value="JOBS"
                                    onChange={onFilterChange}
                                    checked={filter === "JOBS"}
                                  />
                                <label className="" htmlFor="category1">JOBS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="APPLICATIONS"
                                    onChange={onFilterChange}
                                    checked={filter === "APPLICATIONS"}
                                  />
                                <label className="" htmlFor="category2">APPLICATIONS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="EMPLOYERS"
                                    onChange={onFilterChange}
                                    checked={filter === "EMPLOYERS"}
                                  />
                                <label className="" htmlFor="category2">EMPLOYERS</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="CANDIDATES"
                                    onChange={onFilterChange}
                                    checked={filter === "CANDIDATES"}
                                  />
                                <label className="" htmlFor="category2">CANDIDATES</label>
                            </div>
                            <div
                              style={{paddingLeft: '12px'}}
                              className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                                <RadioButton
                                    inputId="category2"
                                    name="category"
                                    value="STATS"
                                    onChange={onFilterChange}
                                    checked={filter === "STATS"}
                                  />
                                <label className="" htmlFor="category2">STATS</label>
                            </div>

                          </li>
                        </ul>

                  </div>
                </>
          )
        }else{
          return(
              <>
                  THERE IS A PROBLEM
              </>
          )
        }
    }


    const getReportsByCategoryDisplay = () =>{
      if (type === 'DAILY'){
        if (dailyFilter ==='JOBS'){
          return (
            <>
              <DailyJobReports/>
            </>

          );
        }else if (dailyFilter ==='APPLICATIONS'){
            return (
              <>
                <DailyApplicationReports/>
              </>

            );

        }else if(dailyFilter ==='EMPLOYERS'){
            return (
              <>
                <EmployerDailyJobReports/>
              </>

            );

        }else if(dailyFilter ==='CANDIDATES'){
            return (
              <>
                <DailyCandidateApplicationReports/>
              </>

            );

        }else if(dailyFilter ==='STATS'){
            return (
              <>
                STATS
              </>

            );

        }
      }else if (type === 'WEEKLY'){
        if (weeklyFilter ==='JOBS'){
          return (
            <>
              <WeeklyJobReports/>
            </>

          );
        }else if (weeklyFilter ==='APPLICATIONS'){
            return (
              <>
               <WeeklyApplicationReports/>
              </>

            );

        }else if(weeklyFilter ==='EMPLOYERS'){
            return (
              <>
                <EmployerWeeklyJobReports/>
              </>

            );

        }else if(weeklyFilter ==='CANDIDATES'){
            return (
              <>
                <WeeklyCandidateApplicationReports/>
              </>

            );

        }else if(weeklyFilter ==='STATS'){
            return (
              <>
                STATS
              </>

            );

        }


      }else if (type==='MONTHLY'){
        if (monthlyFilter ==='JOBS'){
          return (
            <>
              <MonthlyJobReports/>
            </>

          );
        }else if (monthlyFilter ==='APPLICATIONS'){
            return (
              <>
                <MonthlyApplicationReports/>
              </>

            );

        }else if(monthlyFilter ==='EMPLOYERS'){
            return (
              <>
                <EmployerMonthlyJobReports/>
              </>

            );

        }else if(monthlyFilter ==='CANDIDATES'){
            return (
              <>
                <MonthlyCandidateApplicationReports/>
              </>

            );

        }else if(monthlyFilter ==='STATS'){
            return (
              <>
                STATS
              </>

            );

        }

      }else if (type==='YEARLY'){
        if (yearlyFilter ==='JOBS'){
          return (
            <>
              <YearlyJobReports />
            </>

          );
        }else if (yearlyFilter ==='APPLICATIONS'){
            return (
              <>
                <YearlyApplicationReports/>
              </>

            );



        }else if(yearlyFilter ==='EMPLOYERS'){
            return (
              <>
                <EmployerYearlyJobReports />
              </>

            );

        }else if(yearlyFilter ==='CANDIDATES'){
            return (
              <>
                <YearlyCandidateApplicationReports/>
              </>

            );

        }else if(yearlyFilter ==='STATS'){
            return (
              <>
                STATS
              </>

            );

        }

      } else if (type==='BY FILTERS'){
        if (filter ==='JOBS'){
          return (
            <>
              JOBS
            </>

          );
        }else if (filter ==='APPLICATIONS'){
            return (
              <>
                APPLICATIONS
              </>

            );

        }else if(filter ==='EMPLOYERS'){
            return (
              <>
                EMPLOYERS
              </>

            );

        }else if(filter ==='CANDIDATES'){
            return (
              <>
                CANDIDATES
              </>

            );

        }else if(filter ==='STATS'){
            return (
              <>
                STATS
              </>

            );

        }

      }
    }


  return (
    <ManagementLayout>
        <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
              <div className="text-700 text-center" style={{fontFamily: 'cursive'}}>
                  <div className="text-900 font-bold text-5xl mb-3">{getHourDisplay()}</div>
                  <div className="text-700 text-2xl mb-5">{firstName} {lastName}</div>
                  <Button label={date} icon="pi pi-calendar" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
              </div>
            </div>
            <div className="card" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                <h5>THESE ARE THE REPORTS BASED ON THE CATEGORY OF YOUR CHOICE</h5>
                <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                    <li style={{display: 'flex',gap: '20px'}} className="flex align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div
                        style={{paddingLeft: '12px'}}
                        className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center"
                      >
                          <RadioButton
                              inputId="category1"
                              name="category"
                              value="DAILY"

                              onChange={onTypeChange}
                              checked={type === "DAILY"}
                            />
                          <label className="" htmlFor="category1">DAILY</label>
                      </div>
                      <div
                        style={{paddingLeft: '12px'}}
                        className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="WEEKLY"
                              onChange={onTypeChange}
                              checked={type === "WEEKLY"}
                            />
                          <label className="" htmlFor="category2">WEEKLY</label>
                      </div>
                      <div
                        style={{paddingLeft: '12px'}}
                        className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="MONTHLY"
                              onChange={onTypeChange}
                              checked={type === "MONTHLY"}
                            />
                          <label className="" htmlFor="category2">MONTHLY</label>
                      </div>
                      <div
                        style={{paddingLeft: '12px'}}
                        className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="YEARLY"
                              onChange={onTypeChange}
                              checked={type === "YEARLY"}
                            />
                          <label className="" htmlFor="category2">YEARLY</label>
                      </div>
                      <div
                        style={{paddingLeft: '12px'}}
                        className="field-radiobutton flex card box shadow-8 w-full md:w-2 md:flex-order-0 flex-order-1 flex flex-wrap align-items-center justify-content-center">
                          <RadioButton
                              inputId="category2"
                              name="category"
                              value="BY FILTERS"
                              onChange={onTypeChange}
                              checked={type === "BY FILTERS"}
                            />
                          <label className="" htmlFor="category2">BY FILTERS</label>
                      </div>

                    </li>
                  </ul>

            </div>
            {getReportsDisplay()}
            {getReportsByCategoryDisplay()}

    </ManagementLayout>
  )
}



const mapStateToProps = (state) => ({
    token: state.auth.token,
    firstName: state.auth.firstName,
    lastName: state.auth.lastName,

})


export default connect(mapStateToProps, {})
(Reports)

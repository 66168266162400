import React from 'react';
import { Chip } from 'primereact/chip';
import { Button } from 'primereact/button';

import { Accordion, AccordionTab } from 'primereact/accordion';
import {useNavigate} from 'react-router-dom';


const PendingDetail = (props) => {
    const {
        proposal,
        enquiries,
        profileresume,
        profileavailability,
        profilequalifications,
        profilelangauges,
        profilelinks,
        profileroles,
        callForJobInterview,
        rejectJobProposal,
        token,
        profileskills,
    } = props;



    const navigate = useNavigate();



    const callForInterview = (slug, proposal) =>{
        callForJobInterview(slug, proposal, token);
        navigate("/employ/proposals-pending-decision", {replace: true});

    }

    const rejectProposal = (slug, proposal) => {
        rejectJobProposal(slug, proposal, token);
        navigate("/employ/proposals-pending-decision", {replace: true});

    }







  return (
    <>
        <div className="surface-1 card">
            <div className="surface-1 card">
                <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">

                    <div className="mt-5 lg:mt-0">
                        <Button
                            label="CALL FOR INTERVIEW"
                            className="p-button-text p-button-success p-button-success"
                            onClick={() => callForInterview(proposal.slug, proposal)}
                        />

                    </div>
                    <div className="mt-5 lg:mt-0">
                        <Button
                            label="REJECT"
                            className="p-button-text p-button-success p-button-danger"
                            onClick={() => rejectProposal(proposal.slug, proposal)}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="surface-1 card" style={{textAlign: 'center', padding: '40px',  fontFamily: 'cursive'}}>

            <ul className="list-none p-0 m-0">
                <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-primary col-12 md:w-12 font-medium">
                        JOB TITLE
                    </div>
                    <div className="text-primary col-12 md:w-12 font-medium">
                        <Chip label={proposal.job_title} className="mr-2 text-primary"></Chip>
                    </div>
                </li>

                <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-primary col-12 md:w-12 font-medium">
                      JOB DESCRIPTION
                    </div>
                    <div style={{overflowWrap: 'break-word'}} className="text-primary col-12 md:w-12 font-medium">
                        {proposal.job_description}
                    </div>
                </li>
                <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                    <div className="text-primary col-12 md:w-12 font-medium">APPLICATION LETTER</div>
                    <div style={{overflowWrap: 'break-word'}} className="text-primary col-12 md:w-12 font-medium">
                        {proposal.cover_letter}

                    </div>
                </li>
            </ul>
        </div>
        <div className="surface-1" style={{textAlign: 'center', fontFamily: 'cursive'}}>

            <ul className="list-none p-0 m-0">

                <div className="text-primary col-12 md:w-12 font-medium">QUESTIONS ASKED</div>
                {enquiries.map((enq) =>{
                    return(
                        <>
                            <li key={enq.slug} className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                <div className="text-primary col-12 md:w-12 font-medium">{enq.title}</div>
                                <div className="text-primary col-12 md:w-12 font-medium">
                                    <Chip label={enq.answer} className="mr-2 text-primary"></Chip>
                                </div>
                            </li>

                        </>

                    );

                })

                }

            </ul>
        </div>
        <div style={{textAlign: 'center', fontFamily: 'cursive'}}>
          <div className="text-primary col-12 md:w-12 font-medium">APPLICANT DETAILS</div>
          <div className="surface-1 card" >

              <ul className="list-none p-0 m-0">
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT EMAIL
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.email} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT TITLE
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.title} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT NAME
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={`${profileresume.first_name} ${profileresume.middle_name} ${profileresume.last_name}`} className="mr-2 text-primary"/>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT CONTACT NUMBERS
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={`${profileresume.phone_number}, ${profileresume.whatsapp_number}`} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT DATE OF BIRTH
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.dob} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT COUNTRY OF CITIZENSHIP
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.country_of_citizenship} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT COUNTRY OF RESIDENCE
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.country_of_residence} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT CITY/PROVINCE/STATE
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.province} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT TOWN/DISTRICT
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.district} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                          APPLICANT AVALIABILITY
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.availability} className="mr-2 text-primary"></Chip>
                      </div>
                      <div className="text-primary col-12 md:w-12 font-medium">
                          <Chip label={profileresume.availability_category} className="mr-2 text-primary"></Chip>
                      </div>
                  </li>
                  <li className="flex card align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                      <div className="text-primary col-12 md:w-12 font-medium">
                        APPLICANT DESCRIPTION
                      </div>
                      <div style={{overflowWrap: 'break-word'}} className="text-primary col-12 md:w-12 font-medium">
                          {profileresume.description}
                      </div>
                  </li>

              </ul>
          </div>
        </div>

        <div className="text-primary text-center" style={{textAlign: 'center', fontFamily: 'cursive'}}>

                <div className="text-primary col-12 md:w-12 font-medium">APPLICANT ACADEMIC QUALIFICATIONS</div>
                <div style={{textAlign: 'center'}} className="text-primary card">
                    <Accordion activeIndex={800}>
                        {profilequalifications.map((qualification) =>{
                                return(
                                    <AccordionTab data-aos="flip-left" className=" text-primary font-bold mb-3" key={qualification.slug} header={qualification.qualification}>
                                        <ul className="list-none p-0 m-0">
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">TITLE</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={qualification.qualification}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">INSTITUTION</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={qualification.institution}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">LEVEL OF EDUCATION</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={qualification.level_of_education}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">START DATE</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={qualification.start_date}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">END DATE</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={qualification.end_date}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>

                                        </ul>
                                    </AccordionTab>

                                );
                            })
                        }
                    </Accordion>
                </div>

        </div>
        <div className="cardS" style={{textAlign: 'center', fontFamily: 'cursive'}}>

                <div className="text-primary col-12 md:w-12 font-medium">APPLICANT LANGUAGES</div>
              <div style={{textAlign: 'center'}} className="text-primary card">
                    <Accordion activeIndex={800}>
                        {profilelangauges.map((language) =>{
                                return(
                                    <AccordionTab data-aos="flip-left" className=" text-primary font-bold mb-3" key={language.slug} header={language.language}>
                                        <ul className="list-none p-0 m-0">
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">NAME</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={language.language}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">PROFFICIENCY</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={language.proficiency}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>


                                        </ul>
                                    </AccordionTab>

                                );
                            })
                        }
                    </Accordion>
                </div>

        </div>
        <div className="cardS" style={{textAlign: 'center', fontFamily: 'cursive'}}>

              <div className="text-primary col-12 md:w-12 font-medium">APPLICANT JOB EXPERIENCES</div>
            <div style={{textAlign: 'center'}} className="text-primary card">
                <Accordion activeIndex={800}>
                    {profileroles.map((role) =>{
                            return(
                                <AccordionTab data-aos="flip-left" className=" text-primary font-bold mb-3" key={role.slug} header={role.occupation}>
                                    <ul className="list-none p-0 m-0">
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">TITLE</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.occupation}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">CATEGORY</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.category}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">COMPANY</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.entity}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">COUNTRY</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.country}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">CITY</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.city}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">FROM MONTH :</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.from_month}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">FROM YEAR :</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                <Chip label={role.from_year}  className="mr-2 text-primary mb-2 text-primary" />
                                            </div>

                                        </li>
                                        {role.still_working_there
                                            ?<li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">IS STILL EMPLOYED THERE</div>


                                            </li>
                                            :<>
                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className="text-primary w-6 md:w-4 font-medium">TO MONTH :</div>
                                                    <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={role.to_month}  className="mr-2 text-primary mb-2 text-primary" />
                                                    </div>

                                                </li>
                                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                    <div className="text-primary w-6 md:w-4 font-medium">TO YEAR :</div>
                                                    <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                        <Chip label={role.to_year}  className="mr-2 text-primary mb-2 text-primary" />
                                                    </div>

                                                </li>

                                            </>

                                        }
                                        <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                            <div className="text-primary w-6 md:w-4 font-medium">DESCRIPTION :</div>
                                            <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                {role.description}
                                            </div>

                                        </li>

                                    </ul>
                                </AccordionTab>

                            );
                        })
                    }
                </Accordion>
            </div>
        </div>
        <div className="cardS" style={{textAlign: 'center', fontFamily: 'cursive'}}>

                <div className="text-primary col-12 md:w-12 font-medium">APPLICANT SKILLS</div>
                <div style={{textAlign: 'center'}} className="text-primary card">
                    <Accordion activeIndex={800}>
                        {profileskills.map((skill) =>{
                                return(
                                    <AccordionTab data-aos="flip-left" className=" text-primary font-bold mb-3" key={skill.slug} header={skill.skill}>
                                        <ul className="list-none p-0 m-0">
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">NAME</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={skill.skill}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">YEARS OF EXPERIENCE</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={skill.years_of_experience}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">COMPETENCY</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={skill.competency}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>


                                        </ul>
                                    </AccordionTab>

                                );
                            })
                        }
                    </Accordion>
                </div>

        </div>
        <div className="cardS" style={{textAlign: 'center', fontFamily: 'cursive'}}>
                  <div className="text-primary col-12 md:w-12 font-medium">APPLICANT LINKS</div>

                <div style={{textAlign: 'center'}} className="text-primary card">
                    <Accordion activeIndex={800}>
                        {profilelinks.map((link) =>{
                                return(
                                    <AccordionTab data-aos="flip-left" className=" text-primary font-bold mb-3" key={link.slug} header={link.url}>
                                        <ul className="list-none p-0 m-0">
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">NAME</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={link.url}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>
                                            <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                                <div className="text-primary w-6 md:w-4 font-medium">TYPE</div>
                                                <div className="text-primary w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                                    <Chip label={link.category}  className="mr-2 text-primary mb-2 text-primary" />
                                                </div>

                                            </li>


                                        </ul>
                                    </AccordionTab>

                                );
                            })
                        }
                    </Accordion>
                </div>

        </div>

  </>


  );

};


export default PendingDetail;

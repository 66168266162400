import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import {connect} from 'react-redux';
import {
    getManagementJobs

} from '../../actions/jobs';
import { Chip } from 'primereact/chip';
import { useNavigate } from 'react-router-dom';
import AOS from "aos";
import 'aos/dist/aos.css';
import { Badge } from 'primereact/badge';




const Jobs = (props) => {
    const [layout, setLayout] = useState('grid');
    const navigate = useNavigate();
    const unpublishedJobs = props.records.filter((job) => job.visibility_status ==="INVISIBLE" && job.category===props.category)


    const handleClick = id =>{
        navigate(`/management/unpublishedjobs/${id}`)
    }



    useEffect(() => {
        if (!props.fetched){
            props.getManagementJobs(props.token);
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps





    useEffect(() =>{
        AOS.init({duration : 3000})

      }, []);

    const renderTimestamp = timestamp => {
        let prefix = "";
        const timeDiff = Math.round(
          (new Date().getTime() - new Date(timestamp).getTime()) / 60000
        );
        if (timeDiff < 1) {
          // less than one minute ago
          prefix = "just now...";
        } else if (timeDiff < 60 && timeDiff > 1) {
          // less than sixty minutes ago
          prefix = `${timeDiff} minutes ago`;
        } else if (timeDiff < 24 * 60 && timeDiff > 60) {
          // less than 24 hours ago
          prefix = `${Math.round(timeDiff / 60)} hours ago`;
        } else if (timeDiff < 31 * 24 * 60 && timeDiff > 24 * 60) {
          // less than 7 days ago
          prefix = `${Math.round(timeDiff / (60 * 24))} days ago`;
        } else {
          prefix = `${new Date(timestamp)}`;
        }
        return prefix;
      };

      const truncateParagraph = (paragraph) =>{
        if (paragraph.length > 400){
            let paragraph_ = paragraph.slice(0, 399) + 'read more..'
            return paragraph_
        }else{
            return paragraph
        }
    }



    const renderListItem = (data) => {
        return (
            <div

                className="col-12 md:col-12 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div className="surface-1">
                            <ul className="list-none job_link p-0 m-0 align-items-center">
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {data.title}

                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={`Posted ${renderTimestamp(data.created_at)}`}  className="mr-2 mb-2  " />
                                    </div>
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {`EXPECTED DURATION: ${data.expected_duration}`}

                                    </div>
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {`BUDGET: ${data.charge_category}`}

                                    </div>
                                    {data.charge_category === "FIXED"
                                        ?<div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                            {`Estimated Budget: $${data.fixed_price}`}
                                        </div>
                                        :<>
                                            <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                                {`Estimated Hourly Budget: $${data.from_hourly_price} - $${data.to_hourly_price}`}
                                            </div>
                                        </>

                                    }
                                </li>
                                 <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {truncateParagraph(data.description)}
                                    </div>

                                </li>

                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Button
                                            onClick={() =>  handleClick(data.slug)}
                                            label="VIEW MORE & PUBLISH THIS JOB"
                                            className="p-button-outlined p-button-rounded p-button-warning"
                                        />
                                    </div>

                                </li>

                            </ul>
                        </div>
                     </div>
                </div>
        );
    }


    const renderGridItem = (data) => {
        return (
            <div

                className="col-12 md:col-6 border-50 box shadow-8 flex-grow-1"
                style={{textAlign: 'center', padding: '40px', fontFamily: 'cursive'}}
             >

                    <div className="product-list-items  card mb-3" style={{paddingBottom: '15px'}}>
                        <div className="surface-1">
                            <ul className="list-none job_link p-0 m-0 align-items-center">
                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {data.title}

                                    </div>
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Chip label={`Posted ${renderTimestamp(data.created_at)}`}  className="mr-2 mb-2  " />
                                    </div>
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {`EXPECTED DURATION: ${data.expected_duration}`}

                                    </div>
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {`BUDGET: ${data.charge_category}`}

                                    </div>
                                    {data.charge_category === "FIXED"
                                        ?<div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                            {`Estimated Budget: $${data.fixed_price}`}
                                        </div>
                                        :<>
                                            <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                                {`Estimated Hourly Budget: $${data.from_hourly_price} - $${data.to_hourly_price}`}
                                            </div>
                                        </>

                                    }
                                </li>
                                 <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div style={{overflowWrap: 'break-word'}} className=" col-12 md:w-12 font-medium">
                                        {truncateParagraph(data.description)}
                                    </div>

                                </li>

                                <li className="flex card align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                                    <div className=" col-12 md:w-12 font-medium">
                                        <Button
                                            onClick={() =>  handleClick(data.slug)}
                                            label="VIEW MORE & PUBLISH THIS JOB"
                                            className="p-button-outlined p-button-rounded p-button-warning"
                                        />
                                    </div>

                                </li>

                            </ul>
                        </div>
                     </div>
                </div>


        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }


    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>

                </div>
                <div className="col-6" style={{textAlign: 'right', padding: '20px'}}>
                    <DataViewLayoutOptions
                        layout={layout}
                        onChange={(e) => setLayout(e.value)}
                    />
                </div>
            </div>
        );
    }








    const header = renderHeader();


    return (
        <>
          {unpublishedJobs.length === 0
            ?<div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
                <div className="col-12">
                        <div className="card">
                            <h5 className=''>EMPTY JOBS</h5>
                            <p className=''>CURRENTLY YOU DO NOT HAVE UNPUBLISHED JOBS UNDER THIS CATEGORY</p>
                        </div>
                 </div>
            </div>
            :<div className="dataview-demo">
                <div className="surface-section card px-4 py-8 md:px-6 lg:px-8">
                <div className="text-700 text-center">
                    <div className="text-500 font-bold text-5xl mb-3">VERIFY & PUBLISH THESE JOBS</div>
                    <div className="text-500 text-2xl mb-5"> YOU NEED TO PUBLISH THESE JOBS FOR THEM TO BE VISIBLE TO PROSPECTIVE CANDIDATES</div>
                    <Button label={`${unpublishedJobs.length} UNPUBLISHED JOBS`} disabled icon="pi pi-lock" className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"></Button>
                </div>
                </div>
                <div className="card">
                    <DataView
                        value={unpublishedJobs}
                        layout={layout}
                        header={header}
                        itemTemplate={itemTemplate}
                        paginator rows={30}
                    />
                </div>
            </div>
          }

        </>
    );
}




const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.jobs.managementjobs,
})

export default connect(
  mapStateToProps,
  {getManagementJobs
} )
  (Jobs);

export const GET_MANAGEMENT_JOBS = 'GET_MANAGEMENT_JOBS';
export const GET_MANAGEMENT_JOB = 'GET_MANAGEMENT_JOB';
export const ADD_MANAGEMENT_JOB = 'ADD_MANAGEMENT_JOB';
export const EDIT_MANAGEMENT_JOB = 'EDIT_MANAGEMENT_JOB';
export const DELETE_MANAGEMENT_JOB = 'DELETE_MANAGEMENT_JOB';
export const GET_MANAGEMENT_INTERVIEW_INVITATIONS = 'GET_MANAGEMENT_INTERVIEW_INVITATIONS';
export const GET_MANAGEMENT_INTERVIEW_INVITATION = 'GET_MANAGEMENT_INTERVIEW_INVITATION';
export const ADD_MANAGEMENT_INTERVIEW_INVITATION = 'ADD_MANAGEMENT_INTERVIEW_INVITATION';
export const EDIT_MANAGEMENT_INTERVIEW_INVITATION = 'EDIT_MANAGEMENT_INTERVIEW_INVITATION';
export const DELETE_MANAGEMENT_INTERVIEW_INVITATION = 'DELETE_MANAGEMENT_INTERVIEW_INVITATION';
export const GET_MANAGEMENT_JOB_PROPOSALS = 'GET_MANAGEMENT_JOB_PROPOSALS';
export const GET_MANAGEMENT_JOB_PROPOSAL = 'GET_MANAGEMENT_JOB_PROPOSAL';
export const ADD_MANAGEMENT_JOB_PROPOSAL = 'ADD_MANAGEMENT_JOB_PROPOSAL';
export const EDIT_MANAGEMENT_JOB_PROPOSAL = 'EDIT_MANAGEMENT_JOB_PROPOSAL';
export const DELETE_MANAGEMENT_JOB_PROPOSAL = 'DELETE_MANAGEMENT_JOB_PROPOSAL';
export const GET_ADHOC_ENQUIRIES = 'GET_ADHOC_ENQUIRIES';
export const GET_ADHOC_ENQUIRY = 'GET_ADHOC_ENQUIRY';
export const ADD_ADHOC_ENQUIRY = 'ADD_ADHOC_ENQUIRY';
export const EDIT_ADHOC_ENQUIRY = 'EDIT_ADHOC_ENQUIRY';
export const DELETE_ADHOC_ENQUIRY = 'DELETE_ADHOC_ENQUIRY';
export const GET_JOB_REQUIREMENTS = 'GET_JOB_REQUIREMENTS';
export const GET_JOB_REQUIREMENT = 'GET_JOB_REQUIREMENT';
export const ADD_JOB_REQUIREMENT = 'ADD_JOB_REQUIREMENT';
export const EDIT_JOB_REQUIREMENT = 'EDIT_JOB_REQUIREMENT';
export const DELETE_JOB_REQUIREMENT = 'DELETE_JOB_REQUIREMENT';
export const GET_JOB_SATISFACTION_QUESTIONS = 'GET_JOB_SATISFACTION_QUESTIONS';
export const GET_JOB_SATISFACTION_QUESTION = 'GET_JOB_SATISFACTION_QUESTION';
export const ADD_JOB_SATISFACTION_QUESTION = 'ADD_JOB_SATISFACTION_QUESTION';
export const EDIT_JOB_SATISFACTION_QUESTION = 'EDIT_JOB_SATISFACTION_QUESTION';
export const DELETE_JOB_SATISFACTION_QUESTION = 'DELETE_JOB_SATISFACTION_QUESTION';
export const GET_INTERVIEW_INVITATIONS = 'GET_INTERVIEW_INVITATIONS';
export const GET_INTERVIEW_INVITATION = 'GET_INTERVIEW_INVITATION';
export const ADD_INTERVIEW_INVITATION = 'ADD_INTERVIEW_INVITATION';
export const EDIT_INTERVIEW_INVITATION = 'EDIT_INTERVIEW_INVITATION';
export const DELETE_INTERVIEW_INVITATION = 'DELETE_INTERVIEW_INVITATION';
export const GET_JOB_PROPOSALS = 'GET_JOB_PROPOSALS';
export const GET_JOB_PROPOSAL = 'GET_JOB_PROPOSAL';
export const ADD_JOB_PROPOSAL = 'ADD_JOB_PROPOSAL';
export const EDIT_JOB_PROPOSAL = 'EDIT_JOB_PROPOSAL';
export const DELETE_JOB_PROPOSAL = 'DELETE_JOB_PROPOSAL';
export const GET_CANDIDATE_JOBS = 'GET_CANDIDATE_JOBS';
export const GET_CANDIDATE_JOB = 'GET_CANDIDATE_JOB';
export const ADD_CANDIDATE_JOB = 'ADD_CANDIDATE_JOB';
export const EDIT_CANDIDATE_JOB = 'EDIT_CANDIDATE_JOB';
export const DELETE_CANDIDATE_JOB = 'DELETE_CANDIDATE_JOB';
export const GET_RECRUITER_JOBS = 'GET_RECRUITER_JOBS';
export const GET_RECRUITER_JOB = 'GET_RECRUITER_JOB';
export const ADD_RECRUITER_JOB = 'ADD_RECRUITER_JOB';
export const EDIT_RECRUITER_JOB = 'EDIT_RECRUITER_JOB';
export const DELETE_RECRUITER_JOB = 'DELETE_RECRUITER_JOB';
export const MANAGEMENT_PUBLISH_JOB = "MANAGEMENT_PUBLISH_JOB";
export const MANAGEMENT_UNPUBLISH_JOB = "MANAGEMENT_UNPUBLISH_JOB";
export const RECRUITER_PUBLISH_JOB = "RECRUITER_PUBLISH_JOB";
export const RECRUITER_UNPUBLISH_JOB = "RECRUITER_UNPUBLISH_JOB";

export const REJECT_JOB_PROPOSAL = "REJECT_JOB_PROPOSAL";
export const CALL_FOR_INTERVIEW = "CALL_FOR_INTERVIEW";

export const GET_PROFILE_RESUME = "GET_PROFILE_RESUME";
export const GET_PROFILE_AVAILABILITY = "GET_PROFILE_AVAILABILITY";
export const GET_PROFILE_ACADEMIC_QUALIFICATIONS = "GET_PROFILE_ACADEMIC_QUALIFICATIONS";
export const GET_PROFILE_LANGUAGES = "GET_PROFILE_LANGUAGES";
export const GET_PROFILE_LINKS = "GET_PROFILE_LINKS";
export const GET_PROFILE_ROLES = "GET_PROFILE_ROLES";
export const GET_PROFILE_SKILLS = "GET_PROFILE_SKILLS";
export const GET_JOB_SKILLS = 'GET_JOB_SKILLS';
export const EDIT_JOB_SKILL = 'EDIT_JOB_SKILL';
export const DELETE_JOB_SKILL = 'DELETE_JOB_SKILL';
export const GET_JOB_QUALIFICATIONS = 'GET_JOB_QUALIFICATIONS';
export const EDIT_JOB_QUALIFICATION = 'EDIT_JOB_QUALIFICATION';
export const DELETE_JOB_QUALIFICATION = 'DELETE_JOB_QUALIFICATION';
export const ADD_JOB_QUALIFICATION = 'ADD_JOB_QUALIFICATION';
export const ADD_JOB_SKILL = 'ADD_JOB_SKILL';

export const GET_JOB_LEVELS = 'GET_JOB_LEVELS';
export const EDIT_JOB_LEVEL = 'EDIT_JOB_LEVEL';
export const DELETE_JOB_LEVEL = 'DELETE_JOB_LEVEL';
export const ADD_JOB_LEVEL = 'ADD_JOB_LEVEL';

export const GET_RECRUITER_PROPOSALS = 'GET_RECRUITER_PROPOSALS';
export const GET_RECRUITER_PROPOSAL = 'GET_RECRUITER_PROPOSAL';
// const localhost = "http://localhost:8000"
// export const mocalhost = "http://localhost:8000"
// const localhost = "https://www.hletipang.com"

const localhost = "https://zimjobscenter.co.zw"
export const mocalhost = "https://zimjobscenter.co.zw"
const apiURL = "/api"
const endpoint = `${localhost}${apiURL}`
export const loginURL = `${endpoint}/users/login/`;
export const createuserURL = `${endpoint}/users/register-user/`;
export const activateuserURL = `${endpoint}/users/activate-user/`;
export const usersURL = `${endpoint}/users/users/`;
export const resetpasswordURL = `${endpoint}/users/reset-password/`;
export const forgotpasswordURL = `${endpoint}/users/forgot-password/`;
export const jobseekerexperiencesURL = `${endpoint}/users/jobseeker-experiences/`;
export const jobseekerlinksURL = `${endpoint}/users/jobseeker-links/`;
export const jobseekeracademicqualificationsURL = `${endpoint}/users/jobseeker-academic-qualifications/`;
export const jobseekerlanguagesURL = `${endpoint}/users/jobseeker-languages/`;
export const jobseekerskillsURL = `${endpoint}/users/jobseeker-skills/`;
export const jobseekerjobapplicationsURL = `${endpoint}/users/jobseeker-job-applications/`;
export const candidatesavalaibilityURL = `${endpoint}/users/jobseeker-avalaibility/`;
export const candidatesresumeURL = `${endpoint}/users/jobseeker-resume/`;
export const employerjobsURL = `${endpoint}/users/employer-jobs/`;
export const emailsURL = `${endpoint}/users/emails/`;
export const usernamesURL = `${endpoint}/users/usernames/`;
export const userprofileURL = `${endpoint}/users/user-profile/`;
export const phoneNumbersURL = `${endpoint}/users/phone-numbers/`;

export const emailValidateURL = `${endpoint}/users/email-validate/`;
export const usernameValidateURL = `${endpoint}/users/username-validate/`;
export const passwordValidateURL = `${endpoint}/users/password-validate/`;
export const phoneNumberUniqueValidationURL = `${endpoint}/users/phone-number-unique-validation/`;
export const emailExistsValidationURL = `${endpoint}/users/email-exists-validation/`;
export const tokenExpiryValidationURL = `${endpoint}/users/token-expiry-validation/`;
export const createJobseekerURL = `${endpoint}/users/create-jobseeker/`;
export const createEmployerURL = `${endpoint}/users/create-employer/`;
export const createFreelancerURL = `${endpoint}/users/createFreelancer/`;
//places
export const continentsURL = `${endpoint}/places/continents/`;
export const countriesURL = `${endpoint}/places/countries/`;
export const provincesURL = `${endpoint}/places/provinces/`;
export const districtsURL = `${endpoint}/places/districts/`;

//langauges
export const languagesURL = `${endpoint}/languages/languages/`;


//jobs
export const managementinterviewinvitationsURL = `${endpoint}/jobs/management-interview-invitations/`;
export const managementjobsURL = `${endpoint}/jobs/management-jobs/`;
export const managementjobproposalsURL = `${endpoint}/jobs/management-job-proposals/`;
export const adhocenquiriesURL = `${endpoint}/jobs/adhoc-enquiries/`;
export const jobrequirementsURL = `${endpoint}/jobs/job-requirements/`;
export const jobsatisfactionquestionsURL = `${endpoint}/jobs/job-satisfaction-questions/`;
export const candidateinterviewinvitationsURL = `${endpoint}/jobs/candidate-interview-invitations/`;
export const candidatejobproposalsURL = `${endpoint}/jobs/candidate-job-proposals/`;
export const candidatejobsURL = `${endpoint}/jobs/candidate-jobs/`;
export const recruiterjobsURL = `${endpoint}/jobs/recruiter-jobs/`;

export const jobskillsURL = `${endpoint}/jobs/job-skills/`;
export const jobqualificationsURL = `${endpoint}/jobs/job-qualifications/`;
export const joblevelsURL = `${endpoint}/jobs/job-levels/`;
export const recruiterproposalsURL = `${endpoint}/jobs/recruiter-proposals/`;



//resume
export const candidateacademicqualificationsURL = `${endpoint}/resume/candidate-academic-qualifications/`;
export const candidateavailabilityURL = `${endpoint}/resume/candidate-availability/`;
export const candidatelanguagesURL = `${endpoint}/resume/candidate-languages/`;
export const candidateresumeURL = `${endpoint}/resume/candidate-resume/`;
export const candidaterolesURL = `${endpoint}/resume/candidate-roles/`;
export const candidatelinksURL = `${endpoint}/resume/candidate-links/`;
export const jobseekerresumeURL = `${endpoint}/resume/jobseeker-resume/`;
export const jobseekeravailabilityURL = `${endpoint}/resume/jobseeker-availability/`;
export const candidateskillsURL = `${endpoint}/resume/candidate-skills/`;


//faqs
export const faqsURL = `${endpoint}/faqs/faqs/`;

//skills
export const skillsURL = `${endpoint}/skills/skills/`;
export const facultiesURL = `${endpoint}/skills/faculties/`;
export const qualificationsURL = `${endpoint}/skills/qualifications/`;
export const nearestneighboursURL = `${endpoint}/skills/nearest-neighbours/`;

//chats
export const adminchannelsURL = `${endpoint}/chats/admin-channels/`;
export const chatmessagesURL = `${endpoint}/chats/chat-messages/`;
export const usermessagesURL = `${endpoint}/chats/user-messages/`;


//reccomendations
export const reccomendationusersURL = `${endpoint}/reccomendations/reccomendation-users/`;
export const recommendURL = `${endpoint}/reccomendations/recommend/`;

//candidate matches
export const candidatePermanentJobBestMatchesURL = `${endpoint}/jobs/candidate-permanent-job-best-matches/`;
export const candidatePermanentJobNearestNeighboursURL = `${endpoint}/jobs/candidate-permanent-job-nearest-neighbours/`;
export const allCandidatePermanentJobsURL = `${endpoint}/jobs/all-candidate-permanent-jobs/`;
export const candidateAppliedForJobsURL = `${endpoint}/jobs/candidate-applied-for-jobs/`;


//reports
export const jobDailyReportsURL = `${endpoint}/reports/job-daily-reports/`;
export const managementJobWeeklyReportsURL = `${endpoint}/reports/management-job-weekly-reports/`;
export const managementJobMonthlyReportsURL = `${endpoint}/reports/management-job-monthly-reports/`;
export const managementJobYearlyReportsURL = `${endpoint}/reports/management-job-yearly-reports/`;
export const recruiterJobDailyReportsURL = `${endpoint}/reports/recruiter-job-daily-reports/`;
export const recruiterJobWeeklyReportsURL = `${endpoint}/reports/recruiter-job-weekly-reports/`;
export const recruiterJobMonthlyReportsURL = `${endpoint}/reports/recruiter-job-monthly-reports/`;
export const recruiterJobYearlyReportsURL = `${endpoint}/reports/recruiter-job-yearly-reports/`;
export const managementDailyJobApplicationReportsURL = `${endpoint}/reports/management-daily-job-applications-reports/`;
export const managementWeeklyJobApplicationReportsURL = `${endpoint}/reports/management-weekly-job-applications-reports/`;
export const managementMonthlyJobApplicationReportsURL = `${endpoint}/reports/management-monthly-job-applications-reports/`;
export const managementYearlyJobApplicationReportsURL = `${endpoint}/reports/management-yearly-job-applications-reports/`;

export const recruiterDailyJobApplicationReportsURL = `${endpoint}/reports/recruiter-daily-job-applications-reports/`;
export const recruiterWeeklyJobApplicationReportsURL = `${endpoint}/reports/recruiter-weekly-job-applications-reports/`;
export const recruiterMonthlyJobApplicationReportsURL = `${endpoint}/reports/recruiter-monthly-job-applications-reports/`;
export const recruiterYearlyJobApplicationReportsURL = `${endpoint}/reports/recruiter-yearly-job-applications-reports/`;
export const candidateDailyJobApplicationReportsURL = `${endpoint}/reports/candidate-daily-job-applications-reports/`;
export const candidateWeeklyJobApplicationReportsURL = `${endpoint}/reports/candidate-weekly-job-applications-reports/`;
export const candidateMonthlyJobApplicationReportsURL = `${endpoint}/reports/candidate-monthly-job-applications-reports/`;
export const candidateYearlyJobApplicationReportsURL = `${endpoint}/reports/candidate-yearly-job-applications-reports/`;
export const managementDailyCandidateJobApplicationReportsURL = `${endpoint}/reports/management-daily-candidate-job-application-reports/`;
export const managementWeeklyCandidateJobApplicationReportsURL = `${endpoint}/reports/management-weekly-candidate-job-application-reports/`;
export const managementMonthlyCandidateJobApplicationReportsURL = `${endpoint}/reports/management-monthly-candidate-job-application-reports/`;
export const managementYearlyCandidateJobApplicationReportsURL = `${endpoint}/reports/management-yearly-candidate-job-application-reports/`;
export const employerDailyJobReportsURL = `${endpoint}/reports/employer-daily-job-reports/`;
export const employerWeeklyJobReportsURL = `${endpoint}/reports/employer-weekly-job-reports/`;
export const employerMonthlyJobReportsURL = `${endpoint}/reports/employer-monthly-job-reports/`;
export const employerYearlyJobReportsURL = `${endpoint}/reports/employer-yearly-job-reports/`;
export const managementStatsURL = `${endpoint}/reports/management-stats/`;
export const managementMonthlyJobDataURL = `${endpoint}/reports/management-monthly-job-line-data/`;
export const recruiterStatsURL = `${endpoint}/reports/recruiter-stats/`;
export const recruiterMonthlyJobDataURL = `${endpoint}/reports/recruiter-monthly-job-line-data/`;

//portfolio
export const userPrivacyAgreementsURL = `${endpoint}/portfolio/user-privacy-agreements/`;
export const userPrivacyAgreementURL = `${endpoint}/portfolio/user-privacy-agreement/`;
export const candidatePortfoliosURL = `${endpoint}/portfolio/candidate-portfolios/`;
export const candidateServicesURL = `${endpoint}/portfolio/candidate-services/`;
export const candidateObjectivesURL = `${endpoint}/portfolio/candidate-objectives/`;
export const candidateMissionsURL = `${endpoint}/portfolio/candidate-missions/`;


//matches
export const matchedUserPortfoliosURL = `${endpoint}/matches/matched-user-portfolios/`;
export const matchedUserServicesURL = `${endpoint}/matches/matched-user-services/`;
export const matchedUserObjectivesURL = `${endpoint}/matches/matched-user-objectives/`;
export const matchedUserAcademicQualificationsURL = `${endpoint}/matches/matched-user-academic-qualifications/`;
export const matchedUserLanguagesURL = `${endpoint}/matches/matched-user-languages/`;
export const matchedUserLinksURL = `${endpoint}/matches/matched-user-links/`;
export const matchedUserExperiencesURL = `${endpoint}/matches/matched-user-experiences/`;
export const matchedUserSkillsURL = `${endpoint}/matches/matched-user-skills/`;
export const usersMatchedByJobURL = `${endpoint}/matches/users-matched-by-job/`;
export const usersMatchedByIndustryURL = `${endpoint}/matches/users-matched-by-industry/`;
export const matchedUserProfileURL = `${endpoint}/matches/matched-user-profile/`;

//FEEDBACK
export const enquiriesURL = `${endpoint}/feedback/enquiries/`

export const GET_MATCHED_USER_PORTFOLIOS_START = "GET_MATCHED_USER_PORTFOLIOS_START";
export const GET_MATCHED_USER_PORTFOLIOS_SUCCESS = "GET_MATCHED_USER_PORTFOLIOS_SUCCESS";
export const GET_MATCHED_USER_PORTFOLIOS_FAIL = "GET_MATCHED_USER_PORTFOLIOS_FAIL";
export const GET_MATCHED_USER_SERVICES_START = "GET_MATCHED_USER_SERVICES_START";
export const GET_MATCHED_USER_SERVICES_SUCCESS = "GET_MATCHED_USER_SERVICES_SUCCESS";
export const GET_MATCHED_USER_SERVICES_FAIL = "GET_MATCHED_USER_SERVICES_FAIL";
export const GET_MATCHED_USER_OBJECTIVES_START = "GET_MATCHED_USER_OBJECTIVES_START";
export const GET_MATCHED_USER_OBJECTIVES_SUCCESS = "GET_MATCHED_USER_OBJECTIVES_SUCCESS";
export const GET_MATCHED_USER_OBJECTIVES_FAIL = "GET_MATCHED_USER_OBJECTIVES_FAIL";
export const GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_START = "GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_START";
export const GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_SUCCESS = "GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_SUCCESS";
export const GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_FAIL = "GET_MATCHED_USER_ACADEMIC_QUALIFICATIONS_FAIL";
export const GET_MATCHED_USER_LANGUAGES_START = "GET_MATCHED_USER_LANGUAGES_START";
export const GET_MATCHED_USER_LANGUAGES_SUCCESS = "GET_MATCHED_USER_LANGUAGES_SUCCESS";
export const GET_MATCHED_USER_LANGUAGES_FAIL = "GET_MATCHED_USER_LANGUAGES_FAIL";
export const GET_MATCHED_USER_LINKS_START = "GET_MATCHED_USER_LINKS_START";
export const GET_MATCHED_USER_LINKS_SUCCESS = "GET_MATCHED_USER_LINKS_SUCCESS";
export const GET_MATCHED_USER_LINKS_FAIL = "GET_MATCHED_USER_LINKS_FAIL";
export const GET_MATCHED_USER_EXPERIENCES_START = "GET_MATCHED_USER_EXPERIENCES_START";
export const GET_MATCHED_USER_EXPERIENCES_SUCCESS = "GET_MATCHED_USER_EXPERIENCES_SUCCESS";
export const GET_MATCHED_USER_EXPERIENCES_FAIL = "GET_MATCHED_USER_EXPERIENCES_FAIL";
export const GET_MATCHED_USER_SKILLS_START = "GET_MATCHED_USER_SKILLS_START";
export const GET_MATCHED_USER_SKILLS_SUCCESS = "GET_MATCHED_USER_SKILLS_SUCCESS";
export const GET_MATCHED_USER_SKILLS_FAIL = "GET_MATCHED_USER_SKILLS_FAIL";

export const GET_USERS_MATCHED_BY_JOB_START = "GET_USERS_MATCHED_BY_JOB_START";
export const GET_USERS_MATCHED_BY_JOB_SUCCESS = "GET_USERS_MATCHED_BY_JOB_SUCCESS";
export const GET_USERS_MATCHED_BY_JOB_FAIL = "GET_USERS_MATCHED_BY_JOB_FAIL";

export const GET_USERS_MATCHED_BY_INDUSTRY_START = "GET_USERS_MATCHED_BY_INDUSTRY_START";
export const GET_USERS_MATCHED_BY_INDUSTRY_SUCCESS = "GET_USERS_MATCHED_BY_INDUSTRY_SUCCESS";
export const GET_USERS_MATCHED_BY_INDUSTRY_FAIL = "GET_USERS_MATCHED_BY_INDUSTRY_FAIL";

export const GET_MATCHED_USER_PROFILE_START = "GET_MATCHED_USER_PROFILE_START";
export const GET_MATCHED_USER_PROFILE_SUCCESS = "GET_MATCHED_USER_PROFILE_SUCCESS";
export const GET_MATCHED_USER_PROFILE_FAIL = "GET_MATCHED_USER_PROFILE_FAIL";

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Routes,
    Route,

  } from "react-router-dom";
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import './assets/demo/flags/flags.css';
import './assets/demo/Demos.scss';
import './assets/layout/layout.scss';
import './App.scss';
import './global.css';
import ManagementJobForm from './management/jobs/JobForm';
import ManagementDashBoard from './management/dashboard/Dashboard';
import LandingPage from "./public/home/LandingPage";
import Login from './public/accounts/Login';
import {authCheckState} from './actions/auth';
import RequireAuth from './RequireAuth';
import UnAuthorized from './UnAuthorized';
import Alert from './Alert';
import LandingAbout from './public/about/About';
import ManagementLanguages from './management/languages/Languages';
import ManagementContinents from './management/places/Continents';
import ManagementCountries from './management/places/Countries';
import ManagementProvinces from './management/places/Provinces';
import ManagementDistricts from './management/places/Districts';
import JobSeekerProfile from './jobseeker/profile/Profile';
import ManagementJob from './management/jobs/Job';
import JobSeekerJobs from './jobseeker/jobs/Jobs';
import JobSeekerJob from './jobseeker/jobs/Job';
import ManagementUnPublishedJob from './management/jobs/UnPublishedJob';
import Chats from './management/messages/Chats';
import ChatMessages from './management/messages/Chat';
import ManagementSkills from './management/skills/Skills';
import ManagementFaculties from './management/skills/Faculties';
import ManagementQualifications from './management/skills/Qualifications';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import ManagementFaqs from './management/enquiries/Faqs';
import Registration from './public/accounts/RegisterAccount';
import Activate from './public/accounts/Activate';
import EmployerDashboard from './employer/dashboard/Dashboard';
import EmployerUnpublishedJobs from './employer/jobs/UnPublishedJobs';
import EmployerPublishedJobs from './employer/jobs/PublishedJobs';
import EmployerUnpublishedJob from './employer/jobs/UnPublishedJob';
import EmployerPublishedJob from './employer/jobs/Job';
import CreateEmployerJob from './employer/jobs/JobForm';
import ManagementUnpublishedJobs from './management/jobs/UnPublishedJobs';
import ManagementPublishedJobs from './management/jobs/PublishedJobs';
import RecruiterPendingProposals from './employer/jobs/pendingproposals/PendingProposals';
import RecruiterPendingProposal from './employer/jobs/pendingproposals/PendingProposal';
import EmployerChats from './employer/messages/Chats';
import EmployerChat from './employer/messages/Chat';
import CandidateChats from './jobseeker/messages/Chats';
import CandidateChat from './jobseeker/messages/Chat';
import RecruiterRejectedProposals from './employer/jobs/rejected/RejectedProposals';
import RecruiterRejectedProposal from './employer/jobs/rejected/RejectedProposal';
import RecruiterCalledForInterviewProposals from './employer/jobs/interview/CalledForInterviewProposals';
import RecruiterCalledForInterviewProposal from './employer/jobs/interview/CalledForInterviewProposal';
import ManagementEmployerDetails from './management/employers/EmployerDetails';
import ManagementCandidateDetails from './management/candidates/CandidatesDetails';
import ManagementJobSeekerSkills from './management/candidates/Skills';
import ManagementJobSeekerAcademicQualifications from './management/candidates/JobSeekerAcademicQualifications'
import ManagementJobSeekerExperiences from './management/candidates/Experiences';
import ManagementJobSeekerLangauges from './management/candidates/Langauges';
import ManagementJobSeekerLinks from './management/candidates/Links';
import ManagementJobSeekerPendingProposals from './management/candidates/PendingProposals';
import ManagementJobSeekerCalledForInterviewProposals from './management/candidates/CalledForInterviewProposals';
import ManagementEmployerUnPublishedJobs from './management/employers/EmployerUnPublishedJobs';
import ManagementEmployerPublishedJobs from './management/employers/EmployerPublishedJobs';
import ManagementEmployerClosedJobs from './management/employers/EmployerClosedJobs';
import ManagementRecommendations from './management/recommendations/Recommendations';
import NearestNeighbours from './management/skills/NearestNeighbours';
import JobSeekerProposals from './jobseeker/proposals/Proposals';
import ManagementCandidatePendingProposal from './management/candidates/pending/Proposal';
import ManagementCandidateInterviewProposal from './management/candidates/interview/Proposal';
import ManagementJobSeekerRejectedProposals from './management/candidates/RejectedProposals';
import ManagementCandidateRejectedProposal from './management/candidates/rejected/Proposal';
import ManagementProposals from './management/jobs/proposals/Proposals';
import ManagementPendingApplication from './management/jobs/pendingproposals/Proposal';
import ManagementInterviewApplication from './management/jobs/interview/Proposal';
import ManagementRejectedApplication from './management/jobs/rejected/Proposal';
import PageNotFound from './PageNotFound';
import ManagementReports from './management/reports/Reports';
import EmployerReports from './employer/reports/Reports';
import CandidateReports from './jobseeker/reports/Reports';
import PublicPortfolios from './public/portfolio/Portfolios';
import Portfolio from './public/portfolio/Portfolio';
import Why from './public/home/Why';
import Industries from './public/home/Industries';
import EmployerMatchedUser from './employer/jobs/matched/MatchedUser';
import EmployerIndustries from './employer/industries/Industries';
import EmployerMatchedByIndustryUsers from './employer/industries/MatchedUsers';
import EmployerMatchedUserByIndustry from './employer/industries/matched/MatchedUser';
// import FindJobs from './public/accounts/FindJobs';
// import ApplyAsFreelancer from './public/accounts/ApplyAsFreelancer';
import RegisterAccount from './public/accounts/RegisterAccount';
import ContactUs from './public/home/ContactUs';


const alertOptions = {
    timeout: 30000,
    position: 'bottom center'
}

const App = (props) => {

    useEffect(() => {
        props.onTryAutoSignup();
        console.log('mount it!');
      }, []);// eslint-disable-line react-hooks/exhaustive-deps






    return (
      <AlertProvider template={AlertTemplate} {...alertOptions} >
        <Alert/>

          <Routes>


              <Route
                  path="/"
                  element={<LandingPage/>}
              />

              <Route
                  path="/unauthorized"
                  element={<UnAuthorized/>}
              />
              <Route
                  path="/about"
                  element={<LandingAbout/>}
              />
              <Route
                  path="/register-account"
                  element={<RegisterAccount/>}
              />
              <Route
                  path="/why"
                  element={<Why/>}
              />
              <Route
                  path="/login"
                  element={<Login/>}
              />
              <Route
                  path="/industries"
                  element={<Industries/>}
              />

              <Route
                  path="/portfolios"
                  element={<PublicPortfolios/>}
              />
              <Route
                  path="/activate/:activateToken"
                  element={<Activate/>}
              />

              <Route
                  path="/contact-us"
                  element={<ContactUs/>}
              />

              <Route element={<RequireAuth allowedRoles='admin' />}>
                <Route
                  path="/management"
                  element={<ManagementDashBoard/>}
                />
                <Route
                  path="/management/unpublished-jobs"
                  element={<ManagementUnpublishedJobs/>}
                />
                <Route
                  path="/management/published-jobs"
                  element={<ManagementPublishedJobs/>}
                />
                <Route
                  path="/management/jobs/create"
                  element={<ManagementJobForm/>}
                />
                <Route
                  path="/management/jobs/:slug"
                  element={<ManagementJob/>}
                />

                <Route
                  path="/management/unpublishedjobs/:slug"
                  element={<ManagementUnPublishedJob/>}
                />
                <Route
                  path="/management/languages"
                  element={<ManagementLanguages/>}
                />
                <Route
                  path="/management/continents"
                  element={<ManagementContinents/>}
                />
                <Route
                  path="/management/countries"
                  element={<ManagementCountries/>}
                />
                <Route
                  path="/management/provinces"
                  element={<ManagementProvinces/>}
                />
                <Route
                  path="/management/districts"
                  element={<ManagementDistricts/>}
                />
                <Route
                  path="/management/messages"
                  element={<Chats/>}
                />
                <Route
                  path="/management/messages/:slug"
                  element={<ChatMessages/>}
                />
                <Route
                  path="/management/skills"
                  element={<ManagementSkills/>}
                />
                <Route
                  path="/management/faculties"
                  element={<ManagementFaculties/>}
                />
                <Route
                  path="/management/qualifications"
                  element={<ManagementQualifications/>}
                />
                <Route
                  path="/management/neighbours"
                  element={<NearestNeighbours/>}
                />
                <Route
                  path="/management/recommendations"
                  element={<ManagementRecommendations/>}
                />
                <Route
                  path="/management/faqs"
                  element={<ManagementFaqs/>}
                />
              <Route
                  path="/management/employer-details"
                  element={<ManagementEmployerDetails/>}
                />
              <Route
                  path="/management/employer-details/unpublished-jobs/:slug"
                  element={<ManagementEmployerUnPublishedJobs/>}
                />
              <Route
                  path="/management/employer-details/published-jobs/:slug"
                  element={<ManagementEmployerPublishedJobs/>}
                />
              <Route
                  path="/management/employer-details/closed-jobs/:slug"
                  element={<ManagementEmployerClosedJobs/>}
              />

              <Route
                  path="/management/candidate-details"
                  element={<ManagementCandidateDetails/>}
                />
                <Route
                    path="/management/candidate-details/skills/:slug"
                    element={<ManagementJobSeekerSkills/>}
                  />
                <Route
                    path="/management/candidate-details/academic-qualifications/:slug"
                    element={<ManagementJobSeekerAcademicQualifications/>}
                  />
                <Route
                    path="/management/candidate-details/job-experiences/:slug"
                    element={<ManagementJobSeekerExperiences/>}
                  />
                <Route
                    path="/management/candidate-details/languages/:slug"
                    element={<ManagementJobSeekerLangauges/>}
                  />
                <Route
                    path="/management/candidate-details/links/:slug"
                    element={<ManagementJobSeekerLinks/>}
                  />
                  <Route
                    path="/management/candidate-details/pending-proposals/:slug"
                    element={<ManagementJobSeekerPendingProposals/>}
                  />
                  <Route
                    path="/management/candidate/pending-proposals/:slug"
                    element={<ManagementCandidatePendingProposal/>}
                  />
                  <Route
                    path="/management/candidate-details/called-for-interview-proposals/:slug"
                    element={<ManagementJobSeekerCalledForInterviewProposals/>}
                  />
                  <Route
                    path="/management/candidate/on-interview-proposals/:slug"
                    element={<ManagementCandidateInterviewProposal/>}
                  />
                  <Route
                    path="/management/candidate-details/rejected-proposals/:slug"
                    element={<ManagementJobSeekerRejectedProposals/>}
                  />
                  <Route
                    path="/management/candidate/rejected-proposals/:slug"
                    element={<ManagementCandidateRejectedProposal/>}
                  />
                  <Route
                    path="/management/job-applications"
                    element={<ManagementProposals/>}
                  />
                  <Route
                    path="/management/pending-decision-application/:slug"
                    element={<ManagementPendingApplication/>}
                  />
                  <Route
                    path="/management/called-for-interview-application/:slug"
                    element={<ManagementInterviewApplication/>}
                  />
                  <Route
                    path="/management/rejected-application/:slug"
                    element={<ManagementRejectedApplication/>}
                  />
                  <Route
                    path="/management/accepted-application/:slug"
                    element={<ManagementRejectedApplication/>}
                  />
                  <Route
                    path="/management/reports"
                    element={<ManagementReports/>}
                  />

              </Route>
              <Route element={<RequireAuth allowedRoles='jobseeker' />}>
                <Route
                  path="/jobs"
                  element={<JobSeekerJobs/>}
                />
                <Route
                  path="/jobs/:slug"
                  element={<JobSeekerJob/>}
                />
                <Route
                  path="/jobs/profile"
                  element={<JobSeekerProfile/>}
                />
                <Route
                  path="/jobs/chats"
                  element={<CandidateChats/>}
                />
                <Route
                  path="/jobs/proposals"
                  element={<JobSeekerProposals/>}
                />
                <Route
                  path="/jobs/chats/:slug"
                  element={<CandidateChat/>}
                />
                <Route
                    path="/jobs/reports"
                    element={<CandidateReports/>}
                />

              </Route>
              <Route element={<RequireAuth allowedRoles='employer' />}>
                <Route
                  path="/employ"
                  element={<EmployerDashboard/>}
                />
                <Route
                  path="/employ/job-matches/:slug"
                  element={<EmployerMatchedUser/>}
                />
                <Route
                  path="/employ/my-unpublished-jobs"
                  element={<EmployerUnpublishedJobs/>}
                />
                <Route
                  path="/employ/my-unpublished-jobs/:slug"
                  element={<EmployerUnpublishedJob/>}
                />
                <Route
                  path="/employ/my-published-jobs"
                  element={<EmployerPublishedJobs/>}
                />
                <Route
                  path="/employ/my-published-jobs/:slug"
                  element={<EmployerPublishedJob/>}
                />
                <Route
                  path="/employ/post-job"
                  element={<CreateEmployerJob/>}
                />
                <Route
                  path="/employ/proposals-pending-decision"
                  element={<RecruiterPendingProposals/>}
                />
                <Route
                  path="/employ/proposals-pending-decision/:slug"
                  element={<RecruiterPendingProposal/>}
                />
                <Route
                  path="/employ/rejected-proposals"
                  element={<RecruiterRejectedProposals/>}
                />
                <Route
                  path="/employ/rejected-proposals/:slug"
                  element={<RecruiterRejectedProposal/>}
                />
                <Route
                  path="/employ/called-for-interview-proposals"
                  element={<RecruiterCalledForInterviewProposals/>}
                />
                <Route
                  path="/employ/called-for-interview-proposals/:slug"
                  element={<RecruiterCalledForInterviewProposal/>}
                />
                <Route
                  path="/employ/chats"
                  element={<EmployerChats/>}
                />
                <Route
                  path="/employ/chats/:slug"
                  element={<EmployerChat/>}
                />
                <Route
                    path="/employ/reports"
                    element={<EmployerReports/>}
                />
                <Route
                  path="/employ/industries"
                  element={<EmployerIndustries/>}
                />
                <Route
                  path="/employ/industries/:slug"
                  element={<EmployerMatchedByIndustryUsers/>}
                />
                <Route
                  path="/employ/industry-matches/:slug"
                  element={<EmployerMatchedUserByIndustry/>}
                />
              </Route>
              <Route
                  path="*"
                  element={
                    <PageNotFound />
                  }
                />

          </Routes>
        </AlertProvider>

    );

}

const mapStateToProps = state => {
    return {
      token: state.auth.token,
          user: state.auth.user,
          email: state.auth.email,
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      onTryAutoSignup: () => dispatch(authCheckState()),
    };
  };

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(App);

import {
    GET_ADMIN_CHATS,
    GET_CHAT_MESSAGES,
    READ_MESSAGES,
    DELETE_MESSAGE,
    ADD_MESSAGE,
    GET_CHAT,
    GET_USER_MESSAGES,
} from '../types/chatTypes';



const initialState = {
    adminchats: [],
    chatmessages: [],
    chat: {},
    usermessages: {},
};





export default function chats(state = initialState, action){

    switch(action.type){

        case GET_ADMIN_CHATS:
            return {
                ...state,
                adminchats : action.payload
        };
        case GET_CHAT:
            return {
                ...state,
                chat : action.payload
        };
        //MESSAGES 
        case GET_CHAT_MESSAGES:
            return {
                ...state,
                chatmessages : action.payload
        };
        case GET_USER_MESSAGES:
            return {
                ...state,
                usermessages : action.payload
        };
        case ADD_MESSAGE:
            return {
                ...state,
               message: [...state.chatmessages, action.payload]
            }
        case READ_MESSAGES:
            return {
                ...state,
                message: [...state.chatmessages, action.payload]
            }

        case DELETE_MESSAGE:
            return {
                ...state,
                message: [...state.chatmessages, action.payload]
            }

       
    
        default:
            return state;
    }
}

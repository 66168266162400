import React, { useState, useEffect } from 'react';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import ManagementLayout from '../layout/ManagementLayout';
import {connect} from 'react-redux';
import { Chip } from 'primereact/chip';
import {
    getCountries, 
    addCountry,
    editCountry,
    getContinents
    
} from '../../actions/places';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AOS from "aos";
import 'aos/dist/aos.css';



const Continents = (props) => {

    const [filters2, setFilters2] = useState({
      'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'name': { value: null, matchMode: FilterMatchMode.CONTAINS},
      'continent': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'isocode': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'abbreviation': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'phone_code': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

  const [globalFilterValue2, setGlobalFilterValue2] = useState('');
  const [loading2, setLoading2] = useState(false);
  const {records, token} = props;

  const onGlobalFilterChange2 = (e) => {
    const value = e.target.value;
    let _filters2 = { ...filters2 };
    _filters2['global'].value = value;

    setFilters2(_filters2);
    setGlobalFilterValue2(value);
  }

    const [layout, setLayout] = useState('grid');
    const [sortKey, setSortKey] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    let initialValues = {
        name: '',
        continent: '',
        abbreviation: '',
        isocode: '',
        phone_code: '',
    }
    const [record, setRecord] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const sortOptions = [
        {label: 'Price High to Low', value: '!price'},
        {label: 'Price Low to High', value: 'price'},
    ];
    
    const [displayMaximizable, setDisplayMaximizable] = useState(false);
    const [tableDialog, setTableDialog] = useState(false);
    const [displayPosition, setDisplayPosition] = useState(false);
    const [position, setPosition] = useState('center');
    const [globalFilter, setGlobalFilter] = useState(null);
    


    useEffect(() => {
        if (!props.fetched){
            props.getCountries(props.token);
        }
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() =>{
        props.getCountries(props.token);
        props.getContinents(props.token);
        console.log('Call this nigga!!!');
        setIsSubmit(false);
        

    }, [isSubmit])// eslint-disable-line react-hooks/exhaustive-deps


    const onSortChange = (event) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        }
        else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }

    const onClick = (name, position) => {
        dialogFuncMap[`${name}`](true);

        if (position) {
            setPosition(position);
        }
    }


    const onSubmit = (e) => {
        e.preventDefault();
        let _record = {...record};
        if (record.id) {
            if (validate()){
                setIsSubmit(true);
                props.editCountry(record.id, record, props.token);
                props.getCountries(props.token);
                resetForm();
                setDisplayMaximizable(false);
            }
        }
        else {
            if (validate()){
                setIsSubmit(true);
                console.log(errors)
                props.addCountry(record, props.token);
                props.getCountries(props.token);
                resetForm();
                setDisplayMaximizable(false);
        }
        }
        
    }

    const validate = (fieldValues = record) => {
        let temp = { ...errors }
        if ('name' in fieldValues)
            temp.name = fieldValues.name ? "" : "NAME IS REQUIRED."
        if ('abbreviation' in fieldValues)
            temp.abbreviation = fieldValues.abbreviation ? "" : "ABBREVIATION IS REQUIRED."
        if ('isocode' in fieldValues)
            temp.isocode = fieldValues.isocode ? "" : "ISOCODE IS REQUIRED."
        if ('phone_code' in fieldValues)
            temp.phone_code = fieldValues.phone_code ? "" : "PHONE CODE IS REQUIRED."
        if ('continent' in fieldValues)
            temp.continent = fieldValues.continent ? "" : "CONTINENT IS REQUIRED."

        setErrors({
            ...temp

        })
    
        if (fieldValues === record)
            return Object.values(temp).every(x => x === "")
      }

    const resetForm = () => {
        setRecord(initialValues);
        setErrors({})
    }


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...record};
        _record[`${name}`] = val;
        setRecord(_record);
    }

    const onHide = (name) => {
        dialogFuncMap[`${name}`](false);
    }

    const dialogFuncMap = {
        'displayMaximizable': setDisplayMaximizable,
        'displayPosition': setDisplayPosition,
        'tableDialog': setTableDialog,
    }

    const editContinentButton = (record) => {
        setRecord({...record});
        setDisplayMaximizable(true);
    }


    

    const renderListItem = (data) => {
        return (
            <div className="col-12">
                <div className="product-list-item">
                   
                    <div className="product-list-detail">
                        <div className="product-name">{data.name}</div>    
                    </div>
                    <div className="product-list-detail">
                        <div className="product-name">{data.continent}</div>    
                    </div>
                    <div className="product-list-detail">
                        <div className="product-name">{data.isocode}</div>    
                    </div>
                    <div className="product-list-detail">
                        <div className="product-name">{data.phone_code}</div>    
                    </div>
                    <div className="product-list-action">
                        <Button 
                            icon="pi pi-pencil" 
                            label="EDIT" 
                            onClick={() => editContinentButton(data)}

                        />
                        
                    </div>
                </div>
                
            </div>
        );
    }

    const renderGridItem = (data) => {
        return (
            <div data-aos="flip-left" className="col-12 flex-grow-1 md:col-6 card border-50 box border-round shadow-8" style={{padding: '20px', fontFamily: 'cursive'}}>
                <div data-aos="zoom-in">
                    <ul data-aos="fade-down"  className="box shadow-8 list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.name}  className="mr-2 mb-2 " />
                            </div>       
                        </li>
                        <li className="flex-wrap col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                CONTINENT
                            </div> 
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.continent}  className="mr-2 mb-2  " />
                            </div>       
                        </li>
                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                ISO CODE
                            </div> 
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.isocode}  className="mr-2 mb-2  " />
                            </div>       
                        </li>
                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                PHONE CODE
                            </div> 
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.phone_code}  className="mr-2 mb-2  " />
                            </div>       
                        </li>
                        <li className="flex  col-12 md:col-12 align-items-center py-3 px-2 border-top-1 border-bottom-1 border-300 flex-wrap">
                            <div className=" col-12 md:w-12 font-medium">
                                ABBREVIATION
                            </div> 
                            <div className=" col-12 md:w-12 font-medium">
                                <Chip label={data.abbreviation}  className="mr-2 mb-2  " />
                            </div>       
                        </li>
                        
                        <li>
                            <div className='grid p-fluid'>
                                <div className="col-12 md:col-2">
                                    <Button
                                        className="p-button-text p-button-warning"
                                        icon="pi pi-pencil"
                                        onClick={() => editContinentButton(data)}
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        );
    }

    const itemTemplate = (product, layout) => {
        if (!product) {
            return;
        }

        if (layout === 'list')
            return renderListItem(product);
        else if (layout === 'grid')
            return renderGridItem(product);
    }

    const renderHeader = () => {
        return (
            <div className="grid grid-nogutter">
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>
                    <Button
                        label="TABLE"
                        className="p-button-primary "
                        onClick={() => onClick('tableDialog')}
                    />       
                </div>
                <div className="col-6" style={{textAlign: 'center', padding: '20px'}}>
                    <DataViewLayoutOptions 
                        layout={layout} 
                    />
                </div>
            </div>
        );
    }



    const tableHeader = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );


    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button
                   
                    className="p-button-text "
                    
                />
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
               
                <Button  icon="pi pi-upload" className="p-button-text"/>
            </React.Fragment>
        )
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button 
                    icon="pi pi-pencil" 
                    className="p-button-text p-button-success mr-2" 
                    onClick={() => editContinentButton(rowData)} 
                />
                <Button 
                    icon="pi pi-trash" 
                    className="p-button-text p-button-danger"  
                />
            </React.Fragment>
        );
    }

    const header = renderHeader();
    

    return (
        <ManagementLayout>
            <div className="dataview-demo">
                <div className="card">
                    <DataView 
                        value={props.records} 
                        layout={layout} 
                        header={header}
                        itemTemplate={itemTemplate} 
                        paginator rows={30}
                        sortOrder={sortOrder} 
                        sortField={sortField} 
                    />
                </div>
            </div>
            <Dialog
                header="COUNTRY FORM" 
                visible={displayMaximizable} 
                maximizable modal 
                style={{ width: '50vw' }} 
                onHide={() => onHide('displayMaximizable')}
            >
                <div className="card">
                    <form onSubmit={onSubmit}>
                        <ul data-aos="flip-left"  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none card grid p-fluid p-0 m-0" >
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    SELECT NAME
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="name"
                                    value={record.name}
                                    onChange={(e) => onInputChange(e, 'name')}
                                    /> 
                                <small id="username2-help"  className="p-error block">{errors.name}</small> 
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    SELECT ABBREVIATION
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="abbreviation"
                                    value={record.abbreviation}
                                    onChange={(e) => onInputChange(e, 'abbreviation')}
                                    />             
                                <small id="username2-help"  className="p-error block">{errors.abbreviation}</small> 
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    SELECT CONTINENT
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <Dropdown
                                    value={record.continent}
                                    optionLabel="name"
                                    optionValue="name"
                                    options={props.continents}
                                    onChange={(e) => onInputChange(e, 'continent')}
                                    filterBy="name"    
                                />     
                                <small id="username2-help"  className="p-error block">{errors.continent}</small>
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    PUT ISOCODE
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="isocode"
                                    value={record.isocode}
                                    onChange={(e) => onInputChange(e, 'isocode')}
                                    />             
                                <small id="username2-help"  className="p-error block">{errors.isocode}</small> 
                                </div>
                            </li>
                            <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     

                                <div
                                    className="w-full  md:w-4 md:flex-order-0 flex-order-1"
                                    style={{fontSize: '15px', fontFamily: 'cursive'}}
                                >
                                    PUT PHONE CODE
                                </div>
                                <div className=" w-full md:w-8 md:flex-order-0 flex-order-1">
                                <InputText
                                    id="phone_code"
                                    value={record.phone_code}
                                    onChange={(e) => onInputChange(e, 'phone_code')}
                                    />             
                                <small id="username2-help"  className="p-error block">{errors.phone_code}</small> 
                                </div>
                            </li>
                            
                            <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">     
                                <Button 
                                    type="submit" 
                                    label="ADD COUNTRY" 
                                    className="p-button-outlined p-button-warning"
                                    
                                />
                            </li>
                        </ul>    
                    </form>    
                </div>
                    
            </Dialog>
            <Dialog
                header="TABLE" 
                visible={tableDialog} 
                maximizable modal 
                style={{ width: '1200px' }} 
                onHide={() => onHide('tableDialog')}
            >
                <div className="card">
                    <Toolbar 
                        className="mb-4" 
                        left={leftToolbarTemplate} 
                        right={rightToolbarTemplate}
                    />
                    <DataTable 
                        value={props.records} 
                        responsiveLayout="scroll"
                        dataKey="id" 
                        paginator 
                        globalFilter={globalFilter}
                        filterDisplay="row"
                        filters={filters2}
                        style={{fontSize: '15px', fontFamily: 'cursive'}}
                        rows={30} 
                        header={tableHeader}
                        globalFilterFields={['name', 'continent', 'isocode', 'abbreviation', 'phone_code']}
                        rowsPerPageOptions={[5, 10, 25]}
                        showGridlines
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} COUNTRIES"
                    >
                        <Column 
                            field="name" 
                            header="NAME"
                            sortable 
                            filter
                        />
                        <Column 
                            field="continent" 
                            header="CONTINENT"
                            sortable 
                            filter
                        />
                        <Column 
                            field="abbreviation"
                            sortable  
                            header="ABBRV"
                            filter
                        />
                        <Column 
                            field="isocode" 
                            header="ISOCODE"
                            sortable 
                            filter
                        />
                        <Column 
                            field="phone_code" 
                            header="PHONE CODE"
                            sortable 
                            filter
                        />
                         <Column 
                            body={actionBodyTemplate} 
                            exportable={false} 
                            style={{ minWidth: '8rem' }}
                        />
                    </DataTable>
                </div>


            </Dialog>
        </ManagementLayout>
    );
}



const mapStateToProps = state =>({
    token: state.auth.token,
    records: state.places.countries,
    continents: state.places.continents,
})

export default connect(
  mapStateToProps,
  {getCountries, 
    addCountry,
    editCountry,
    getContinents,
} )
  (Continents);
   
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import { InputText } from 'primereact/inputtext';
import {
  phoneNumberValidation,
} from '../../../actions/validations';
import { Message } from 'primereact/message';


const PhoneNumber = (props) => {
  const {
    phone_number,
    onPhoneNumberInputChange,
    phoneNumberValidation,
    clear,
    setClear,
    setProceedCleared,
    phoneValidation,
  } =props;
  const [checking, setChecking] = useState(false);
  const [payload, setPayload] = useState({});
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (phone_number !== ''){
      const timer = setTimeout(() => {
        props.phoneNumberValidation(phone_number)
        setChecking(true);
        setClear(false);
      }, 3000)

      return () => clearTimeout(timer)
    }



  }, [phone_number])

  useEffect(() =>{
    setChecking(false);
    if (phoneValidation.unique_pass !==undefined){
      setPayload(
        {
          unique_pass: phoneValidation.unique_pass,
        }
      )
      if (!phoneValidation.unique_pass){
        let err = {...errors};
        err.unique_pass = false
        setErrors({...err})
        setProceedCleared(false)
      }else{
        let err = {...errors};
        err.unique_pass = true
        setErrors({...err})
        if (phoneValidation.unique_pass){
          setProceedCleared(true)
        }
      }

    }

  }, [phoneValidation, checking])


  const displayMessage = (message) =>{
    if (message ==true){
      return (
        <small
          id="username-help"
          style={{color: 'green'}}
          className="p-info">
            PhoneNumber not Taken Proceed ...
        </small>
      )
    }else if(message ==false){
      return (
        <small
          id="username-help"
          className="p-error">
            Phone Number Taken!!!
        </small>
      )
    }else{
      return;
    }
  }


  return (
      <ul  style={{textAlign: 'center'}} className="list-none card grid p-fluid p-0 m-0" >


        <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
            <div
                className="w-full mb-3  md:w-12 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px'}}
            >
                PHONE NUMBER
            </div>
            <div
                className="w-full mb-3 grid  md:w-12 md:flex-order-0 flex-order-1"
                style={{fontSize: '15px'}}
            >
              <div
                  className="w-full mb-3 col-12  md:w-4 md:flex-order-0 flex-order-1"
                  style={{fontSize: '15px'}}
              >
              </div>
              <div
                  className="w-full mb-3 col-12 md:w-4 md:flex-order-0 flex-order-1"
                  style={{fontSize: '15px'}}
              >
                {checking
                  ?<div className="col-12 md:col-3">
                        <Message severity="success" text="Checking..." />
                    </div>
                  :<>
                    {!clear
                      ?<>{displayMessage(errors.unique_pass)}</>
                      :<></>
                    }

                  </>
                }
              </div>
            </div>

            <div className=" w-full md:w-12 md:flex-order-0 flex-order-1">
              <InputText
                  id="phone_number"
                  name="phone_number"
                  className="w-full mb-3"
                  value={phone_number}
                  onChange={(e) => onPhoneNumberInputChange(e,"phone_number")}

              />
            </div>
        </li>
    </ul>


  )
}

const mapStateToProps = state =>({
  phoneValidation: state.validations.phoneNumberValidation,
  loading: state.validations.loading,
})

export default connect(mapStateToProps, {phoneNumberValidation}) (PhoneNumber);

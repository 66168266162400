import axios from 'axios';
import {
    GET_EMPLOYER_JOBS,
    

} from '../types/recruiterTypes';

import {
    employerjobsURL,
} from '../constants';


export const getEmployerJobs = (slug, token) => dispatch => {

    let headers = axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    };
    axios.get(`${employerjobsURL}?slug=${slug}`, headers)
        .then(res => {
            dispatch({
                type: GET_EMPLOYER_JOBS,
                payload: res.data
            });
        }).catch(err => console.log(err))
}

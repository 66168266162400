import React, { useState, useEffect, useRef } from 'react';
import {getRecruiterYearlyJobApplicationReports} from '../../actions/reports';
import { Dropdown } from 'primereact/dropdown';
import {connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Skeleton } from 'primereact/skeleton';
import {getRecruiterJobs} from '../../actions/jobs';
import {
    yearChoices,
} from './data'



const ApplicationReports = props => {
  const initialParams = {
    year: 2022,
    job: '',
  }
  const [params, setParams] = useState(initialParams);
  const dt = useRef(null);
  
  const cols = [
      { field: 'job_title', header: 'TITLE' },
      { field: 'applicant', header: 'APPLICANT' },
      { field: 'status', header: 'STATUS' },
      { field: 'decision', header: 'DECISION' },

  ];
  // const exportColumns = cols.map(col => ({ title: col.header, dataKey: col.field }));
  const {report} =props;
  const {applications} = report;

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  }

  const exportPdf = () => {
      alert('Fuck you')
  }

  const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(applications);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'applications');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }




    const skeletonData = [
        {"key": 1},
        {"key": 2},
        {"key": 3},
        {"key": 4},
        {"key": 5},
        {"key": 6},
        {"key": 7},
        {"key": 8},
        {"key": 9},
        {"key": 10},
        {"key": 11},
        {"key": 12},
        {"key": 13},
        {"key": 14},
        {"key": 15},
        {"key": 16},
    ]



  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _params = {...params};
      _params[`${name}`] = val;
      setParams(_params);
  }


  useEffect(() => {
    props.getRecruiterYearlyJobApplicationReports(params, props.token);
    props.getRecruiterJobs(props.token);
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    props.getRecruiterYearlyJobApplicationReports(params, props.token);
    props.getRecruiterJobs(props.token);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps




    const header = (
      <div className="flex align-items-center export-buttons">
          <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" />
          <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
          <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
          <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" />
      </div>
    );

    let footerGroup = <ColumnGroup>
        <Row>
          <Column footer="STATS" colSpan={4} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', fontSize: '70px'}}/>
         
        </Row>
        <Row>
          <Column footer="REPORT DATE" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.date} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="YEAR" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.year} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="JOB" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.job} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        
        <Row>
          <Column footer="TOTAL APPLICATIONS" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.total_applications} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TOTAL PENDING DECISION APPLICATIONS" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.total_pending_decision_applications} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TOTAL ON INTERVIEW APPLICATIONS" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.total_on_interview_applications} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TOTAL REJECTED APPLICATIONS" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.total_rejected_applications} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        <Row>
          <Column footer="TOTAL ACCEPTED APPLICATIONS" colSpan={3} footerStyle={{textAlign: 'center'}} style={{fontFamily: 'cursive', textAlign: 'center'}}/>
          <Column footer={report.total_rejected_applications} style={{fontFamily: 'cursive', textAlign: 'center'}} />
        </Row>
        
        

    </ColumnGroup>;

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>
  }


  return(
    <>
      <div className="card">
            <ul  style={{textAlign: 'center', fontFamily: 'cursive'}} className="list-none p-fluid grid formgridid p-0 m-0">
              <li className="flex card align-items-center col-12  col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">

                
                  <div
                      className="col-12  md:w-2 md:flex-order-0 flex-order-1"
                      style={{fontSize: '15px', fontFamily: 'cursive'}}
                  >
                      YEAR
                  </div>
                  <div className=" col-12 md:w-4 md:flex-order-0 flex-order-1">
                      <Dropdown
                        value={params.year}
                        optionLabel="key"
                        optionValue="key"
                        options={yearChoices}
                        onChange={(e) => onInputChange(e, 'year')}
                        filter
                        filterBy="key"
                        
                    /> 
                  </div>
                  <div
                      className="col-12  md:w-2 md:flex-order-0 flex-order-1"
                      style={{fontSize: '15px', fontFamily: 'cursive'}}
                  >
                      JOB
                  </div>
                  <div className=" col-12 md:w-4 md:flex-order-0 flex-order-1">
                      <Dropdown
                        value={params.job}
                        optionLabel="title"
                        optionValue="slug"
                        options={props.jobs}
                        onChange={(e) => onInputChange(e, 'job')}
                        filter
                        filterBy="title"
                        showClear
                    /> 
                  </div>
              </li>
            </ul>
      </div>
      {props.loading
          ?<DataTable value={skeletonData} className="p-datatable-striped">
            <Column field="code"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="name"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="category"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
            <Column field="quantity"  style={{ width: '10%' }} body={bodyTemplate} ></Column>
          </DataTable>
          :<div className="card" >
            <h5>Export</h5>

            <Tooltip target=".export-buttons>button" position="bottom" />

            <DataTable 
              ref={dt} 
              value={applications} 
              header={header} 
              footerColumnGroup={footerGroup}
              emptyMessage='No Applications for this Day'
              dataKey="slug" 
              showGridlines
              responsiveLayout="scroll"
                selectionMode="multiple" 
            >
                {
                    cols.map((col, index) => <Column style={{fontFamily: 'cursive', textAlign: 'center'}} key={index} field={col.field} header={col.header} />)
                }
            </DataTable>
          </div>
      }

    </>
  );

};



const mapStateToProps = state =>({
  report: state.reports.recruiterYearlyJobApplicationReports,
  loading: state.reports.loading,
  token: state.auth.token,
  jobs: state.jobs.recruiterjobs,
})

export default connect(mapStateToProps, {getRecruiterYearlyJobApplicationReports, getRecruiterJobs})(ApplicationReports);

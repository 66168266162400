export const GET_JOBSEEKER_EXPERIENCES = "GET_JOBSEEKER_EXPERIENCES";
export const GET_JOBSEEKER_LINKS = "GET_JOBSEEKER_LINKS";
export const GET_JOBSEEKER_LANGUAGES = "GET_JOBSEEKER_LANGUAGES";
export const GET_JOBSEEKER_SKILLS = "GET_JOBSEEKER_SKILLS";
export const GET_JOBSEEKER_JOB_APPLICATIONS = "GET_JOBSEEKER_JOB_APPLICATIONS";
export const GET_JOBSEEKER_RESUME = "GET_JOBSEEKER_RESUME";
export const GET_JOBSEEKER_AVAILABILITY = "GET_JOBSEEKER_AVAILABILITY";
export const GET_JOBSEEKER_ACADEMIC_APPLICATIONS = "GET_JOBSEEKER_ACADEMIC_APPLICATIONS";
export const GET_USERNAMES = 'GET_USERNAMES';
export const GET_EMAILS = 'GET_EMAILS';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
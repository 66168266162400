import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import EmployerLayout from '../layout/EmployerLayout';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { MultiSelect } from 'primereact/multiselect';
import {connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    getCountries,
    getProvinces,
    getDistricts

} from '../../actions/places';
import { getUsers } from '../../actions/auth';
import {addRecruiterJob, getRecruiterJobs} from '../../actions/jobs';
import { getFaculties} from '../../actions/skills';
import { RadioButton } from 'primereact/radiobutton';
import { RiFlag2Fill} from "react-icons/ri";
import { FaUserGraduate} from "react-icons/fa";
import { FiBookOpen } from "react-icons/fi";


import {
    jobFishingExpectedTimelineOptions,
    genderOptions,
    levelOptions

} from './data';




const JobForm = (props) => {
    let requirementInitialData = {
      gender: '',
      age_high: '',
      age_low: ''
    }
    const [requirement, setRequirement] = useState(requirementInitialData);
    const [compensation_type, setCompensationType] = useState('WEEKLY');
    const [location, setLocation] = useState('ONSITE');
    const [fixed_price, setFixedPrice] = useState(0.00);
    const [from_hourly_price, setFromHourlyPrice] = useState(0.00);
    const [to_hourly_price, setToHourlyPrice] = useState(0.00);
    const [duration_category, setDurationCategory] = useState('HOURS');
    const [category, setCategory] = useState('ONE TIME JOB');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [charge_category, setChargeCategory] = useState('FIXED');
    const [expected_duration, setExpectedDuration] = useState(null);
    const [required_skillset, setRequiredSkillset] = useState('EXPERT REQUIRED');
    const [country, setCountry] = useState(null);
    const [location_type, setLocationType] = useState("WORLDWIDE");
    const [cities, setPrefferedCity] = useState([]);
    const [academic_levels, setLevels] = useState([]);
    const [employer, setEmployer] = useState(null);
    const [how_to_apply, setHowToApply] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [qualifications, setQualifications] = useState([]);
    const navigate = useNavigate();
    const [isSubmit, setIsSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const [jobTimelineOptions, setJobTimelineOptions] = useState(jobFishingExpectedTimelineOptions)
    const [countryOptions, setCountryOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);

    const [titleError, setTitleError] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [howToApplyError, setHowToApplyError] = useState('')



    const validate = () => {
      let temp = { ...errors }
      if (title){
        temp.title = title ? "" : "TITLE IS REQUIRED."
      }

      setErrors({
          ...temp
      })


      return Object.values(temp).every(x => x === "")
    }


    useEffect(() =>{

        if(title !== ''){
          setTitleError('')
        }else{
          setTitleError('Title Field is required')
        }

    }, [title])



    useEffect(() =>{
        if(description !== ''){
          setDescriptionError('')
        }else{
          setDescriptionError('Project Description is required')
        }

    }, [description])

    useEffect(() =>{
        if(how_to_apply !== ''){
          setHowToApplyError('')
        }else{
          setHowToApplyError('How to apply is required')
        }

    }, [how_to_apply])




    useEffect(() =>{
        if(!props.fetched){
            props.getCountries(props.token);
            props.getProvinces(props.token);
            props.getDistricts(props.token);
            props.getUsers(props.token);
            props.getFaculties(props.token);
        };


    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onSkillsetChange = (e) => {
      setRequiredSkillset(e.value);
    }

    const onLocationChange = (e) => {
      setLocationType(e.value);
    }


    const getCityOptions = (cities, country) =>{
       return cities.filter((city) => city.country === country);
    }

    useEffect(() =>{
       let citiesCopy = [...props.provinces]
       let cities = getCityOptions(citiesCopy, country);
       setCityOptions(cities);

    }, [country])  // eslint-disable-line react-hooks/exhaustive-deps


    const getCountryChoices = (countries, continent) =>{
      return countries.filter((cn) => cn.continent === continent)
    }


    useEffect(() =>{
        let countriesCopy = [...props.countries];
        let countries = getCountryChoices(countriesCopy, location_type);
        setCountryOptions(countries);
    }, [location_type]) // eslint-disable-line react-hooks/exhaustive-deps

    const onPlaceChange = (e) => {
      setLocation(e.value);
    }

    const onJobDescriptionChange = (e) => {
      setCategory(e.value);
    }

    const onCompensationTypeChange = (e) => {
      setCompensationType(e.value);
    }


    const getJobTimeLines = (category, timelines) =>{
      return timelines.filter((tm) => tm.key.includes(category))
    }


    const onDurationCategoryChange = (e) => {
      setDurationCategory(e.value);
    }

    useEffect(() =>{
      let timelines = getJobTimeLines(duration_category, jobFishingExpectedTimelineOptions)
      setJobTimelineOptions(timelines);

    }, [duration_category]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
      props.getRecruiterJobs(props.token);
      setIsSubmit(false);

    }, [isSubmit]) // eslint-disable-line react-hooks/exhaustive-deps


    const resetForm = () => {
        setCompensationType();
        setLocation();
        setFixedPrice();
        setFromHourlyPrice();
        setToHourlyPrice();
        setDurationCategory();
        setCategory();
        setTitle();
        setDescription();
        setChargeCategory();
        setExpectedDuration();
        setRequiredSkillset();
        setCountry();
        setLocationType();
        setPrefferedCity();
        setEmployer();
        // setQuestions();
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        let job = {
            compensation_type,
            location,
            fixed_price,
            from_hourly_price,
            to_hourly_price,
            duration_category,
            category,
            title,
            description,
            charge_category,
            expected_duration,
            required_skillset,
            country,
            location_type,
            cities,
            employer,
            questions,
            qualifications,
            requirement,
            how_to_apply,
            academic_levels

        }
        if (validate()){
          props.addRecruiterJob(job, props.token);
          console.log(job)
          // resetForm();
          // setIsSubmit(true);
          // props.getRecruiterJobs(props.token);
          // navigate("/Recruiter/jobs", {replace: true });
        }
      }


    const onOptionsChange = (e, func) => {
        func(e.value);
    }


    const onChargeCategoryChange = (e) => {
      setChargeCategory(e.value);
    }


    const onRequirementChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _record = {...requirement};
        _record[`${name}`] = val;
        setRequirement(_record);
    }




    const handleAddQuestion = (e) => {
        e.preventDefault();
        const inputState = {
            id: Math.random(),
            question: ""
        };
        setQuestions((prev) => [...prev, inputState])

    }



    const handleQuestionOnChange = (id, e) =>{
        e.preventDefault();
        e.persist();
        setQuestions((prev) => {
            return prev.map((item) =>{
                if (item.id !== id){
                    return item;
                }

                return {
                    ...item,
                    [e.target.name]: e.target.value
                }

            });

        });

    }

    const handleRemoveField = (e, index) => {
        e.preventDefault();
        setQuestions((prev) => prev.filter((item) => item !== prev[index]));
    };





    const selectedCitiesTemplate = (option) => {
      if (option) {
          return (
              <div className="country-item card country-item-value">
                  <RiFlag2Fill/>
                  <div style={{fontFamily: 'cursive'}} className=''>{option.name}</div>
              </div>
          );
      }
    }

  const cityTemplate = (option) => {
      return (
          <div className="country-item card">
              <RiFlag2Fill/>
              <div style={{fontFamily: 'cursive'}} className=''>{option.name}</div>
          </div>
      );
  }

  const panelFooterTemplate = () => {
      const selectedItems = [...cities];
      const length = selectedItems ? selectedItems.length : 0;
      return (
          <div className="py-2 px-3 card ">
              <b>{length}</b> item{length > 1 ? 's' : ''} selected.
          </div>
      );
  }


    const selectedLevelsTemplate = (option) => {
      if (option) {
          return (
              <div className="country-item card country-item-value">
                  <FaUserGraduate />
                  <div style={{fontFamily: 'cursive'}}  className=''>{option.name}</div>
              </div>
          );
      }
    }

  const levelTemplate = (option) => {
      return (
          <div className="country-item card ">
              <FaUserGraduate  />
              <div style={{fontFamily: 'cursive'}}  className=''>{option.name}</div>
          </div>
      );
  }

  const levelFooterTemplate = () => {
      const selectedItems = [...academic_levels];
      const length = selectedItems ? selectedItems.length : 0;
      return (
          <div className="py-2 px-3 card ">
              <b>{length}</b> item{length > 1 ? 's' : ''} selected.
          </div>
      );
  }

   console.log(qualifications)
   const selectedFacultiesTemplate = (option) => {
    if (option) {
        return (
            <div className="country-item card country-item-value">
                <FiBookOpen />
                <div style={{fontFamily: 'cursive'}}  className=''>{option.title}</div>
            </div>
        );
    }
  }

  const facultyTemplate = (option) => {
      return (
          <div className="country-item card ">
              <FiBookOpen  />
              <div style={{fontFamily: 'cursive'}}  className=''>{option.title}</div>
          </div>
      );
  }

  const facultyFooterTemplate = () => {
      const selectedItems = [...qualifications];
      const length = selectedItems ? selectedItems.length : 0;
      return (
          <div className="py-2 px-3 card ">
              <b>{length}</b> item{length > 1 ? 's' : ''} selected.
          </div>
      );
  }

    return (
        <EmployerLayout>
            <div className="cards" style={{textAlign: "center",fontFamily: 'cursive'}}>
              <div className="surface-0  text-center">
                <div className="text-blue-600 font-bold mb-3"><i className="pi pi-heart"></i>&nbsp;POWERED BY HLETIPANG</div>
                <div className="text-900 font-bold text-5xl mb-3">ADD YOUR JOB</div>
                <div
                  className=" text-2xl mb-5"
                  style={{fontSize: '10px'}}
                >GAIN ACCESS TO EMPLOY ONE OF THE BRILLIANT MINDS WITH HLETIPANG </div>

              </div>

                <form onSubmit={handleSubmit}>

                    <div className="grid p-fluid">

                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                WHATS YOUR JOB TITLE ?
                              </div>
                              <span className="p-float-label p-input-icon-right">

                                  <i className="pi pi-spin pi-spinner" />
                                  <InputText
                                      id="name"
                                      value={title}
                                      onChange={(e) => setTitle(e.target.value)}
                                      required
                                  />
                              </span>
                              <small  style={{paddingBottom: '30px'}} id="username2-help" className="p-error block">
                                {titleError}
                              </small>
                        </div>
                        <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                DESCRIBE YOUR PROJECT
                              </div>
                              <span className="p-float-label p-input-icon-right">

                                  <i className="pi pi-spin pi-spinner" />
                                    <InputTextarea
                                        rows={2}
                                        cols={30}
                                        autoResize
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    />
                              </span>
                              <small  style={{paddingBottom: '30px'}} id="username2-help" className="p-error block">{descriptionError}</small>
                        </div>
                        <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                HOW WOULD YOU WANT CANDIDATES TO APPLY
                              </div>
                              <span className="p-float-label p-input-icon-right">

                                  <i className="pi pi-spin pi-spinner" />
                                    <InputTextarea
                                        rows={2}
                                        cols={30}
                                        autoResize
                                        value={how_to_apply}
                                        onChange={(e) => setHowToApply(e.target.value)}
                                    />
                              </span>
                              <small  style={{paddingBottom: '30px'}} id="username2-help" className="p-error block">
                                {howToApplyError}
                              </small>
                        </div>

                      </div>
                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className=" ">
                            <div className="col-12 md:col-12">
                              <div className="text-400 text-2xl mb-5">
                                WOULD YOU WANT YOUR JOB TO BE DONE REMOTE OR ONSITE ?
                              </div>
                              <div className="formgrid grid" style={{padding: '30px'}}>
                                <div className="field-radiobutton col-3">
                                </div>
                                <div className="field-radiobutton col-3">
                                    <RadioButton
                                        inputId="category1"
                                        name="category"
                                        value="ONSITE"
                                        onChange={onPlaceChange}
                                        checked={location === "ONSITE"}
                                      />
                                    <label icon='pi pi-spinner' className="" htmlFor="category1">ONSITE<i className="pi pi-spin pi-spinner" style={{'fontSize': '1em'}}></i></label>
                                </div>
                                <div className="field-radiobutton col-3">
                                    <RadioButton
                                        inputId="category2"
                                        name="category"
                                        value="REMOTE"
                                        onChange={onPlaceChange}
                                        checked={location === "REMOTE"}
                                      />
                                    <label className="" htmlFor="category2">REMOTE</label>
                                </div>
                                <div className="field-radiobutton col-3">
                                </div>

                            </div>
                            </div>
                            <div className="col-12 md:col-12">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                HOW WOULD YOU DESCRIBE YOUR JOB?
                              </div>
                              <div style={{padding: '30px'}}>
                                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                                  <li className="flex align-items-center py-3 px-2 border-300 flex-wrap grid fluid">
                                    <div className=" col-12 md:w-3 font-medium">
                                    <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="ONE TIME JOB"
                                            onChange={onJobDescriptionChange}
                                            checked={category === "ONE TIME JOB"}
                                          />
                                        <label className="" htmlFor="category1">ONE TIME</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="PERMANENT JOB"
                                            onChange={onJobDescriptionChange}
                                            checked={category === "PERMANENT JOB"}
                                          />
                                        <label className="" htmlFor="category1">PERMANENT</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category2"
                                            name="category"
                                            value="CONTRACTUAL JOB"
                                            onChange={onJobDescriptionChange}
                                            checked={category === "CONTRACTUAL JOB"}
                                          />
                                        <label className="" htmlFor="category2">CONTRACTUAL</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="INTERNSHIP JOB"
                                            onChange={onJobDescriptionChange}
                                            checked={category === "INTERNSHIP JOB"}
                                          />
                                        <label className="" htmlFor="category1">INTERNSHIP</label>
                                    </div>
                                  </li>
                                </ul>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                HOW WOULD YOU WANT TO COMPENSATE YOUR EMPLOYEE ?
                              </div>
                              <div className="formgrid grid">
                                  <div className="field-radiobutton col-4 ">
                                      <RadioButton
                                          inputId="category1"
                                          name="category"
                                          value="WEEKLY"
                                          onChange={onCompensationTypeChange}
                                          checked={compensation_type === "WEEKLY"}
                                        />
                                      <label className="" htmlFor="category1">WEEKLY</label>
                                  </div>
                                  <div className="field-radiobutton col-4">
                                      <RadioButton
                                          inputId="category2"
                                          name="category"
                                          value="MONTHLY"
                                          onChange={onCompensationTypeChange}
                                          checked={compensation_type === "MONTHLY"}
                                        />
                                      <label className="" htmlFor="category2">MONTHLY</label>
                                  </div>
                                  <div className="field-radiobutton col-4">
                                      <RadioButton
                                          inputId="category2"
                                          name="category"
                                          value="JOB END"
                                          onChange={onCompensationTypeChange}
                                          checked={compensation_type === "JOB END"}
                                        />
                                      <label className="" htmlFor="category2">JOB END</label>
                                  </div>


                              </div>
                        </div>
                        <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                WHAT CALIBRE & EXPERIENCE WOULD YOU BE PRIORITIZING ?
                              </div>
                              <ul  className="list-none grid p-fluid p-0 m-0" style={{textAlign: 'center'}}>
                                <li className="flex card align-items-center col-12 md:col-12 py-3 px-2 border-top-1 surface-border flex-wrap">
                                  <div className="field-radiobutton w-full md:w-4 md:flex-order-0 flex-order-1 ">
                                      <RadioButton
                                          inputId="category1"
                                          name="category"
                                          value="EXPERT REQUIRED"
                                          onChange={onSkillsetChange}
                                          checked={required_skillset === "EXPERT REQUIRED"}
                                        />
                                      <label className="" htmlFor="category1">EXPERT</label>
                                  </div>
                                  <div className="field-radiobutton w-full md:w-4 md:flex-order-0 flex-order-1">
                                      <RadioButton
                                          inputId="category2"
                                          name="category"
                                          value="LEARNER REQUIRED"
                                          onChange={onSkillsetChange}
                                          checked={required_skillset === "LEARNER REQUIRED"}
                                        />
                                      <label className="" htmlFor="category2">LEARNER</label>
                                  </div>
                                  <div className="field-radiobutton w-full md:w-4 md:flex-order-0 flex-order-1">
                                      <RadioButton
                                          inputId="category2"
                                          name="category"
                                          value="INTERMIDIATE SKILLS REQUIRED"
                                          onChange={onSkillsetChange}
                                          checked={required_skillset === "INTERMIDIATE SKILLS REQUIRED"}
                                        />
                                      <label className="" htmlFor="category2">INTERMIDIATE SKILLS</label>
                                  </div>

                                </li>
                              </ul>
                        </div>

                      </div>


                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12 "
                        style={{padding: "30px"}}
                        >
                        <div className=" ">
                        <div className="field">
                            <div
                                className=""
                                style={{fontSize:'15px', paddingBottom: "30px"}}
                            >
                                SET THE DURATION TIMELINE CATEGORY FOR YOUR PROJECT
                            </div>
                                <div className="formgrid grid">
                                    <div className="field-radiobutton col-3 ">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="HOURS"
                                            onChange={onDurationCategoryChange}
                                            checked={duration_category === "HOURS"}
                                          />
                                        <label className="" htmlFor="category1">HOURS</label>
                                    </div>
                                    <div className="field-radiobutton col-3">
                                        <RadioButton
                                            inputId="category2"
                                            name="category"
                                            value="DAYS"
                                            onChange={onDurationCategoryChange}
                                            checked={duration_category === "DAYS"}
                                          />
                                        <label className="" htmlFor="category2">DAYS</label>
                                    </div>
                                    <div className="field-radiobutton col-3">
                                        <RadioButton
                                            inputId="category2"
                                            name="category"
                                            value="MONTHS"
                                            onChange={onDurationCategoryChange}
                                            checked={duration_category === "MONTHS"}
                                          />
                                        <label className="" htmlFor="category2">MONTHS</label>
                                    </div>
                                    <div className="field-radiobutton col-3">
                                        <RadioButton
                                            inputId="category2"
                                            name="category"
                                            value="YEARS"
                                            onChange={onDurationCategoryChange}
                                            checked={duration_category === "YEARS"}
                                          />
                                        <label className="" htmlFor="category2">YEARS</label>
                                    </div>

                                </div>
                            </div>
                            <div
                              className=""
                              style={{fontSize:'15px', paddingBottom: "30px"}}
                            >
                                HOW LONG LONG EXACTLY ?
                              </div>
                              <Dropdown
                                  optionLabel="key"
                                  optionValue="key"
                                  value={expected_duration}
                                  onChange={(e) => onOptionsChange(e, setExpectedDuration)}
                                  options={jobTimelineOptions}
                                  filter
                                  showClear
                                  filterBy="key"

                              />

                        </div>

                      </div>

                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                          <div className="field">
                            <div
                                className=""
                                style={{fontSize:'15px', paddingBottom: "30px"}}
                            >
                                HOW WOULD YOU WANT TO BE CHARGED?
                            </div>
                            <div className="formgrid grid">
                                <div className="field-radiobutton col-3">
                                </div>
                                <div className="field-radiobutton col-3">
                                    <RadioButton
                                        inputId="category1"
                                        name="category"
                                        value="FIXED"
                                        onChange={onChargeCategoryChange}
                                        checked={charge_category === "FIXED"}
                                      />
                                    <label className="" htmlFor="category1">FIXED</label>
                                </div>
                                <div className="field-radiobutton col-3">
                                    <RadioButton
                                        inputId="category2"
                                        name="category"
                                        value="PER HOUR"
                                        onChange={onChargeCategoryChange}
                                        checked={charge_category === "PER HOUR"}
                                      />
                                    <label className="" htmlFor="category2">PER HOUR</label>
                                </div>
                                <div className="field-radiobutton col-3">
                                </div>

                            </div>
                        </div>
                        {charge_category === 'FIXED'


                          ?<div className=" ">
                                <div
                                  className=" text-2xl mb-5"
                                  style={{fontSize: '10px'}}
                                >
                                  WHAT WOULD BE YOUR FIXED BUDGET ?
                                </div>
                                <InputNumber
                                  inputId="horizontal"
                                  showButtons
                                  buttonLayout="horizontal"
                                  step={0.25}
                                  decrementButtonClassName="p-button-danger"
                                  incrementButtonClassName="p-button-success"
                                  incrementButtonIcon="pi pi-plus"
                                  decrementButtonIcon="pi pi-minus"
                                  mode="currency"
                                  currency="USD"
                                  value={fixed_price}
                                  onChange={(e) => setFixedPrice(e.value)}
                              />

                          </div>

                          :<>

                            <div className=" ">
                                  <div
                                    className=" text-2xl mb-5"
                                    style={{fontSize: '10px'}}
                                  >
                                    FROM THOSE THAT CHARGE HOURLY FROM WHICH AMOUNT WOULD YOU START CONSIDERING?
                                  </div>
                                  <InputNumber
                                      inputId="horizontal"
                                      showButtons
                                      buttonLayout="horizontal"
                                      step={0.25}
                                      decrementButtonClassName="p-button-danger"
                                      incrementButtonClassName="p-button-success"
                                      incrementButtonIcon="pi pi-plus"
                                      decrementButtonIcon="pi pi-minus"
                                      mode="currency"
                                      currency="USD"
                                      value={from_hourly_price}
                                      onChange={(e) => setFromHourlyPrice(e.value)}
                                  />
                            </div>
                            <div className=" ">
                                  <div
                                    className=" text-2xl mb-5"
                                    style={{fontSize: '10px'}}
                                  >
                                    FROM THOSE THAT CHARGE HOURLY TO WHICH AMOUNT WOULD YOU STOP CONSIDERING?
                                  </div>
                                  <InputNumber
                                      inputId="horizontal"
                                      showButtons
                                      buttonLayout="horizontal"
                                      step={0.25}
                                      decrementButtonClassName="p-button-danger"
                                      incrementButtonClassName="p-button-success"
                                      incrementButtonIcon="pi pi-plus"
                                      decrementButtonIcon="pi pi-minus"
                                      mode="currency"
                                      currency="USD"
                                      value={to_hourly_price}
                                      onChange={(e) => setToHourlyPrice(e.value)}
                                  />
                              </div>
                          </>
                      }
                      </div>


                      <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                        >
                        <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                WHERE WOULD YOU WANT YOUR EMPLOYEES TO COME FROM ?
                              </div>
                              <div style={{paddingBottom: '30px'}}>
                                <ul  className="list-none col-12 md:col-12 p-0 m-0" style={{textAlign: 'center'}}>
                                  <li className="flex align-items-center py-3 px-2 border-300 flex-wrap grid fluid">
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="WORLDWIDE"
                                            onChange={onLocationChange}
                                            checked={location_type === "WORLDWIDE"}
                                          />
                                        <label className="" htmlFor="category1">WORLDWIDE</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="AFRICA"
                                            onChange={onLocationChange}
                                            checked={location_type === "AFRICA"}
                                          />
                                        <label className="" htmlFor="category1">AFRICA</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category2"
                                            name="category"
                                            value="EUROPE"
                                            onChange={onLocationChange}
                                            checked={location_type === "EUROPE"}
                                          />
                                        <label className="" htmlFor="category2">EUROPE</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="NORTH AMERICA"
                                            onChange={onLocationChange}
                                            checked={location_type === "NORTH AMERICA"}
                                          />
                                        <label className="" htmlFor="category1">NORTH AMERICA</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="SOUTH AMERICA"
                                            onChange={onLocationChange}
                                            checked={location_type === "SOUTH AMERICA"}
                                          />
                                        <label className="" htmlFor="category1">SOUTH AMERICA</label>
                                    </div>
                                    <div className=" col-12 md:w-3 font-medium">
                                        <RadioButton
                                            inputId="category1"
                                            name="category"
                                            value="ASIA"
                                            onChange={onLocationChange}
                                            checked={location_type === "ASIA"}
                                          />
                                        <label className="" htmlFor="category1">ASIA</label>
                                    </div>
                                </li>
                              </ul>
                            </div>

                        </div>
                        {location_type === 'WORLDWIDE'
                          ?<></>
                          :<>
                            <div className=" ">
                                <div
                                  className=" text-2xl mb-5"
                                  style={{fontSize: '10px'}}
                                >
                                  COUNTRY WHERE YOUR PROJECT IS LOCATED ?
                                </div>
                                <Dropdown
                                    optionLabel="name"
                                    optionValue="name"
                                    value={country}
                                    onChange={(e) => onOptionsChange(e, setCountry)}
                                    options={countryOptions}
                                    filter
                                    showClear
                                    filterBy="name"
                                    placeholder='COUNTRY'
                                />
                          </div>
                          <div className=" ">
                              <div
                                className=" text-2xl mb-5"
                                style={{fontSize: '10px'}}
                              >
                                SELECT CITIES WHERE YOU WOULD PREFER YOUR WORKFORCE TO COME FROM
                              </div>
                              <MultiSelect
                                value={cities}
                                options={cityOptions}
                                optionLabel="name"

                                filter
                                className="multiselect-custom"
                                itemTemplate={cityTemplate}
                                selectedItemTemplate={selectedCitiesTemplate}
                                onChange={(e) => onOptionsChange(e, setPrefferedCity)}
                                panelFooterTemplate={panelFooterTemplate}
                              />
                        </div>

                      </>
                    }

                    </div>
                    <div
                        className="surface-section card px-4 py-8 col-12 md:col-12"
                        style={{padding: "30px"}}
                    >
                      <div
                          className=" text-2xl mb-5"
                          style={{fontSize: '10px'}}
                        >
                          SELECT THE PROFFESSIONS YOU MIGHT EXPECT YOUR APPLICANTS TO COME FROM
                        </div>
                        <MultiSelect
                          value={qualifications}
                          options={props.qualifications}
                          optionLabel="name"
                          filter
                          className="multiselect-custom"
                          itemTemplate={facultyTemplate}
                          selectedItemTemplate={selectedFacultiesTemplate}
                          onChange={(e) => onOptionsChange(e, setQualifications)}
                          panelFooterTemplate={facultyFooterTemplate}
                        />



                      <div
                          className=" text-2xl mb-5"
                          style={{fontSize: '10px'}}
                        >
                          SELECT THE ACADEMIC QUALIFICATION LEVELS  YOU MAY CONSIDER
                        </div>
                          <MultiSelect
                            value={academic_levels}
                            options={levelOptions}
                            optionLabel="name"
                            filter
                            className="multiselect-custom"
                            itemTemplate={levelTemplate}
                            selectedItemTemplate={selectedLevelsTemplate}
                            onChange={(e) => onOptionsChange(e, setLevels)}
                            panelFooterTemplate={levelFooterTemplate}
                          />


                    </div>

                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12 "
                      style={{padding: "30px"}}
                      >
                      <div className=" grid p-fluid">
                            <div className=" text-2xl md:col-12">
                              <span style={{fontSize: '10px'}}>ENTER THE REQUIREMENTS BELOW IN ORDER TO HELP THE SYSTEM MATCH YOU WITH RIGHT PEOPLE FOR THE JOB</span>
                            </div>
                            <div className="col-12 md:col-6">
                                <Dropdown
                                    optionLabel="key"
                                    optionValue="key"
                                    name="gender"
                                    value={requirement.gender}
                                    placeholder="GENDER"
                                    onChange={(e)=> onRequirementChange(e, 'gender')}
                                    options={genderOptions}
                                    filter
                                    showClear
                                    filterBy="key"
                                    required
                                />

                            </div>
                            <div className="col-12 md:col-3">
                                <InputText
                                    id="age_high"
                                    required
                                    value={requirement.age_high}
                                    placeholder="AGE HIGH"
                                    type='number'
                                    onChange={(e)=> onRequirementChange(e, 'age_high')}

                                />
                            </div>
                            <div className="col-12 md:col-3">
                                <InputText
                                      id="age_low"
                                      required
                                      value={requirement.age_low}
                                      placeholder="AGE LOW"
                                      type='number'
                                      onChange={(e)=> onRequirementChange(e, 'age_low')}

                                  />
                            </div>
                      </div>



                    </div>
                    <div
                      className="surface-section card px-4 py-8 col-12 md:col-12"
                      style={{padding: "30px"}}
                      >
                      <div className=" ">
                            <div
                              className=" text-2xl mb-5"
                              style={{fontSize: '10px'}}
                            >
                              WHAT QUESTIONS WOULD YOU WANT THE APPLICANTS TO SATISFY
                            </div>
                            <div >
                                {
                                    questions.map((item, i)=>{
                                    return(

                                        <div className="grid p-fluid" key={item.id}>

                                        <div className="col-12 md:col-11">
                                            <span className="p-float-label p-input-icon-right">
                                                <i className="pi pi-spin pi-spinner" />
                                                <InputTextarea
                                                    rows={2}
                                                    cols={30}
                                                    autoResize
                                                    name="question"
                                                    value={item.question}
                                                    placeholder="QUESTION"
                                                    onChange={(e)=> handleQuestionOnChange(item.id, e)}

                                                />
                                            </span>
                                        </div>


                                        <div className="col-12 md:col-1">

                                            <Button
                                                className="p-button-rounded p-button-danger p-button-outlined"
                                                icon = "pi pi-trash"
                                                onClick={(e) => handleRemoveField(e, i)}
                                            />

                                        </div>
                                    </div>
                                    );
                                } )}
                                <div className="col-12 md:col-1">
                                    <Button
                                        className="p-button-rounded p-button-outlined"
                                        onClick={ handleAddQuestion}
                                        icon = "pi pi-plus"
                                    />
                                </div>
                            </div>
                      </div>


                    </div>
                      <div className="col-12 md:col-4">
                          <Button
                              type="submit"
                              label="Submit"
                              className="mt-2"

                          />
                          <label htmlFor="horizontal"></label>
                      </div>
                    </div>
                </form>
            </div>
        </EmployerLayout>
    );
}


const mapStateToProps = state =>({
    token: state.auth.token,
    countries: state.places.countries,
    provinces: state.places.provinces,
    districts: state.places.districts,
    users: state.auth.people,
    skills: state.skills.skills,
    qualifications: state.skills.faculties,
  })

export default connect(
  mapStateToProps,
  {

      getCountries,
      getProvinces,
      getDistricts,
      getUsers,
      addRecruiterJob,
      getRecruiterJobs,
      getFaculties
  } )
(JobForm);

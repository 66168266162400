import React, {useEffect} from 'react';
import feat1 from './images/feat1.svg';
import feat2 from './images/feat2.svg';
import feat3 from './images/feat3.svg';
import feat4 from './images/feat4.svg';
import {connect} from 'react-redux';
import {
    getFaculties,

} from '../../actions/skills';
import { useNavigate } from 'react-router-dom';
import EmployerLayout from '../layout/EmployerLayout';
import feature2 from './images/feature.png';
import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { Avatar } from 'primereact/avatar';


const Industries = (props) =>{
  const navigate = useNavigate();

  const shuffle =(array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
}

const generateRandomPicture = (numberOfPictures) =>{
    const result = [];
    const sequence = [
        feat1,
        feat2,
        feat3,
        feat4,

    ];

    for(let i = 1; i < numberOfPictures; i++){
        result.push(shuffle(sequence))
    }

    return(result[0][0]);
  }


  const handleClick = (slug) =>{
      navigate(`/employ/industries/${slug}`)
  }

  useEffect(() =>{
      props.getFaculties(null);
  }, [])

  return(
    <EmployerLayout>

      <div className="card">
        <div className="grid" style={{fontFamily: 'cursive', textAlign: 'center'}}>
            <div className="col-12">
                    <div className="card">
                        <h5 className='text-primary'>
                          VIEW OUR AMAZING TALENT
                        </h5>
                        <p className='text-primary'>
                          THESE ARE THE INDUSTRIES WE ARE CURRENTLY ALLOWING PROSPECTIVE CANDIDATES TO
                          REGISTER & THESE CANDIDATES ARE VETTED BASED ON CERTAIN ASSESSMENTS, UPON VIEWING
                          EACH INDUSTRY YOU GET TO SEE THE TOP MATCHED CANDIDATES FOR THE SELECTED INDUSTRY.
                          THE NEAREST NEIGHBOURS IS A LIST OF BEST MATCHES & THOSE THAT ARE MATCHED BASED ON
                          THEIR INDUSTRIAL SKILLS & QUALIFICATIIONS BEING AT LEAST CLOSER TO THE INDUSTRIAL
                          REQUIREMENTS
                        </p>
                    </div>
             </div>
        </div>
      </div>

      <div className="card">
          <div className="grid p-fluid flex-grow-1" style={{fontFamily: 'cursive', textAlign: 'center', padding: '30px'}}>
            {props.records.map((rec) =>{

              return(
                <div className="col-12 md:col-4" onClick={() => handleClick(rec.slug)}>
                    <div className="p-3">
                        <div className="surface-100 cursor-pointer z-index border-round">
                            <div className="relative">

                            </div>
                            <div className="p-3">
                                <div className="text-900 font-semibold text-xl mb-3">{rec.title}</div>
                            </div>
                        </div>
                    </div>
                </div>

              );

              })

            }
          </div>
        </div>
    </EmployerLayout>
  );

}

const mapStateToProps = state =>({
    records: state.skills.faculties,
})


export default connect(mapStateToProps, {getFaculties}) (Industries);
